<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useNavigationPanelStore } from '../../stores/navigationPanel';
import RepoFilesTableGET from '../fileRepositories/RepoFilesTableGET.vue';
import { navItems } from '../../helpers/navigationPanelHelper';

const props = defineProps({
  orderId: null,
  actionId: null,
  actionTypeName: null,
  orderNumber: null
});

const router = useRouter();
const actionsResourcesKey = ref(0);

function goToOrderDetails() {
  if (props.orderId == null) return;

  router.push({ name: 'orderDetails', params: { id: props.orderId } });
}

useNavigationPanelStore().setNavigationPanelItems(navItems.SINGLE_ACTION_GALLERY);
</script>

<template>
  <div class="ibox">
    <div class="ibox-content">
      <h3 class="mt-2 mb-4">
        Galeria akcji <i>{{ props.actionTypeName }}</i> ze zlecenia
        <a @click="goToOrderDetails()" :class="'text-navy'">
          {{ props.orderNumber }}
        </a>
      </h3>

      <RepoFilesTableGET
        :key="actionsResourcesKey"
        modalId="modalImageContainer2"
        :url="`action/${actionId}/resources`"
        :showBorder="false"
        :longerSearchBar="true"
        :deletingEnabled="true"
        @fileDeleted="actionsResourcesKey++"
      />
    </div>
  </div>
</template>

<style></style>
