<script setup>
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { PAGINATION_LIMIT } from '../../data/constants/appConstants.js';
import ServerErrorPanel from '@/components/utils/ServerErrorPanel.vue';
import TableElementsInfo from '@/components/utils/TableElementsInfo.vue';
import { usePaginationStore } from '@/stores/storePagination';
import { Bootstrap4Pagination } from 'laravel-vue-pagination';
import { PLACES_TABLE_FOR_MAP_PAGINATION_KEY } from '../../data/paginationInjectionKeys';

const RepositoryActions = RepositoryFactory.get('actions');

const props = defineProps({
  selectedPlacesIds: { type: Array, default: () => [] }
});

const router = useRouter();
const isLoading = ref(false);
const placesObject = ref({});
const paginationStore = usePaginationStore();

watch(
  () => props.selectedPlacesIds,
  () => fetchData(),
  { immediate: true }
);

function fetchData(page = 1) {
  isLoading.value = true;

  RepositoryActions.getSummaryTableForTransportPoints(
    page,
    paginationStore.getPaginationItemsCount(PLACES_TABLE_FOR_MAP_PAGINATION_KEY),
    props.selectedPlacesIds
  )
    .then((data) => {
      placesObject.value = data;
    })
    .catch((error) => console.log(error))
    .finally(() => {
      isLoading.value = false;
    });
}

function showTransportOrderDetails(place) {
  const transportOrderId = place?.id_order_transport;

  let routeData = router.resolve({
    name: 'transportOrderDetails',
    params: { id: transportOrderId }
  });
  window.open(routeData.href, '_blank');
}
</script>

<template>
  <div class="ibox">
    <div class="ibox-content p-0" :class="{ 'sk-loading': isLoading }" style="border-style: none">
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <h3>Zaznaczone punkty</h3>

      <TableElementsInfo :metaObject="placesObject?.meta ?? null" />

      <div v-if="placesObject?.data && placesObject.data.length > 0" class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Nr zlecenia transportowego</th>
              <th>Notatka</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="place in placesObject.data" :key="place.id_action" style="cursor: pointer">
              <td style="white-space: nowrap">
                {{ place?.order_number ?? '-' }}
              </td>
              <td style="white-space: nowrap">
                <img
                  v-if="place?.note != null"
                  src="/static/img/markerIcons/exclamation_mark_circle_icon.png"
                  alt="Notatka"
                  style="width: 22px; height: 22px; padding-right: 0px"
                />
                <span v-else>-</span>
              </td>

              <td style="white-space: nowrap">
                <div class="float-right">
                  <button
                    v-if="place?.id_order_transport != null"
                    class="btn btn-outline btn-primary btn-xs mr-2"
                    type="button"
                    @click="showTransportOrderDetails(place)"
                    onclick="event.stopPropagation()"
                  >
                    Pokaż zlecenie
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-else-if="placesObject?.error">
        <ServerErrorPanel @onRetry="fetchData" />
      </div>

      <div v-else>
        <p class="text-center mt-3">Brak elementów do wyświetlenia</p>
      </div>

      <div class="row mt-3" style="justify-content: center">
        <Bootstrap4Pagination
          v-if="placesObject?.data"
          align="center"
          :data="placesObject"
          :limit="PAGINATION_LIMIT"
          @pagination-change-page="fetchData"
        ></Bootstrap4Pagination>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
