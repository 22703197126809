import { ref } from 'vue';
import { getCurrentDate } from '@/helpers/utilsHelper.js';
import { FILE_MAX_SIZE } from '../data/constants/appConstants';
import { createToaster } from '@meforma/vue-toaster';
import { isNotEmptyArray } from '../helpers/utilsHelper';

export function useFilesHandler(multipleFilesMode, fileInputFieldId) {
  const FILE_TOO_LARGE = `Zbyt duży rozmiar pliku<br/> (maks. ${FILE_MAX_SIZE} MB)`;
  const toaster = createToaster({ position: 'top-right', duration: 3000 });
  const filesRef = ref([]);
  const showFileInput = ref(true);

  function onFilesChanged(event) {
    let files = event.target.files || event.dataTransfer.files;
    filesRef.value = [];

    if (files.length < 1) return;

    let approvedFiles = new DataTransfer();

    const filesMaxCount = multipleFilesMode ? files.length : 1;

    for (let i = 0; i < filesMaxCount; i++) {
      let fileSize = files[i].size / 1024 / 1024;

      if (fileSize > FILE_MAX_SIZE) {
        toaster.show(FILE_TOO_LARGE, { type: 'warning' });
      } else {
        approvedFiles.items.add(files[i]);
        filesRef.value.push({ id: getNewIdForFile(), file: files[i] });
      }
    }

    let fileInput = document.getElementById(fileInputFieldId);

    if (fileInput) {
      fileInput.files = approvedFiles.files;
    }
  }

  function onDropAreaFilesChanged(files) {
    filesRef.value = [];
    let approvedFiles = new DataTransfer();
    let fileInput = document.getElementById(fileInputFieldId);

    const filesMaxCount = multipleFilesMode ? files.length : 1;

    for (let i = 0; i < filesMaxCount; i++) {
      let fileSize = files[i].size / 1024 / 1024;

      if (fileSize > FILE_MAX_SIZE) {
        toaster.show(FILE_TOO_LARGE, { type: 'warning' });
      } else {
        approvedFiles.items.add(files[i]);
        filesRef.value.push({ id: getNewIdForFile(), file: files[i] });
      }
    }

    if (fileInput) {
      fileInput.files = approvedFiles.files;
    }
  }

  function getNewIdForFile() {
    if (!isNotEmptyArray(filesRef.value)) return 0;

    let ids = filesRef.value.map((x) => x.id);
    let max = Math.max(...ids);

    return max + 1;
  }

  function getCreationDate() {
    return getCurrentDate(); //TODO maybe delete from here
  }

  function clearFileInput() {
    let fileInput = document.getElementById(fileInputFieldId);
    if (fileInput) fileInput.value = '';
  }

  function refreshFileInput() {
    let files = new DataTransfer();

    for (let i = 0; i < filesRef.value.length; i++) {
      files.items.add(filesRef.value[i].file);
    }

    let fileInput = document.getElementById(fileInputFieldId);

    if (fileInput) {
      fileInput.files = files.files;
    }
  }

  return {
    filesRef,
    showFileInput,
    onFilesChanged,
    onDropAreaFilesChanged,
    getCreationDate,
    clearFileInput,
    refreshFileInput
  };
}
