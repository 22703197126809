<script setup>
import { ref } from 'vue';
import { useModalTables } from '@/composables/modalTables.js';
import ModalBase from '@/components/utils/ModalBase.vue';
import ModalBody from '@/components/utils/ModalBody.vue';

const props = defineProps({
  showCloseButton: { type: Boolean, default: false },
  overrideCloseButtonText: { type: String, default: null }
});
const emit = defineEmits(['onModalCompleted', 'onModalRejected']);

const modalBase = ref(null);
const { modal, showModal } = useModalTables(modalBase);

function hide() {
  modalBase.value.hide();
}

defineExpose({ hide, modal, showModal });
</script>

<template>
  <ModalBase ref="modalBase">
    <ModalBody>
      <template v-slot:title>
        {{ modal.title }}
      </template>
      <template v-slot:body>
        <component
          :is="modal.component"
          @on-completed="
            (event, event2) => emit('onModalCompleted', event, event2, modal.properties)
          "
          :key="modal.key"
          v-bind="modal.properties"
        ></component>
      </template>
      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-outline btn-danger"
          @click="emit('onModalRejected', modal.properties)"
        >
          Usuń wybór
        </button>
        <button
          v-if="props.showCloseButton"
          type="button"
          class="btn btn-outline btn-info"
          @click="hide"
        >
          {{ props.overrideCloseButtonText ?? 'Zamknij' }}
        </button>
      </template>
    </ModalBody>
  </ModalBase>
</template>

<style scoped></style>
