<script setup>
import { ref, computed, onMounted } from 'vue';
/* eslint-disable no-undef */
import { MODAL_USER_TYPES_TABLE } from '@/data/constants/modalConstants.js';
import ModalInputField from '@/components/utils/ModalInputField.vue';
import ModalTable from '@/components/utils/ModalTable.vue';

const props = defineProps({
  longerSearchBar: { type: Boolean, default: false },
  userFilterEnabled: { type: Boolean, default: true },
  hideSearchBar: { type: Boolean, default: false },
  oneFilterPerRow: { type: Boolean, default: false }
});

const emit = defineEmits(['fetchData']);

const modalTable = ref();
const searchBox = ref('');
const filterPanel = ref({ userType: null, active: null });
const filterPanelDisplay = ref({ userTypeDisplayName: null });
const appliedFilter = ref(new Object());

const filtersCount = computed(() => {
  let count = 0;

  for (const property in appliedFilter.value) {
    if (appliedFilter.value[property] != null) count++;
  }

  return count;
});

const userTypeApplied = computed(() => {
  return filterApplied(filterPanel.value?.userType?.id, appliedFilter.value?.userType?.id);
});

const activeApplied = computed(() => {
  return filterApplied(filterPanel.value?.active, appliedFilter.value?.active);
});

onMounted(() => {
  configureCollapsingFilterPanel();
});

function configureCollapsingFilterPanel() {
  $('.collapse-link-modified')
    .off('click')
    .on('click', function (e) {
      e.preventDefault();
      var ibox = $(this).closest('div.ibox');
      var button = $(this).find('i');
      var content = ibox.children('.ibox-content');
      content.slideToggle(200);
      button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
      setTimeout(function () {
        ibox.resize();
        ibox.find('[id^=map-]').resize();
      }, 50);
    });
}

function onModalCompleted(data) {
  modalTable.value.hide();

  switch (modalTable.value.modal.type) {
    case MODAL_USER_TYPES_TABLE:
      filterPanel.value.userType = data;
      filterPanelDisplay.value.userTypeDisplayName = data?.name ?? '';
      break;
  }
}

function onModalRejected() {
  modalTable.value.hide();

  switch (modalTable.value.modal.type) {
    case MODAL_USER_TYPES_TABLE:
      filterPanel.value.userType = null;
      filterPanelDisplay.value.userTypeDisplayName = null;
      break;
  }
}

function onClearAllFiltersButtonClicked() {
  for (const property in filterPanel.value) {
    filterPanel.value[property] = null;
  }

  for (const property in filterPanelDisplay.value) {
    filterPanelDisplay.value[property] = null;
  }

  triggerFetchData();
}

function onApplyFiltersButtonClicked() {
  triggerFetchData();
}

function getUserFilter() {
  let userFilter = new Object();

  if (filterPanel.value?.userType?.id != null)
    userFilter.id_user_type = filterPanel.value.userType.id;

  if (filterPanel.value?.active != null) userFilter.active = filterPanel.value.active ? 1 : 0;

  return userFilter;
}

function updateAppliedFilters() {
  appliedFilter.value = Object.assign({}, filterPanel.value);
}

function filterApplied(filterPanelProp, appliedFilterProp) {
  if (appliedFilterProp != null && filterPanelProp != null)
    return appliedFilterProp === filterPanelProp;
  else return appliedFilterProp == filterPanelProp;
}

function search() {
  triggerFetchData();
}

function triggerFetchData() {
  emit('fetchData', {
    search: searchBox.value,
    filter: getUserFilter()
  });
}

defineExpose({ updateAppliedFilters });
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="ibox">
          <div class="row mb-3">
            <div v-if="props.userFilterEnabled" class="col-sm-6">
              <a type="button" class="collapse-link-modified btn btn-w-m btn-default">
                <span v-if="filtersCount > 0" class="badge badge-warning">{{ filtersCount }}</span>
                Filtry
                <i class="fa fa-chevron-down"></i>
              </a>
            </div>

            <div
              v-if="!props.hideSearchBar"
              :class="
                props.longerSearchBar
                  ? 'col-sm-4 ' + (props.userFilterEnabled ? 'offset-sm-2' : 'offset-sm-8')
                  : 'col-sm-3 ' + (props.userFilterEnabled ? 'offset-sm-3' : 'offset-sm-9')
              "
            >
              <div class="input-group">
                <input
                  placeholder="Szukaj.."
                  type="text"
                  class="form-control form-control-sm"
                  v-model="searchBox"
                  v-on:keyup.enter="search"
                />
                <span class="input-group-append">
                  <button @click="search" type="button" class="btn btn-sm btn-primary">
                    <i class="fa fa-search"></i> Szukaj
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div class="ibox-content" style="display: none">
            <div class="row">
              <div :class="props.oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <ModalInputField
                  title="Typ użytkownika"
                  :titleCssClass="userTypeApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.userTypeDisplayName"
                  @showModal="modalTable.showModal(MODAL_USER_TYPES_TABLE)"
                />
              </div>

              <div :class="props.oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <div class="form-group">
                  <label class="col-form-label pt-0" :class="activeApplied ? '' : 'text-warning'"
                    >Aktywny</label
                  >

                  <div class="btn-group btn-group-toggle" style="display: block">
                    <label
                      class="btn btn-sm btn-white"
                      :class="filterPanel.active == null ? 'active' : ''"
                    >
                      <input type="radio" :value="null" v-model="filterPanel.active" />
                      -
                    </label>
                    <label
                      class="btn btn-sm btn-white"
                      :class="filterPanel.active === true ? 'active' : ''"
                    >
                      <input type="radio" :value="true" v-model="filterPanel.active" />
                      Tak
                    </label>
                    <label
                      class="btn btn-sm btn-white"
                      :class="filterPanel.active === false ? 'active' : ''"
                    >
                      <input type="radio" :value="false" v-model="filterPanel.active" />
                      Nie
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6 offset-6">
                <button
                  class="btn btn-outline btn-info btn-xs float-right"
                  @click="onApplyFiltersButtonClicked"
                >
                  Zastosuj filtry
                </button>
                <button
                  class="btn btn-outline btn-danger btn-xs float-right mr-1"
                  @click="onClearAllFiltersButtonClicked"
                >
                  Wyczyść filtry
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalTable
      ref="modalTable"
      @onModalCompleted="onModalCompleted"
      @onModalRejected="onModalRejected"
    ></ModalTable>
  </div>
</template>

<style scoped></style>
