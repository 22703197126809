import { APP_PRIMARY_COLOR } from '../data/constants/appConstants';

export function getSwalConfiguration(title, text) {
  return {
    title,
    html: text,
    icon: 'question',
    showCancelButton: true,
    confirmButtonColor: `#${APP_PRIMARY_COLOR}`,
    cancelButtonColor: '#f8ac59',
    confirmButtonText: 'Tak',
    cancelButtonText: 'Nie',
    customClass: {
      title: 'swal2-title'
    }
  };
}
