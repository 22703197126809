<script setup>
import { ref } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { isNotEmptyArray } from '../../helpers/utilsHelper.js';

const RepositoryActions = RepositoryFactory.get('actions');

const props = defineProps({
  searchFromOutside: { type: String, default: null },
  userFilter: { type: Object, default: null }
});

const summaryObj = ref(null);
const isLoading = ref(false);

async function getRegisterSummary() {
  isLoading.value = true;
  summaryObj.value = await RepositoryActions.getRegisterSummary(
    props.searchFromOutside,
    props.userFilter
  );
  isLoading.value = false;
}

// created
getRegisterSummary();
</script>

<template>
  <div class="ibox">
    <div class="ibox-title">
      <!-- TODO maybe set min-height probably -->
      <h5>Podsumowanie</h5>
      <div class="ibox-tools">
        <slot name="button"></slot>
      </div>
    </div>
    <div class="ibox-content" :class="{ 'sk-loading': isLoading }">
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <div v-if="summaryObj?.data != null">
        <p>
          Liczba unikalnych zleceń:
          {{
            summaryObj.data.unique_orders_count != null ? summaryObj.data.unique_orders_count : '-'
          }}
        </p>

        <div v-if="isNotEmptyArray(summaryObj?.data?.actions_summary)">
          <p>Liczba akcji z podziałem na typy:</p>
          <ul>
            <li
              v-for="actionType in summaryObj.data.actions_summary"
              :key="actionType.id_action_type"
            >
              {{ actionType.name }}:
              {{ actionType.total }}
            </li>
          </ul>
        </div>

        <div v-if="isNotEmptyArray(summaryObj?.data?.actions_failures)">
          <p>Liczba niepowodzeń z podziałem na powody:</p>
          <ul>
            <li
              v-for="actionFailureType in summaryObj.data.actions_failures"
              :key="actionFailureType.id_failure_action_reason"
            >
              {{ actionFailureType.name }}: {{ actionFailureType.total }}
            </li>
          </ul>
        </div>
      </div>

      <div v-else>Brak danych.</div>
    </div>
  </div>
</template>

<style scoped></style>
