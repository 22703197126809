<script setup>
import { ref, shallowRef } from 'vue';
import { getDateWithoutTime } from '../../helpers/dateFormatHelper';
import DictionaryTable from '../dictionaries/DictionaryTable.vue';
import { useRouter } from 'vue-router';
import Popper from 'vue3-popper';
import ActionsRegisterSummary from './ActionsRegisterSummary.vue';
import ActionsRegisterFilterAndSearchPanel from './ActionsRegisterFilterAndSearchPanel.vue';
import { ACCESS_KEY_ACTIONS_REGISTER_TABLE_REQUEST_REPORT_BUTTON } from '../../data/constants/authConstants';
import Swal from 'sweetalert2';
import { getSwalConfiguration } from '../../helpers/swalHelper';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { useAuthStore } from '../../stores/auth';
import { createToaster } from '@meforma/vue-toaster';
import { TOASTER_SERVER_ERROR_MSG } from '../../data/constants/toasterMessagesConstants';

const RepositoryActions = RepositoryFactory.get('actions');

const emit = defineEmits(['reportRequested']);
const dictTableKey = ref(0);
const actionsRegisterSummaryKey = ref(0);
const filterAndSearchRef = ref();
const searchBox = ref('');
const userFilter = ref(null);
const authStore = useAuthStore();
const toaster = createToaster({ position: 'top-right' });

const filterPanel = ref({
  creationDate: shallowRef({
    startDate: null,
    endDate: null
  }),
  orders: null,
  actionKind: null,
  actionTypes: null,
  failureReasons: null,
  lastActionOnly: null,
  workers: null,
  demElemTypes: null,
  boroughs: null,
  regions: null,
  counties: null,
  priorityChange: null,
  orderStatuses: null,
  coordinators: null,
  orderRanges: null,
  additionalDismantlingSources: null
});

const filterPanelDisplay = ref({
  ordersDisplayName: null,
  actionTypesDisplayName: null,
  failureReasonsDisplayName: null,
  workersDisplayName: null,
  demElemTypesDisplayName: null,
  boroughsDisplayName: null,
  regionsDisplayName: null,
  countiesDisplayName: null,
  orderStatusesDisplayName: null,
  coordinatorsDisplayName: null,
  orderRangesDisplayName: null,
  additionalDismantlingSourcesDisplayName: null
});

const appliedFilter = ref(new Object());
const router = useRouter();

const tableColumns = [
  { key: 'order_number', header: 'Nr zlecenia' },
  { key: 'date', header: 'Data zgłoszenia', computed: true },
  { key: 'workerName', header: 'Brygadzista', computed: true, requiredKeysOverride: ['worker'] },
  { key: 'ksp', header: 'KSP' },
  { key: 'geotimestamp.lat', header: 'Szerokość' },
  { key: 'geotimestamp.lon', header: 'Długość' },
  {
    key: 'mapOrPoint',
    header: 'Rodzaj akcji',
    computed: true,
    requiredKeysOverride: ['dem_point']
  },
  { key: 'action_type.name', header: 'Typ akcji' },
  { key: 'note', header: 'Notatka', computed: true },
  { key: 'failure_reason.name', header: 'Typ niepowodzenia' },
  { key: 'cable_type_for_failure.name', header: 'Typ przewodu' },
  { key: 'extra_dem_source.name', header: 'Źródło dem. dod.' }
];

function onDataFetched() {
  appliedFilter.value = Object.assign({}, filterPanel.value);
}

function onFetchDataWithFiltersRequest({ search, filter }) {
  searchBox.value = search;
  userFilter.value = filter;
  dictTableKey.value++;
  actionsRegisterSummaryKey.value++;
}

function onShowOrderButtonClicked(action) {
  let routeData = router.resolve({
    name: 'orderDetails',
    params: { id: action.id_order, actionId: action.id }
  });

  window.open(routeData.href, '_blank');
}

// eslint-disable-next-line no-unused-vars
function onSearchChangedInFilterPanel(newSearchValue) {
  //searchBox.value = newSearchValue;
}

function onRequestReportButtonClicked() {
  Swal.fire(
    getSwalConfiguration(
      'Zapytanie o raport',
      'Czy na pewno chcesz wysłać zapytanie o wygenerowanie raportu akcji?'
    )
  ).then((result) => {
    if (result.value) {
      requestReportGeneration();
    }
  });
}

async function requestReportGeneration() {
  var response = await RepositoryActions.requestActionsRegisterReport(
    authStore.loggedInUser.id,
    searchBox.value,
    userFilter.value
  );

  if (response?.serverError) {
    toaster.show(TOASTER_SERVER_ERROR_MSG, { duration: 3000, type: 'error' });
  } else if (response?.success === true) {
    toaster.show('Wysłano zapytanie o wygenerowanie raportu akcji.', {
      duration: 3000,
      type: 'success'
    });
    emit('reportRequested');
  }
}
</script>

<template>
  <div class="row">
    <div class="col-12">
      <!-- <div class="ibox">
        <div class="ibox-content"> -->
      <ActionsRegisterFilterAndSearchPanel
        ref="filterAndSearchRef"
        @fetchData="onFetchDataWithFiltersRequest"
        :searchBox="searchBox"
        :filterPanel="filterPanel"
        :filterPanelDisplay="filterPanelDisplay"
        :appliedFilter="appliedFilter"
        @searchChanged="onSearchChangedInFilterPanel"
        @updateFilterPanel="
          (value) => {
            filterPanel = value;
          }
        "
        @updateFilterPanelDisplay="
          (value) => {
            filterPanelDisplay = value;
          }
        "
      />
      <!-- </div>
      </div> -->
    </div>
  </div>

  <div class="row">
    <div class="col-lg-9">
      <DictionaryTable
        :key="dictTableKey"
        :showHeader="false"
        :showBorder="false"
        url="action/raportRegister"
        :columns="tableColumns"
        :customButtons="true"
        :showTableElementsInfo="true"
        :searchFromOutside="searchBox"
        :showSearchBar="false"
        :userFilter="userFilter"
        @dataFetched="onDataFetched"
      >
        <template #date="{ element }">
          {{ element.date != null ? getDateWithoutTime(element.date) : '-' }}
        </template>

        <template #workerName="{ element }">
          {{
            element.worker?.firstName && element.worker?.lastName
              ? `${element.worker.firstName} ${element.worker.lastName}`
              : '-'
          }}
        </template>

        <template #mapOrPoint="{ element }">
          {{ element.dem_point != null ? 'Punkt' : 'Mapa' }}
        </template>

        <template #note="{ element }">
          <Popper
            v-if="element?.note != null"
            arrow
            :content="'Treść: ' + element.note"
            class="light"
          >
            <i class="fa fa-check" style="color: #049d04; cursor: pointer" />
          </Popper>
          <span v-else>-</span>
        </template>

        <template #buttons="{ element }">
          <div class="buttons-container">
            <button
              type="button"
              class="btn btn-outline-primary btn-xs"
              @click="onShowOrderButtonClicked(element)"
              onclick="event.stopPropagation()"
            >
              Pokaż zlecenie
            </button>
          </div>
        </template>
      </DictionaryTable>
    </div>

    <div class="col-lg-3">
      <ActionsRegisterSummary
        :key="actionsRegisterSummaryKey"
        :searchFromOutside="searchBox"
        :userFilter="userFilter"
      >
        <template v-slot:button>
          <a
            v-if="authStore.canAccess(ACCESS_KEY_ACTIONS_REGISTER_TABLE_REQUEST_REPORT_BUTTON)"
            @click="onRequestReportButtonClicked"
            class="btn btn-primary btn-xs"
            >Generuj raport</a
          >
        </template>
      </ActionsRegisterSummary>
    </div>
  </div>
</template>

<style scoped>
.buttons-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px 5px;
}

.light {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #d6dfe4;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 20px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>
