import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { isNotEmptyArray } from './utilsHelper';
import { createToaster } from '@meforma/vue-toaster';

const DEFAULT_FIRST_ROW_CELLS_COLOR = 'f67ac4';
const DEFAULT_COLUMNS_COLOR = 'ccecff';
const DEFAULT_COLUMNS_WIDTH = 20;
const FIRST_COLUMN_COLOR = 'ffe598';
const FIRST_ROW_HEIGHT = 60;
const SECOND_ROW_HEIGHT = 60;
const WRAP_DATA_ROWS = true;

export function generateReport(reportData, worksheetName = 'Worksheet') {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(worksheetName);
  const headers = reportData.headers;

  const firstRowValues = [''];
  const secondRowValues = ['Lp'];
  const thirdRowValues = [''];
  let firstRowColors = [];
  let secondRowColors = [];
  let secondRowWidths = [];

  if (!isNotEmptyArray(headers)) {
    const toaster = createToaster({ position: 'top-right' });
    toaster.show('Brak danych, nie można wygenerować raportu.', {
      duration: 3000,
      type: 'warning'
    });

    return;
  }

  let subcolumnIndex = 1;

  headers.forEach((header) => {
    firstRowValues.push(header.column);
    firstRowColors.push(header.color ?? DEFAULT_FIRST_ROW_CELLS_COLOR);

    for (let i = 0; i < header.subcolumns.length; i++) {
      if (i > 0) {
        firstRowValues.push('');
        firstRowColors.push(null);
      }

      const isObject = typeof header.subcolumns[i] === 'object';

      secondRowValues.push(isObject ? header.subcolumns[i]?.name : header.subcolumns[i]);
      secondRowColors.push(
        isObject ? header.subcolumns[i]?.color ?? DEFAULT_COLUMNS_COLOR : DEFAULT_COLUMNS_COLOR
      );

      secondRowWidths.push(
        isObject ? header.subcolumns[i]?.width ?? DEFAULT_COLUMNS_WIDTH : DEFAULT_COLUMNS_WIDTH
      );

      thirdRowValues.push(subcolumnIndex++);
    }
  });

  firstRowColors = [FIRST_COLUMN_COLOR].concat(firstRowColors);
  secondRowColors = [FIRST_COLUMN_COLOR].concat(secondRowColors);
  secondRowWidths = [10].concat(secondRowWidths);

  // Fill with the data
  worksheet.addRow(firstRowValues);
  worksheet.addRow(secondRowValues);
  worksheet.addRow(thirdRowValues);

  for (let i = 0; i < reportData.rows.length; i++) {
    const row = reportData.rows[i].data;
    const rowColor = reportData.rows[i].color;
    const addedRow = worksheet.addRow([i + 1].concat(row));

    addedRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: rowColor }
    };
  }

  // Merge cells
  const rowToMerge = 0;
  const mergesCounts = headers.map((header) => header.subcolumns.length);

  let lastMergeEndCell = 1;

  for (let index = 0; index < mergesCounts.length; index++) {
    const start = lastMergeEndCell + 1;
    lastMergeEndCell = lastMergeEndCell + mergesCounts[index];
    const end = lastMergeEndCell;
    worksheet.mergeCells(rowToMerge, start, rowToMerge, end);
  }

  worksheet.mergeCells(2, 1, 3, 1);

  // Format cells
  worksheet.getRow(1).font = { name: 'Calibri', size: 14, bold: true };
  worksheet.getRow(2).font = { name: 'Calibri', size: 11, bold: true };

  worksheet.getRow(1).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
  worksheet.getRow(2).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

  if (WRAP_DATA_ROWS) {
    for (let i = 0; i < reportData.rows.length; i++) {
      worksheet.getRow(i + 4).alignment = { wrapText: true };
    }
  }

  if (FIRST_ROW_HEIGHT != undefined) {
    worksheet.getRow(1).height = FIRST_ROW_HEIGHT;
  }

  if (SECOND_ROW_HEIGHT != undefined) {
    worksheet.getRow(2).height = SECOND_ROW_HEIGHT;
  }

  for (let i = 0; i < secondRowWidths.length; i++) {
    worksheet.getColumn(i + 1).width = secondRowWidths[i];
  }

  const firstRow = worksheet.getRow(1);

  for (let i = 0; i < firstRowColors.length; i++) {
    const color = firstRowColors[i];
    if (color == null) continue;

    firstRow.getCell(i + 1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: color } };
  }

  const secondRow = worksheet.getRow(2);
  const thirdRow = worksheet.getRow(3);

  for (let i = 0; i < secondRowColors.length; i++) {
    const color = secondRowColors[i];
    secondRow.getCell(i + 1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: color }
    };
    thirdRow.getCell(i + 1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: color }
    };
  }

  worksheet.columns.forEach((column) => {
    column.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    };
  });

  worksheet.autoFilter = {
    from: 'B3',
    to: 'AN10000'
  };

  worksheet.views = [{ state: 'frozen', xSplit: 2, ySplit: 3 }];

  // Export xlsx
  workbook.xlsx
    .writeBuffer()
    .then((buffer) =>
      saveAs(
        new Blob([buffer]),
        `Raport z rejestru zleceń - ${new Date().toLocaleDateString()}.xlsx`
      )
    )
    .catch((err) => console.log('generateReport(): Error writing excel export.', err));
}
