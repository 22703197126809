<script setup>
import { computed } from 'vue';
import { isNotEmptyArray } from '../../helpers/utilsHelper';

const props = defineProps({
  title: { type: String, default: 'Błąd formularza!' },
  errors: { type: Array }
});

const opened = computed(() => isNotEmptyArray(props?.errors));
</script>

<template>
  <transition name="slide">
    <div v-if="opened">
      <div class="panel panel-danger">
        <div class="panel-heading">
          {{ props.title }}
        </div>
        <div class="panel-body">
          <ul v-if="props.errors.length > 1">
            <li v-for="error in props.errors" :key="error.id">
              <p>{{ error.toString() }}</p>
            </li>
          </ul>
          <p v-else-if="props.errors.length === 1">{{ props.errors.toString() }}</p>
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped>
.slide-enter-active {
  transition: all 0.5s;
}
.slide-leave-active {
  transition: all 0.5s;
}
.slide-enter,
.slide-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
