//quick template: import { handleResponseWithMessages } from "@/helpers/wwwHelper.js";
import { createToaster } from '@meforma/vue-toaster';
import {
  TOASTER_ERROR_OCCURED_MSG,
  TOASTER_SERVER_ERROR_MSG
} from '../data/constants/toasterMessagesConstants';

export function handleResponseWithMessages(response, successMsg, successAction) {
  const toaster = createToaster({
    position: 'top-right',
    duration: 3000
  });

  if (response?.serverError) {
    toaster.show(TOASTER_SERVER_ERROR_MSG, { type: 'error' });
  } else {
    if (response?.error != null) {
      if (response?.message != null && Array.isArray(response?.message)) {
        response.message.forEach((element) => {
          toaster.show(element, { type: 'warning' });
        });
      } else toaster.show(TOASTER_ERROR_OCCURED_MSG, { type: 'warning' });
    } else {
      if (successMsg) {
        toaster.show(successMsg, { type: 'success' });
      }

      successAction();
    }
  }
}
