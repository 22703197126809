import Swal from 'sweetalert2';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { APP_PRIMARY_COLOR } from '../data/constants/appConstants';
import { isNotEmptyArray, isNonEmptyString } from './utilsHelper';
import { createToaster } from '@meforma/vue-toaster';

export function addEditDemPointsNote(orderId, demPoints, onSucceededAction) {
  if (!isNotEmptyArray(demPoints)) {
    console.warn('addEditDemPointsNote(): demPoints should be a non zero elements array.');
    return;
  }

  const singleMode = demPoints.length === 1;
  const toBeOverwrittenCount = demPoints.filter((demPoint) =>
    isNonEmptyString(demPoint.note)
  ).length;
  const editMode = toBeOverwrittenCount > 0;
  const title =
    (editMode ? 'Edycja' : 'Dodawanie') +
    ' uwag do ' +
    (singleMode ? 'punktu' : 'punktów (' + demPoints.length + ')');
  const confirmButtonText = editMode ? 'Zatwierdź' : 'Dodaj';
  const message =
    toBeOverwrittenCount > 0
      ? `<span class='text-warning'>Liczba notatek, które zostaną nadpisane: ${toBeOverwrittenCount}</span>`
      : null;

  let newNote = null;

  const swalConfiguration = {
    title,
    html: message,
    icon: 'info',
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonColor: `#${APP_PRIMARY_COLOR}`,
    cancelButtonColor: '#f8ac59',
    confirmButtonText,
    cancelButtonText: 'Anuluj',
    customClass: {
      title: 'swal2-title'
    },
    width: '',
    input: 'textarea',
    inputValue: singleMode ? demPoints[0].note : null,
    inputPlaceholder: 'Wpisz uwagi',
    inputAttributes: {
      maxlength: '1000'
    },
    preConfirm: (inputValue) => {
      if (isNonEmptyString(inputValue)) {
        newNote = inputValue;
        return true;
      } else return Swal.showValidationMessage(`Wartość nie może być pusta`);
    }
  };

  Swal.fire(swalConfiguration).then((result) => {
    if (result.value) {
      changeNoteForDemPoints(
        orderId,
        demPoints.map((d) => d.id),
        newNote,
        onSucceededAction
      );
    }
  });
}

async function changeNoteForDemPoints(orderId, demPointsIds, note, onSucceededAction) {
  const RepositoryDemPoints = RepositoryFactory.get('demPoints');
  const response = await RepositoryDemPoints.changeNoteForDemPoints(orderId, demPointsIds, note);

  const toaster = createToaster({
    position: 'top-right',
    duration: 3000
  });

  if (response.data == null) {
    toaster.show('Coś poszło nie tak.', { type: 'error' });
  }

  if (response.data === 'OK') {
    const successMsg =
      demPointsIds.length > 1 ? 'Notatki zostały zmienione' : 'Notatka została zmieniona';
    toaster.show(successMsg, { type: 'success' });
    onSucceededAction();
  } else {
    toaster.show(response.data, { type: 'warning' });
  }
}
