<script setup>
import { ref } from 'vue';
import MapPointsReportsMap from './MapPointsReportsMap.vue';
import MapPointsReportsTable from './MapPointsReportsTable.vue';
import { APP_PRIMARY_COLOR } from '../../data/constants/appConstants';

const props = defineProps({ orderId: Number, order: Object });

const mapPointsReportsTableKey = ref(0);
const mapPointsReportsMapRef = ref();

function refreshMapPointsReportsMapBounds() {
  mapPointsReportsMapRef.value?.refreshMapBounds();
}

defineExpose({ refreshMapPointsReportsMapBounds });
</script>

<template>
  <div class="ibox">
    <div
      class="ibox-content"
      :class="{ 'sk-loading': props.orderId == null }"
      style="border-style: none"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <MapPointsReportsTable :key="mapPointsReportsTableKey" :orderId="props.orderId" />

      <div class="mt-5 mb-4" :style="`border-bottom: 1px dashed #${APP_PRIMARY_COLOR}`" />

      <MapPointsReportsMap
        ref="mapPointsReportsMapRef"
        :orderId="props.orderId"
        :order="props.order"
        @sharingCreated="mapPointsReportsTableKey++"
      />
    </div>
  </div>
</template>

<style scoped></style>
