<script setup>
import { ref } from 'vue';

const emit = defineEmits([
  'showPlacesFilterChanged',
  'showPriorityPlacesFilterChanged',
  'showOrdersFilterChanged',
  'showPriorityOrdersFilterChanged',
  'showOrdersWithAdditionalStatusFilterChanged'
]);

const showPlacesFilter = ref(true);
const showPriorityPlacesFilter = ref(true);
const showOrdersFilter = ref(true);
const showPriorityOrdersFilter = ref(true);
const showOrdersWithAdditionalStatusFilter = ref(true);

function showPlacesFilterChanged() {
  emit('showPlacesFilterChanged', showPlacesFilter.value);
}

function showPriorityPlacesFilterChanged() {
  emit('showPriorityPlacesFilterChanged', showPriorityPlacesFilter.value);
}

function showOrdersFilterChanged() {
  emit('showOrdersFilterChanged', showOrdersFilter.value);
}

function showPriorityOrdersFilterChanged() {
  emit('showPriorityOrdersFilterChanged', showPriorityOrdersFilter.value);
}

function showOrdersWithAdditionalStatusFilterChanged() {
  emit('showOrdersWithAdditionalStatusFilterChanged', showOrdersWithAdditionalStatusFilter.value);
}
</script>

<template>
  <div class="ibox-content profile-content" style="border-style: none">
    <div class="row">
      <div class="col-lg-6">
        <h4>Filtruj markery zleceń</h4>

        <div class="table-responsive mt-3">
          <table class="table table-striped">
            <tbody>
              <tr>
                <td>
                  <label>Pokaż zlecenia transportowe</label>
                </td>
                <td class="d-flex justify-content-end pr-5">
                  <div class="switch">
                    <div class="onoffswitch">
                      <input
                        type="checkbox"
                        class="onoffswitch-checkbox"
                        id="showOrdersFilterInputId"
                        v-model="showOrdersFilter"
                        @change="showOrdersFilterChanged"
                      />
                      <label class="onoffswitch-label" for="showOrdersFilterInputId">
                        <span class="onoffswitch-inner"></span>
                        <span class="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <label>Pokaż zlecenia priorytetowe</label>
                </td>
                <td class="d-flex justify-content-end pr-5">
                  <div class="switch">
                    <div class="onoffswitch">
                      <input
                        type="checkbox"
                        class="onoffswitch-checkbox"
                        id="showPriorityOrdersFilterInputId"
                        v-model="showPriorityOrdersFilter"
                        @change="showPriorityOrdersFilterChanged"
                      />
                      <label class="onoffswitch-label" for="showPriorityOrdersFilterInputId">
                        <span class="onoffswitch-inner"></span>
                        <span class="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <label>Pokaż zakazy wjazdu</label>
                </td>
                <td class="d-flex justify-content-end pr-5">
                  <div class="switch">
                    <div class="onoffswitch">
                      <input
                        type="checkbox"
                        class="onoffswitch-checkbox"
                        id="showOrdersWithAdditionalStatusFilterInputId"
                        v-model="showOrdersWithAdditionalStatusFilter"
                        @change="showOrdersWithAdditionalStatusFilterChanged"
                      />
                      <label
                        class="onoffswitch-label"
                        for="showOrdersWithAdditionalStatusFilterInputId"
                      >
                        <span class="onoffswitch-inner"></span>
                        <span class="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="col-lg-6">
        <h4>Filtruj markery miejsc składowania</h4>

        <div class="table-responsive mt-3">
          <table class="table table-striped">
            <tbody>
              <tr>
                <td>
                  <label>Pokaż miejsca składowania</label>
                </td>
                <td class="d-flex justify-content-end pr-5">
                  <div class="switch">
                    <div class="onoffswitch">
                      <input
                        type="checkbox"
                        class="onoffswitch-checkbox"
                        id="showPlacesFilterInputId"
                        v-model="showPlacesFilter"
                        @change="showPlacesFilterChanged"
                      />
                      <label class="onoffswitch-label" for="showPlacesFilterInputId">
                        <span class="onoffswitch-inner"></span>
                        <span class="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <label>Pokaż miejsca priorytetowe</label>
                </td>
                <td class="d-flex justify-content-end pr-5">
                  <div class="switch">
                    <div class="onoffswitch">
                      <input
                        type="checkbox"
                        class="onoffswitch-checkbox"
                        id="showPriorityPlacesFilterInputId"
                        v-model="showPriorityPlacesFilter"
                        @change="showPriorityPlacesFilterChanged"
                      />
                      <label class="onoffswitch-label" for="showPriorityPlacesFilterInputId">
                        <span class="onoffswitch-inner"></span>
                        <span class="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
