<script setup>
/* eslint-env jquery */
import { ref, nextTick, computed } from 'vue';
import { createToaster } from '@meforma/vue-toaster';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { isNotEmptyArray } from '../../helpers/utilsHelper';
import { getDateWithoutTime } from '../../helpers/dateFormatHelper';
import Swal from 'sweetalert2';
import { getSwalConfiguration } from '../../helpers/swalHelper';
import { handleResponseWithMessages } from '../../helpers/wwwHelper';

const RepositoryOrders = RepositoryFactory.get('orders');
const props = defineProps({ orderId: Number, isLoading: Boolean });

const toaster = createToaster({ position: 'top-right', duration: 3000 });
const isLoadingLocal = ref(false);
const fetchedDataObject = ref(null);
const additionalInfoData = ref(null);
const editMode = ref(false);
const commentsRegistrationTextArea = ref();
const commentsInternalsTextArea = ref();

const unsavedFormData = computed(() => {
  if (fetchedDataObject.value == null || additionalInfoData.value == null) return false;

  if (additionalInfoData.value.ln_present != fetchedDataObject.value.ln_present) return true;

  if (
    additionalInfoData.value.comments_registration !=
      fetchedDataObject.value.comments_registration &&
    !(
      additionalInfoData.value.comments_registration === '' &&
      fetchedDataObject.value.comments_registration == null
    )
  )
    return true;

  if (
    additionalInfoData.value.comments_internals != fetchedDataObject.value.comments_internals &&
    !(
      additionalInfoData.value.comments_internals === '' &&
      fetchedDataObject.value.comments_internals == null
    )
  )
    return true;

  let dateChanged = false;
  additionalInfoData.value.dates.forEach((date) => {
    const found = fetchedDataObject.value.dates.find((d) => d.orderStatusId === date.orderStatusId);

    if (found && found.date !== date.date) dateChanged = true;
  });

  if (dateChanged) return true;

  return false;
});

async function fetchData() {
  isLoadingLocal.value = true;

  let fetchedData = await RepositoryOrders.getOrderRegisterDetails(props.orderId);

  if (fetchedData == null) return;

  fetchedDataObject.value = fetchedData;
  fetchedDataObject.value.ln_present = fetchedDataObject.value.ln_present === 1;

  if (isNotEmptyArray(fetchedDataObject.value.order_statuses_changes)) {
    let dates = [];
    fetchedDataObject.value.order_statuses_changes.forEach((order_status_change) => {
      dates.push({
        id: order_status_change.id,
        name: order_status_change.order_status.name,
        date: getDateWithoutTime(order_status_change.actualized_at),
        orderStatusId: order_status_change?.order_status?.id
      });
    });

    fetchedDataObject.value.dates = dates;
  }

  additionalInfoData.value = JSON.parse(JSON.stringify(fetchedDataObject.value));

  nextTick(() => {
    reinitializeDatePickers();
    resizeTextareas();
  });

  isLoadingLocal.value = false;
}

function reinitializeDatePickers() {
  const datePickerConf = {
    todayBtn: 'linked',
    keyboardNavigation: true,
    forceParse: true,
    calendarWeeks: true,
    autoclose: true,
    weekStart: 1
  };

  if (!isNotEmptyArray(additionalInfoData.value.dates)) return;

  additionalInfoData.value.dates.forEach((date) => {
    $(`#additionalInfoData_date_${date.orderStatusId} .input-group.date`).datepicker('destroy');
    $(`#additionalInfoData_date_${date.orderStatusId} .input-group.date`).datepicker(
      datePickerConf
    );
    $(`#additionalInfoData_date_${date.orderStatusId} .input-group.date`).datepicker(
      'setDate',
      date.date
    );

    $(document.body).on(
      'change',
      `input[id^="additionalInfoData_date_${date.orderStatusId}_input"]`,
      function () {
        const foundDate = additionalInfoData.value.dates.find(
          (x) => x.orderStatusId === date.orderStatusId
        );
        if (foundDate) {
          foundDate.date = document.getElementById(
            `additionalInfoData_date_${date.orderStatusId}_input`
          ).value;
        }
      }
    );
  });
}

function onEditButtonClicked(e) {
  e.preventDefault();
  editMode.value = true;
}

function onCancelButtonClicked(e) {
  e.preventDefault();
  additionalInfoData.value = JSON.parse(JSON.stringify(fetchedDataObject.value));
  refreshDatepickers();
  editMode.value = false;
}

function refreshDatepickers() {
  additionalInfoData.value.dates.forEach((date) => {
    $(`#additionalInfoData_date_${date.orderStatusId} .input-group.date`).datepicker(
      'setDate',
      date.date
    );
  });
}

function onSaveButtonClicked(e) {
  e.preventDefault();

  Swal.fire(
    getSwalConfiguration('Zapisanie zmian', 'Czy na pewno chcesz zaktualizować dane?')
  ).then((result) => {
    if (result.value) {
      sendUpdateRequest();
    }
  });
}

async function sendUpdateRequest() {
  var response = await RepositoryOrders.updateOrderRegisterDetails(
    props.orderId,
    additionalInfoData.value.ln_present ? 1 : 0,
    additionalInfoData.value.comments_registration,
    additionalInfoData.value.comments_internals,
    additionalInfoData.value.dates.map((date) => ({
      id: date.id,
      id_order_status: date.orderStatusId,
      date: date.date
    }))
  );

  handleResponseWithMessages(response, 'Zmiany zostały zapisane.', () => {
    fetchData();
    editMode.value = false;
  });
}

function onComponentDeactivated() {
  if (unsavedFormData.value) {
    toaster.show('Niezapisane dane formularza w zakładce "Uwagi".', {
      duration: 3000,
      type: 'warning'
    });
  }
}

function resizeTextareas() {
  commentsRegistrationTextArea.value.style.height = 'auto';
  commentsRegistrationTextArea.value.style.height = `${
    commentsRegistrationTextArea.value.scrollHeight + 5
  }px`;

  commentsInternalsTextArea.value.style.height = 'auto';
  commentsInternalsTextArea.value.style.height = `${
    commentsInternalsTextArea.value.scrollHeight + 5
  }px`;
}

fetchData();

defineExpose({ onComponentDeactivated });
</script>

<template>
  <div class="ibox">
    <div
      class="ibox-content"
      :class="{ 'sk-loading': props.isLoading || isLoadingLocal }"
      style="border-style: none"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <form v-if="additionalInfoData != null" class="mb-3 pb-3" @submit="onDataUpload">
        <div class="row mr-3" style="display: flex; justify-content: flex-end">
          <button
            v-if="!editMode"
            class="btn btn-primary mr-1"
            style="display: table-cell; vertical-align: bottom"
            @click="onEditButtonClicked"
          >
            Edytuj
          </button>

          <span v-if="editMode">
            <button
              class="btn btn-danger mr-1"
              style="display: table-cell; vertical-align: bottom"
              @click="onCancelButtonClicked"
            >
              Anuluj
            </button>
            <button
              :disabled="!unsavedFormData"
              class="btn btn-primary"
              style="display: table-cell; vertical-align: bottom"
              @click="onSaveButtonClicked"
            >
              Zapisz zmiany
            </button>
          </span>
        </div>

        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <label>Informacja o kablach z LN</label>
              <div class="switch">
                <div class="onoffswitch">
                  <input
                    :disabled="!editMode"
                    type="checkbox"
                    class="onoffswitch-checkbox"
                    id="actionDirectlyOnTheMapSwitchId"
                    v-model="additionalInfoData.ln_present"
                  />
                  <label class="onoffswitch-label" for="actionDirectlyOnTheMapSwitchId">
                    <span class="onoffswitch-inner"></span>
                    <span class="onoffswitch-switch"></span>
                  </label>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label>Uwagi przy zgłoszeniu</label>
              <textarea
                ref="commentsRegistrationTextArea"
                :disabled="!editMode"
                type="text"
                placeholder="Wpisz notatkę"
                v-model="additionalInfoData.comments_registration"
                class="form-control"
                maxlength="500"
              />
            </div>

            <div class="form-group">
              <label>Uwagi wewnętrzne</label>
              <textarea
                ref="commentsInternalsTextArea"
                :disabled="!editMode"
                type="text"
                placeholder="Wpisz notatkę"
                v-model="additionalInfoData.comments_internals"
                class="form-control"
                maxlength="500"
              />
            </div>

            <h5 class="mt-4 mb-3">Daty zmiany statusów</h5>

            <div
              v-for="date in additionalInfoData.dates"
              :key="date.orderStatusId"
              class="form-group"
              :id="`additionalInfoData_date_${date.orderStatusId}`"
            >
              <label>{{ date.name ?? '-' }}</label>
              <div class="input-group date">
                <span class="input-group-addon" :style="'background-color: #e9ecef'"
                  ><i class="fa fa-calendar"></i></span
                ><input
                  :disabled="!editMode"
                  :id="`additionalInfoData_date_${date.orderStatusId}_input`"
                  type="text"
                  class="form-control"
                  v-model="date.date"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped></style>
