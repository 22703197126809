<script setup>
import { ref, watch } from 'vue';
import { usePaginationStore } from '@/stores/storePagination';

const props = defineProps({ paginationKey: { type: String, default: null } });
const emit = defineEmits(['selectionChanged']);
const selected = ref(null);
const eventEmitBlocked = ref(true);
const paginationStore = usePaginationStore();

watch(selected, () => {
  paginationStore.setPaginationItemsCount(selected.value, props.paginationKey);
  if (eventEmitBlocked.value === false) {
    emit('selectionChanged');
  } else {
    eventEmitBlocked.value = false;
  }
});

selected.value = paginationStore.getPaginationItemsCount(props.paginationKey);
</script>

<template>
  <div class="flex-container">
    <div class="mr-1" style="text-align: right; text-wrap: nowrap">Pokaż na stronę</div>
    <select v-model="selected" class="form-control mr-1" style="max-width: 75px">
      <option>10</option>
      <option>25</option>
      <option>50</option>
    </select>
  </div>
</template>

<style scoped>
.flex-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
