<script setup>
import { ref } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { handleResponseWithMessages } from '../../helpers/wwwHelper';
import RepoFilesTablePOST from '@/components/fileRepositories/RepoFilesTablePOST.vue';
import TransportRepoFilesFilterAndSearchPanel from './TransportRepoFilesFilterAndSearchPanel.vue';

const RepositoryFiles = RepositoryFactory.get('files');
const props = defineProps({
  orderId: { type: Number, required: true },
  modalImageContainerId: { type: String, required: true },
  urlOverride: { type: String, required: false, default: null },
  longerSearchBar: { type: Boolean, default: false }
});
const transportKey = ref(0);
const searchBox = ref('');
const userFilter = ref(null);
const transportRepoFilesFilterAndSearchPanel = ref();

function onFetchDataWithFiltersRequest({ search, filter }) {
  searchBox.value = search;
  userFilter.value = filter;
  transportKey.value++;
}

async function deleteTransportResource(resourceId) {
  var response = await RepositoryFiles.deleteTransportResource(resourceId);

  handleResponseWithMessages(response, 'Plik został usunięty.', () => {
    transportKey.value++;
  });
}
</script>

<template>
  <TransportRepoFilesFilterAndSearchPanel
    ref="transportRepoFilesFilterAndSearchPanel"
    :longerSearchBar="props.longerSearchBar"
    @fetchData="onFetchDataWithFiltersRequest"
  />

  <RepoFilesTablePOST
    :key="transportKey"
    :modalId="props.modalImageContainerId"
    :url="props.urlOverride ?? `order/${props.orderId}/transportFiles`"
    :showBorder="false"
    :longerSearchBar="props.longerSearchBar"
    :deletingEnabled="true"
    :searchFromOutside="searchBox"
    :userFilter="userFilter"
    :deleteFunctionOverride="deleteTransportResource"
    @dataFetched="transportRepoFilesFilterAndSearchPanel?.updateAppliedFilters"
  />
</template>

<style scoped></style>
