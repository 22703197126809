<script setup>
import { ref, watch } from 'vue';
import Swal from 'sweetalert2';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { handleResponseWithMessages } from '@/helpers/wwwHelper.js';
import { getDateWithoutTime } from '../../helpers/dateFormatHelper.js';
import { showFileInNewTab } from '@/helpers/utilsHelper.js';
import DictionaryTable from '../dictionaries/DictionaryTable.vue';
import { getSwalConfiguration } from '../../helpers/swalHelper';

const RepositoryFiles = RepositoryFactory.get('files');
const props = defineProps({ orderId: Number });
const emit = defineEmits(['onGeodeticProtocolsFetched', 'onFileDeleted']);
const dictTableKey = ref(0);

const tableColumns = [
  { key: 'pg_date', header: 'Data realizacji', computed: true },
  { key: 'attachment', header: 'Załącznik', computed: true, requiredKeysOverride: [] }
];

watch(
  () => props.orderId,
  () => dictTableKey.value++
);

function onDataFetched(dataObject) {
  emit('onGeodeticProtocolsFetched', dataObject);
}

function onDeleteFileButtonClicked(fileToDelete) {
  const fileExists = fileToDelete?.resource?.id != null;

  Swal.fire(
    getSwalConfiguration(
      fileExists ? 'Usuwanie pliku' : 'Usuwanie protokołu geodezji',
      fileExists
        ? 'Czy na pewno chcesz usunąć wybrany plik?'
        : 'Czy na pewno chcesz usunąć wybrany protokół geodezji?'
    )
  ).then((result) => {
    if (result.value) {
      if (fileExists) {
        deleteFile(fileToDelete);
      } else {
        deleteGeodeticProtocol(fileToDelete);
      }
    }
  });
}

async function deleteFile(fileToDelete) {
  var response = await RepositoryFiles.deleteFile(fileToDelete?.resource?.id);
  handleResponseWithMessages(response, 'Plik został usunięty.', onFileDeleted);
}

async function deleteGeodeticProtocol(fileToDelete) {
  var response = await RepositoryFiles.deleteGeodeticProtocol(fileToDelete?.id);
  handleResponseWithMessages(response, 'Protokół geodezji został usunięty.', onFileDeleted);
}

function onFileDeleted() {
  emit('onFileDeleted');
  dictTableKey.value++;
}
</script>

<template>
  <DictionaryTable
    :key="dictTableKey"
    v-if="props.orderId != null"
    :showHeader="false"
    :showBorder="false"
    :url="`order/${props.orderId}/PG`"
    :columns="tableColumns"
    :customButtons="true"
    @dataFetched="onDataFetched"
  >
    <template #pg_date="{ element }">
      {{ element.pg_date ? getDateWithoutTime(element.pg_date) : '-' }}
    </template>

    <template #attachment="{ element }">
      {{ element.resource?.name ?? '-' }}
    </template>

    <template #buttons="{ element }">
      <button
        v-if="element?.resource?.path"
        type="button"
        class="btn btn-outline-info"
        @click="showFileInNewTab(element)"
        onclick="event.stopPropagation()"
      >
        Pokaż
      </button>
      <button
        type="button"
        class="btn btn-outline-danger ml-2"
        @click="onDeleteFileButtonClicked(element)"
        onclick="event.stopPropagation()"
      >
        Usuń
      </button>
    </template>
  </DictionaryTable>
</template>

<style scoped></style>
