import { ref } from 'vue';
import { getMarker } from '../../helpers/markersCreator';
import L from 'leaflet';
import { MarkerClusterGroup } from 'leaflet.markercluster';
import { isNotEmptyArray } from '../../helpers/utilsHelper';

export function useFeatureGroups(map, features) {
  const featureGroups = ref([]);

  function getFeatureGroupWithKey(key) {
    return featureGroups.value.find((group) => group.key === key)?.group;
  }

  function createEmptyFeatureGroup(groupKey) {
    const newFeatureGroup = L.featureGroup();
    features.value.leafletObject.addLayer(newFeatureGroup);
    featureGroups.value.push({ key: groupKey, group: newFeatureGroup });

    return newFeatureGroup;
  }

  function createEmptyClusterGroup(groupKey, clusterSettings = undefined) {
    const newClusterGroup = new MarkerClusterGroup(clusterSettings);
    features.value.leafletObject.addLayer(newClusterGroup);
    featureGroups.value.push({ key: groupKey, group: newClusterGroup });

    return newClusterGroup;
  }

  /*
    Creates a marker and adds it to the feature group (can be cluster group).
    IMPORTANT: If group with given key does not exist, it is created. If you want to use cluster group, create it earlier with createEmptyClusterGroup() function and pass its key here.
  */
  function createAdditionalMarker(groupKey, markerData, onMarkerClick, onMarkerMoveEnd) {
    const newMarker = getMarker(markerData, onMarkerClick, onMarkerMoveEnd);
    const foundFeatureGroup = getFeatureGroupWithKey(groupKey);

    if (foundFeatureGroup) {
      foundFeatureGroup.addLayer(newMarker);
    } else {
      const newFeatureGroup = createEmptyFeatureGroup(groupKey);
      newFeatureGroup.addLayer(newMarker);
    }

    return newMarker;
  }

  /*
    Creates a marker with connection and adds it to the feature group (can be cluster group).
    IMPORTANT: If group with given key does not exist, it is created. If you want to use cluster group, create it earlier with createEmptyClusterGroup() function and pass its key here.
  */
  function createAdditionalMarkerWithConnection(
    groupKey,
    markerData,
    onMarkerClick,
    onMarkerMoveEnd,
    markerToConnectTo,
    fitConnectionBounds,
    lineColor = 'red',
    showLineDelayMilis = null // if set, the polyline will appear after given delay in miliseconds
  ) {
    const additionalMarker = createAdditionalMarker(
      groupKey,
      markerData,
      onMarkerClick,
      onMarkerMoveEnd
    );
    const polyline = getPolylineBetweenMarkers(additionalMarker, markerToConnectTo, lineColor);

    if (showLineDelayMilis != null) {
      polyline.setStyle({ opacity: 0 });
      setTimeout(() => polyline.setStyle({ opacity: 1 }), showLineDelayMilis);
    }

    getFeatureGroupWithKey(groupKey).addLayer(polyline);

    if (fitConnectionBounds) {
      map.value.leafletObject.fitBounds(polyline.getBounds());
    }

    return additionalMarker;
  }

  // returns true if removed
  function removeFeatureGroupWithKey(groupKey) {
    const foundFeatureGroup = getFeatureGroupWithKey(groupKey);

    if (foundFeatureGroup) {
      features.value.leafletObject.removeLayer(foundFeatureGroup);
      const index = featureGroups.value.findIndex((group) => group.key === groupKey);
      featureGroups.value.splice(index, 1);
      return true;
    }

    return false;
  }

  function getPolylineBetweenMarkers(marker1, marker2, lineColor = 'red') {
    if (marker1?.data?.lng == null || marker2?.data?.lng == null) return;

    const latlngs = [
      [marker1.data.lat, marker1.data.lng],
      [marker2.data.lat, marker2.data.lng]
    ];

    return L.polyline(latlngs, { color: lineColor });
  }

  function removeAllFeatureGroups() {
    if (!isNotEmptyArray(featureGroups.value)) return;

    featureGroups.value.forEach((featureGroup) => {
      features.value.leafletObject.removeLayer(featureGroup.group);
    });

    featureGroups.value = [];
  }

  return {
    createAdditionalMarker,
    removeFeatureGroupWithKey,
    createAdditionalMarkerWithConnection,
    removeAllFeatureGroups,
    createEmptyFeatureGroup,
    createEmptyClusterGroup
  };
}
