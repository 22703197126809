import { getLocalFormattedDateAndTime } from '@/helpers/dateFormatHelper.js';

export function createPdfDataForGenerator(data) {
  if (!Array.isArray(data?.data)) return null;

  if (data.data.length === 0)
    return `<p class="text-center mt-3">Brak elementów do wyświetlenia</p>`;

  let pdfContent = `<h1>Lista punktów zbioru ${getLocalFormattedDateAndTime(
    new Date().toJSON()
  )}</h1><br>`;

  pdfContent += `<div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Numer zlecenia</th>
                <th>Zgł. składowania brygadzisty</th>
                <th>Zgł. składowania kierowcy</th>
                <th>Współrzędne</th>
                <th>Notatka</th>
                <th>Adres</th>
              </tr>
            </thead>
            <tbody>`;

  data.data.forEach((transportPoint) => {
    pdfContent += `
                <tr>
                <td>${transportPoint.order_number ?? '-'}</td>
                <td>${
                  getElementsColumnContentForTransportPoint(
                    transportPoint?.amounts,
                    transportPoint?.date
                  ) ?? '-'
                }</td>
                <td>${getDriversLeftColumnInfo(transportPoint)}</td>
                <td>${formatCoords(transportPoint.coordinates)}</td>
                <td>${transportPoint.note ?? '-'}</td>
                <td>${transportPoint.address ?? '-'}</td>
              </tr>
          `;
  });

  pdfContent += `</tbody></table></div>`;

  return pdfContent;
}

function getElementsColumnContentForTransportPoint(elements, date) {
  let content = date ?? '-';

  if (!Array.isArray(elements) || elements.length === 0) return content;

  content += '<br/>';

  elements.forEach((elem) => {
    if (elem.number > 0) {
      content += `${elem.name}: ${elem.number}<br/>`;
    }
  });

  return content;
}

function getDriversLeftColumnInfo(transportPoint) {
  let text = transportPoint?.last_driver_left_date ?? '-';

  if (transportPoint?.last_driver_left == null) return text;

  text += '<br/>';

  for (const property in transportPoint.last_driver_left) {
    text += `${property}: ${transportPoint.last_driver_left[property]}<br/>`;
  }

  if (text.endsWith('<br/>')) {
    text = text.slice(0, -1);
  }

  return text;
}

function formatCoords(coords) {
  if (coords == null) return '-';

  const coordsArray = coords.split(',');

  return coordsArray.length == 2 ? coordsArray[1] + ',<br/>' + coordsArray[0] : "'Błędne dane'";
}
