//TODO NOT IMPORTANT check if up-to-date
export const navItems = {
  USERS_LIST: [{ name: 'Użytkownicy' }],
  USER_DETAILS: [{ name: 'Użytkownicy', link: '/users' }, { name: 'Szczegóły użytkownika' }],
  USER_ADD: [{ name: 'Użytkownicy', link: '/users' }, { name: 'Nowy użytkownik' }],
  USER_EDIT: [{ name: 'Użytkownicy', link: '/users' }, { name: 'Edycja użytkownika' }],
  ORDERS_LIST: [{ name: 'Zlecenia' }],
  ORDER_DETAILS: [
    { name: 'Zlecenia', link: '/orders' },
    { name: 'Szczegóły zlecenia', headerAppendix: ' #orderNumber' }
  ],
  ORDER_ADD: [{ name: 'Zlecenia', link: '/orders' }, { name: 'Nowe zlecenie' }],
  ORDER_EDIT: [
    { name: 'Zlecenia', link: '/orders' },
    { name: 'Szczegóły zlecenia', link: '/orders/details/#orderId' },
    { name: 'Edycja zlecenia' }
  ],
  ORDER_ADDRESS_ADD: [{ name: 'Zlecenia', link: '/orders' }, { name: 'Nowy adres do zlecenia' }],
  ORDER_ADDRESS_EDIT: [
    { name: 'Zlecenia', link: '/orders' },
    { name: 'Edycja adresu do zlecenia' }
  ],
  ORDER_CONTRACTOR_DETAILS: [
    { name: 'Zlecenia', link: '/orders' },
    { name: 'Szczegóły zlecenia', link: '/orders/details/#orderId' },
    { name: 'Szczegóły demontażu' }
  ],
  POLES_LIST: [{ name: 'Słupy' }],
  POLE_DETAILS: [{ name: 'Słupy', link: '/poles' }, { name: 'Szczegóły słupa' }],
  DICTIONARIES: [{ name: 'Słowniki' }],
  POLE_ADD: [
    { name: 'Zlecenia', link: '/orders' },
    { name: 'Szczegóły zlecenia', link: '/orders/details/#orderId' },
    { name: 'Nowy słup' }
  ],
  TRANSPORT_MODULE: [{ name: 'Transport' }],
  SINGLE_ACTION_GALLERY: [{ name: 'Galeria akcji' }],
  TRANSPORT_ORDERS_LIST: [{ name: 'Zlecenia transportowe' }],
  TRANSPORT_ORDER_DETAILS: [
    { name: 'Zlecenia transportowe', link: '/transportOrders' },
    { name: 'Szczegóły zlecenia transportowego', headerAppendix: ' #transportOrderNumber' }
  ],
  TRANSPORT_ORDER_ADD: [
    { name: 'Zlecenia transportowe', link: '/transportOrders' },
    { name: 'Nowe zlecenie transportowe' }
  ],
  ACTIONS_REGISTER: [{ name: 'Rejestr akcji' }]
};

export function getOrderEditItem(orderId) {
  const newNavItems = JSON.parse(JSON.stringify(navItems.ORDER_EDIT));
  const navItem = newNavItems.find((x) => x.link.includes('#orderId'));

  if (navItem) {
    navItem.link = `/orders/details/${orderId}`;
  }

  return newNavItems;
}
