import {
  ADD_EDIT_DEMPOINT_NOTE_BUTTON_TYPE_NAME,
  NEXT_ACTION_BUTTON_TYPE_NAME,
  PREVIOUS_ACTION_BUTTON_TYPE_NAME
} from '../../data/constants/buttonsNamesConstants';
import { isNonEmptyString } from '../utilsHelper';

export default {
  leftRightButtonsSnippet(current, count, actionId, demPointId) {
    const previousIsActive = current > 1;
    const nextIsActive = current < count;
    const activeColorCode = '#676a6c';
    const inactiveColorCode = '#ebeded';
    const buttonsStyle = 'font-size: 23px; width: 50px; text-align: center;';

    return `<div class="mt-3" style="display: flex; flex-direction: row; gap: 10px; justify-content: space-evenly; align-items: center">
      
    <i class="fa fa-angle-left" style="${buttonsStyle} color: ${
      previousIsActive ? activeColorCode : inactiveColorCode
    }; ${previousIsActive ? 'cursor: pointer;' : ''}" clickable click_action_type='${
      previousIsActive ? PREVIOUS_ACTION_BUTTON_TYPE_NAME : ''
    }' id_action='${actionId}' id_demPoint='${demPointId}'></i>
      
      <span style="color: #676a6c; line-height: 1;">${current} z ${count}</span>
      
      <i class="fa fa-angle-right" style="${buttonsStyle} color: ${
      nextIsActive ? activeColorCode : inactiveColorCode
    }; ${nextIsActive ? 'cursor: pointer;' : ''}" clickable click_action_type='${
      nextIsActive ? NEXT_ACTION_BUTTON_TYPE_NAME : ''
    }' id_action='${actionId}' id_demPoint='${demPointId}'></i>
    </div>`;
  },

  addEditDemPointNoteButtonSnippet(demPoint) {
    if (!demPoint) return '';

    return `<button clickable type='button' class='btn btn-outline btn-info btn-xs' id='${ADD_EDIT_DEMPOINT_NOTE_BUTTON_TYPE_NAME}:${
      demPoint?.id
    }' id_demPoint='${
      demPoint?.id
    }' click_action_type='${ADD_EDIT_DEMPOINT_NOTE_BUTTON_TYPE_NAME}'>${
      isNonEmptyString(demPoint?.note) ? 'Edytuj' : 'Dodaj'
    } uwagi</button>`;
  }
};
