<script setup>
import { ref, computed } from 'vue';
import { MODAL_TRANSPORT_PHOTO_TYPES_TABLE } from '@/data/constants/modalConstants.js';
import ModalInputField from '@/components/utils/ModalInputField.vue';
import FilterAndSearchPanel from '../utils/FilterAndSearchPanel.vue';

const props = defineProps({
  showSearchBar: { type: Boolean, default: true },
  longerSearchBar: { type: Boolean, default: false },
  userFilterEnabled: { type: Boolean, default: true }
});
const emit = defineEmits(['fetchData']);
const filterComponent = ref();

const transportphotoTypeApplied = computed(() => {
  return filterComponent.value
    ? filterComponent.value.filterApplied(
        filterComponent.value.filterPanel?.transportphotoType?.id,
        filterComponent.value.appliedFilter?.transportphotoType?.id
      )
    : false;
});

function onModalCompleted(modalType, data) {
  switch (modalType) {
    case MODAL_TRANSPORT_PHOTO_TYPES_TABLE:
      filterComponent.value.filterPanel.transportphotoType = data;
      filterComponent.value.filterPanelDisplay.transportphotoTypeDisplayName = data?.name ?? '';
      break;
  }
}

function onModalRejected(modalType) {
  switch (modalType) {
    case MODAL_TRANSPORT_PHOTO_TYPES_TABLE:
      filterComponent.value.filterPanel.transportphotoType = null;
      filterComponent.value.filterPanelDisplay.transportphotoTypeDisplayName = null;
      break;
  }
}

function getUserFilter() {
  let userFilter = new Object();

  if (filterComponent.value.filterPanel?.transportphotoType?.id != null) {
    userFilter.id_transport_photo_type = filterComponent.value.filterPanel.transportphotoType.id;
  }

  return userFilter;
}

function updateAppliedFilters() {
  filterComponent.value?.updateAppliedFilters();
}

defineExpose({ updateAppliedFilters });
</script>

<template>
  <FilterAndSearchPanel
    ref="filterComponent"
    :showSearchBar="props.showSearchBar"
    :longerSearchBar="props.longerSearchBar"
    :userFilterEnabled="props.userFilterEnabled"
    :onModalCompletedAction="onModalCompleted"
    :onModalRejectedAction="onModalRejected"
    :getUserFilterOverride="getUserFilter"
    @fetchData="emit('fetchData', $event)"
  >
    <div class="col-sm-4">
      <ModalInputField
        title="Typ zdjęcia"
        :titleCssClass="transportphotoTypeApplied ? '' : 'text-warning'"
        :displayText="filterComponent?.filterPanelDisplay?.transportphotoTypeDisplayName ?? '-'"
        @showModal="filterComponent.showModal(MODAL_TRANSPORT_PHOTO_TYPES_TABLE)"
      />
    </div>
  </FilterAndSearchPanel>
</template>

<style scoped></style>
