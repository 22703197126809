import Swal from 'sweetalert2';
import { isNotEmptyArray } from '../utilsHelper';
import { generateRandomString } from '../utilsHelper';
import { APP_PRIMARY_COLOR } from '../../data/constants/appConstants';

export async function verifyOrderDeletionWithStringRetyping(apiVerificationFunction, onConfirmed) {
  const response = await apiVerificationFunction();

  const canDelete = response?.can_delete === true;
  const icon = !canDelete ? 'error' : isNotEmptyArray(response?.warnings) ? 'warning' : 'question';
  const stringToRetype = canDelete ? generateRandomString(5) : null;

  let message = response?.message ?? '';

  if (isNotEmptyArray(response?.warnings)) {
    message += '<br/><br/><b>Ostrzeżenia:</b>';
    response.warnings.forEach((warning) => {
      message += "<br/><span style='font-size: 14px'>" + warning + '</span>';
    });
  }

  message += canDelete
    ? `<br/><br/>Aby usunąć zlecenie przepisz kod: <b>${stringToRetype}</b>`
    : '';

  const swalConfiguration = {
    title: 'Weryfikacja usunięcia zlecenia',
    html: message,
    icon: icon,
    showConfirmButton: canDelete,
    showCancelButton: true,
    confirmButtonColor: `#${APP_PRIMARY_COLOR}`,
    cancelButtonColor: '#f8ac59',
    confirmButtonText: 'Usuń',
    cancelButtonText: canDelete ? 'Anuluj' : 'Zamknij',
    customClass: {
      title: 'swal2-title'
    },
    width: isNotEmptyArray(response?.warnings) ? '50%' : '',
    input: canDelete ? 'text' : '',
    preConfirm: (retypedString) => {
      return retypedString === stringToRetype
        ? true
        : Swal.showValidationMessage(`Niepoprawnie przepisany kod`);
    }
  };

  Swal.fire(swalConfiguration).then((result) => {
    if (result.value) {
      onConfirmed();
    }
  });
}
