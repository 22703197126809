<script setup>
import ChartPanel from '../components/dashboard/ChartPanel.vue';
import StatsBoxPanel from '../components/dashboard/StatsBoxPanel.vue';

const stats = [
  {
    header: '2,346',
    subheader: 'Total orders in period',
    progressBarPercent: 48,
    progressBarColor: '#1ab394',
    showAdditionalValue: true,
    additionalValueCaption: '48%',
    additionalValueIcon: 'fa-level-up',
    additionalValueColor: '#1ab394'
  },

  {
    header: '4,422',
    subheader: 'Orders in last month',
    progressBarPercent: 60,
    progressBarColor: '#1ab394',
    showAdditionalValue: true,
    additionalValueCaption: '60%',
    additionalValueIcon: 'fa-level-down',
    additionalValueColor: '#1ab394'
  },

  {
    header: '9,180',
    subheader: 'Monthly income from orders',
    progressBarPercent: 22,
    progressBarColor: '#1ab394',
    showAdditionalValue: true,
    additionalValueCaption: '22%',
    additionalValueIcon: 'fa-bolt',
    additionalValueColor: '#1ab394'
  }
];
</script>

<template>
  <div class="wrapper wrapper-content">
    <div class="row">
      <div class="col-lg-3">
        <StatsBoxPanel
          header="Income"
          tagCaption="Monthly"
          tagColor="red"
          mainValue="40 882"
          subheader="Total income"
          additionalValueCaption="98%"
          additionalValueIcon="fa-bolt"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <ChartPanel header="Zlecenia" :stats="stats" />
      </div>
    </div>
  </div>
</template>
