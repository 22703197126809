import axios from 'axios';
import vueInstance from '@/main.js';
import { useAuthStore } from '@/stores/auth';

let instance = axios.create({
  baseURL: import.meta.env.VITE_VUE_APP_URL
});

instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response?.config.url !== '/login' && error.response?.status === 401) {
      useAuthStore()
        .logOut()
        .then(() => {
          vueInstance.config.globalProperties.$router.push({ name: 'login' });
        });
    }
    return Promise.reject(error);
  }
);

export default instance;
