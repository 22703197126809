<script setup>
import { ref, computed } from 'vue';
import { USER_COLORS } from '../../data/constants/appConstants.js';

const props = defineProps({
  initialPickedColor: { type: String, default: null }
});

const emit = defineEmits(['onColorChanged']);

const pickedColor = ref(null);

function onOptionClicked(option) {
  pickedColor.value = option;
  emit('onColorChanged', pickedColor.value);
}

const isPredefinedPicked = computed(() => {
  return USER_COLORS.includes(pickedColor.value);
});

function initialize() {
  if (!Array.isArray(USER_COLORS) || !USER_COLORS.length) return;

  if (props.initialPickedColor && USER_COLORS.includes(props.initialPickedColor)) {
    pickedColor.value = props.initialPickedColor;
  } else {
    pickedColor.value = USER_COLORS[0];
  }

  emit('onColorChanged', pickedColor.value);
}

function onPickerColorChanged() {
  emit('onColorChanged', pickedColor.value);
}

// created
initialize();
</script>

<template>
  <div class="colors-container">
    <div v-for="option in USER_COLORS" :key="option">
      <div
        @click="onOptionClicked(option)"
        class="color-option"
        :style="'background-color: ' + option"
      >
        <i v-if="option === pickedColor" class="fa fa-check" style="color: white" />
      </div>
    </div>
  </div>

  <div class="color-picker-wrapper">
    <input type="color" class="color-picker" v-model="pickedColor" @change="onPickerColorChanged" />
    <div v-if="isPredefinedPicked" class="color-picker-overlay"></div>
    <i v-if="!isPredefinedPicked" class="fa fa-check color-picker-icon" style="color: white" />
    <span v-if="isPredefinedPicked" class="color-picker-icon" style="color: white"
      >Kliknij, aby wybrać kolor z palety</span
    >
  </div>
</template>

<style scoped>
.colors-container {
  display: flex;
  max-width: 450px;
  flex-wrap: wrap;
  gap: 5px;
}

.color-option {
  width: 50px;
  height: 25px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.color-picker-wrapper {
  position: relative;
  display: inline-block;
  margin-top: 10px;
}

.color-picker {
  padding: 0px;
  width: 435px;
  height: 25px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background: none;
}

.color-picker::-webkit-color-swatch-wrapper {
  padding: 0;
}

.color-picker::-webkit-color-swatch {
  border: none;
  border-radius: 5px;
}

.color-picker-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  pointer-events: none;
  color: white;
  font-size: 1em;
}

.color-picker-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 435px;
  height: 25px;
  pointer-events: none;
  background-color: rgb(183, 183, 183);
  border-radius: 5px;
}
</style>
