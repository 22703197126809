<script setup>
import { ref, computed, watch } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useRouter } from 'vue-router';
import { RADO_LOGO_SIZE_LOGIN_PAGE } from '../../data/constants/appConstants';
import { useSideMenuStore } from '../../stores/sideMenu';
import { formatRemainingTimeInPolish } from '../../helpers/utilsHelper';
import FormErrorPanel from '../utils/FormErrorPanel.vue';

const TIME_TO_EXIT_AFTER_CODE_EXCEEDED_SECS = 5;

const props = defineProps({
  username: { type: String, default: null },
  remainingSeconds: { type: Number, default: null }
});

const emit = defineEmits(['redirect-to-login']);

const emailCode = ref('');
const isLoading = ref(false);
const authStore = useAuthStore();
const sideMenuStore = useSideMenuStore();
const router = useRouter();
const timerSecs = ref(null);
const timer = ref(null);
const exitTimer = ref(null);
const exitTimerSecs = ref(null);
const formErrors = ref([]);

const formInfo = computed(() => authStore.formInfo);

const timerInfoText = computed(() => {
  if (timerSecs.value != null && timerSecs.value >= 0) {
    return `Pozostały czas: ${formatRemainingTimeInPolish(timerSecs.value)}`;
  }

  if (exitTimerSecs.value != null && exitTimerSecs.value > 0) {
    return `<span style='color: red'>Czas się skończył, przekierowanie do strony logowania za: ${formatRemainingTimeInPolish(
      exitTimerSecs.value
    )}</span>`;
  }

  return ' ';
});

watch(
  () => props.remainingSeconds,
  () => {
    timerSecs.value = props.remainingSeconds;
    startTimer();
  },
  { immediate: true }
);

async function verify(e) {
  e.preventDefault();
  isLoading.value = true;

  authStore
    .request2FAVerification(props.username, emailCode.value)
    .then(() => {
      sideMenuStore.resetState();
      //redirected to orders after logging in
      router.push('/orders');
      authStore.resetFormError();
    })
    .catch((error) => {
      console.log(error);
      emailCode.value = '';
    })
    .finally(() => {
      isLoading.value = false;
    });
}

function startTimer() {
  timer.value = setInterval(() => {
    if (timerSecs.value != null && timerSecs.value > 0) {
      timerSecs.value--;
    } else {
      timerSecs.value = null;
      clearInterval(timer.value);
      startExitTimer();
    }
  }, 1000);
}

function startExitTimer() {
  exitTimerSecs.value = TIME_TO_EXIT_AFTER_CODE_EXCEEDED_SECS;
  updateExitTimer();
  exitTimer.value = setInterval(updateExitTimer, 1000);
}

function updateExitTimer() {
  if (exitTimerSecs.value != null && exitTimerSecs.value > 0) {
    exitTimerSecs.value--;
  } else {
    exitTimerSecs.value = null;
    clearInterval(exitTimer.value);
    authStore.resetFormError();
    emit('redirect-to-login');
  }
}
</script>

<template>
  <div
    class="middle-box text-center loginscreen animated fadeIn"
    style="width: 100vh; max-width: fit-content"
  >
    <div>
      <img
        src="/static/img/rado_logo.png"
        alt="RADO - logo"
        :width="RADO_LOGO_SIZE_LOGIN_PAGE[0]"
        :height="RADO_LOGO_SIZE_LOGIN_PAGE[1]"
      />

      <div class="my-3" style="text-align: center">
        Wymagane potwierdzenie kodem ze skrzynki mailowej
      </div>

      <h4 class="my-3" style="text-align: center">
        <span v-html="timerInfoText" />
      </h4>

      <FormErrorPanel :errors="formErrors" />

      <form class="m-t" style="text-align: center; width: 300px; margin: auto" @submit="verify">
        <div class="form-group">
          <input
            v-model="emailCode"
            type="text"
            class="form-control"
            placeholder="Wpisz otrzymany kod"
            required
            minlength="1"
            maxlength="100"
          />
        </div>

        <div v-if="formInfo && formInfo !== ''">
          <p style="color: red">{{ formInfo }}</p>
        </div>

        <div v-if="isLoading" class="sk-spinner sk-spinner-circle mb-3 mt-3">
          <div class="sk-circle1 sk-circle"></div>
          <div class="sk-circle2 sk-circle"></div>
          <div class="sk-circle3 sk-circle"></div>
          <div class="sk-circle4 sk-circle"></div>
          <div class="sk-circle5 sk-circle"></div>
          <div class="sk-circle6 sk-circle"></div>
          <div class="sk-circle7 sk-circle"></div>
          <div class="sk-circle8 sk-circle"></div>
          <div class="sk-circle9 sk-circle"></div>
          <div class="sk-circle10 sk-circle"></div>
          <div class="sk-circle11 sk-circle"></div>
          <div class="sk-circle12 sk-circle"></div>
        </div>

        <button type="submit" class="btn btn-primary block full-width m-b">Weryfikuj</button>
      </form>
      <p class="m-t">
        <small>DKK Development &copy; 2023</small>
      </p>
    </div>
  </div>
</template>

<style scoped></style>
