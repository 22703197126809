import repository from './repository.js';

export default {
  async createPGShare(orderId, name, selectedElements) {
    const content = JSON.stringify({
      demPoints: selectedElements.demPoints.map((demPoint) => ({
        id: demPoint.id,
        lat: demPoint.lat,
        lon: demPoint.lon,
        oznaczenie: demPoint.oznaczenie,
        note: demPoint.note
      })),
      actions: selectedElements.actions.map((action) => ({
        id: action.id,
        demPointId: action.demPointId,
        demPointOznaczenie: action.demPointOznaczenie,
        demPointNote: action.demPointNote,
        lat: action.geotimestamp.lat,
        lon: action.geotimestamp.lon,
        note: action.note
      }))
    });

    const content_types = JSON.stringify({
      demPoints: selectedElements.demPoints.map((demPoint) => ({
        id: demPoint.id,
        lat: demPoint.lat,
        lon: demPoint.lon,
        oznaczenie: demPoint.oznaczenie,
        note: demPoint.note
      })),
      actions: selectedElements.actions.map((action) => ({
        id: action.id,
        demPointId: action.demPointId,
        demPointOznaczenie: action.demPointOznaczenie,
        demPointNote: action.demPointNote,
        lat: action.geotimestamp.lat,
        lon: action.geotimestamp.lon,
        note: action.note,
        action_type: action.action_type
      }))
    });

    const objectToSend = {
      id_order: orderId,
      name,
      content,
      content_types,
      content_full: JSON.stringify(selectedElements)
    };

    return repository
      .post(`/pgsharing`, objectToSend)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async deletePGSharing(elementId) {
    return repository
      .delete(`/pgsharing/${elementId}`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async fetchReportData(uuid) {
    return repository
      .get(`/pgsharing/${uuid}/GetSharingContentWithTypesByUUID`)
      .then((response) => {
        if (response.data.error == null && response.data != null) return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async fetchSharedMapData(uuid) {
    return repository
      .get(`/pgsharing/${uuid}/GetSharingContentByUUID`)
      .then((response) => {
        if (response.data.error == null && response.data != null) return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  }
};
