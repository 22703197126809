import repository from './repository.js';

export default {
  async getUsersObject(page, itemsPerPage, search, sortBy, orderType, filter, userFilter) {
    let url = '/user';

    if (filter && filter.subject && filter.id != null)
      url = `/${filter.subject}/${filter.id}/users`;
    else if (filter && filter.url) url = filter.url;

    let userFilterUrlEnding = '';

    if (userFilter) {
      for (const property in userFilter) {
        userFilterUrlEnding += `&${property}=${userFilter[property]}`;
      }
    }

    return repository
      .get(
        `${url}?page=${page}&search=${search}&orderBy=${sortBy}&orderType=${orderType}&paginate=${itemsPerPage}${userFilterUrlEnding}`
      )
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((error) => {
        return { error };
      });
  },

  async getUserDetailsGeneral(id) {
    return repository.get(`/user/${id}`).then((response) => {
      if (response.data.error == null) return response.data.data;
    });
  },

  async getUserDataToEdit(id) {
    return repository.get(`/user/${id}/edit`).then((response) => {
      if (response.data.error == null) return response.data.data;
    });
  },

  async createUser(user) {
    return repository
      .post('/user', user)
      .then(function (response) {
        if (response && response.data && response.data.message) return response.data.message;
        else return null;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async updateUser(user) {
    return repository
      .put(`/user/${user.id}`, user)
      .then((response) => {
        return response?.data?.message ?? null;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async updateUsersPassword(userId, newPassword) {
    return repository
      .post(`/user/adminPasswordChange`, { user_id: userId, new_password: newPassword })
      .then((response) => {
        return response?.data?.message ?? null;
      })
      .catch((serverError) => {
        return { serverError };
      });
  }
};
