<script setup>
import { ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import ClusterMap from '@/components/map/ClusterMap.vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { useMapLogic } from '../../composables/mapLogic.js';
import {
  getDemPointsForMapMarkers,
  getActionsWithNoDemPointForMapMarkers,
  getWorkersStartStopMarkers
} from '../../helpers/actions/actionMarkersHelper.js';
import {
  COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME,
  SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME,
  SHOW_ACTION_GALLERY_BUTTON_TYPE_NAME
} from '../../data/constants/buttonsNamesConstants.js';
import { copyToClipboard, isNotEmptyArray } from '../../helpers/utilsHelper.js';
import MapPointsReportsMapFilters from './MapPointsReportsMapFilters.vue';
import { getAvailableActionTypes } from '../../helpers/actions/actionsHelper.js';
import { handleResponseWithMessages } from '../../helpers/wwwHelper.js';
import { useAuthStore } from '../../stores/auth.js';
import { APP_PRIMARY_COLOR } from '../../data/constants/appConstants.js';
import Swal from 'sweetalert2';
import { showOnGoogleMaps } from '../../helpers/navigator';

const RepositoryOrders = RepositoryFactory.get('orders');
const RepositoryMapPointsReports = RepositoryFactory.get('mapPointsReports');

const props = defineProps({ orderId: Number, order: Object });
const emit = defineEmits(['sharingCreated']);
const router = useRouter();
const isLoading = ref(false);
const demPointsForMap = ref(null);
const actionsWithNoDemPointForMap = ref(null);
const workersStartStopData = ref(null);
const disabledActionTypesIds = ref([]);
const disabledFailureReasonsIds = ref([]);
const disabledAdditionalDismantlingSourcesIds = ref([]);
const disabledUserOrdersIds = ref([]);
const enabledUserOrdersStartStopsIds = ref([]);
const showOnlyLastActionsFilterEnabled = ref(false);
const showInactiveDemPointsFilter = ref(true);
const authStore = useAuthStore();
const {
  clusterMap,
  selectedMarkerIds,
  onSelectedMarkersChanged,
  onSingleMarkerSelected,
  resetSelectedIfCtrl
} = useMapLogic();

const availableActionTypes = computed(() => {
  return getAvailableActionTypes(demPointsForMap.value, actionsWithNoDemPointForMap.value);
});

const selectedMarkersData = computed(() => {
  if (!isNotEmptyArray(selectedMarkerIds.value)) return [];

  const demPoints = [];
  const actions = [];

  selectedMarkerIds.value.forEach((selectedMarkerId) => {
    if (selectedMarkerId.toString().startsWith('action_')) {
      let foundAction = actionsWithNoDemPointForMap.value.find(
        (action) => action.id == selectedMarkerId.slice(7)
      );

      if (foundAction == null) {
        for (const demPoint of demPointsForMap.value) {
          if (!isNotEmptyArray(demPoint?.actions)) continue;

          foundAction = demPoint.actions.find((action) => action.id == selectedMarkerId.slice(7));

          if (foundAction != null) {
            foundAction.demPointId = demPoint.id;
            foundAction.demPointOznaczenie = demPoint.oznaczenie;
            foundAction.demPointNote = demPoint.note;
            break;
          }
        }
      }

      if (foundAction != null) {
        actions.push(foundAction);
      }
    } else {
      const foundDemPoint = demPointsForMap.value.find(
        (demPoint) => demPoint.id === selectedMarkerId
      );

      if (foundDemPoint != null) {
        demPoints.push(foundDemPoint);
      }
    }
  });

  return { demPoints, actions };
});

const selectedElementsText = computed(() => {
  if (
    selectedMarkersData.value == null ||
    (!isNotEmptyArray(selectedMarkersData.value?.demPoints) &&
      !isNotEmptyArray(selectedMarkersData.value?.actions))
  )
    return '<div>Zaznaczonych markerów: 0</div>';

  const demPointsCount = selectedMarkersData.value?.demPoints.length;
  const actionsCount = selectedMarkersData.value?.actions.length;

  let text = `<div>Zaznaczonych markerów: ${demPointsCount + actionsCount}</div>`;

  text += `<div class='pl-3'>Punkty: ${demPointsCount}</div>`;
  text += `<div class='pl-3'>Akcje: ${actionsCount}</div>`;

  return text;
});

watch(
  () => props.orderId,
  () => {
    initialize();
  }
);

// created
if (props.orderId != null) {
  initialize();
}

function initialize() {
  fetchMarkersInfo(true);
}

async function fetchMarkersInfo(setBoundsAfterRefresh = false) {
  isLoading.value = true;

  demPointsForMap.value = await RepositoryOrders.getDemPointsForMap(props.orderId);
  actionsWithNoDemPointForMap.value = await RepositoryOrders.getActionsWithNoDemPointForMap(
    props.orderId
  );

  workersStartStopData.value = await RepositoryOrders.getWorkersStartStopData(props.orderId);
  prepareMarkersForMap(setBoundsAfterRefresh);

  isLoading.value = false;
}

function prepareMarkersForMap(setBoundsAfterRefresh = false) {
  const demPointsForMapMarkers = getDemPointsForMapMarkers(
    demPointsForMap.value,
    disabledActionTypesIds.value,
    disabledFailureReasonsIds.value,
    disabledAdditionalDismantlingSourcesIds.value,
    disabledUserOrdersIds.value,
    showOnlyLastActionsFilterEnabled.value,
    showInactiveDemPointsFilter.value,
    true,
    false,
    '',
    true,
    authStore,
    true,
    { selectableActionsOverride: true, selectableDemPointsOverride: true }
  );

  const actionsWithNoDemPointForMapMarkers = getActionsWithNoDemPointForMapMarkers(
    actionsWithNoDemPointForMap.value,
    disabledActionTypesIds.value,
    disabledFailureReasonsIds.value,
    disabledAdditionalDismantlingSourcesIds.value,
    disabledUserOrdersIds.value,
    true,
    '',
    authStore,
    true,
    { selectableActionsOverride: true }
  );

  const workersStartStopMarkers = getWorkersStartStopMarkers(
    workersStartStopData.value,
    enabledUserOrdersStartStopsIds.value
  );

  const markers = demPointsForMapMarkers.concat(
    actionsWithNoDemPointForMapMarkers,
    workersStartStopMarkers
  );

  clusterMap.value.initializeMap({ markersData: markers, setBoundsAfterRefresh });
}

function onMapClickableClicked(clickable) {
  let actionId = clickable.getAttribute('id_action');
  let clickActionType = clickable.getAttribute('click_action_type');

  if (clickActionType == undefined) return;

  const lat = clickable.getAttribute('lat');
  const lon = clickable.getAttribute('lon');
  const actionTypeName = clickable.getAttribute('action_type_name');

  switch (clickActionType) {
    case COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME:
      if (lat != undefined && lon != undefined) {
        copyToClipboard(lat + ',' + lon);
      }
      break;
    case SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME:
      if (lat != undefined && lon != undefined) {
        showOnGoogleMaps(lat, lon);
      }
      break;
    case SHOW_ACTION_GALLERY_BUTTON_TYPE_NAME:
      if (actionId != undefined && actionTypeName != undefined) {
        onShowActionGalleryButtonClicked(actionId, actionTypeName);
      }
      break;
  }
}

function onShowActionGalleryButtonClicked(actionId, actionTypeName) {
  let routeData = router.resolve({
    name: 'singleActionGallery',
    query: {
      orderId: props.orderId,
      actionId: actionId,
      actionTypeName: actionTypeName,
      orderNumber: props.order.order_number
    }
  });

  window.open(routeData.href, '_blank');
}

function onFiltersChanged(
  updatedDisabledActionTypesIds,
  updatedDisabledFailureReasonsIds,
  updatedDisabledAdditionalDismantlingSourcesIds,
  updatedDisabledUserOrdersIds,
  updatedEnabledUserOrdersStartStopsIds,
  updatedShowOnlyLastActionsFilter,
  updatedShowInactiveDemPointsFilter
) {
  disabledActionTypesIds.value = updatedDisabledActionTypesIds;
  disabledFailureReasonsIds.value = updatedDisabledFailureReasonsIds;
  disabledAdditionalDismantlingSourcesIds.value = updatedDisabledAdditionalDismantlingSourcesIds;
  disabledUserOrdersIds.value = updatedDisabledUserOrdersIds;
  enabledUserOrdersStartStopsIds.value = updatedEnabledUserOrdersStartStopsIds;
  showOnlyLastActionsFilterEnabled.value = updatedShowOnlyLastActionsFilter;
  showInactiveDemPointsFilter.value = updatedShowInactiveDemPointsFilter;

  prepareMarkersForMap();
}

function onMarkerClickedOverride(markerToSelect) {
  onSingleMarkerSelected(markerToSelect);
}

function onMapMouseUp() {
  if (clusterMap.value?.isBeingDragged === false) {
    resetSelectedIfCtrl();
  }
}

function onShareButtonClicked() {
  const swalConfiguration = {
    title: 'Nowe udostępnienie',
    html: 'Podaj nazwę udostępnienia.',
    icon: 'info',
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonColor: `#${APP_PRIMARY_COLOR}`,
    cancelButtonColor: '#f8ac59',
    confirmButtonText: 'Utwórz',
    cancelButtonText: 'Anuluj',
    customClass: {
      title: 'swal2-title'
    },
    width: '',
    input: 'text',
    preConfirm: (shareName) => {
      return shareName.length > 2
        ? shareName
        : Swal.showValidationMessage(
            `Nazwa udostępnienia powinna składać się z przynajmniej 3 znaków.`
          );
    }
  };

  Swal.fire(swalConfiguration).then((result) => {
    if (result.value) {
      createPGShare(result.value);
    }
  });
}

async function createPGShare(name) {
  var response = await RepositoryMapPointsReports.createPGShare(
    props.orderId,
    name,
    selectedMarkersData.value
  );

  handleResponseWithMessages(response, 'Udostępnienie zostało utworzone.', () => {
    emit('sharingCreated');
  });
}

function refreshMapBounds() {
  clusterMap.value.refreshMapAfterTick();
}

defineExpose({ refreshMapBounds });
</script>

<template>
  <div>
    <h4 class="mb-3">Mapa punktów i akcji</h4>

    <div class="row">
      <div class="col-lg-8">
        <div class="ibox">
          <div
            class="ibox-content p-0"
            :class="{ 'sk-loading': isLoading }"
            style="border-style: none"
          >
            <div class="sk-spinner sk-spinner-three-bounce">
              <div class="sk-bounce1"></div>
              <div class="sk-bounce2"></div>
              <div class="sk-bounce3"></div>
            </div>

            <ClusterMap
              ref="clusterMap"
              height="700px"
              :showZeroPositions="false"
              :disableClusteringAtZoomOverride="19"
              :maxClusterRadiusOverride="1"
              :refreshable="true"
              :areaSelecting="true"
              @selected-markers-changed="onSelectedMarkersChanged"
              @markerClicked="onMarkerClickedOverride"
              @mapMouseUp="onMapMouseUp"
              @clickableClicked="onMapClickableClicked"
            >
              <button
                class="btn btn-primary"
                style="position: absolute; bottom: 10px; left: 10px; z-index: 500"
                @click="refreshMapBounds"
              >
                <i class="fa fa-refresh" /></button
            ></ClusterMap>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="tabs-container mb-3">
          <ul class="nav nav-tabs" role="tablist">
            <li>
              <a class="nav-link active" data-toggle="tab">
                <i class="fa fa-info" />
                Informacje
              </a>
            </li>
          </ul>

          <div class="tab-content">
            <div role="tabpanel" class="tab-pane active">
              <div class="panel-body">
                <div style="display: flex; justify-content: space-between; gap: 10px">
                  <div>
                    <h4>Podsumowanie:</h4>
                    <span v-html="selectedElementsText" />
                  </div>

                  <div style="display: flex; align-items: flex-start; justify-content: flex-end">
                    <button
                      :disabled="selectedMarkerIds.length === 0"
                      class="btn btn-primary btn-sm"
                      @click="onShareButtonClicked"
                    >
                      Generuj udostępnienie
                    </button>
                  </div>
                </div>

                <MapPointsReportsMapFilters
                  :orderId="props.orderId"
                  :order="props.order"
                  :actionTypes="availableActionTypes"
                  @filtersChanged="onFiltersChanged"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
