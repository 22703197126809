<script setup>
import { ref, watch, computed, nextTick, toRefs, onMounted } from 'vue';
import { LMap, LTileLayer, LFeatureGroup, LGeoJson } from '@vue-leaflet/vue-leaflet';
import { latLng, latLngBounds } from 'leaflet';
import { getLocalFormattedDateAndTime } from '@/helpers/dateFormatHelper.js';
import { SimpleMapScreenshoter } from 'leaflet-simple-map-screenshoter';
import LeafletDraw from '@/components/map/LeafletDraw.vue';
import { getMarker } from '@/helpers/markersCreator.js';
import { MarkerClusterGroup } from 'leaflet.markercluster';
import { useMapStore } from '@/stores/map';
import { useLogger } from '../../composables/logger.js';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import { isNotEmptyArray } from '../../helpers/utilsHelper';
import { useFeatureGroups } from '../../composables/map/featureGroups';
import { groupByClusterGroupId } from '../../helpers/mapHelper.js';
import { ClusterGroupsSettings } from '../../data/models/ClusterGroupsSettings.js';

const MAP_ELEMENT_ID = 'lMap';
const MAP_MAX_ZOOM_LEVEL = 19;

const props = defineProps({
  height: { type: String, required: false, default: '400px' },
  showZeroPositions: { type: Boolean, default: true },
  centerLat: { type: Number, default: 50.29044 },
  centerLng: { type: Number, default: 21.42891 },
  zoomLvl: { type: Number, default: 13 },
  refreshable: { type: Boolean, default: false },
  geom: { type: Object, default: null },
  addScreenShooter: { type: Boolean, default: false },
  areaSelecting: { type: Boolean, default: false },
  disableClusteringAtZoomOverride: { type: Number, default: null },
  maxClusterRadiusOverride: { type: Number, default: null }
});

const emit = defineEmits([
  'mapClicked',
  'markerClicked',
  'markerDragged',
  'selectedMarkersChanged',
  'clickableClicked',
  'mapMoveStarted',
  'mapMoveEnded',
  'mapMouseUp',
  'popupClosed'
]);

const map = ref(null);
const features = ref(null);
const mapClickBlocked = ref(false);
const currentCenter = ref(0);
const currentZoom = ref(0);
const mapOptions = ref({ zoomSnap: 0.5, maxZoom: MAP_MAX_ZOOM_LEVEL });
const mapStore = useMapStore();
const screenShooter = ref(null);
const clusterGroups = ref([]);
const clusterOptions = ref({ disableClusteringAtZoom: 11 });
const markersRef = ref([]);
const mapReady = ref(false);
const { conditionalLog } = useLogger(false);
let onMapReadyAction = null;
const {
  createAdditionalMarker,
  createAdditionalMarkerWithConnection,
  removeFeatureGroupWithKey,
  removeAllFeatureGroups,
  createEmptyClusterGroup,
  createEmptyFeatureGroup
} = useFeatureGroups(map, features);
const isBeingDragged = ref(false);

// computed
const center = computed(() => {
  return props.geom == null ? latLng(props.centerLat, props.centerLng) : null;
});

const zoom = computed(() => {
  return props.zoomLvl;
});

const geomValid = computed(() => {
  return props.geom && props.geom.type && props.geom.coordinates;
});

// watch
watch(
  () => mapStore.mapRefreshKey,
  () => {
    if (!props.refreshable) return;

    refreshMapAfterTick();
  }
);

//methods
function initializeMap({
  markersData,
  clusterGroupsSettingsOverride = null,
  additionalAction = null,
  setBoundsAfterRefresh = true
}) {
  removeAllGroups();
  conditionalLog('initializeMap');

  if (mapReady.value) {
    if (isNotEmptyArray(markersData)) {
      initializeMarkers({
        markersData,
        clusterGroupsSettingsOverride,
        additionalAction,
        setBoundsAfterRefresh
      });
    } else if (typeof additionalAction === 'function') {
      additionalAction();
    } else {
      refresh(setBoundsAfterRefresh);
    }
  } else {
    onMapReadyAction = () => {
      if (isNotEmptyArray(markersData)) {
        initializeMarkers({
          markersData,
          clusterGroupsSettingsOverride,
          additionalAction,
          setBoundsAfterRefresh
        });
      } else if (typeof additionalAction === 'function') {
        additionalAction();
      } else {
        refresh(setBoundsAfterRefresh);
      }
    };
  }
}

function removeAllGroups() {
  conditionalLog('removeAllGroups');

  if (isNotEmptyArray(clusterGroups.value)) {
    clusterGroups.value.forEach((group) => {
      features.value.leafletObject.removeLayer(group);
    });
    clusterGroups.value = [];
  }

  removeAllFeatureGroups();
  markersRef.value = [];
}

function initializeMarkers({
  markersData,
  clusterGroupsSettingsOverride = null,
  additionalAction = null,
  setBoundsAfterRefresh = true
}) {
  conditionalLog('initializeMarkers');

  const groupedMarkers = groupByClusterGroupId(markersData);
  const clustersMarkersData = groupedMarkers.map((markersGroup) => ({
    clusterGroupId: markersGroup[0].clusterGroupId,
    markersData: markersGroup
  }));

  addClusteredMarkers({
    clustersMarkersData,
    clusterGroupsSettingsOverride,
    refreshAfter: true,
    additionalAction,
    setBoundsAfterRefresh
  });
}

function onMapClicked(event) {
  if (!mapClickBlocked.value && event?.target?.id === MAP_ELEMENT_ID) {
    conditionalLog('onMapClicked');
    emit('mapClicked');
  }
}

function onMarkerClick(marker) {
  conditionalLog('onMarkerClick');
  emit('markerClicked', marker);
}

function onMarkerMoveEnd(marker) {
  conditionalLog('onMarkerMoveEnd');
  emit('markerDragged', marker._latlng, marker.data);
}

function refresh(setBoundsAsWell = true) {
  conditionalLog('refresh');

  if (mapReady.value) {
    map.value.leafletObject.invalidateSize();
  }

  if (setBoundsAsWell) {
    setBounds();
  }
}

async function refreshMapAfterTick(setBounds = true) {
  conditionalLog('refreshMapAfterTick');
  await nextTick();
  await nextTick();
  refresh(setBounds);
}

function setBounds(padValue = 0.2, durationSecs = 0.0) {
  if (markersRef.value.length === 0) return;

  let group = features.value.leafletObject;
  const bounds = group.getBounds().pad(padValue);

  map.value.leafletObject.flyToBounds(bounds, {
    duration: durationSecs,
    animate: durationSecs > 0.0,
    easeLinearity: durationSecs > 0.0 ? 0.25 : undefined,
    noMoveStart: true
  });
}

function centerUpdate(center) {
  currentCenter.value = center;
}

function zoomUpdate(zoom) {
  currentZoom.value = zoom;
}

function onMapReady() {
  conditionalLog('onMapReady');

  if (props.addScreenShooter) addScreenShooterToMap();

  onMapReadyAction?.call();
  onMapReadyAction = null;

  mapReady.value = true;
}

function addScreenShooterToMap() {
  let pluginOptions = {
    cropImageByInnerWH: true, // crop blank opacity from image borders
    hidden: false, // hide screen icon
    domtoimageOptions: {}, // see options for dom-to-image
    position: 'topright', // position of take screen icon
    screenName: 'Zrzut mapy ' + getLocalFormattedDateAndTime(new Date().toJSON()), // string or function
    //iconUrl: ICON_SVG_BASE64, // screen btn icon base64 or url
    hideElementsWithSelectors: ['.leaflet-control-container'], // by default hide map controls All els must be child of _map._container
    mimeType: 'image/png', // used if format == image,
    caption: null, // string or function, added caption to bottom of screen
    captionFontSize: 15,
    captionFont: 'Arial',
    captionColor: 'black',
    captionBgColor: 'white',
    captionOffset: 5
  };

  screenShooter.value = new SimpleMapScreenshoter(pluginOptions).addTo(map.value.leafletObject);
}

function geomReady() {
  setBounds();
}

function emitNewGeometry(ev) {
  let area = ev.layer;
  let selectedMarkers = [];

  markersRef.value.forEach((marker) => {
    if (area.getBounds().contains(latLng(marker.data.lat, marker.data.lng))) {
      selectedMarkers.push(marker);
    }
  });

  emit('selectedMarkersChanged', selectedMarkers);

  mapClickBlocked.value = true;
  setTimeout(() => {
    mapClickBlocked.value = false;
  }, 500);
}

// In order to add markers as multiple clusters groups, just add 'clusterGroupId' field to each marker's data that should be in a specific cluster group
async function addClusteredMarkers({
  clustersMarkersData,
  clusterGroupsSettingsOverride = null,
  refreshAfter = true,
  additionalAction = null,
  setBoundsAfterRefresh = true
}) {
  conditionalLog('addClusteredMarkers');

  if (!isNotEmptyArray(clustersMarkersData)) {
    console.error('addClusteredMarkers(): ClustersMarkersData, must be a non-empty array.');
    return;
  }

  removeAllGroups();

  for (let i = 0; i < clustersMarkersData.length; i++) {
    const clusterMarkersData = clustersMarkersData[i];

    let customClusterSettings = null;
    if (
      clusterMarkersData.clusterGroupId != null &&
      clusterGroupsSettingsOverride instanceof ClusterGroupsSettings
    ) {
      customClusterSettings = clusterGroupsSettingsOverride.get(clusterMarkersData.clusterGroupId);
    }

    const newClusterGroup = new MarkerClusterGroup(customClusterSettings ?? clusterOptions.value);
    features.value.leafletObject.addLayer(newClusterGroup);
    await nextTick();

    let markersToAdd = clusterMarkersData.markersData.filter(
      (markerData) =>
        props.showZeroPositions === true ||
        (Number.parseFloat(markerData.lat) !== 0.0 && Number.parseFloat(markerData.lng) !== 0.0)
    );

    if (markersToAdd.length === 0) continue;

    newClusterGroup.addLayers(
      markersToAdd.map((markerData) => {
        const newMarker = getMarker(markerData, onMarkerClick, onMarkerMoveEnd);
        markersRef.value.push(newMarker);
        return newMarker;
      })
    );

    clusterGroups.value.push(newClusterGroup);
  }

  await nextTick();

  if (typeof additionalAction === 'function') {
    additionalAction();
  }

  if (refreshAfter) {
    refresh(setBoundsAfterRefresh);
  }
}

function tryToOpenMarkersPopup(markerId) {
  const marker = markersRef.value.find(
    (marker) => marker?.markerId != null && marker.markerId === markerId
  );

  if (marker) {
    marker.openPopup();
  }
}

function addClickListener() {
  document.getElementById('baseDivId').addEventListener('click', (ev) => {
    if (ev?.target == undefined) return;

    let clickable = undefined;

    if (ev.target.getAttribute('clickable') != undefined) {
      clickable = ev.target;
    } else if (ev.target.getAttribute('clickable_child') != undefined) {
      clickable = ev.target.parentElement;
    }

    if (clickable != undefined) {
      emit('clickableClicked', clickable);
    }
  });
}

function addMarker(featureGroupKey, markerData) {
  const newMarker = createAdditionalMarker(
    featureGroupKey,
    markerData,
    onMarkerClick,
    onMarkerMoveEnd
  );
  markersRef.value.push(newMarker);

  return newMarker;
}

function addConnectedMarker(
  featureGroupKey,
  markerData,
  markerToConnectTo,
  fitConnectionBounds,
  lineColor = 'red',
  showLineDelayMilis = null
) {
  const newMarker = createAdditionalMarkerWithConnection(
    featureGroupKey,
    markerData,
    onMarkerClick,
    onMarkerMoveEnd,
    markerToConnectTo,
    fitConnectionBounds,
    lineColor,
    showLineDelayMilis
  );

  markersRef.value.push(newMarker);

  return newMarker;
}

// returns true if removed
function removeFeatureGroup(featureGroupKey) {
  conditionalLog('removeFeatureGroup with key: ' + featureGroupKey);
  const result = removeFeatureGroupWithKey(featureGroupKey);
  updateMarkersRef();
  return result;
}

function updateMarkersRef() {
  markersRef.value = markersRef.value.filter((markerRef) => markerRef._map != null);
}

async function focusOnMarker(marker) {
  await nextTick();

  map.value.leafletObject.setView(marker.getLatLng(), MAP_MAX_ZOOM_LEVEL);
  tryToOpenMarkersPopup(marker.markerId);
}

async function loadMapState(center, zoom, openedTooltipsMarkersIds) {
  await nextTick();

  if (center != undefined && zoom != undefined) {
    map.value.leafletObject.setView(center, zoom);
  }

  if (isNotEmptyArray(openedTooltipsMarkersIds)) {
    openedTooltipsMarkersIds.forEach((markerId) => {
      tryToOpenMarkersPopup(markerId);
    });
  }
}

function onMapMoveStarted() {
  isBeingDragged.value = true;
  conditionalLog('onMapMoveStarted');
  emit('mapMoveStarted');
}

function onMapMoveEnded() {
  isBeingDragged.value = false;
  conditionalLog('onMapMoveEnded');
  emit('mapMoveEnded');
}

function onMapMouseUp(element) {
  if (element.target !== map.value.leafletObject.getContainer()) return;

  conditionalLog('onMapMouseUp');
  emit('mapMouseUp');
}

function onPopupClose() {
  conditionalLog('onPopupClose');
  emit('popupClosed');
}

function ensureMarkerVisible(marker, moveDurationSecs = 0.0, zoomOutEvenMore = false) {
  const markerLatLng = marker.getLatLng();
  const mapBounds = map.value.leafletObject.getBounds();

  if (mapBounds.contains(markerLatLng)) return;

  const targetBounds = latLngBounds(mapBounds.extend(markerLatLng));

  map.value.leafletObject.flyToBounds(targetBounds, {
    padding: [20, 20],
    maxZoom: map.value.leafletObject.getZoom() - (zoomOutEvenMore ? 1 : 0),
    animate: moveDurationSecs > 0.0,
    duration: moveDurationSecs,
    noMoveStart: true
  });
}

function ensureFeatureGroupVisible(featureGroup, moveDurationSecs = 0.0, zoomOutEvenMore = false) {
  const groupBounds = featureGroup.getBounds();
  const mapBounds = map.value.leafletObject.getBounds();

  if (mapBounds.contains(groupBounds)) return;

  const targetBounds = groupBounds;

  map.value.leafletObject.flyToBounds(targetBounds, {
    padding: [20, 20],
    maxZoom: map.value.leafletObject.getZoom() - (zoomOutEvenMore ? 1 : 0),
    animate: moveDurationSecs > 0.0,
    duration: moveDurationSecs,
    noMoveStart: true
  });
}

// actual setup
if (props.disableClusteringAtZoomOverride) {
  let { disableClusteringAtZoomOverride } = toRefs(props);
  clusterOptions.value.disableClusteringAtZoom = disableClusteringAtZoomOverride;
}

if (props.maxClusterRadiusOverride) {
  let { maxClusterRadiusOverride } = toRefs(props);
  clusterOptions.value.maxClusterRadius = maxClusterRadiusOverride;
}

onMounted(() => {
  addClickListener();
});

defineExpose({
  refreshMapAfterTick,
  currentCenter,
  currentZoom,
  tryToOpenMarkersPopup,
  initializeMap,
  markersRef,
  addMarker,
  addConnectedMarker,
  removeFeatureGroup,
  focusOnMarker,
  isBeingDragged,
  loadMapState,
  ensureMarkerVisible,
  ensureFeatureGroupVisible,
  createEmptyClusterGroup,
  createEmptyFeatureGroup
});
</script>

<template>
  <div id="baseDivId" :style="'height: ' + height + ';'" style="width: 100%; position: relative">
    <slot></slot>
    <LMap
      :id="MAP_ELEMENT_ID"
      ref="map"
      :zoomAnimation="false"
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      @ready="onMapReady"
      @click="onMapClicked"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
      @l-draw-created="emitNewGeometry"
      @movestart="onMapMoveStarted"
      @moveend="onMapMoveEnded"
      @mouseup="onMapMouseUp"
      @popupclose="onPopupClose"
      :useGlobalLeaflet="true"
    >
      <LTileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        layer-type="base"
        name="OpenStreetMap"
        :maxZoom="MAP_MAX_ZOOM_LEVEL"
      ></LTileLayer>

      <LFeatureGroup ref="features">
        <LGeoJson v-if="geomValid" :geojson="props.geom" @ready="geomReady" />
      </LFeatureGroup>

      <LeafletDraw v-if="props.areaSelecting" :rectangle="true"></LeafletDraw>
    </LMap>
  </div>
</template>

<style scoped></style>
