import repository from './repository.js';

export default {
  async resetPassword(token, newPassword) {
    return repository
      .post('/user/passwordReset', { token, newPassword })
      .then((response) => {
        if (response && response.data && response.data.message) return response.data.message;
        else return null;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async sendResetPasswordLink(email) {
    return repository
      .post('/user/passwordResetMail', { email })
      .then((response) => {
        if (response && response.data && response.data.message) return response.data.message;
        else return null;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async changePassword(user_id, oldPassword, newPassword) {
    return repository
      .post('/user/passwordChange', { user_id, oldPassword, newPassword })
      .then((response) => {
        if (response && response.data && response.data.message) return response.data.message;
        else return null;
      })
      .catch((serverError) => {
        return { serverError };
      });
  }
};
