<script setup>
import { ref, computed } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { handleResponseWithMessages } from '@/helpers/wwwHelper.js';
import WizardComponent from '../utils/WizardComponent.vue';
import CoordsFromAddressStep1 from './CoordsFromAddressStep1.vue';
import CoordsFromAddressStep2 from './CoordsFromAddressStep2.vue';
import CoordsFromAddressStep3 from './CoordsFromAddressStep3.vue';

const RepositoryAddresses = RepositoryFactory.get('addresses');

const props = defineProps({ orderId: { type: Number, required: true } });
const emit = defineEmits(['allStepsFinished', 'refresh']);
const wizardComponent = ref();
const address = ref(null);
const point = ref(null);
const demElemTypeId = ref(null);
const note = ref(null);
const showDraggableMarker = ref(false);

const nextButtonForSecondStepDisabled = computed(() => {
  return !point.value;
});

const nextButtonForThirdStepDisabled = computed(() => {
  return !demElemTypeId.value;
});

const summaryAddressName = computed(() => {
  return address.value?.address ?? null;
});

const summaryQuantity = computed(() => {
  return address.value?.quantity ?? null;
});

const summaryCoords = computed(() => {
  return point.value?.lat
    ? { lat: point.value.lat, lon: point.value.lon ?? point.value.lng }
    : { lat: null, lon: null };
});

function onAddressPicked(pickedAddress) {
  address.value = pickedAddress;
  wizardComponent.value.goToNextStep();
}

function onPointPicked(pickedPoint) {
  point.value = pickedPoint;
}

function onDemElemTypePicked(pickedDemElemTypeId) {
  demElemTypeId.value = pickedDemElemTypeId;
}

function onNoteUpdated(newNote) {
  note.value = newNote;
}

function toggleDraggableMarker() {
  showDraggableMarker.value = !showDraggableMarker.value;
}

function resetAddress() {
  address.value = null;
}

function onFinished() {
  sendSetGeocoding();
  emit('allStepsFinished');
}

async function sendSetGeocoding() {
  var response = await RepositoryAddresses.setGeocoding(
    address.value?.id,
    address.value?.address,
    summaryCoords.value?.lat,
    summaryCoords.value?.lon,
    demElemTypeId.value,
    note.value !== '' ? note.value : null
  );

  handleResponseWithMessages(response, 'Dane zostały przesłane.', () => {
    emit('refresh');
  });
}

function onStep3PreviousAction() {
  point.value = null;
  demElemTypeId.value = null;
  note.value = null;
}
</script>

<template>
  <WizardComponent
    ref="wizardComponent"
    :steps="[
      {
        number: 1,
        header: '1. Lista adresów',
        onPreviousAction: null,
        onNextAction: null,
        previousButtonDisabled: true,
        nextButtonDisabled: true
      },
      {
        number: 2,
        header: '2. Pozyskane adresy',
        onPreviousAction: resetAddress,
        onNextAction: null,
        previousButtonDisabled: false,
        nextButtonDisabled: nextButtonForSecondStepDisabled
      },
      {
        number: 3,
        header: '3. Typ i podsumowanie',
        onPreviousAction: onStep3PreviousAction,
        onNextAction: null,
        previousButtonDisabled: false,
        nextButtonDisabled: nextButtonForThirdStepDisabled
      }
    ]"
    :onFinishAction="onFinished"
  >
    <template #wizardContent1>
      <CoordsFromAddressStep1 :orderId="props.orderId" @addressPicked="onAddressPicked" />
    </template>
    <template #wizardContent2>
      <CoordsFromAddressStep2
        :orderId="props.orderId"
        :addressId="address?.id"
        :showDraggableMarker="showDraggableMarker"
        @pointPicked="onPointPicked"
      />
    </template>
    <template #wizardButtons2>
      <div class="btn btn-warning" @click="toggleDraggableMarker">
        <span v-if="!showDraggableMarker">Pokaż własny marker</span
        ><span v-else>Ukryj własny marker</span>
      </div>
    </template>
    <template #wizardContent3>
      <CoordsFromAddressStep3
        :addressId="address?.id"
        :addressName="summaryAddressName"
        :quantity="summaryQuantity"
        :coords="summaryCoords"
        @demElemTypePicked="onDemElemTypePicked"
        @noteUpdated="onNoteUpdated"
      />
    </template>
  </WizardComponent>
</template>

<style scoped></style>
