import repository from './repository.js';

export default {
  async getUserTypesObject(page, itemsPerPage, search, sortBy, orderType) {
    return repository
      .get(
        `/usertype?page=${page}&search=${search}&orderBy=${sortBy}&orderType=${orderType}&paginate=${itemsPerPage}`
      )
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((error) => {
        return { error };
      });
  },

  async getDictionaryElementsObject(
    page,
    itemsPerPage,
    search,
    sortBy,
    orderType,
    url,
    userFilter = null
  ) {
    let userFilterUrlEnding = '';

    if (userFilter) {
      for (const property in userFilter) {
        if (Array.isArray(userFilter[property])) {
          userFilter[property].forEach((x) => {
            userFilterUrlEnding += `&${property}[]=${x}`;
          });
        } else userFilterUrlEnding += `&${property}=${userFilter[property]}`;
      }
    }

    return repository
      .get(
        `/${url}?page=${page}&search=${search}&orderBy=${sortBy}&orderType=${orderType}&paginate=${itemsPerPage}${userFilterUrlEnding}`
      )
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((error) => {
        return { error };
      });
  }
};
