import { getDateWithoutTime, getLocalFormattedDateAndTime } from '@/helpers/dateFormatHelper.js';
import { createToaster } from '@meforma/vue-toaster';

export function isNotEmptyArray(object) {
  return Array.isArray(object) && object.length > 0;
}

export function showFileInNewTab(fileToShow) {
  if (fileToShow?.resource?.path != null) {
    window.open(import.meta.env.VITE_VUE_APP_IMAGES_URL + fileToShow.resource.path, '_blank');
  }
}

export function getCurrentDate() {
  return getDateWithoutTime(getLocalFormattedDateAndTime(new Date().toJSON()));
}

export function copyToClipboard(textToCopy, overwriteMessage = null) {
  navigator.clipboard.writeText(textToCopy);
  const toaster = createToaster({ position: 'top-right' });
  const message = overwriteMessage ?? 'Skopiowano do schowka: ' + textToCopy;

  toaster.show(message, { duration: 3000, type: 'info' });
}

export function generateRandomString(length, numbers = false, specialCharacters = false) {
  let chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

  if (numbers) chars += '0123456789';
  if (specialCharacters) chars += '!@#$%^&*()';

  const charLength = chars.length;
  let result = '';

  for (var i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * charLength));
  }

  return result;
}

export function logFormData(formData) {
  for (var pair of formData.entries()) {
    console.log(pair[0] + ': ' + pair[1]);
  }
}

export function pushSafe(array, obj) {
  if (Array.isArray(array)) {
    array.push(obj);
  } else {
    array = [obj];
  }

  return array;
}

export function isNullOrEmptyString(text) {
  return !text || text.trim().length === 0;
}

export function isNonEmptyString(text) {
  return !isNullOrEmptyString(text);
}

export function computeProgress(done, all, decimalPlaces = 1) {
  const doneFloat = parseFloat(done);
  const allFloat = parseFloat(all);

  if (isNaN(doneFloat) || isNaN(allFloat)) return undefined;
  if (allFloat === 0) return '0%';

  return ((doneFloat / allFloat) * 100).toFixed(decimalPlaces) + '%';
}

export function formatRemainingTimeInPolish(seconds) {
  const hourForms = ['godzina', 'godziny', 'godzin'];
  const minuteForms = ['minuta', 'minuty', 'minut'];
  const secondForms = ['sekunda', 'sekundy', 'sekund'];

  const getPolishForm = (value, forms) => {
    if (value === 1) return forms[0];
    if (
      (value >= 2 && value <= 4) ||
      (value >= 22 && value <= 24) ||
      (value >= 32 && value <= 34) ||
      (value >= 42 && value <= 44) ||
      (value >= 52 && value <= 54)
    )
      return forms[1];
    return forms[2];
  };

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let result = '';

  if (hours > 0) {
    result += `${hours} ${getPolishForm(hours, hourForms)}`;
  }

  if (minutes > 0) {
    if (hours > 0) {
      result += ` i `;
    }
    result += `${minutes} ${getPolishForm(minutes, minuteForms)}`;
  }

  if (remainingSeconds > 0) {
    if (hours > 0 || minutes > 0) {
      result += ` i `;
    }
    result += `${remainingSeconds} ${getPolishForm(remainingSeconds, secondForms)}`;
  }

  if (hours === 0 && minutes === 0 && remainingSeconds === 0) {
    result += `${remainingSeconds} ${getPolishForm(remainingSeconds, secondForms)}`;
  }

  return result;
}

export function trimString(str, maxChars = 100) {
  return isNonEmptyString(str) && str.length > maxChars ? str.slice(0, maxChars) + '...' : str;
}

export function waitUntil(condition, checkFrequencyMs = 50) {
  return new Promise((resolve) => {
    const check = () => {
      if (condition) {
        resolve();
      } else {
        setTimeout(check, checkFrequencyMs);
      }
    };
    check();
  });
}
