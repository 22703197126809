<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
  initialOptions: { type: Array, default: () => new Array() }
});

const emit = defineEmits(['onChanged']);
const options = ref(null);
const activeOption = ref(null);

watch(
  () => props.initialOptions,
  () => {
    initialize();
  },
  { immediate: true }
);

function initialize() {
  if (options.value) return;

  options.value = props.initialOptions;
  options.value.forEach((option) => {
    if (option.checked) activeOption.value = option;
  });
}

function onRadioButtonClicked(clickedOption) {
  options.value.forEach((option) => {
    if (option.id === clickedOption.id) {
      option.checked = true;
      activeOption.value = option;
    } else option.checked = false;
  });

  emit('onChanged', activeOption.value.id);
}
</script>

<template>
  <div class="btn-group btn-group-toggle">
    <label
      v-for="option in options"
      :key="option.id"
      class="btn btn-sm btn-white"
      :class="option.checked ? `active` : ``"
      @click="onRadioButtonClicked(option)"
    >
      <input type="radio" autocomplete="off" :checked="option.checked" />
      {{ option.title }}
    </label>
  </div>
</template>

<style scoped></style>
