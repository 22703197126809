<script setup>
/* eslint-env jquery */
import { ref, onMounted } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { isNotEmptyArray } from '../../helpers/utilsHelper.js';
import { getDateWithoutTime } from '../../helpers/dateFormatHelper.js';

const RepositoryTransportOrders = RepositoryFactory.get('transportOrders');

const isLoading = ref(false);
const startDate = ref();
const endDate = ref();

onMounted(() => {
  setInitialDates();

  const datepickerConfig = {
    todayBtn: 'linked',
    keyboardNavigation: true,
    forceParse: true,
    calendarWeeks: true,
    autoclose: true,
    weekStart: 1
  };

  $('#transportReportDateRangeId .input-daterange').datepicker(datepickerConfig);
  $('#transportReportStartDateId').datepicker('setDate', startDate.value);
  $('#transportReportEndDateId').datepicker('setDate', endDate.value);
  $(document.body).on('change', 'input[id^="transportReportStartDateId"]', updateDateFromPicker);
  $(document.body).on('change', 'input[id^="transportReportEndDateId"]', updateDateFromPicker);
});

function setInitialDates() {
  let now = new Date();
  let month = now.getMonth() + 1;
  let monthString = month.toString().length === 1 ? '0' + month : month;
  let day =
    now.getDate().toString().length === 1
      ? '0' + now.getDate().toString()
      : now.getDate().toString();

  startDate.value = now.getFullYear() + '-' + monthString + '-' + day;
  endDate.value = now.getFullYear() + '-' + monthString + '-' + day;
}

function updateDateFromPicker() {
  startDate.value = document.getElementById('transportReportStartDateId').value;
  endDate.value = document.getElementById('transportReportEndDateId').value;
}

function submitForm(e) {
  e.preventDefault();
  exportData();
}

function exportData() {
  isLoading.value = true;

  RepositoryTransportOrders.getTransportOrdersReport(startDate.value, endDate.value)
    .then((data) => {
      generateXlsx(data);
    })
    .catch((error) => console.log(error))
    .finally(() => {
      isLoading.value = false;
    });
}

function generateXlsx(data) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Raport zebranych rzeczy');
  const dictionary = data.dictionary;
  const labelsFontStyle = { size: 12, bold: true };

  const chosenRangeTagRow = worksheet.addRow(['Wybrany okres:']);
  chosenRangeTagRow.font = labelsFontStyle;
  worksheet.addRow([`${getDateWithoutTime(data.date_from)} - ${getDateWithoutTime(data.date_to)}`]);
  worksheet.addRow();

  const elementsTagRow = worksheet.addRow(['Zebrane elementy:']);
  elementsTagRow.font = labelsFontStyle;

  data.general_stats.sort((a, b) => a.id - b.id);

  data.general_stats.forEach((stat) => {
    worksheet.addRow([`${stat.name}:`, `${stat.number}`]);
  });

  worksheet.addRow();

  if (isNotEmptyArray(data?.workers)) {
    const elementsByDriversTagRow = worksheet.addRow(['Pogrupowane według kierowców:']);
    elementsByDriversTagRow.font = labelsFontStyle;
    worksheet.addRow();

    const headers = prepareWorkersSectionHeadersRow(dictionary);
    const headersRow = worksheet.addRow(headers);
    headersRow.font = { bold: true };

    data.workers.forEach((worker) => {
      const workerRow = [worker.worker];
      for (let i = 0; i < dictionary.length; i++) {
        workerRow.push(0);
      }

      if (isNotEmptyArray(worker.storages)) {
        worker.storages.forEach((storage) => {
          workerRow[storage.id] = storage.number;
        });
      }
      worksheet.addRow(workerRow);
    });
  }

  worksheet.addRow();
  const authorTagRow = worksheet.addRow(['Autor raportu:']);
  authorTagRow.font = labelsFontStyle;
  worksheet.addRow([`${data?.author?.firstName} ${data?.author?.lastName}`]);

  worksheet.getColumn(1).width = 25;

  for (let i = 2; i <= worksheet.columnCount; i++) {
    worksheet.getColumn(i).width = 15;
  }

  workbook.xlsx
    .writeBuffer()
    .then((buffer) =>
      saveAs(
        new Blob([buffer]),
        `Raport zebranych rzeczy - ${new Date().toLocaleDateString()}.xlsx`
      )
    )
    .catch((err) => console.log('generateReport(): Error writing excel export.', err));
}

function prepareWorkersSectionHeadersRow(dict) {
  const headers = ['Kierowca'];

  dict.forEach((dictEntry) => {
    headers[dictEntry.id] = dictEntry.name;
  });

  return headers;
}
</script>
<template>
  <div class="ibox">
    <div
      class="ibox-content"
      :class="{ 'sk-loading': isLoading }"
      style="max-width: 600px; border-style: none"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <form role="form" @submit="submitForm">
        <div class="form-group" id="transportReportDateRangeId">
          <label class="font-normal">Wybierz zakres</label>
          <div class="input-daterange input-group" id="dateRangePickerId">
            <input
              id="transportReportStartDateId"
              type="text"
              class="form-control-sm form-control"
              v-model="startDate"
            />
            <span class="input-group-addon" style="width: 50px">do</span>
            <input
              id="transportReportEndDateId"
              type="text"
              class="form-control-sm form-control"
              v-model="endDate"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <button class="float-right btn btn-sm btn-primary m-t-n-xs" type="submit">
              Wygeneruj raport
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<style scoped></style>
