import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { isNotEmptyArray } from './utilsHelper';
import { createToaster } from '@meforma/vue-toaster';

const GREY_COLOR = 'DCDDDC';

export function generateReport(reportData, worksheetName = 'Worksheet') {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(worksheetName);

  if (!Array.isArray(reportData?.demPoints) || !Array.isArray(reportData?.actions)) {
    const toaster = createToaster({ position: 'top-right' });
    toaster.show('Brak danych, nie można wygenerować raportu.', {
      duration: 3000,
      type: 'warning'
    });
    return;
  }

  const demPoints = reportData.demPoints;
  const actions = reportData.actions;

  worksheet.getCell('B2').value = 'Zestaw punktów i akcji z mapy';
  worksheet.getCell('B2').font = { bold: true, size: 14 };
  worksheet.getCell('B2').alignment = { wrapText: true, vertical: 'middle', horizontal: 'center' };
  worksheet.mergeCells('B2:D3');
  worksheet.addRow();

  const tableHeadersRow = worksheet.addRow([
    '',
    'Lp.',
    'Szerokość geograficzna',
    'Długość geograficzna',
    'Oznaczenie',
    'Uwagi',
    'Typ akcji'
  ]);

  for (let col = 2; col <= 7; col++) {
    tableHeadersRow.getCell(col).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      right: { style: 'thin' },
      bottom: { style: 'thin' }
    };

    tableHeadersRow.getCell(col).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: GREY_COLOR }
    };

    tableHeadersRow.getCell(col).font = { bold: true };
  }

  // demPoints table
  if (isNotEmptyArray(demPoints)) {
    for (let i = 0; i < demPoints.length; i++) {
      const demPoint = demPoints[i];
      const newRow = worksheet.addRow([
        '',
        i + 1,
        demPoint.lat,
        demPoint.lon,
        demPoint.oznaczenie ?? '-',
        demPoint.note ?? '-',
        '-'
      ]);

      for (let col = 2; col <= 7; col++) {
        newRow.getCell(col).border = {
          left: { style: 'thin' },
          right: { style: 'thin' },
          bottom: { style: i === demPoints.length - 1 && actions.length === 0 ? 'thin' : '' }
        };
      }
    }
  }

  // actions table
  if (isNotEmptyArray(actions)) {
    for (let i = 0; i < actions.length; i++) {
      const action = actions[i];

      const newRow = worksheet.addRow([
        '',
        demPoints.length + i + 1,
        action.lat,
        action.lon,
        action.demPointOznaczenie ?? '-',
        action.demPointNote ?? '-',
        action.action_type?.name ?? '-'
      ]);

      for (let col = 2; col <= 7; col++) {
        newRow.getCell(col).border = {
          left: { style: 'thin' },
          right: { style: 'thin' },
          bottom: { style: i === actions.length - 1 ? 'thin' : '' }
        };
      }
    }
  }

  // Widths
  worksheet.getColumn(2).width = 5;
  worksheet.getColumn(3).width = 22;
  worksheet.getColumn(4).width = 22;
  worksheet.getColumn(5).width = 30;
  worksheet.getColumn(5).alignment = { wrapText: true };
  worksheet.getColumn(6).width = 60;
  worksheet.getColumn(6).alignment = { wrapText: true };
  worksheet.getColumn(7).width = 30;
  worksheet.getColumn(8).width = 22;

  // Export xlsx
  workbook.xlsx
    .writeBuffer()
    .then((buffer) =>
      saveAs(
        new Blob([buffer]),
        `Raport z zestawu punktów na mapie - ${new Date().toLocaleDateString()}.xlsx`
      )
    )
    .catch((err) => console.log('generateReport(): Error writing excel export.', err));
}
