import html2pdf from 'html2pdf.js';

export function generatePdf(content, filename = 'PDF file') {
  if (!content) {
    console.warn('PDF content is null');
    return;
  }

  var options = {
    margin: [2, 0],
    filename,
    jsPDF: {
      orientation: 'landscape',
      format: 'a4'
    },
    pagebreak: { avoid: ['tr', 'td'], after: ['.html2pdf__page-break'] },
    html2canvas: {
      scrollY: 0
    }
  };

  html2pdf().set(options).from(content).save();
}
//TODO to remove later

// <template>
//   <div>
//     <vue3-html2pdf
//       ref="html2Pdf"
//       :show-layout="false"
//       :float-layout="true"
//       :enable-download="props.enableDownload"
//       :preview-modal="props.previewModal"
//       :paginate-elements-by-height="1400"
//       :pdf-quality="2"
//       :manual-pagination="false"
//       :htmlToPdfOptions="pdfOptions"
//       pdf-content-width="1100px"
//     >
//       <template v-slot:pdf-content>
//         <slot></slot>
//       </template>
//     </vue3-html2pdf>
//   </div>
// </template>
