<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { isNotEmptyArray } from '../../helpers/utilsHelper';
import ModalBase from '@/components/utils/ModalBase.vue';
import ModalBody from '@/components/utils/ModalBody.vue';
import FormErrorPanel from '@/components/utils/FormErrorPanel.vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { createToaster } from '@meforma/vue-toaster';
import { TOASTER_SERVER_ERROR_MSG } from '../../data/constants/toasterMessagesConstants';

const RepositoryTransportOrders = RepositoryFactory.get('transportOrders');

const props = defineProps({
  userOrderTransport: Object,
  orderId: Number,
  orderNumber: String
});

const toaster = createToaster({ position: 'top-right', duration: 3000 });
const emit = defineEmits(['succeeded']);
const router = useRouter();
const modalBase = ref();
const isLoadingForm = ref(false);
const formErrors = ref([]);
const driversWorkDataObject = ref(null);

function goToTransportOrderDetails(id) {
  if (id == null) return;

  let routeData = router.resolve({ name: 'transportOrderDetails', params: { id } });
  window.open(routeData.href, '_blank');
}

onMounted(() => {
  show();
});

function show() {
  modalBase.value.show();
  fetchDriversWorkData();
}

async function fetchDriversWorkData() {
  isLoadingForm.value = true;

  const response = await RepositoryTransportOrders.getDataForDriversWorkVerification(
    props.userOrderTransport?.id
  );

  driversWorkDataObject.value = response?.data;
  isLoadingForm.value = false;
}

async function verifyTransportAction() {
  isLoadingForm.value = true;

  driversWorkDataObject.value.gathered.forEach((element) => {
    if (element.number === '') element.number = null;
  });

  driversWorkDataObject.value.left.forEach((element) => {
    if (element.number === '') element.number = null;
  });

  var response = await RepositoryTransportOrders.verifyTransportAction(
    props.userOrderTransport?.id,
    driversWorkDataObject.value
  );

  if (response?.serverError) {
    toaster.show(TOASTER_SERVER_ERROR_MSG, { type: 'error' });
  } else {
    if (response?.id != null) {
      toaster.show('Akcja transportowa została zweryfikowana.', { type: 'success' });
      modalBase.value.hide();
      emit('succeeded');
    } else {
      formErrors.value = response;
    }
  }

  isLoadingForm.value = false;
}

function submitForm(e) {
  e.preventDefault();
  verifyTransportAction();
}

defineExpose({ show });
</script>

<template>
  <ModalBase ref="modalBase">
    <ModalBody>
      <template v-slot:title>
        Weryfikacja zlecenia
        <a @click="goToTransportOrderDetails(props.orderId)" :class="'text-navy'">
          {{ props.orderNumber }}
        </a>
        dla
        {{
          props.userOrderTransport?.worker.firstName +
          ' ' +
          props.userOrderTransport?.worker.lastName
        }}
      </template>
      <template v-slot:body>
        <div class="row">
          <div class="col-12">
            <div class="ibox">
              <div
                class="ibox-content"
                :class="{ 'sk-loading': isLoadingForm }"
                style="border-style: none"
              >
                <div class="sk-spinner sk-spinner-three-bounce">
                  <div class="sk-bounce1"></div>
                  <div class="sk-bounce2"></div>
                  <div class="sk-bounce3"></div>
                </div>

                <FormErrorPanel :errors="formErrors" />

                <form @submit="submitForm">
                  <div class="row">
                    <div class="col-6">
                      <h4>Zebrano</h4>
                      <div v-if="isNotEmptyArray(driversWorkDataObject?.gathered)" class="mt-3">
                        <div
                          v-for="storageType in driversWorkDataObject.gathered"
                          :key="storageType.id_storage_type"
                          class="form-group"
                        >
                          <label>{{ storageType.name }}</label>
                          <input
                            type="number"
                            @mousewheel.prevent=""
                            placeholder="-"
                            v-model="storageType.number"
                            class="form-control"
                            min="0"
                            max="999999999"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-6">
                      <h4>Pozostawiono</h4>
                      <div v-if="isNotEmptyArray(driversWorkDataObject?.left)" class="mt-3">
                        <div
                          v-for="storageType in driversWorkDataObject.left"
                          :key="storageType.id_storage_type"
                          class="form-group"
                        >
                          <label>{{ storageType.name }}</label>
                          <input
                            type="number"
                            @mousewheel.prevent=""
                            placeholder="-"
                            v-model="storageType.number"
                            class="form-control"
                            min="0"
                            max="999999999"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <button class="btn btn-primary mt-3 float-right" type="submit">
                        Zatwierdź
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ModalBody>
  </ModalBase>
</template>

<style scoped></style>
