<script setup>
import { ref, computed, watch } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { isNotEmptyArray } from '../../helpers/utilsHelper';

const RepositoryOrders = RepositoryFactory.get('orders');
const props = defineProps({ orderId: Number });
const isLoading = ref(false);
const orderAddresses = ref(null);

const groupedRanges = computed(() => {
  if (!Array.isArray(orderAddresses.value)) return null;

  return orderAddresses.value.reduce((accumulator, currentItem) => {
    const existingItem = accumulator.find(
      (item) => item?.id != null && item.id === currentItem.order_range.id
    );

    if (existingItem) {
      existingItem.quantity += currentItem.quantity;
    } else {
      accumulator.push({
        id: currentItem.order_range.id,
        name: currentItem.order_range.name,
        quantity: currentItem.quantity
      });
    }
    return accumulator;
  }, []);
});

watch(
  () => props.orderId,
  () => fetchData()
);

async function fetchData() {
  isLoading.value = true;
  orderAddresses.value = await RepositoryOrders.getOrderAddresses(props.orderId);
  isLoading.value = false;
}

// created
if (props.orderId) {
  fetchData();
}
</script>

<template>
  <div class="ibox">
    <div class="ibox-content" :class="{ 'sk-loading': isLoading }" style="border: none">
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <h3 class="text-center mb-4">Zestawy adresów</h3>

      <div v-if="isNotEmptyArray(orderAddresses)" class="table-responsive mb-3 ml-auto mr-auto">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Adres</th>
              <th>Zakres</th>
              <th>Ilość</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in orderAddresses" :key="row.id">
              <td>{{ row.address ?? '-' }}</td>
              <td>
                {{ row.order_range?.name ?? '-' }}
              </td>
              <td class="text-center">
                {{ row.quantity ?? '-' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <p class="text-center mt-3 mb-3">Brak elementów do wyświetlenia</p>
      </div>

      <h3 class="text-center mb-4 mt-4">Zakres prac</h3>

      <div
        v-if="groupedRanges && groupedRanges.length > 0"
        class="table-responsive mb-4 ml-auto mr-auto"
      >
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Zakres</th>
              <th>Ilość</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="range in groupedRanges" :key="range.id">
              <td>
                {{ range.name ?? '-' }}
              </td>
              <td class="text-center">
                {{ range.quantity ?? '-' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <p class="text-center mt-3">Brak elementów do wyświetlenia</p>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
