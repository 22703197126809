<script setup>
import { navItems } from '@/helpers/navigationPanelHelper.js';
import { useNavigationPanelStore } from '@/stores/navigationPanel';
import TransportOrderAdd from './TransportOrderAdd.vue';

const navigationPanelStore = useNavigationPanelStore();

navigationPanelStore.setNavigationPanelItems(navItems.TRANSPORT_ORDER_ADD);
</script>

<template>
  <TransportOrderAdd />
</template>

<style scoped></style>
