<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import OrderAddEdit from './OrderAddEdit.vue';
import OrderAddEditAdditional from './OrderAddEditAdditional.vue';
import { navItems, getOrderEditItem } from '@/helpers/navigationPanelHelper.js';
import { useNavigationPanelStore } from '@/stores/navigationPanel';

const navigationPanelStore = useNavigationPanelStore();
const route = useRoute();

const addMode = computed(() => {
  return isNaN(parseInt(route.params.id));
});

// created
navigationPanelStore.setNavigationPanelItems(
  addMode.value ? navItems.ORDER_ADD : getOrderEditItem(route.params.id)
);
</script>

<template>
  <div>
    <div class="row">
      <div class="col-xl-4 col-lg-auto">
        <OrderAddEdit />
      </div>
      <div class="col-xl-8">
        <OrderAddEditAdditional />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
