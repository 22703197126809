import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { isNotEmptyArray } from './utilsHelper';
import { createToaster } from '@meforma/vue-toaster';

const LIGHT_BLUE_COLOR = 'C5D9F1';
const LIGHT_YELLOW_COLOR = 'FFFFCC';
const RED_COLOR = 'FF7C80';
const ORANGE_COLOR = 'FABF8F';

export function generateReport(reportData, worksheetName = 'Worksheet') {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(worksheetName, {
    pageSetup: {
      paperSize: 9,
      orientation: 'landscape',
      fitToPage: true,
      fitToWidth: 1,
      fitToHeight: 1
    }
  });

  if (reportData?.data == null || reportData.data == '') {
    const toaster = createToaster({ position: 'top-right' });
    toaster.show('Brak danych, nie można wygenerować raportu.', {
      duration: 3000,
      type: 'warning'
    });
    return;
  }

  const data = JSON.parse(reportData?.data);

  worksheet.getCell('B4').value = {
    richText: [
      { text: 'Protokół odbioru ', font: { bold: true, size: 14 } },
      { text: 'końcowego', font: { strike: data.po_type === 'częściowego', bold: true, size: 14 } },
      { text: '/', font: { bold: true, size: 14 } },
      { text: 'częściowego', font: { strike: data.po_type === 'końcowego', bold: true, size: 14 } },
      { text: '* Nr', font: { bold: true, size: 14 } }
    ]
  };

  worksheet.getCell('B5').value = 'z dnia';

  worksheet.getCell('C4').value = data?.order_number;
  worksheet.getCell('C5').value = data?.raport_creation_date;

  worksheet.mergeCells('D4:E5');
  worksheet.getCell('D4').value = 'Nr zamówienia w PZ';

  worksheet.mergeCells('D6:E6');
  worksheet.getCell('D6').value = data?.ebp;

  worksheet.getCell('B7').value = 'Komisja w składzie';
  worksheet.getCell('C7').value = '1. Przedstawiciel WUUiI - jak niżej';
  worksheet.getCell('C8').value = '2. Przedstawiciel Rado Sp. z o o - jak niżej';
  worksheet.getCell('C9').value = '3. Przedstawiciel Zamawiającego - jak niżej';

  worksheet.getCell('B10').value =
    'Dokonała odbioru prac zrealizowanych przez firmę Rado Sp. z o.o. dotyczących demontażu elementów infrastruktury telekomunikacyjnej w ramach Umowy nr  OP/Z/CV/U/NI/1/82715/22, zakresy jak w tabeli:';

  worksheet.getCell('B14').value = '* skreślić niepotrzebne';
  worksheet.getCell('B15').value =
    'Demontaż wyspecyfikowanych elementów i zagospodarowanie odpadów wykonano zgodnie z zapisami Umowy';

  worksheet.getCell('B16').value =
    'Przedstawiciele Komisji Zamawiającego przyjmują bez zastrzeżeń/z zastrzeżeniami* wymienione w Protokole zakresy robót';

  worksheet.getCell('B17').value = 'Zastrzeżenia……………………………………………………………';
  worksheet.getCell('B18').value =
    'Wykonawca zobowiązuje się do usunięcia zastrzeżeń zgodnie z zapisami Umowy';
  worksheet.getCell('B19').value = 'Na tym protokoł zakończono i podpisano';
  worksheet.getCell('B20').value = 'Ze strony Zamawiającego:';
  worksheet.getCell('D20').value = 'Ze strony Wykonawcy:';

  worksheet.getCell('B20').font = { size: 12 };
  worksheet.getCell('D20').font = { size: 12 };
  worksheet.getCell('B20').alignment = { horizontal: 'right', vertical: 'middle' };
  worksheet.getCell('D20').alignment = { horizontal: 'left', vertical: 'middle' };
  worksheet.getRow(4).height = 25;
  worksheet.getRow(5).height = 25;
  worksheet.getRow(20).height = 35;

  // Table
  const tableHeaders = [
    '',
    'Dane identyfikacyjne linii - relacji - zakresu do likwidacji (powiat/gmina/miejscowość/ulica/nr/działka',
    'Zakres',
    'Ilość zlecona',
    'Ilość zrealizowana',
    'UWAGI_1 informacja o kablach z LN',
    'UWAGI_2 dołączyć załączniki 6a; 6b; 6c',
    'UWAGI_3 dołączyć inne dokumenty i notatki',
    'UWAGI_4 informacje z placu budowy'
  ];

  worksheet.insertRow(12, tableHeaders);

  for (let col = 2; col <= 9; col++) {
    worksheet.getCell(12, col).alignment = {
      wrapText: true,
      vertical: 'middle',
      horizontal: 'center'
    };
    worksheet.getCell(12, col).border = {
      top: { style: 'medium' },
      left: { style: 'medium' },
      right: { style: 'medium' }
    };
  }

  worksheet.getRow(12).font = { size: 10 };
  worksheet.getRow(12).height = 30;
  worksheet.getRow(13).height = 30;

  worksheet.mergeCells('A12:A13');
  worksheet.mergeCells('B12:B13');
  worksheet.mergeCells('C12:C13');
  worksheet.mergeCells('D12:D13');
  worksheet.mergeCells('E12:E13');
  worksheet.mergeCells('F12:F13');
  worksheet.mergeCells('G12:G13');
  worksheet.mergeCells('H12:H13');
  worksheet.mergeCells('I12:I13');
  worksheet.mergeCells('D31:E31');

  if (isNotEmptyArray(data?.addresses)) {
    const tableRows = [];

    for (let i = 0; i < data.addresses.length; i++) {
      const address = data.addresses[i];

      tableRows.push([
        '',
        address.address,
        address.order_range,
        address.quantity,
        address.quantity_fullfilled,
        i === 0 ? (data.ln_present ? data.ln_text : '-') : '',
        address.attachment,
        i === 0 ? data.comments_type : '',
        i === 0 ? data.collection_text + getCollectionInfo(data) : ''
      ]);
    }

    worksheet.insertRows(14, tableRows);
  }

  // Widths
  worksheet.getColumn(1).width = 5;
  worksheet.getColumn(2).width = 62;
  worksheet.getColumn(3).width = 55;
  worksheet.getColumn(4).width = 10;
  worksheet.getColumn(5).width = 13;
  worksheet.getColumn(6).width = 13;
  worksheet.getColumn(7).width = 20;
  worksheet.getColumn(8).width = 25;
  worksheet.getColumn(9).width = 30;

  // Colors
  worksheet.getCell('C4').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: LIGHT_BLUE_COLOR }
  };
  worksheet.getCell('C5').fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: LIGHT_BLUE_COLOR }
  };

  if (isNotEmptyArray(data?.addresses)) {
    worksheet.getCell('F14').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: ORANGE_COLOR }
    };
  }

  if (isNotEmptyArray(data?.addresses)) {
    const addressesRows = worksheet.getRows(14, data.addresses.length);

    const lightYellowFill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: LIGHT_YELLOW_COLOR }
    };

    const redFill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: RED_COLOR }
    };

    addressesRows.forEach((row) => {
      row.font = { size: 10 };

      row.getCell(2).fill = lightYellowFill;
      row.getCell(3).fill = lightYellowFill;
      row.getCell(4).fill = lightYellowFill;
      row.getCell(5).fill = redFill;
    });
  }

  if (isNotEmptyArray(data?.addresses)) {
    const lastRowIdToSet = 12 + 2 + data.addresses.length;

    for (let rowId = 14; rowId < lastRowIdToSet; rowId++) {
      const row = worksheet.getRow(rowId);

      for (let col = 2; col <= 9; col++) {
        row.getCell(col).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' },
          bottom: { style: 'thin' }
        };

        row.getCell(col).alignment = {
          vertical: 'middle',
          horizontal: col > 3 ? 'center' : 'left',
          wrapText: true
        };
      }
    }
  }

  worksheet.getCell('B5').font = { bold: true, size: 14 };
  worksheet.getCell('C4').font = { bold: true, size: 14 };
  worksheet.getCell('C5').font = { bold: true, size: 14 };
  worksheet.getCell('B7').font = { italic: true, size: 12 };
  worksheet.getCell('C7').font = { italic: true, size: 12 };
  worksheet.getCell('C8').font = { italic: true, size: 12 };
  worksheet.getCell('C9').font = { italic: true, size: 12 };
  worksheet.getCell('B10').font = { italic: true, size: 12 };
  worksheet.getCell('D4').font = { size: 14 };
  worksheet.getCell('D6').font = { italic: true, size: 14 };

  worksheet.getCell('C4').alignment = { vertical: 'middle', horizontal: 'center' };
  worksheet.getCell('C5').alignment = { vertical: 'middle', horizontal: 'center' };
  worksheet.getCell('D4').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
  worksheet.getCell('D6').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
  worksheet.getCell('B4').alignment = { vertical: 'middle', horizontal: 'right' };
  worksheet.getCell('B5').alignment = { vertical: 'middle', horizontal: 'right' };
  worksheet.getCell('B7').alignment = { vertical: 'middle', horizontal: 'right' };

  setFontNameForEntireWorksheet(worksheet, 'Arial');

  // Export xlsx
  workbook.xlsx
    .writeBuffer()
    .then((buffer) =>
      saveAs(
        new Blob([buffer]),
        `Raport z protokołu odbioru - ${new Date().toLocaleDateString()}.xlsx`
      )
    )
    .catch((err) => console.log('generateReport(): Error writing excel export.', err));
}

function getCollectionInfo(reportInfo) {
  if (!isNotEmptyArray(reportInfo?.collection_info)) return '';

  let collectionInfoText = '';

  reportInfo.collection_info.forEach((element) => {
    collectionInfoText += ` \r\n${element.lat}, ${element.lon}`;
  });

  return collectionInfoText;
}

function setFontNameForEntireWorksheet(worksheet, fontName) {
  // eslint-disable-next-line no-unused-vars
  worksheet.eachRow((row, rowNumber) => {
    // eslint-disable-next-line no-unused-vars
    row.eachCell((cell, colNumber) => {
      if (cell.font != null) {
        cell.font.name = fontName;
      } else {
        cell.font = { name: fontName };
      }
    });
  });
}
