export class ClusterGroupsSettings {
  constructor() {
    this.clusterGroupsDictionary = {};
  }

  add(clusterGroupId, clusterOptions) {
    if (clusterOptions != null) {
      this.clusterGroupsDictionary[clusterGroupId] = clusterOptions;
    } else {
      console.error('ClusterGroupsSettings.add(): The clusterOptions object must not be null.');
    }
  }

  get(clusterGroupId) {
    return this.clusterGroupsDictionary[clusterGroupId];
  }

  remove(clusterGroupId) {
    delete this.clusterGroupsDictionary[clusterGroupId];
  }

  logAll() {
    for (const clusterGroupId in this.clusterGroupsDictionary) {
      console.log(
        `clusterGroupId: ${clusterGroupId}, clusterOptions: ${this.clusterGroupsDictionary[clusterGroupId]}`
      );
    }
  }
}
