<script setup>
import { ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import OrderAddressesTable from '@/components/orders/OrderAddressesTable.vue';
import OrderLocations from '@/components/orders/OrderLocations.vue';

const alreadyLoaded = ref({ addresses: true, locations: false });
const route = useRoute();
const router = useRouter();
const orderId = computed(() => {
  const parsedId = parseInt(route.params?.id);
  return isNaN(parsedId) ? null : parsedId;
});

function onAddAddressClicked() {
  router.push({ name: 'orderAddressAddEdit', params: { orderId: orderId.value } });
}

function onTabClicked(tabId) {
  switch (tabId) {
    case 1:
      alreadyLoaded.value.addresses = true;
      break;
    case 2:
      alreadyLoaded.value.locations = true;
      break;
  }
}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="tabs-container">
          <ul class="nav nav-tabs" role="tablist">
            <li @click="onTabClicked(1)">
              <a class="nav-link active" data-toggle="tab" href="#tab-1">
                <i class="fa fa-compass" />
                Adresy</a
              >
            </li>
            <li @click="onTabClicked(2)">
              <a class="nav-link" data-toggle="tab" href="#tab-2">
                <i class="fa fa-map-marker" />
                Lokalizacje</a
              >
            </li>
          </ul>

          <div v-if="orderId != null" class="tab-content">
            <div role="tabpanel" id="tab-1" class="tab-pane active">
              <div class="panel-body">
                <button class="btn btn-primary btn-xs" @click="onAddAddressClicked">
                  Dodaj adres
                </button>

                <OrderAddressesTable
                  v-if="alreadyLoaded.addresses"
                  :showBorder="false"
                  :longerSearchBar="true"
                />
              </div>
            </div>
            <div role="tabpanel" id="tab-2" class="tab-pane">
              <div class="panel-body">
                <OrderLocations v-if="alreadyLoaded.locations" />
              </div>
            </div>
          </div>

          <div v-else class="tab-content">
            <div class="ibox">
              <div class="ibox-content">
                <p class="text-center mt-3">Aby zobaczyć tę część, utwórz zlecenie</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
