import repositoryUsers from './repositoryUsers.js';
import repositoryAuth from './repositoryAuth.js';
import repositoryOrders from './repositoryOrders.js';
import repositoryDictionaries from './repositoryDictionaries.js';
import repositoryActions from './repositoryActions.js';
import repositoryFiles from './repositoryFiles.js';
import repositoryOrderContractors from './repositoryOrderContractors.js';
import repositoryAddresses from './repositoryAddresses.js';
import repositoryTransportOrders from './repositoryTransportOrders.js';
import repositoryDemPoints from './repositoryDemPoints.js';
import repositoryAcceptanceProtocols from './repositoryAcceptanceProtocols.js';
import repositoryMapPointsReports from './repositoryMapPointsReports.js';

const repositories = {
  auth: repositoryAuth,
  users: repositoryUsers,
  orders: repositoryOrders,
  dictionaries: repositoryDictionaries,
  actions: repositoryActions,
  files: repositoryFiles,
  orderContractors: repositoryOrderContractors,
  addresses: repositoryAddresses,
  transportOrders: repositoryTransportOrders,
  demPoints: repositoryDemPoints,
  acceptanceProtocols: repositoryAcceptanceProtocols,
  mapPointsReports: repositoryMapPointsReports
};

export const RepositoryFactory = {
  get: (name) => repositories[name]
};
