export const MAIN_ORDERS_TABLE_PAGINATION_KEY = 'main_orders_table_pagination_key';
export const ORDERS_TABLE_FOR_MAP_PAGINATION_KEY = 'orders_table_for_map_pagination_key';
export const ORDERS_TABLE_FOR_USER_PAGINATION_KEY = 'orders_table_for_user_pagination_key';
export const USERS_TABLE_PAGINATION_KEY = 'users_table_pagination_key';
export const ORDER_ADDRESSES_TABLE_PAGINATION_KEY = 'order_addresses_table_pagination_key';
export const ORDER_CONTRACTORS_TABLE_PAGINATION_KEY = 'order_contractors_table_pagination_key';
export const REPO_FILES_TABLE_GET_COMPONENT_PAGINATION_KEY =
  'repo_files_table_get_component_pagination_key';
export const REPO_FILES_TABLE_POST_COMPONENT_PAGINATION_KEY =
  'repo_files_table_post_component_pagination_key';
export const DICTIONARY_TABLE_COMPONENT_PAGINATION_KEY =
  'dictionary_table_component_pagination_key';
export const TRANSPORT_ORDERS_TABLE_PAGINATION_KEY = 'transport_orders_table_pagination_key';
export const TRANSPORT_ORDER_DRIVERS_TABLE_PAGINATION_KEY =
  'transport_order_drivers_table_pagination_key';
export const PLACES_TABLE_FOR_MAP_PAGINATION_KEY = 'places_table_for_map_pagination_key';
