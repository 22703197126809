import { isNotEmptyArray, isNullOrEmptyString, pushSafe } from '../utilsHelper';
import { v4 as uuidv4 } from 'uuid';

const CLUSTER_MAP_SNAPSHOT_CENTER_KEY = 'CLUSTER_MAP_SNAPSHOT_CENTER_KEY';
const CLUSTER_MAP_SNAPSHOT_ZOOM_KEY = 'CLUSTER_MAP_SNAPSHOT_ZOOM_KEY';
const CLUSTER_MAP_SNAPSHOT_OPENED_TOOLTIPS_KEY = 'CLUSTER_MAP_SNAPSHOT_OPENED_TOOLTIPS_KEY';

export function save(
  clusterMapRef,
  snapshotKey = null,
  saveCenterAndZoom = true,
  saveOpenedTooltips = true
) {
  const snapshot = {};

  if (saveCenterAndZoom) {
    snapshot[CLUSTER_MAP_SNAPSHOT_CENTER_KEY] = clusterMapRef.value?.currentCenter;
    snapshot[CLUSTER_MAP_SNAPSHOT_ZOOM_KEY] = clusterMapRef.value?.currentZoom;
  }

  if (saveOpenedTooltips && isNotEmptyArray(clusterMapRef.value?.markersRef)) {
    clusterMapRef.value.markersRef.forEach((marker) => {
      if (marker.getPopup()?.isOpen()) {
        snapshot[CLUSTER_MAP_SNAPSHOT_OPENED_TOOLTIPS_KEY] = pushSafe(
          snapshot[CLUSTER_MAP_SNAPSHOT_OPENED_TOOLTIPS_KEY],
          marker.markerId
        );
      }
    });
  }

  if (snapshotKey == null) {
    snapshotKey = uuidv4();
  }

  localStorage.setItem(snapshotKey, JSON.stringify(snapshot));

  return snapshotKey;
}

export function tryApply(clusterMapRef, snapshotKey, deleteAfterApplied = true) {
  let snapshot = localStorage.getItem(snapshotKey);

  if (isNullOrEmptyString(snapshot)) {
    console.log('tryApply(): snapshot is null or empty.');
    return;
  }

  snapshot = JSON.parse(snapshot);

  clusterMapRef.value?.loadMapState(
    snapshot[CLUSTER_MAP_SNAPSHOT_CENTER_KEY],
    snapshot[CLUSTER_MAP_SNAPSHOT_ZOOM_KEY],
    snapshot[CLUSTER_MAP_SNAPSHOT_OPENED_TOOLTIPS_KEY]
  );

  if (deleteAfterApplied) {
    removeSnapshot(snapshotKey);
  }
}

export function removeSnapshot(snapshotKey) {
  localStorage.removeItem(snapshotKey);
}
