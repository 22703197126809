<script setup>
import { ref } from 'vue';
import ModalBase from '@/components/utils/ModalBase.vue';
import ModalBody from '@/components/utils/ModalBody.vue';
import ManualAcceptanceProtocolUpdateForm from './ManualAcceptanceProtocolUpdateForm.vue';

const props = defineProps({ orderId: Number });
const emit = defineEmits(['succeeded']);
const modalBase = ref();
const apUpdateFormRef = ref();
const apUpdateFormKey = ref(0);
const acceptanceProtocol = ref(null);

function show(ap) {
  acceptanceProtocol.value = ap;
  modalBase.value.show();
  apUpdateFormKey.value++;
}

function onSuccessfullyUpdated() {
  modalBase.value.hide();
  emit('succeeded');
}

function submitExternal() {
  apUpdateFormRef.value?.requestSubmit();
}

defineExpose({ show });
</script>

<template>
  <ModalBase ref="modalBase">
    <ModalBody>
      <template v-slot:title>Edycja protokołu odbioru</template>

      <template v-slot:body>
        <ManualAcceptanceProtocolUpdateForm
          ref="apUpdateFormRef"
          v-if="apUpdateFormKey > 0"
          :key="apUpdateFormKey"
          :orderId="props.orderId"
          :acceptanceProtocol="acceptanceProtocol"
          @succeeded="onSuccessfullyUpdated"
        />
      </template>

      <template v-slot:footer>
        <button type="button" class="btn btn-outline btn-info" @click="modalBase.hide">
          Zamknij
        </button>

        <button type="button" @click="submitExternal" class="btn btn-info">Aktualizuj</button>
      </template>
    </ModalBody>
  </ModalBase>
</template>

<style scoped></style>
