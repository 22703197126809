<script setup>
import { ref, watch, onMounted, unref } from 'vue';
import { navItems } from '@/helpers/navigationPanelHelper.js';
import { useNavigationPanelStore } from '@/stores/navigationPanel';
import ActionsRegisterTable from './ActionsRegisterTable.vue';
import ActionsReportsTable from './ActionsReportsTable.vue';

const navigationPanelStore = useNavigationPanelStore();

const props = defineProps({
  mode: { type: String, default: null }
});
const alreadyLoaded = ref({ actions: false, reports: false });
const actionsReportsTableKey = ref(0);
const mode = ref(null);
mode.value = unref(props.mode) ?? 'actions';

watch(
  () => props.mode,
  () => {
    mode.value = unref(props.mode);
  }
);

function onTabClicked(tabId) {
  switch (tabId) {
    case 'actions':
      alreadyLoaded.value.actions = true;
      break;
    case 'reports':
      alreadyLoaded.value.reports = true;
      break;
  }
}

function switchTabs() {
  let tabIdToActivate = null;

  switch (mode.value) {
    case 'actions':
      alreadyLoaded.value.actions = true;
      tabIdToActivate = 1;
      break;
    case 'reports':
      alreadyLoaded.value.reports = true;
      tabIdToActivate = 2;
      break;
  }

  if (tabIdToActivate == null) return;

  let tabIdIterator = 1;
  let headerElement = null;
  let element = null;

  do {
    headerElement = document.getElementById('tab-' + tabIdIterator.toString() + '-header');
    element = document.getElementById('tab-' + tabIdIterator.toString());

    if (headerElement == null || element == null) break;

    if (tabIdIterator === tabIdToActivate) {
      headerElement.classList.add('active');
      element.classList.add('active');
    } else {
      headerElement.classList.remove('active');
      element.classList.remove('active');
    }

    tabIdIterator++;
  } while (headerElement != null && element != null);
}

// created
navigationPanelStore.setNavigationPanelItems(navItems.ACTIONS_REGISTER);

switch (mode.value) {
  case 'actions':
    alreadyLoaded.value.actions = true;
    break;
  case 'reports':
    alreadyLoaded.value.reports = true;
    break;
}

// mounted
onMounted(() => {
  switchTabs();
});
</script>

<template>
  <div class="col-lg-12">
    <div class="tabs-container">
      <ul class="nav nav-tabs" role="tablist">
        <li @click="onTabClicked('actions')">
          <a class="nav-link active" id="tab-1-header" data-toggle="tab" href="#tab-1">
            Tabela akcji</a
          >
        </li>
        <li @click="onTabClicked('reports')">
          <a class="nav-link" id="tab-2-header" data-toggle="tab" href="#tab-2">
            Tabela raportów
          </a>
        </li>
      </ul>
      <div class="tab-content">
        <div role="tabpanel" id="tab-1" class="tab-pane active">
          <div class="panel-body">
            <ActionsRegisterTable @reportRequested="actionsReportsTableKey++" />
          </div>
        </div>
        <div role="tabpanel" id="tab-2" class="tab-pane">
          <div class="panel-body">
            <ActionsReportsTable :key="actionsReportsTableKey" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
