<script setup>
import { ref } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import RepoFilesTablePOST from '@/components/fileRepositories/RepoFilesTablePOST.vue';
import ActionsRepoFilesFilterAndSearchPanel from './ActionsRepoFilesFilterAndSearchPanel.vue';
import { handleResponseWithMessages } from '../../helpers/wwwHelper';

const RepositoryFiles = RepositoryFactory.get('files');
const props = defineProps({
  orderId: { type: Number, required: true },
  modalImageContainerId: { type: String, required: true }
});
const actionsResourcesKey = ref(0);
const searchBox = ref('');
const userFilter = ref(null);
const actionsRepoFilesFilterAndSearchPanel = ref();

function onFetchDataWithFiltersRequest({ search, filter }) {
  searchBox.value = search;
  userFilter.value = filter;
  actionsResourcesKey.value++;
}

async function deletActionResource(resourceId) {
  var response = await RepositoryFiles.deletActionResource(resourceId);

  handleResponseWithMessages(response, 'Plik został usunięty.', () => {
    actionsResourcesKey.value++;
  });
}
</script>

<template>
  <ActionsRepoFilesFilterAndSearchPanel
    ref="actionsRepoFilesFilterAndSearchPanel"
    @fetchData="onFetchDataWithFiltersRequest"
  />

  <RepoFilesTablePOST
    :key="actionsResourcesKey"
    :modalId="props.modalImageContainerId"
    :url="`order/${props.orderId}/actionFiles`"
    :showBorder="false"
    :longerSearchBar="false"
    :deletingEnabled="true"
    :searchFromOutside="searchBox"
    :userFilter="userFilter"
    :deleteFunctionOverride="deletActionResource"
    @dataFetched="actionsRepoFilesFilterAndSearchPanel?.updateAppliedFilters"
  />
</template>

<style scoped></style>
