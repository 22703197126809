<script setup>
import { ref, onMounted } from 'vue';

const props = defineProps({
  id: { type: String },
  caption: { type: String, default: 'Upuść plik tutaj' },
  fileInputId: { type: String }
});

const emit = defineEmits(['onFileChanged']);
const dropArea = ref(null);

function preventDefaults(e) {
  e.preventDefault();
  e.stopPropagation();
}

function highlight() {
  dropArea.value.classList.add('highlight');
}

function unhighlight() {
  dropArea.value.classList.remove('highlight');
}

function handleDrop(e) {
  var files = e.dataTransfer.files;
  emit('onFileChanged', [...files]);
}

onMounted(() => {
  dropArea.value = document.getElementById(props.id);

  // Prevent default drag behaviors
  ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
    dropArea.value.addEventListener(eventName, preventDefaults, false);
    document.body.addEventListener(eventName, preventDefaults, false);
  });

  // Highlight drop area when item is dragged over it
  ['dragenter', 'dragover'].forEach((eventName) => {
    dropArea.value.addEventListener(eventName, highlight, false);
  });

  ['dragleave', 'drop'].forEach((eventName) => {
    dropArea.value.addEventListener(eventName, unhighlight, false);
  });

  // Handle dropped files
  dropArea.value.addEventListener('drop', handleDrop, false);
});
</script>

<template>
  <div :id="id">
    <strong class="dropAreaCaption">
      {{ caption }}
    </strong>
  </div>
</template>

<style scoped>
.dropArea {
  height: 140px;
  border: 1px dashed #276fb3;
  position: relative;
}

.dropAreaCaption {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
  transform: translateX(-50%);
}

.dropArea.highlight {
  border-color: green;
}
</style>
