import { ACCESS_KEY_DICTIONARIES_VIEW_ID_COLUMN } from './constants/authConstants';

const dictionariesViewConfig = [
  {
    id: 0,
    searchPlaceholder: 'Szukaj rodzaju zlecenia',
    header: 'Rodzaje zlecenia',
    url: 'direction',
    columns: [
      { key: 'id', header: 'Id', accessKey: ACCESS_KEY_DICTIONARIES_VIEW_ID_COLUMN },
      { key: 'name', header: 'Nazwa' }
    ],
    iconClass: 'fa fa-sitemap',
    iconSource: null,
    iconAltText: 'Rodzaje zlecenia'
  },
  {
    id: 1,
    searchPlaceholder: 'Szukaj statusu zlecenia demontażu',
    header: 'Statusy zlecenia demontażu',
    url: 'disassemblystatus',
    columns: [
      { key: 'id', header: 'Id', accessKey: ACCESS_KEY_DICTIONARIES_VIEW_ID_COLUMN },
      { key: 'name', header: 'Nazwa' }
    ],
    iconClass: 'fa fa-tasks',
    iconSource: null,
    iconAltText: 'Statusy zlecenia demontażu'
  },
  {
    id: 3,
    searchPlaceholder: 'Szukaj etapu geodezji',
    header: 'Etapy geodezji',
    url: 'geodesyphase',
    columns: [
      { key: 'id', header: 'Id', accessKey: ACCESS_KEY_DICTIONARIES_VIEW_ID_COLUMN },
      { key: 'name', header: 'Nazwa' }
    ],
    iconClass: 'fa fa-globe',
    iconSource: null,
    iconAltText: 'Etapy geodezji'
  },
  {
    id: 4,
    searchPlaceholder: 'Szukaj typu akcji',
    header: 'Typy akcji',
    url: 'actiontype',
    columns: [
      { key: 'id', header: 'Id', accessKey: ACCESS_KEY_DICTIONARIES_VIEW_ID_COLUMN },
      { key: 'name', header: 'Nazwa' }
    ],
    iconClass: 'fa fa-info-circle',
    iconSource: null,
    iconAltText: 'Typy akcji'
  },
  {
    id: 5,
    searchPlaceholder: 'Szukaj typu zlecenia',
    header: 'Typy zleceń',
    url: 'ordertype',
    columns: [
      { key: 'id', header: 'Id', accessKey: ACCESS_KEY_DICTIONARIES_VIEW_ID_COLUMN },
      { key: 'name', header: 'Nazwa' }
    ],
    iconClass: 'fa fa-code-fork',
    iconSource: null,
    iconAltText: 'Typy zleceń'
  },
  {
    id: 6,
    searchPlaceholder: 'Szukaj powiatu',
    header: 'Powiaty',
    url: 'powiat',
    columns: [
      { key: 'id', header: 'Id', accessKey: ACCESS_KEY_DICTIONARIES_VIEW_ID_COLUMN },
      { key: 'name', header: 'Nazwa' }
    ],
    iconClass: 'fa fa-circle',
    iconSource: null,
    iconAltText: 'Powiaty'
  },
  {
    id: 7,
    searchPlaceholder: 'Szukaj gminy',
    header: 'Gminy',
    url: 'gmina',
    columns: [
      { key: 'id', header: 'Id', accessKey: ACCESS_KEY_DICTIONARIES_VIEW_ID_COLUMN },
      { key: 'name', header: 'Nazwa' }
    ],
    iconClass: 'fa fa-dot-circle-o',
    iconSource: null,
    iconAltText: 'Gminy'
  },
  {
    id: 8,
    searchPlaceholder: 'Szukaj regionu',
    header: 'Regiony',
    url: 'region',
    columns: [
      { key: 'id', header: 'Id', accessKey: ACCESS_KEY_DICTIONARIES_VIEW_ID_COLUMN },
      { key: 'name', header: 'Nazwa' }
    ],
    iconClass: 'fa fa-circle-o',
    iconSource: null,
    iconAltText: 'Regiony'
  },
  {
    id: 9,
    searchPlaceholder: 'Szukaj typu użytkownika',
    header: 'Typy użytkowników',
    url: 'usertype',
    columns: [
      { key: 'id', header: 'Id', accessKey: ACCESS_KEY_DICTIONARIES_VIEW_ID_COLUMN },
      { key: 'name', header: 'Nazwa' }
    ],
    iconClass: 'fa fa-users',
    iconSource: null,
    iconAltText: 'Typy użytkowników'
  },
  {
    id: 10,
    searchPlaceholder: 'Szukaj typu zasobów',
    header: 'Typy zasobów',
    url: 'resourcetype',
    columns: [
      { key: 'id', header: 'Id', accessKey: ACCESS_KEY_DICTIONARIES_VIEW_ID_COLUMN },
      { key: 'name', header: 'Nazwa' }
    ],
    iconClass: 'fa fa-cubes',
    iconSource: null,
    iconAltText: 'Typy zasobów'
  },
  {
    id: 11,
    searchPlaceholder: 'Szukaj zakresu prac',
    header: 'Zakresy prac',
    url: 'orderrange',
    columns: [
      { key: 'id', header: 'Id', accessKey: ACCESS_KEY_DICTIONARIES_VIEW_ID_COLUMN },
      { key: 'name', header: 'Nazwa' }
    ],
    iconClass: 'fa fa-wrench',
    iconSource: null,
    iconAltText: 'Zakresy prac'
  },
  {
    id: 12,
    searchPlaceholder: 'Szukaj zakresu/typu elementów',
    header: 'Zakresy/typy elementów',
    url: 'demelemtype',
    columns: [
      { key: 'id', header: 'Id', accessKey: ACCESS_KEY_DICTIONARIES_VIEW_ID_COLUMN },
      { key: 'name', header: 'Nazwa' }
    ],
    iconClass: 'fa fa-chain',
    iconSource: null,
    iconAltText: 'Zakresy/typy elementów'
  },
  {
    id: 13,
    searchPlaceholder: 'Szukaj statusu',
    header: 'Transportowe statusy nadrzędne',
    url: 'ordertransportadditionalstatus',
    columns: [
      { key: 'id', header: 'Id', accessKey: ACCESS_KEY_DICTIONARIES_VIEW_ID_COLUMN },
      { key: 'name', header: 'Nazwa' }
    ],
    iconClass: 'fa fa-plus-circle',
    iconSource: null,
    iconAltText: 'Transportowe statusy nadrzędne'
  },
  {
    id: 14,
    searchPlaceholder: 'Szukaj powodu niepowodzenia',
    header: 'Powody niepowodzenia demontażu',
    url: 'failureactionreason',
    columns: [
      { key: 'id', header: 'Id', accessKey: ACCESS_KEY_DICTIONARIES_VIEW_ID_COLUMN },
      { key: 'name', header: 'Nazwa' }
    ],
    iconClass: 'fa fa-frown-o',
    iconSource: null,
    iconAltText: 'Powody niepowodzenia demontażu'
  },
  {
    id: 15,
    searchPlaceholder: 'Szukaj typu protokołu',
    header: 'Typy protokołów dla zlecenia',
    url: 'protocoltype',
    columns: [
      { key: 'id', header: 'Id', accessKey: ACCESS_KEY_DICTIONARIES_VIEW_ID_COLUMN },
      { key: 'name', header: 'Nazwa' }
    ],
    iconClass: 'fa fa-list-alt',
    iconSource: null,
    iconAltText: 'Typy protokołów dla zlecenia'
  },
  {
    id: 16,
    searchPlaceholder: 'Szukaj inicjatywy zlecenia',
    header: 'Inicjatywy zlecenia',
    url: 'orderinitiative',
    columns: [
      { key: 'id', header: 'Id', accessKey: ACCESS_KEY_DICTIONARIES_VIEW_ID_COLUMN },
      { key: 'name', header: 'Nazwa' }
    ],
    iconClass: 'fa fa-tag',
    iconSource: null,
    iconAltText: 'Inicjatywy zlecenia'
  },
  {
    id: 17,
    searchPlaceholder: 'Szukaj źródła demontażu dodatkowego',
    header: 'Źródła demontażu dodatkowego',
    url: 'extrademsource',
    columns: [
      { key: 'id', header: 'Id', accessKey: ACCESS_KEY_DICTIONARIES_VIEW_ID_COLUMN },
      { key: 'name', header: 'Nazwa' }
    ],
    iconClass: 'fa fa-square-o',
    iconSource: null,
    iconAltText: 'Źródła demontażu dodatkowego'
  }
];

export default dictionariesViewConfig;
