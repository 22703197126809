export function groupByClusterGroupId(array) {
  const grouped = array.reduce((acc, obj) => {
    const key = obj.clusterGroupId;

    if (!acc[key]) {
      acc[key] = [];
    }

    acc[key].push(obj);

    return acc;
  }, {});

  return Object.values(grouped);
}
