<script setup>
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';
import ClusterMap from '../components/map/ClusterMap.vue';
import { RepositoryFactory } from '../data/repositoryFactory';
import { showOnGoogleMaps } from '../helpers/navigator';
import { copyToClipboard, isNotEmptyArray } from '../helpers/utilsHelper';
import { useMapLogic } from '../composables/mapLogic';

const RepositoryMapPointsReports = RepositoryFactory.get('mapPointsReports');

const route = useRoute();
const isLoading = ref(false);
const mapPointsObject = ref(null);
const pickedPoint = ref(null);
const { clusterMap, updateMarkerSelectedState, resetSelectedIfCtrl } = useMapLogic();

const uuid = computed(() => {
  return route.params.uuid;
});

const mapPointsAvailable = computed(
  () =>
    isNotEmptyArray(mapPointsObject.value?.demPoints) ||
    isNotEmptyArray(mapPointsObject.value?.actions)
);

async function getMapPoints() {
  isLoading.value = true;

  RepositoryMapPointsReports.fetchSharedMapData(uuid.value)
    .then((data) => {
      mapPointsObject.value = data;
      prepareMarkersForMap();
    })
    .catch((error) => console.log(error))
    .finally(() => {
      isLoading.value = false;
    });
}

function prepareMarkersForMap() {
  if (!mapPointsAvailable.value) return;

  const markers = new Array();

  if (isNotEmptyArray(mapPointsObject.value?.demPoints)) {
    mapPointsObject.value.demPoints.forEach((demPoint) => {
      let marker = {
        lat: demPoint.lat,
        lng: demPoint.lon,
        draggable: false,
        markerId: `demPoint_${demPoint.id}`,
        selected: false
      };
      marker.icon = 'blue_marker';
      marker.iconSize = [25, 40];
      marker.iconAnchor = [12, 40];
      markers.push(marker);
    });
  }

  if (isNotEmptyArray(mapPointsObject.value?.actions)) {
    mapPointsObject.value.actions.forEach((action) => {
      let marker = {
        lat: action.lat,
        lng: action.lon,
        draggable: false,
        markerId: `action_${action.id}`,
        selected: false
      };
      marker.icon = action.demPointId != null ? 'blue_marker' : 'purple_marker';
      marker.iconSize = [25, 40];
      marker.iconAnchor = [12, 40];
      markers.push(marker);
    });
  }

  clusterMap.value.initializeMap({ markersData: markers });
}

function onMarkerClicked(clickedMarker) {
  if (clickedMarker.markerId.startsWith('demPoint_')) {
    pickedPoint.value = mapPointsObject.value.demPoints.find(
      (demPoint) => `demPoint_${demPoint.id}` === clickedMarker.markerId
    );
  } else {
    pickedPoint.value = mapPointsObject.value.actions.find(
      (action) => `action_${action.id}` === clickedMarker.markerId
    );
  }

  if (isNotEmptyArray(clusterMap.value?.markersRef)) {
    clusterMap.value.markersRef.forEach((marker) => {
      updateMarkerSelectedState(marker, clickedMarker.markerId === marker.markerId);
    });
  }
}

function onClickShowOnMaps({ lat, lon }) {
  showOnGoogleMaps(lat, lon);
}

function onClickCopyToClipboard({ lat, lon }) {
  copyToClipboard(lat + ',' + lon);
}

function onMapClickedOverride() {
  resetSelectedIfCtrl();
  pickedPoint.value = null;
}

getMapPoints();
</script>

<template>
  <div class="row m-3">
    <div class="ibox col-lg-8 pr-1">
      <div class="ibox-content" :class="{ 'sk-loading': isLoading }" style="border-style: none">
        <div class="sk-spinner sk-spinner-three-bounce">
          <div class="sk-bounce1"></div>
          <div class="sk-bounce2"></div>
          <div class="sk-bounce3"></div>
        </div>

        <ClusterMap
          ref="clusterMap"
          height="700px"
          @markerClicked="onMarkerClicked"
          @map-clicked="onMapClickedOverride"
        />
      </div>
    </div>

    <div class="ibox col-lg-4 pl-1">
      <div
        class="ibox-content profile-content pl-4"
        :class="{ 'sk-loading': isLoading }"
        style="border-style: none"
      >
        <div class="sk-spinner sk-spinner-three-bounce">
          <div class="sk-bounce1"></div>
          <div class="sk-bounce2"></div>
          <div class="sk-bounce3"></div>
        </div>

        <div v-if="pickedPoint" class="mt-4">
          <b>Wybrany punkt: </b>
          <div class="pl-2">
            <br />
            <b>Oznaczenie:</b>
            {{ pickedPoint.oznaczenie ?? pickedPoint.demPointOznaczenie ?? '-' }}
            <br />
            <b>Szerokość geograficzna:</b>
            {{ pickedPoint.lat ?? '-' }}
            <br />
            <b>Długość geograficzna:</b>
            {{ pickedPoint.lon ?? '-' }}
            <br />
            <b>Uwagi:</b>
            {{ pickedPoint.demPointNote ?? '-' }}
            <br />
            <button
              class="btn btn-outline btn-primary btn-xs mr-2 mt-2"
              type="button"
              @click="onClickShowOnMaps(pickedPoint)"
            >
              Nawiguj
            </button>
            <button
              class="btn btn-outline btn-primary btn-xs mr-2 mt-2"
              type="button"
              @click="onClickCopyToClipboard(pickedPoint)"
            >
              Kopiuj współrzędne
            </button>
          </div>
        </div>
        <div v-else-if="isNotEmptyArray(clusterMap?.markersRef)" class="mt-4">
          <p class="text-center">Wybierz punkt z mapy</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
