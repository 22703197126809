<script setup>
/* eslint-env jquery */
import { ref, computed, watch } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { createToaster } from '@meforma/vue-toaster';
import { useFilesHandler } from '@/composables/filesHandler.js';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import DropArea from '@/components/utils/DropArea.vue';
import ManualAcceptanceProtocolsTable from '../acceptanceProtocols/ManualAcceptanceProtocolsTable.vue';
import GeneratedAcceptanceProtocolsTable from '../acceptanceProtocols/GeneratedAcceptanceProtocolsTable.vue';
import { isNotEmptyArray, isNullOrEmptyString } from '../../helpers/utilsHelper';
import {
  TOASTER_ATTACH_FILE_MSG,
  TOASTER_NO_EBP_IN_ORDER_MSG,
  TOASTER_SERVER_ERROR_MSG
} from '../../data/constants/toasterMessagesConstants';
import { APP_PRIMARY_COLOR } from '../../data/constants/appConstants';
import AcceptanceProtocolGenerationPopup from '../acceptanceProtocols/AcceptanceProtocolGenerationPopup.vue';
import RadioToggle from '../utils/RadioToggle.vue';
import ModalInputField from '../utils/ModalInputField.vue';
import { MODAL_ORDER_PROTOCOL_TYPES_TABLE } from '../../data/constants/modalConstants';
import ModalTable from '../utils/ModalTable.vue';
import { handleResponseWithMessages } from '../../helpers/wwwHelper.js';

const PROTOCOL_FILE_INPUT_ID = 'fileInputField2';

const RepositoryOrders = RepositoryFactory.get('orders');
const props = defineProps({
  orderId: Number,
  isLoading: Boolean,
  protocolType: Object,
  orderEbp: String
});
const emit = defineEmits(['refresh']);
const toaster = createToaster({ position: 'top-right', duration: 3000 });
const PONumber = ref(null);
const amount = ref(null);
const billingMonth = ref(null);
const authStore = useAuthStore();
const {
  filesRef,
  showFileInput,
  onFilesChanged,
  onDropAreaFilesChanged,
  getCreationDate,
  clearFileInput
} = useFilesHandler(false, PROTOCOL_FILE_INPUT_ID);
const generatedAPTableKey = ref(0);
const manualAPTableKey = ref(0);
const acceptanceProtocolGenerationPopupRef = ref();
const activeTabId = ref(0);
const alreadyLoadedAPTables = ref({
  addedManually: true,
  generated: false
});
const modalTable = ref();

const loggedInUser = computed(() => authStore.loggedInUser);

watch(showFileInput, (newValue) => {
  if (newValue) {
    const datepickerConfig = {
      todayBtn: 'linked',
      keyboardNavigation: true,
      forceParse: true,
      calendarWeeks: true,
      autoclose: true,
      weekStart: 1,
      format: 'yyyy-mm',
      minViewMode: 'months'
    };

    $('#billingMonthFormGroupId .input-group.date').datepicker(datepickerConfig);
    $(document.body).on('change', 'input[id^="billingMonthInputId"]', updateBillingMonthFromPicker);
  }
});

function onDataUpload(e) {
  e.preventDefault();
  onFileUpload();
}

async function onFileUpload() {
  if (!isNotEmptyArray(filesRef.value)) {
    toaster.show(TOASTER_ATTACH_FILE_MSG, { type: 'warning' });
    return;
  }

  var response = await RepositoryOrders.sendAcceptanceProtocol(
    props.orderId,
    filesRef.value[0].file,
    getCreationDate(),
    loggedInUser.value?.id,
    PONumber.value,
    amount.value,
    billingMonth.value
  );

  if (response?.serverError) {
    toaster.show(TOASTER_SERVER_ERROR_MSG, { type: 'error' });
  } else {
    if (response) {
      let msg = 'Coś poszło nie tak.';
      if (Array.isArray(response)) {
        msg = '';
        response.forEach((element) => {
          msg += element + '. ';
        });
      }
      toaster.show(msg, { type: 'warning' });
    } else {
      emit('refresh');
      toaster.show('Protokół odbioru został przesłany.', { type: 'success' });
      filesRef.value = [];
      PONumber.value = null;
      amount.value = null;
      billingMonth.value = null;
      manualAPTableKey.value++;
      clearFileInput();
    }
  }
}

function onShowPOGenerationPanelButtonClicked() {
  if (isNullOrEmptyString(props.orderEbp)) {
    toaster.show(TOASTER_NO_EBP_IN_ORDER_MSG, { type: 'warning' });
    return;
  }

  showPOGenerationPanel();
}

function showPOGenerationPanel() {
  acceptanceProtocolGenerationPopupRef.value.show();
}

function onPOTablesTabChanged(activeId) {
  activeTabId.value = activeId;

  switch (activeId) {
    case 0:
      alreadyLoadedAPTables.value.addedManually = true;
      break;
    case 1:
      alreadyLoadedAPTables.value.generated = true;
      break;
  }
}

function updateBillingMonthFromPicker() {
  billingMonth.value = document.getElementById('billingMonthInputId').value;
}

function onModalCompleted(data) {
  modalTable.value.hide();

  switch (modalTable.value.modal.type) {
    case MODAL_ORDER_PROTOCOL_TYPES_TABLE:
      changeOrderProtocolType(data);
      break;
  }
}

function onModalRejected() {
  modalTable.value.hide();

  switch (modalTable.value.modal.type) {
    case MODAL_ORDER_PROTOCOL_TYPES_TABLE:
      changeOrderProtocolType(null);
      break;
  }
}

async function changeOrderProtocolType(newProtocolType) {
  var response = await RepositoryOrders.changeOrderProtocolType(props.orderId, newProtocolType?.id);

  handleResponseWithMessages(response, 'Typ protokołu został zmieniony.', () => {
    emit('refresh');
  });
}

// created
showFileInput.value = false;
</script>

<template>
  <div class="ibox">
    <div class="ibox-content" :class="{ 'sk-loading': isLoading }" style="border-style: none">
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <h3 class="mb-3">Typ protokołu</h3>

      <div class="row">
        <div class="col-lg-6" style="max-width: 300px">
          <ModalInputField
            title="Typ"
            :displayText="props.protocolType?.name ?? '-'"
            nullText="Wybierz typ protokołu"
            :required="false"
            @showModal="modalTable.showModal(MODAL_ORDER_PROTOCOL_TYPES_TABLE)"
          />
        </div>
      </div>

      <h3 class="mb-3">Nowy protokół odbioru</h3>
      <div class="row" style="min-height: 150px">
        <div class="col-lg-6" :style="`border-right: 1px dashed #${APP_PRIMARY_COLOR};`">
          <h4 class="mb-3">Dodaj..</h4>
          <button
            v-show="!showFileInput"
            class="btn btn-primary btn-outline"
            @click="showFileInput = true"
          >
            Dodaj
          </button>

          <form v-show="showFileInput" class="mb-3 pb-3" @submit="onDataUpload">
            <div class="row">
              <div class="col-4">
                <div class="form-group">
                  <label>Numer</label>
                  <input
                    type="text"
                    placeholder="Wpisz numer"
                    v-model="PONumber"
                    class="form-control"
                    required
                    minlength="1"
                    maxlength="100"
                  />
                </div>

                <div class="form-group">
                  <label>Liczba elementów</label>
                  <input
                    type="number"
                    @mousewheel.prevent=""
                    placeholder="Wpisz liczbę"
                    v-model="amount"
                    class="form-control"
                    required
                    min="0"
                    max="999999999"
                  />
                </div>

                <div class="form-group" id="billingMonthFormGroupId">
                  <label>Miesiąc rozliczeniowy</label>
                  <div class="input-group date">
                    <span class="input-group-addon" :style="'background-color: #e9ecef'"
                      ><i class="fa fa-calendar"></i></span
                    ><input
                      id="billingMonthInputId"
                      type="text"
                      class="form-control"
                      v-model="billingMonth"
                      required
                    />
                  </div>
                </div>
              </div>

              <div class="col-8">
                <h5>Wczytaj protokół odbioru</h5>

                <DropArea
                  id="dropArea2"
                  class="dropArea"
                  caption="Upuść plik tutaj albo kliknij 'Wybierz plik' poniżej"
                  :fileInputId="PROTOCOL_FILE_INPUT_ID"
                  @onFileChanged="onDropAreaFilesChanged($event)"
                />
                <input
                  :id="PROTOCOL_FILE_INPUT_ID"
                  name="file"
                  type="file"
                  class="mt-2"
                  @change="onFilesChanged($event)"
                />
                <button class="btn btn-primary mt-2 float-right" type="submit">
                  Prześlij dane
                </button>
              </div>
            </div>
          </form>
        </div>

        <div class="col-lg-6">
          <h4 class="mb-3">... lub wygeneruj protokół odbioru</h4>

          <button class="btn btn-primary btn-outline" @click="onShowPOGenerationPanelButtonClicked">
            Wygeneruj
          </button>

          <p v-if="isNullOrEmptyString(props.orderEbp)" class="mt-2 text-muted font-italic">
            Aby wygenerować protokół odbioru, zlecenie musi posiadać numer EBP.
          </p>
        </div>
      </div>

      <h3>Dodane protokoły odbioru</h3>

      <div class="row">
        <div class="mt-1 pl-3">
          <RadioToggle
            :initialOptions="[
              { id: 0, title: `Dodane ręcznie`, checked: true },
              { id: 1, title: `Wygenerowane`, checked: false }
            ]"
            @onChanged="onPOTablesTabChanged"
          />
        </div>
      </div>

      <span v-if="alreadyLoadedAPTables.addedManually" v-show="activeTabId === 0">
        <ManualAcceptanceProtocolsTable :key="manualAPTableKey" :order-id="props.orderId" />
      </span>

      <span v-if="alreadyLoadedAPTables.generated" v-show="activeTabId === 1">
        <GeneratedAcceptanceProtocolsTable :key="generatedAPTableKey" :order-id="props.orderId" />
      </span>
    </div>
  </div>

  <AcceptanceProtocolGenerationPopup
    ref="acceptanceProtocolGenerationPopupRef"
    :orderId="props.orderId"
    @succeeded="
      () => {
        generatedAPTableKey++;
        emit('refresh');
      }
    "
  />

  <ModalTable
    ref="modalTable"
    @onModalCompleted="onModalCompleted"
    @onModalRejected="onModalRejected"
    :showCloseButton="true"
  ></ModalTable>
</template>

<style scoped></style>
