import repository from './repository.js';
import { isFormattedYYYYMM } from '../helpers/dateFormatHelper.js';

export default {
  async getRaportPreSummary(orderId) {
    return repository
      .get(`/po/${orderId}/getPreRaportSummary`)
      .then((response) => {
        if (response.data.error == null && response.data != null) return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async generateReport(orderId, reportData, verification) {
    return repository
      .post(`/po/saveRaportSummary`, { id_order: orderId, data: reportData, verification })
      .then((response) => {
        return response?.data?.message ?? response.data.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async fetchReportData(poId) {
    return repository
      .get(`/po/${poId}/generateRaportFile`)
      .then((response) => {
        if (response.data.error == null && response.data != null) return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async updateAP(id, number, amount, billingMonth) {
    const updatedAP = {
      number,
      amount
    };

    if (isFormattedYYYYMM(billingMonth)) {
      updatedAP.billing_month = billingMonth + '-1';
    }

    return repository
      .put(`/po/${id}`, updatedAP)
      .then((response) => {
        return response?.data?.message ?? null;
      })
      .catch((serverError) => {
        return { serverError };
      });
  }
};
