<script setup>
import { ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { isNotEmptyArray } from '../../helpers/utilsHelper';
import { createToaster } from '@meforma/vue-toaster';
import { generatePdf } from '@/helpers/PDFGenerator.js';
import { getLocalFormattedDateAndTime } from '../../helpers/dateFormatHelper';
import { createPdfDataForGenerator } from '@/helpers/transportListPdfContentHelper.js';
import { TOASTER_FILE_ERROR_MSG } from '../../data/constants/toasterMessagesConstants';
import TransportMapAdditionalSummaryForSinglePoint from '../fileRepositories/TransportMapAdditionalSummaryForSinglePoint.vue';
import TransportPlacesTableForMap from './TransportPlacesTableForMap.vue';

const RepositoryTransportOrders = RepositoryFactory.get('transportOrders');
const props = defineProps({
  selectedPlacesIds: { type: Array, default: () => [] }
});
const toaster = createToaster({ position: 'top-right', duration: 3000 });
const router = useRouter();
const isLoading = ref(false);
const summaryObject = ref(null);
const transportMapAdditionalSummaryForSinglePointComponent = ref();

const transportListFilename = computed(
  () => 'Lista punktów zbioru ' + getLocalFormattedDateAndTime(new Date().toJSON())
);

const infoText = computed(() => {
  if (!summaryObject.value?.summary) return null;

  let text = '';

  for (const property in summaryObject.value.summary) {
    text += `${property}: ${summaryObject.value.summary[property]}\n`;
  }

  if (text.endsWith('\n')) {
    text = text.slice(0, -1);
  }

  if (text === '') text = '<brak>';

  return text;
});

watch(
  () => props.selectedPlacesIds,
  (newValue, oldValue) => {
    if (oldValue.length === 0 && newValue.length === 0) return;

    fetchData();
  }
);

async function fetchData() {
  isLoading.value = true;

  summaryObject.value = await RepositoryTransportOrders.getSummaryForSelectedTransportPoints(
    props.selectedPlacesIds
  );

  if (props.selectedPlacesIds?.length === 1) {
    transportMapAdditionalSummaryForSinglePointComponent.value?.fetchData();
  }

  isLoading.value = false;
}

async function onGeneratePDFButtonClicked() {
  isLoading.value = true;

  const data = await RepositoryTransportOrders.getDataForTransportList(props.selectedPlacesIds);

  const pdfContent = createPdfDataForGenerator(data);

  if (pdfContent != null) {
    generatePdf(pdfContent, transportListFilename.value);
  } else {
    toaster.show(TOASTER_FILE_ERROR_MSG, { type: 'error' });
  }

  isLoading.value = false;
}

function onAssignDriversButtonClicked() {
  router.push({
    name: 'transportOrderAdd',
    query: { placesIds: props.selectedPlacesIds.toString() }
  });
}
</script>

<template>
  <div class="ibox-content" :class="{ 'sk-loading': isLoading }" style="border-style: none">
    <div class="sk-spinner sk-spinner-three-bounce">
      <div class="sk-bounce1"></div>
      <div class="sk-bounce2"></div>
      <div class="sk-bounce3"></div>
    </div>

    <div v-if="infoText" class="mt-3">
      <h3>Szczegóły zaznaczonych punktów:</h3>

      <span style="white-space: pre">{{ infoText }}</span>
    </div>
    <div v-else class="mt-4">
      <p class="text-center">Wybierz punkty z mapy</p>
    </div>

    <div v-if="infoText" class="mt-4">
      <button
        class="btn btn-outline btn-primary btn-xs mr-2"
        type="button"
        :disabled="!isNotEmptyArray(props.selectedPlacesIds)"
        @click="onGeneratePDFButtonClicked"
      >
        Wygeneruj plik PDF
      </button>

      <button
        class="btn btn-primary btn-xs"
        type="button"
        :disabled="!isNotEmptyArray(props.selectedPlacesIds)"
        @click="onAssignDriversButtonClicked"
      >
        Przydziel kierowców
      </button>
    </div>

    <TransportPlacesTableForMap
      v-if="props.selectedPlacesIds != null && props.selectedPlacesIds.length > 1"
      class="mt-5"
      :selectedPlacesIds="props.selectedPlacesIds"
    />

    <TransportMapAdditionalSummaryForSinglePoint
      v-if="props.selectedPlacesIds != null && props.selectedPlacesIds.length == 1"
      class="mt-5"
      ref="transportMapAdditionalSummaryForSinglePointComponent"
      :actionId="props.selectedPlacesIds[0]"
    />
  </div>
</template>

<style scoped></style>
