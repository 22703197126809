<script setup>
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import RepoFilesTablePOST from '../components/fileRepositories/RepoFilesTablePOST.vue';

const route = useRoute();

const orderNumber = computed(() => {
  return route.query.orderNumber;
});

const actionId = computed(() => {
  return route.query.actionId;
});

const actionType = computed(() => {
  return route.query.actionType;
});

const token = computed(() => {
  return route.query.token;
});

const userFilter = computed(() => {
  return {
    id_action: actionId.value != null ? parseInt(actionId.value) : null,
    token: token.value
  };
});
</script>

<template>
  <div class="ibox m-3">
    <div class="ibox-content" style="border-style: none">
      <h2 class="p-3" style="text-align: center">
        Galeria akcji <b>{{ actionType }}</b> ze zlecenia <b>{{ orderNumber }}</b>
      </h2>

      <RepoFilesTablePOST
        modalId="modalImageContainerIdOrderActionGallery"
        url="action/resourcesAuthorized"
        :showBorder="false"
        :deletingEnabled="false"
        :userFilter="userFilter"
      />
    </div>
  </div>
</template>

<style scoped></style>
