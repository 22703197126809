<script setup>
import { ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { getDateWithoutTime } from '@/helpers/dateFormatHelper.js';
import Swal from 'sweetalert2';
import {
  MODAL_ORDER_STATUSES_TABLE,
  MODAL_ORDER_GEODETIC_PHASES_TABLE,
  MODAL_USERS_TABLE,
  MODAL_ORDER_INTERRUPTION_STATUSES_TABLE,
  MODAL_ORDER_SETTLEMENT_PHASES_TABLE
} from '@/data/constants/modalConstants.js';
import OrderAddressesAndRangesTables from './OrderAddressesAndRangesTables.vue';
import { handleResponseWithMessages } from '@/helpers/wwwHelper.js';
import { useNavigationPanelStore } from '@/stores/navigationPanel';
import ModalTable from '@/components/utils/ModalTable.vue';
import { useAuthStore } from '../../stores/auth';
import {
  ACCESS_KEY_ORDER_DETAILS_ARCHIVE_BUTTON,
  ACCESS_KEY_ORDER_DETAILS_DELETE_BUTTON,
  ACCESS_KEY_ORDER_DETAILS_EDIT_BUTTON
} from '../../data/constants/authConstants';
import { getSwalConfiguration } from '../../helpers/swalHelper';
import { verifyOrderDeletionWithStringRetyping } from '../../helpers/orders/ordersDeletionVerification';
import { verifyOrderArchiving } from '../../helpers/orders/ordersArchivingVerification';
import { getLocalFormattedDateAndTime } from '../../helpers/dateFormatHelper';

const INTERRUPTED_DISASSEMBLY_ORDER_STATUS_ID = 3;
const RepositoryOrders = RepositoryFactory.get('orders');
const props = defineProps({ id: Number, collapsed: Boolean });
const emit = defineEmits([
  'onIsLoadingChanged',
  'onOrderLoaded',
  'refresh',
  'collapsePanel',
  'expandPanel'
]);

const isLoading = ref(false);
const order = ref(null);
const router = useRouter();
const navigationPanelStore = useNavigationPanelStore();
const modalTable = ref();
const authStore = useAuthStore();

const ordersDelayDays = computed(() => {
  if (order.value?.delayDays == null) return '-';
  else if (order.value.delayDays <= 0) return 'brak';

  if (order.value.delayDays === 1) return order.value.delayDays.toString() + ' dzień';
  else return order.value.delayDays.toString() + ' dni';
});

const isAuthorLoggedIn = computed(
  () => order.value?.created_by != null && order.value.created_by.id === authStore.loggedInUser.id
);

const canEditOrder = computed(() => {
  if (authStore.canAccess(ACCESS_KEY_ORDER_DETAILS_EDIT_BUTTON)) return true;
  if (authStore.isTransportManagerLoggedIn && isAuthorLoggedIn.value) return true;

  return false;
});

watch(
  () => props.id,
  () => getOrderDetails()
);

watch(isLoading, () => {
  emit('onIsLoadingChanged', isLoading.value);
});

watch(order, () => {
  emit('onOrderLoaded', order.value);
});

async function getOrderDetails() {
  isLoading.value = true;

  navigationPanelStore.setOrderNumber('-');
  const urlEndingOverride = authStore.isSubcontractorLoggedIn ? 'details_web_subcontractor' : null;
  order.value = await RepositoryOrders.getOrderDetailsGeneral(props.id, urlEndingOverride);
  navigationPanelStore.setOrderNumber(order.value?.order_number);

  isLoading.value = false;
}

function goToUserDetails(id) {
  if (id == null) return;

  router.push({ name: 'userDetails', params: { id } });
}

function onReopenOrderButtonClicked() {
  Swal.fire(
    getSwalConfiguration('Ponowne otwarcie zlecenia', 'Czy na pewno chcesz otworzyć zlecenie?')
  ).then((result) => {
    if (result.value) {
      reopenOrder();
    }
  });
}

async function reopenOrder() {
  var response = await RepositoryOrders.reopenOrder(order.value?.id);

  handleResponseWithMessages(this, response, 'Otwarto zlecenie.', () => {
    emit('refresh');
  });
}

function editOrder() {
  router.push({ name: 'orderAddEdit', params: { id: props.id } });
}

function onRestoreOrderButtonClicked() {
  Swal.fire(
    getSwalConfiguration('Przywrócenie zlecenia', 'Czy na pewno chcesz przywrócić zlecenie?')
  ).then((result) => {
    if (result.value) {
      restoreOrder();
    }
  });
}

async function onArchiveOrderButtonClicked() {
  isLoading.value = true;
  await verifyOrderArchiving(() => RepositoryOrders.verifyArchive(order.value?.id), archiveOrder);
  isLoading.value = false;
}

async function archiveOrder() {
  var response = await RepositoryOrders.archiveOrder(order.value?.id);

  handleResponseWithMessages(response, 'Zarchiwizowano zlecenie.', () => {
    emit('refresh');
  });
}

async function restoreOrder() {
  var response = await RepositoryOrders.restoreOrder(order.value?.id);

  handleResponseWithMessages(response, 'Przywrócono zlecenie.', () => {
    emit('refresh');
  });
}

function archiveAndRestoreButtonDisplay(buttonType) {
  if (authStore.canAccess(ACCESS_KEY_ORDER_DETAILS_ARCHIVE_BUTTON) == false) return false;

  switch (buttonType) {
    case 'archive':
      return order.value.archived_at == null;
    case 'restore':
      return order.value.archived_at != null;
  }
}

function onDeleteOrderButtonClicked() {
  verifyOrderDeletion();
}

async function verifyOrderDeletion() {
  isLoading.value = true;

  await verifyOrderDeletionWithStringRetyping(
    () => RepositoryOrders.verifyDelete(order.value?.id),
    deleteOrder
  );

  isLoading.value = false;
}

async function deleteOrder() {
  var response = await RepositoryOrders.deleteOrder(order.value?.id);

  handleResponseWithMessages(response, 'Usunięto zlecenie.', () => {
    router.push({ name: 'ordersTable' });
  });
}

function onModalCompleted(data) {
  modalTable.value.hide();

  switch (modalTable.value.modal.type) {
    case MODAL_ORDER_STATUSES_TABLE:
      changeOrderStatus(data);
      break;
    case MODAL_ORDER_GEODETIC_PHASES_TABLE:
      changeOrderGeodeticPhase(data);
      break;
    case MODAL_USERS_TABLE:
      changeSubcontractor(data);
      break;
    case MODAL_ORDER_INTERRUPTION_STATUSES_TABLE:
      changeInterruptionStatus(data);
      break;
    case MODAL_ORDER_SETTLEMENT_PHASES_TABLE:
      changeSettlementStatus(data);
      break;
  }
}

function onModalRejected() {
  modalTable.value.hide();
}

async function changeOrderStatus(newStatus) {
  var response = await RepositoryOrders.changeOrderStatus(order.value?.id, newStatus?.id);

  handleResponseWithMessages(response, 'Status został zmieniony.', () => {
    order.value.order_status = newStatus;
  });
}

async function changeOrderGeodeticPhase(newGeodeticPhase) {
  var response = await RepositoryOrders.changeOrderGeodeticPhase(
    order.value?.id,
    newGeodeticPhase?.id
  );

  handleResponseWithMessages(response, 'Etap geodezji został zmieniony.', () => {
    order.value.geodesy_phase = newGeodeticPhase;
  });
}

async function changeSubcontractor(newSubcontractor) {
  var response = await RepositoryOrders.changeSubcontractor(order.value?.id, newSubcontractor?.id);

  handleResponseWithMessages(response, 'Podwykonawca został zmieniony.', () => {
    order.value.subcontractor = newSubcontractor;
  });
}

async function changeInterruptionStatus(newStatus) {
  var response = await RepositoryOrders.changeInterruptionStatus(order.value?.id, newStatus?.id);

  handleResponseWithMessages(response, 'Powód przerwania został zmieniony.', () => {
    order.value.interruption_status = newStatus;
  });
}

async function changeSettlementStatus(newStatus) {
  var response = await RepositoryOrders.changeSettlementStatus(order.value?.id, newStatus?.id);

  handleResponseWithMessages(response, 'Status rozliczenia został zmieniony.', () => {
    order.value.settlement_phase = newStatus;
  });
}

function onRemoveSubcontractorButtonClicked() {
  Swal.fire(
    getSwalConfiguration('Usunięcie podwykonawcy', 'Czy na pewno chcesz usunąć podwykonawcę?')
  ).then((result) => {
    if (result.value) {
      removeSubcontractor();
    }
  });
}

async function removeSubcontractor() {
  var response = await RepositoryOrders.removeSubcontractor(order.value?.id);

  handleResponseWithMessages(response, 'Podwykonawca został usunięty.', () => {
    order.value.subcontractor = null;
  });
}

function onChangeClaimClicked() {
  const message = `Czy na pewno chcesz zmienić wartość pola 'Roszczenie' na wartość ${
    order.value.claim === 1 ? "'Nie'" : "'Tak'"
  }?`;

  Swal.fire(getSwalConfiguration('Zmiana ustawienia', message)).then((result) => {
    if (result.value) {
      toggleClaim();
    }
  });
}

async function toggleClaim() {
  const newClaimValue = order.value.claim === 1 ? 0 : 1;

  var response = await RepositoryOrders.changeClaim(props.id, newClaimValue);

  handleResponseWithMessages(response, "Status pola 'Roszczenie' został zmieniony.", () => {
    order.value.claim = newClaimValue;
  });
}

function onChangePriorityClicked() {
  const message = `Czy na pewno chcesz ${
    order.value.priority === 1 ? 'wyłączyć' : 'włączyć'
  } priorytet zlecenia?`;

  Swal.fire(getSwalConfiguration('Zmiana priorytetu', message)).then((result) => {
    if (result.value) {
      togglePriority();
    }
  });
}

async function togglePriority() {
  const newPriorityValue = order.value.priority === 1 ? 0 : 1;
  var response = await RepositoryOrders.changePriority(props.id, newPriorityValue);

  handleResponseWithMessages(response, "Status pola 'Priorytet' został zmieniony.", () => {
    order.value.priority = newPriorityValue;
  });
}

// created
getOrderDetails();
</script>

<template>
  <div v-show="!props.collapsed" class="ibox">
    <div class="ibox-title pr-3">
      <div class="row">
        <div class="col-12" style="display: flex; justify-content: space-between">
          <div v-if="order">
            <button
              v-if="archiveAndRestoreButtonDisplay('archive')"
              class="btn btn-outline btn-info btn-xs mr-2"
              @click="onArchiveOrderButtonClicked"
            >
              Zarchiwizuj
            </button>
            <button
              v-if="archiveAndRestoreButtonDisplay('restore')"
              class="btn btn-outline btn-info btn-xs mr-2"
              @click="onRestoreOrderButtonClicked"
            >
              Przywróć
            </button>
            <button
              v-if="canEditOrder"
              class="btn btn-outline btn-info btn-xs mr-2"
              @click="editOrder"
            >
              Edytuj
            </button>
            <button
              v-if="authStore.canAccess(ACCESS_KEY_ORDER_DETAILS_DELETE_BUTTON)"
              class="btn btn-outline btn-danger btn-xs"
              @click="onDeleteOrderButtonClicked"
            >
              Usuń
            </button>
          </div>

          <button
            class="btn btn-primary btn-xs float-right ml-2 mr-1"
            @click="emit('collapsePanel')"
          >
            Ukryj panel
          </button>
        </div>
      </div>
    </div>

    <div class="ibox-content profile-content" :class="{ 'sk-loading': isLoading }">
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <table class="mt-4 mb-4 equal-columns-table">
        <tr v-if="order?.order_status !== undefined">
          <td>Status:</td>
          <td>
            <span
              class="label label-primary"
              :style="
                order?.order_status?.color != null
                  ? 'background-color:' + order.order_status.color
                  : ''
              "
            >
              {{ order?.order_status?.name ?? '-' }}</span
            >
            <button
              type="button"
              @click="modalTable.showModal(MODAL_ORDER_STATUSES_TABLE)"
              class="btn btn-xs btn-outline btn-link"
            >
              Zmień
            </button>
          </td>
        </tr>

        <tr
          v-if="
            order?.order_status?.id === INTERRUPTED_DISASSEMBLY_ORDER_STATUS_ID &&
            order?.interruption_status !== undefined
          "
        >
          <td>Powód przerwania:</td>
          <td>
            <span
              class="label label-primary"
              :style="
                order?.interruption_status?.color != null
                  ? 'background-color:' + order.interruption_status.color
                  : ''
              "
            >
              {{ order?.interruption_status?.name ?? '-' }}</span
            >
            <button
              type="button"
              @click="modalTable.showModal(MODAL_ORDER_INTERRUPTION_STATUSES_TABLE)"
              class="btn btn-xs btn-outline btn-link"
            >
              Zmień
            </button>
          </td>
        </tr>

        <tr
          v-if="
            order?.geodesy_phase !== undefined &&
            (order?.order_type?.id === 2 || order?.order_type?.id === 3) &&
            order?.order_status?.id === 6
          "
        >
          <td>Etap geodezji:</td>
          <td>
            <span
              class="label label-primary"
              :style="
                order?.geodesy_phase?.color != null
                  ? 'background-color:' + order.geodesy_phase.color
                  : ''
              "
            >
              {{ order?.geodesy_phase?.name ?? '-' }}</span
            >
            <button
              type="button"
              @click="modalTable.showModal(MODAL_ORDER_GEODETIC_PHASES_TABLE)"
              class="btn btn-xs btn-outline btn-link"
            >
              Zmień
            </button>
          </td>
        </tr>

        <tr v-if="order?.settlement_phase !== undefined && order?.order_status?.id === 9">
          <td>Status rozliczenia:</td>
          <td>
            <span
              class="label label-primary"
              :style="
                order?.settlement_phase?.color != null
                  ? 'background-color:' + order.settlement_phase.color
                  : ''
              "
            >
              {{ order?.settlement_phase?.name ?? '-' }}</span
            >
            <button
              type="button"
              @click="modalTable.showModal(MODAL_ORDER_SETTLEMENT_PHASES_TABLE)"
              class="btn btn-xs btn-outline btn-link"
            >
              Zmień
            </button>
          </td>
        </tr>

        <tr v-if="order?.order_type !== undefined">
          <td>Typ:</td>
          <td>
            <span
              class="label label-primary"
              :style="
                order?.order_type?.color != null ? 'background-color:' + order.order_type.color : ''
              "
            >
              {{ order?.order_type?.name ?? '-' }}</span
            >
          </td>
        </tr>
        <tr v-if="order?.priority !== undefined">
          <td>Priorytet:</td>
          <td>
            <div style="display: flex; align-items: flex-start">
              <div>
                <i
                  v-if="order?.priority === 1"
                  class="fa fa-exclamation ml-1"
                  style="color: red; font-size: 20px"
                ></i>
                <span v-else>-</span>
              </div>

              <button
                type="button"
                @click="onChangePriorityClicked"
                class="btn btn-xs btn-outline btn-link"
                style="padding: 0px"
              >
                Zmień
              </button>
            </div>
          </td>
        </tr>
        <tr v-if="order?.direction !== undefined">
          <td>Rodzaj zlecenia:</td>
          <td>
            <span
              class="label label-primary"
              :style="
                order?.direction?.color != null ? 'background-color:' + order.direction.color : ''
              "
            >
              {{ order?.direction?.name ?? '-' }}</span
            >
          </td>
        </tr>
        <tr v-if="order?.claim !== undefined">
          <td>Roszczenie:</td>
          <td>
            <div style="display: flex; align-items: end">
              <div class="switch">
                <div class="onoffswitch">
                  <input type="checkbox" class="onoffswitch-checkbox" :checked="order?.claim" />
                  <label class="onoffswitch-label" style="margin-bottom: 0px">
                    <span class="onoffswitch-inner" style="cursor: default"></span>
                    <span class="onoffswitch-switch" style="cursor: default"></span>
                  </label>
                </div>
              </div>

              <button
                type="button"
                @click="onChangeClaimClicked"
                class="btn btn-xs btn-outline btn-link"
                style="padding: 0px"
              >
                Zmień
              </button>
            </div>
          </td>
        </tr>

        <tr v-if="order?.order_initiative !== undefined">
          <td>Inicjatywa:</td>
          <td>
            {{ order?.order_initiative?.name ?? '-' }}
          </td>
        </tr>

        <tr v-if="order?.note_presence !== undefined">
          <td>Notatka:</td>
          <td>
            <img
              v-if="order?.note_presence > 0"
              src="/static/img/markerIcons/exclamation_mark_circle_icon.png"
              alt="Notatka"
              style="width: 22px; height: 22px; padding-right: 0px"
            />
            <span v-else>-</span>
          </td>
        </tr>

        <tr v-if="order?.order_number !== undefined">
          <td>Numer zlecenia:</td>
          <td>{{ order?.order_number ?? '-' }}</td>
        </tr>
        <tr v-if="order?.ebp !== undefined">
          <td>Numer EBP:</td>
          <td>{{ order?.ebp ?? '-' }}</td>
        </tr>
        <tr v-if="order?.region !== undefined">
          <td>Region:</td>
          <td>
            {{ order?.region?.name ?? '-' }}
          </td>
        </tr>
        <tr v-if="order?.gmina !== undefined">
          <td>Gmina:</td>
          <td>
            {{ order?.gmina?.name ?? '-' }}
          </td>
        </tr>
        <tr v-if="order?.powiat !== undefined">
          <td>Powiat:</td>
          <td>
            {{ order?.powiat?.name ?? '-' }}
          </td>
        </tr>
        <tr v-if="order?.description !== undefined">
          <td>Opis ogólny:</td>
          <td>{{ order?.description ?? '-' }}</td>
        </tr>
        <tr v-if="order?.coordinator_opl !== undefined">
          <td>Koordynator:</td>
          <td>
            <span
              v-if="
                order?.coordinator_opl?.id != null &&
                order?.coordinator_opl?.firstName != null &&
                order?.coordinator_opl?.lastName != null
              "
            >
              <a @click="goToUserDetails(order.coordinator_opl.id)" :class="'text-navy'">
                {{ order.coordinator_opl.firstName + ' ' + order.coordinator_opl.lastName }}
              </a>
            </span>
            <span v-else>-</span>
          </td>
        </tr>
        <tr v-if="order?.coordinator_opl !== undefined">
          <td>Adres e-mail:</td>
          <td>
            {{ order?.coordinator_opl?.email ?? '-' }}
          </td>
        </tr>
        <tr v-if="order?.coordinator_opl !== undefined">
          <td>Telefon koordynatora:</td>
          <td>
            {{ order?.coordinator_opl?.phone ?? '-' }}
          </td>
        </tr>
        <tr v-if="order?.first_address !== undefined">
          <td>Telefon właściciela:</td>
          <td>
            {{ order?.first_address?.owner_phone ?? '-' }}
          </td>
        </tr>
        <tr v-if="order?.last_worker !== undefined">
          <td>Pracownik:</td>
          <td>
            <span
              v-if="
                order?.last_worker?.id != null &&
                order?.last_worker?.firstName != null &&
                order?.last_worker?.lastName != null
              "
            >
              <a @click="goToUserDetails(order.last_worker.id)" :class="'text-navy'">
                {{ order.last_worker.firstName + ' ' + order.last_worker.lastName }}
              </a>
            </span>
            <span v-else>-</span>
          </td>
        </tr>
        <tr v-if="order?.subcontractor !== undefined">
          <td>Podwykonawca:</td>
          <td>
            <div style="display: flex; justify-content: flex-start; align-items: baseline">
              <span
                v-if="
                  order?.subcontractor?.id != null &&
                  order?.subcontractor?.firstName != null &&
                  order?.subcontractor?.lastName != null
                "
                style="text-align: center"
              >
                <a @click="goToUserDetails(order.subcontractor.id)" :class="'text-navy'">
                  {{ order.subcontractor.firstName + ' ' + order.subcontractor.lastName }}
                </a>
              </span>
              <span v-else>-</span>
              <button
                type="button"
                @click="
                  modalTable.showModal(MODAL_USERS_TABLE, {
                    additionalProps: {
                      filter: { url: 'user/subcontractors' }
                    }
                  })
                "
                class="btn btn-xs btn-outline btn-link"
              >
                Zmień
              </button>
              <button
                v-if="order?.subcontractor?.id != null"
                type="button"
                @click="onRemoveSubcontractorButtonClicked"
                class="btn btn-xs btn-outline btn-link"
                style="color: red"
              >
                Usuń
              </button>
            </div>
          </td>
        </tr>
        <tr v-if="order?.receive_date !== undefined">
          <td>Data wpływu zlecenia:</td>
          <td>
            {{ order?.receive_date ? getDateWithoutTime(order.receive_date) : '-' }}
          </td>
        </tr>
        <tr v-if="order?.deadline !== undefined">
          <td>Termin realizacji:</td>
          <td>
            {{ order?.deadline ? getDateWithoutTime(order.deadline) : '-' }}
          </td>
        </tr>
        <tr v-if="order?.delayDays !== undefined">
          <td>Opóźnienia:</td>
          <td>{{ ordersDelayDays }}</td>
        </tr>
        <tr v-if="order?.surveyor_deadline !== undefined">
          <td>Termin realizacji aktualizacji geodezyjnej:</td>
          <td>
            {{ order?.surveyor_deadline ? getDateWithoutTime(order.surveyor_deadline) : '-' }}
          </td>
        </tr>

        <tr v-if="order?.por !== undefined">
          <td>POR:</td>
          <td>
            <div class="switch">
              <div class="onoffswitch">
                <input type="checkbox" class="onoffswitch-checkbox" :checked="order?.por" />
                <label class="onoffswitch-label">
                  <span class="onoffswitch-inner"></span>
                  <span class="onoffswitch-switch"></span>
                </label>
              </div>
            </div>
          </td>
        </tr>

        <tr v-if="order?.created_at !== undefined">
          <td>Utworzone:</td>
          <td>
            {{ order?.created_at ? getLocalFormattedDateAndTime(order.created_at) : '-' }}
          </td>
        </tr>

        <tr v-if="order?.created_by !== undefined">
          <td>Autor:</td>
          <td>
            <a
              v-if="order?.created_by != null"
              @click="goToUserDetails(order.created_by.id)"
              :class="'text-navy'"
            >
              {{ order.created_by.firstName + ' ' + order.created_by.lastName }}
            </a>
            <span v-else>-</span>
          </td>
        </tr>
      </table>

      <OrderAddressesAndRangesTables v-if="order && order.id" :orderId="order.id" />

      <div class="user-button mt-2" v-if="order && order.stopDatetime">
        <button
          class="btn btn-outline btn-info btn-sm btn-block"
          @click="onReopenOrderButtonClicked"
        >
          Otwórz zlecenie
        </button>
      </div>
    </div>

    <ModalTable
      ref="modalTable"
      @onModalCompleted="onModalCompleted"
      @onModalRejected="onModalRejected"
    ></ModalTable>
  </div>

  <div v-show="props.collapsed" class="ibox">
    <div class="ibox-title pr-3" style="height: 100vh">
      <div class="row">
        <div class="col-12" style="display: flex; justify-content: space-between">
          <button class="btn btn-primary btn-xs float-right ml-2 mr-1" @click="emit('expandPanel')">
            Pokaż panel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
td {
  padding-bottom: 0.75rem;
}

tr :first-child {
  text-align: right;
  padding-right: 0.5rem;
}

.equal-columns-table td:nth-child(1) {
  /* width: auto; */
  /* white-space:nowrap; */
  /* width: 50%; */
  vertical-align: text-top;
}

.equal-columns-table td:nth-child(2) {
  /* width: auto; */
  overflow-wrap: break-word;
}

.equal-columns-table {
  width: 100%;
  table-layout: fixed;
}
</style>
