import {
  COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME,
  SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME
} from '../data/constants/buttonsNamesConstants';
import { isNotEmptyArray } from '../helpers/utilsHelper';

export function getConstructedMarkerForTransportOrderActionsPerUserOrderSetsMap(
  actionsPerUserOrderSet
) {
  const driver = `<b>Kierowca:</b> ${actionsPerUserOrderSet.worker?.firstName} ${actionsPerUserOrderSet.worker?.lastName}`;
  let note = `<br><b>Notatka:</b> `;
  note += actionsPerUserOrderSet?.note != null ? actionsPerUserOrderSet.note : '-';
  let summary = '';

  if (!isNotEmptyArray(actionsPerUserOrderSet.actions)) return null;

  for (const action of actionsPerUserOrderSet.actions) {
    if (action.details) {
      const actionType = `<br><b>${action.action_type?.name}:</b>`;
      summary += actionType;

      for (const property in action.details) {
        if (Object.hasOwnProperty.call(action.details, property)) {
          const element = action.details[property];
          summary += `<br><span class='pl-2'>${property}: ${element}</span>`;
        }
      }
    }
  }

  const firstAction = actionsPerUserOrderSet.actions[0];
  const lat = firstAction.geotimestamp.lat;
  const lon = firstAction.geotimestamp.lon;

  const copyToClipboardButtonHtml =
    `${lat}, ${lon} ` +
    `<button clickable type='button' class="btn btn-white btn-xs ml-1" id='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}:${firstAction?.id}' lat='${lat}' lon='${lon}' click_action_type='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}'><i class="fa fa-copy" clickable_child></i></button>` +
    `<button clickable type='button' class="btn btn-white btn-xs ml-1" id='${SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME}:${firstAction?.id}' lat='${lat}' lon='${lon}' click_action_type='${SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME}'><i class="fa fa-google" clickable_child></i></button>`;

  let marker = {
    lat: lat,
    lng: lon,
    popupCaption: `${copyToClipboardButtonHtml}<br>${driver}${summary}${note}`,
    draggable: false,
    icon: undefined,
    iconSize: undefined,
    iconAnchor: undefined
  };

  return marker;
}
