<script setup>
import { ref } from 'vue';
import Swal from 'sweetalert2';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import DictionaryTable from '../dictionaries/DictionaryTable.vue';
import ActionsReportsTableFilterAndSearch from './ActionsReportsTableFilterAndSearch.vue';
import { getLocalFormattedDateAndTime } from '../../helpers/dateFormatHelper';
import { getSwalConfiguration } from '../../helpers/swalHelper';
import { generateActionsReport } from '../../helpers/actions/actionsRegisterReportGeneratorHelper.js';
import { copyToClipboard } from '../../helpers/utilsHelper';
import { handleResponseWithMessages } from '../../helpers/wwwHelper.js';
import { COMPLETED_REPORT_TYPE_ID } from '../../data/constants/appConstants.js';

const RepositoryActions = RepositoryFactory.get('actions');
const tableFilterAndSearchRef = ref();
const searchBox = ref('');
const userFilter = ref(null);
const dictTableKey = ref(0);

const tableColumns = [
  { key: 'status', header: 'Status', computed: true, requiredKeysOverride: ['raport_status'] },
  { key: 'created_at', header: 'Data', computed: true },
  { key: 'author', header: 'Wygenerował', computed: true }
];

// eslint-disable-next-line no-unused-vars
function onDataFetched(dataObject) {
  tableFilterAndSearchRef.value.updateAppliedFilters();
}

function onFetchDataWithFiltersRequest({ search, filter }) {
  searchBox.value = search;
  userFilter.value = filter;
  dictTableKey.value++;
}

function onDownloadButtonClicked(reportRow) {
  Swal.fire(
    getSwalConfiguration('Pobieranie raportu', 'Czy na pewno chcesz pobrać wybrany raport?')
  ).then((result) => {
    if (result.value) {
      generateActionsRegisterReport(reportRow);
    }
  });
}

function onCopyLinkButtonClicked(uuid) {
  copyToClipboard(window.location.origin + '/actionsRegisterDownload/' + uuid);
}

function generateActionsRegisterReport(reportRow) {
  RepositoryActions.getActionsRegisterReportContent(reportRow.id)
    .then((data) => {
      generateActionsReport(data, 'Rejestr akcji');
    })
    .catch((error) => console.log(error))
    .finally(() => {});
}

function onDeleteReportButtonClicked(reportRow) {
  Swal.fire(
    getSwalConfiguration('Usuwanie raportu', 'Czy na pewno chcesz usunąć wybrany raport akcji?')
  ).then((result) => {
    if (result.value) {
      deleteActionsRegisterReport(reportRow);
    }
  });
}

async function deleteActionsRegisterReport(reportRow) {
  var response = await RepositoryActions.deleteActionsRegisterReport(reportRow.id);
  handleResponseWithMessages(response, 'Usunięto raport akcji.', () => {
    dictTableKey.value++;
  });
}
</script>

<template>
  <div class="ibox">
    <div class="ibox-content" style="padding: 0px; border: none">
      <ActionsReportsTableFilterAndSearch
        ref="tableFilterAndSearchRef"
        :longerSearchBar="false"
        :showSearchBar="false"
        @fetchData="onFetchDataWithFiltersRequest"
      />

      <DictionaryTable
        :key="dictTableKey"
        :showHeader="false"
        :showBorder="false"
        url="actionregisterraport"
        :columns="tableColumns"
        :customButtons="true"
        :showTableElementsInfo="true"
        :searchFromOutside="searchBox"
        :showSearchBar="false"
        :userFilter="userFilter"
        @dataFetched="onDataFetched"
      >
        <template #status="{ element }">
          <span
            class="label label-primary"
            :style="
              element?.raport_status?.color != null
                ? 'background-color:' + element.raport_status.color
                : ''
            "
          >
            {{ element?.raport_status?.name ?? '-' }}</span
          >
        </template>

        <template #created_at="{ element }">
          {{ element.created_at != null ? getLocalFormattedDateAndTime(element.created_at) : '-' }}
        </template>

        <template #author="{ element }">
          {{
            element.author?.firstName && element.author?.lastName
              ? `${element.author.firstName} ${element.author.lastName}`
              : '-'
          }}
        </template>
        <template #buttons="{ element }">
          <button
            type="button"
            class="btn btn-outline btn-primary btn-xs mr-1"
            @click="onCopyLinkButtonClicked(element.uuid)"
            onclick="event.stopPropagation()"
          >
            Skopiuj link
          </button>
          <button
            type="button"
            class="btn btn-outline btn-xs mr-1"
            :class="
              element?.raport_status.id !== COMPLETED_REPORT_TYPE_ID
                ? 'btn-secondary'
                : 'btn-primary'
            "
            @click="onDownloadButtonClicked(element)"
            onclick="event.stopPropagation()"
            :disabled="element?.raport_status.id !== COMPLETED_REPORT_TYPE_ID"
          >
            Pobierz
          </button>
          <button
            type="button"
            class="btn btn-outline-danger btn-xs"
            @click="onDeleteReportButtonClicked(element)"
            onclick="event.stopPropagation()"
          >
            Usuń
          </button>
        </template>
      </DictionaryTable>
    </div>
  </div>
</template>

<style scoped></style>
