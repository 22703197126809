<script setup>
import { ref, watch } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';

const RepositoryTransportOrders = RepositoryFactory.get('transportOrders');
const props = defineProps({ orderId: Number });
const emit = defineEmits(['disabledUserOrdersChanged']);
const isLoading = ref(false);
const userOrdersFilters = ref([]);
const summaryData = ref(null);

watch(
  () => props.orderId,
  () => fetchSummaryData()
);

async function fetchSummaryData() {
  isLoading.value = true;

  summaryData.value = await RepositoryTransportOrders.getTransportOrderSummaryData(props.orderId);
  let userOrders = await RepositoryTransportOrders.getTransportOrderUsers(props.orderId);
  updateUserOrdersFilters(userOrders);

  isLoading.value = false;
}

function updateUserOrdersFilters(userOrders) {
  if (!Array.isArray(userOrders)) return;

  userOrdersFilters.value = new Array();

  userOrders.forEach((userOrder) => {
    userOrdersFilters.value.push({
      userOrderId: userOrder.id,
      name: userOrder.worker.firstName + ' ' + userOrder.worker.lastName,
      enabled: true
    });
  });
}

if (props.orderId != null) {
  fetchSummaryData();
}

function userOrdersFilterChanged() {
  let disabledUserOrdersIds = userOrdersFilters.value
    .filter((x) => !x.enabled)
    .map((x) => x.userOrderId);
  emit('disabledUserOrdersChanged', disabledUserOrdersIds);
}
</script>

<template>
  <div
    v-if="summaryData"
    class="ibox-content profile-content"
    :class="{ 'sk-loading': isLoading }"
    style="border-style: none"
  >
    <div class="sk-spinner sk-spinner-three-bounce">
      <div class="sk-bounce1"></div>
      <div class="sk-bounce2"></div>
      <div class="sk-bounce3"></div>
    </div>

    <h4>Elementy do zebrania:</h4>

    <div v-if="summaryData.targets">
      <div v-for="(propValue, propName) in summaryData.targets" :key="propName" class="pl-3">
        {{ propName }}: {{ propValue }}
      </div>
    </div>
    <div v-else>
      <h4 style="text-align: center">Brak danych</h4>
    </div>

    <h4 class="mt-3">Wykonano:</h4>

    <div v-if="summaryData.done">
      <div v-for="(propValue, propName) in summaryData.done" :key="propName" class="pl-3">
        {{ propName }}: {{ propValue }}
      </div>
    </div>
    <div v-else>
      <h4 class="pl-3">-</h4>
    </div>

    <h4 class="mt-3">Pozostawiono:</h4>

    <div v-if="summaryData.left">
      <div v-for="(propValue, propName) in summaryData.left" :key="propName" class="pl-3">
        {{ propName }}: {{ propValue }}
      </div>
    </div>
    <div v-else>
      <h4 class="pl-3">-</h4>
    </div>

    <div v-if="Array.isArray(userOrdersFilters)" class="table-responsive mt-5">
      <h4>Filtruj po wykonaniu kierowcy</h4>
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Kierowca</th>
            <th>Pokaż</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="userOrderFilter in userOrdersFilters" :key="userOrderFilter.userOrderId">
            <td>
              <label>{{ userOrderFilter.name }}</label>
            </td>
            <td>
              <div class="switch">
                <div class="onoffswitch">
                  <input
                    type="checkbox"
                    class="onoffswitch-checkbox"
                    :id="'userOrder' + userOrderFilter.userOrderId"
                    v-model="userOrderFilter.enabled"
                    @change="userOrdersFilterChanged"
                  />
                  <label class="onoffswitch-label" :for="'userOrder' + userOrderFilter.userOrderId">
                    <span class="onoffswitch-inner"></span>
                    <span class="onoffswitch-switch"></span>
                  </label>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div v-else>
    <h4 style="text-align: center">Brak danych</h4>
  </div>
</template>

<style scoped></style>
