export function multifilterApplied(array1, array2) {
  if (array1 == null && array2 == null) return true;
  if ((array1 == null && array2 != null) || (array1 != null && array2 == null)) return false;

  const array1Ids = array1.map((x) => x.id);
  const array2Ids = array2.map((x) => x.id);

  if (array1Ids.length !== array2Ids.length) return false;

  return array1Ids.every((value) => array2Ids.includes(value));
}
