<script setup>
import { computed } from 'vue';

const props = defineProps({
  columns: { type: Array, required: true },
  isSettingsSaving: { type: Boolean, default: false }
});

const visibleColumnsCount = computed(() => props.columns.filter((col) => col.visible).length);

const emit = defineEmits(['save', 'change', 'showAll']);
</script>

<template>
  <div class="dropup">
    <button class="btn btn-primary btn-sm dropdown-toggle" type="button" data-toggle="dropdown">
      Kolumny
    </button>

    <div class="dropdown-menu p-2" @click.stop>
      <div class="alert alert-info py-1 text-center">Widoczność kolumn</div>
      <div
        v-for="column in props.columns"
        :key="column.key"
        class="form-check d-flex align-items-center"
      >
        <input
          type="checkbox"
          class="form-check-input mt-0"
          :id="column.key"
          v-model="column.visible"
          @change="emit('change')"
          :disabled="column.visible && visibleColumnsCount === 1"
          :title="
            column.visible && visibleColumnsCount === 1
              ? 'Przynajmniej jedna kolumna musi być widoczna'
              : ''
          "
          style="width: 1.2em; height: 1.2em"
        />
        <label class="form-check-label text-nowrap cursor-pointer ms-2" :for="column.key">{{
          column.header
        }}</label>
      </div>
      <div class="dropdown-divider"></div>

      <div class="d-flex justify-content-center mt-2">
        <button
          class="btn btn-primary btn-xs mr-2"
          @click.stop.prevent="emit('showAll')"
          title="Pokaż wszystkie"
        >
          <i class="fa fa-eye"></i>&nbsp;&nbsp;Wszystkie
        </button>

        <button
          class="btn btn-primary btn-xs"
          @click.stop.prevent="emit('save')"
          :disabled="props.isSettingsSaving"
        >
          <span v-if="props.isSettingsSaving" class="spinner-border spinner-border-sm mr-1"></span>
          <i v-else class="fa fa-save"></i>&nbsp;&nbsp; Zapisz
        </button>
      </div>
    </div>
  </div>
</template>
