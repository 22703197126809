<script setup>
import { ref, watch } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { isNotEmptyArray } from '../../helpers/utilsHelper';
import { v4 as uuidv4 } from 'uuid';

const RepositoryOrders = RepositoryFactory.get('orders');
const props = defineProps({ orderId: Number, actionTypes: Array, order: Object });
const emit = defineEmits(['filtersChanged']);
const componentUUID = ref(uuidv4());
const isLoading = ref(false);
const actionTypesFilters = ref([]);
const failureReasonsFilters = ref([]);
const additionalDismantlingSourcesFilters = ref([]);
const userOrdersFilters = ref([]);
const summaryData = ref(null);
const lastActionsOnlyFilterEnabled = ref(false);
const showInactiveDemPointsFilter = ref(true);

watch(
  () => props.orderId,
  () => fetchSummaryData()
);

watch(
  () => props.actionTypes,
  () => updateActionTypesFilters()
);

async function fetchSummaryData() {
  isLoading.value = true;

  summaryData.value = await RepositoryOrders.getOrderSummaryData(props.orderId);
  let userOrders = await RepositoryOrders.getOrderUsers(props.orderId);
  updateUserOrdersFilters(userOrders);
  updateFailureReasonsFilters();
  updateAdditionalDismantlingSourcesFilters();

  isLoading.value = false;
}

function updateActionTypesFilters() {
  if (!Array.isArray(props.actionTypes)) return;

  actionTypesFilters.value = new Array();

  props.actionTypes.forEach((actionType) => {
    actionTypesFilters.value.push({ id: actionType.id, name: actionType.name, enabled: true });
  });
}

function updateFailureReasonsFilters() {
  if (!isNotEmptyArray(summaryData.value?.actions_failures)) return;

  failureReasonsFilters.value = new Array();

  summaryData.value.actions_failures.forEach((failureReason) => {
    failureReasonsFilters.value.push({
      id: failureReason.id_failure_action_reason,
      name: failureReason.name,
      enabled: true
    });
  });
}

function updateAdditionalDismantlingSourcesFilters() {
  if (!isNotEmptyArray(summaryData.value?.actions_extra_dem_source)) return;

  additionalDismantlingSourcesFilters.value = new Array();

  summaryData.value.actions_extra_dem_source.forEach((entry) => {
    additionalDismantlingSourcesFilters.value.push({
      id: entry.id_extra_dem_source,
      name: entry.name,
      enabled: true
    });
  });
}

function updateUserOrdersFilters(userOrders) {
  if (!Array.isArray(userOrders)) return;

  userOrdersFilters.value = new Array();

  userOrders.forEach((userOrder) => {
    userOrdersFilters.value.push({
      userOrderId: userOrder.id,
      name: userOrder.user.firstName + ' ' + userOrder.user.lastName,
      enabled: true,
      startStopMarkersVisible: false
    });
  });
}

if (props.orderId != null) {
  fetchSummaryData();
  updateActionTypesFilters();
}

function onFiltersChanged() {
  let disabledActionTypes = actionTypesFilters.value.filter((x) => !x.enabled).map((x) => x.id);

  let disabledFailureReasons = failureReasonsFilters.value
    .filter((x) => !x.enabled)
    .map((x) => x.id);

  let disabledElements = additionalDismantlingSourcesFilters.value
    .filter((x) => !x.enabled)
    .map((x) => x.id);

  let disabledUserOrders = userOrdersFilters.value
    .filter((x) => !x.enabled)
    .map((x) => x.userOrderId);

  let enabledUserOrdersStartStops = userOrdersFilters.value
    .filter((x) => x.startStopMarkersVisible)
    .map((x) => x.userOrderId);

  emit(
    'filtersChanged',
    disabledActionTypes,
    disabledFailureReasons,
    disabledElements,
    disabledUserOrders,
    enabledUserOrdersStartStops,
    lastActionsOnlyFilterEnabled.value,
    showInactiveDemPointsFilter.value
  );
}

function toggleUserOrderFilterStartStop(userOrderFilter) {
  userOrderFilter.startStopMarkersVisible = !userOrderFilter.startStopMarkersVisible;
  onFiltersChanged();
}
</script>

<template>
  <div
    class="ibox-content profile-content p-0"
    :class="{ 'sk-loading': isLoading }"
    style="border-style: none"
  >
    <div class="sk-spinner sk-spinner-three-bounce">
      <div class="sk-bounce1"></div>
      <div class="sk-bounce2"></div>
      <div class="sk-bounce3"></div>
    </div>

    <div class="table-responsive mt-4">
      <h4>Filtruj punkty</h4>
      <table class="table table-striped">
        <tbody>
          <tr>
            <td>
              <label>Pokaż dezaktywowane</label>
            </td>
            <td class="d-flex justify-content-end pr-5">
              <div class="switch">
                <div class="onoffswitch">
                  <input
                    type="checkbox"
                    class="onoffswitch-checkbox"
                    :id="componentUUID + 'showInactiveDemPointsFilterInputId'"
                    v-model="showInactiveDemPointsFilter"
                    @change="onFiltersChanged"
                  />
                  <label
                    class="onoffswitch-label"
                    :for="componentUUID + 'showInactiveDemPointsFilterInputId'"
                  >
                    <span class="onoffswitch-inner"></span>
                    <span class="onoffswitch-switch"></span>
                  </label>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="table-responsive mt-4">
      <h4>Filtruj po ostatniej akcji</h4>
      <table class="table table-striped">
        <tbody>
          <tr>
            <td>
              <label>Pokaż tylko, jeśli ostatnia akcja</label>
            </td>
            <td class="d-flex justify-content-end pr-5">
              <div class="switch">
                <div class="onoffswitch">
                  <input
                    type="checkbox"
                    class="onoffswitch-checkbox"
                    :id="componentUUID + 'lastActionsOnlyFilterInputId'"
                    v-model="lastActionsOnlyFilterEnabled"
                    @change="onFiltersChanged"
                  />
                  <label
                    class="onoffswitch-label"
                    :for="componentUUID + 'lastActionsOnlyFilterInputId'"
                  >
                    <span class="onoffswitch-inner"></span>
                    <span class="onoffswitch-switch"></span>
                  </label>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div v-if="isNotEmptyArray(props.actionTypes)" class="table-responsive mt-2">
        <h4>Filtruj po typie akcji</h4>
        <table class="table table-striped">
          <tbody>
            <tr v-for="actionTypeFilter in actionTypesFilters" :key="actionTypeFilter.id">
              <td>
                <label>{{ actionTypeFilter.name }}</label>
              </td>
              <td class="d-flex justify-content-end pr-5">
                <div class="switch">
                  <div class="onoffswitch">
                    <input
                      type="checkbox"
                      class="onoffswitch-checkbox"
                      :id="componentUUID + 'actionType' + actionTypeFilter.id"
                      v-model="actionTypeFilter.enabled"
                      @change="onFiltersChanged"
                    />
                    <label
                      class="onoffswitch-label"
                      :for="componentUUID + 'actionType' + actionTypeFilter.id"
                    >
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="isNotEmptyArray(summaryData?.actions_failures)" class="table-responsive mt-2">
        <h4>Filtruj po typie niepowodzenia</h4>

        <table class="table table-striped">
          <tbody>
            <tr v-for="failureReasonFilter in failureReasonsFilters" :key="failureReasonFilter.id">
              <td>
                <label>{{ failureReasonFilter.name }}</label>
              </td>
              <td class="d-flex justify-content-end pr-5">
                <div class="switch">
                  <div class="onoffswitch">
                    <input
                      type="checkbox"
                      class="onoffswitch-checkbox"
                      :id="componentUUID + 'failureReason' + failureReasonFilter.id"
                      v-model="failureReasonFilter.enabled"
                      @change="onFiltersChanged"
                    />
                    <label
                      class="onoffswitch-label"
                      :for="componentUUID + 'failureReason' + failureReasonFilter.id"
                    >
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        v-if="isNotEmptyArray(summaryData?.actions_extra_dem_source)"
        class="table-responsive mt-2"
      >
        <h4>Filtruj po źródle dodatkowego demontażu</h4>

        <table class="table table-striped">
          <tbody>
            <tr v-for="filter in additionalDismantlingSourcesFilters" :key="filter.id">
              <td>
                <label>{{ filter.name }}</label>
              </td>
              <td class="d-flex justify-content-end pr-5">
                <div class="switch">
                  <div class="onoffswitch">
                    <input
                      type="checkbox"
                      class="onoffswitch-checkbox"
                      :id="componentUUID + 'additionalDismantlingSource' + filter.id"
                      v-model="filter.enabled"
                      @change="onFiltersChanged"
                    />
                    <label
                      class="onoffswitch-label"
                      :for="componentUUID + 'additionalDismantlingSource' + filter.id"
                    >
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="isNotEmptyArray(userOrdersFilters)" class="table-responsive mt-2">
        <h4>Filtruj po wykonaniu</h4>
        <table class="table table-striped">
          <tbody>
            <tr v-for="userOrderFilter in userOrdersFilters" :key="userOrderFilter.userOrderId">
              <td>
                <label>{{ userOrderFilter.name }}</label>
              </td>
              <td class="d-flex justify-content-end pr-5" style="gap: 10px">
                <img
                  @click="toggleUserOrderFilterStartStop(userOrderFilter)"
                  src="/static/img/markerIcons/startStop.png"
                  width="30"
                  height="30"
                  style="cursor: pointer"
                  :style="
                    userOrderFilter.startStopMarkersVisible
                      ? ''
                      : 'filter: grayscale(100%) opacity(50%) brightness(70%)'
                  "
                  :title="`Kliknij, aby ${
                    userOrderFilter.startStopMarkersVisible ? 'wyłączyć' : 'włączyć'
                  } markery start/stop zlecenia.`"
                />

                <div class="switch">
                  <div class="onoffswitch">
                    <input
                      type="checkbox"
                      class="onoffswitch-checkbox"
                      :id="componentUUID + 'userOrder' + userOrderFilter.userOrderId"
                      v-model="userOrderFilter.enabled"
                      @change="onFiltersChanged"
                    />
                    <label
                      class="onoffswitch-label"
                      :for="componentUUID + 'userOrder' + userOrderFilter.userOrderId"
                    >
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
