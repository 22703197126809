import { isNotEmptyArray } from '../helpers/utilsHelper.js';
import repository from './repository.js';

export default {
  async getFilesObject(page, itemsPerPage, search, sortBy, orderType, url) {
    return repository
      .get(
        `/${url}?page=${page}&search=${search}&orderBy=${sortBy}&orderType=${orderType}&paginate=${itemsPerPage}`
      )
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((error) => {
        return { error };
      });
  },

  async getFilesObjectPOST(page, itemsPerPage, search, sortBy, url, userFilter = null) {
    let formData = new FormData();

    if (userFilter) {
      for (const property in userFilter) {
        if (isNotEmptyArray(userFilter[property])) {
          for (let index = 0; index < userFilter[property].length; index++) {
            formData.append(`${property}[${index}]`, userFilter[property][index]);
          }
        } else {
          formData.append(property, userFilter[property]);
        }
      }
    }

    return repository
      .post(
        `${url}?page=${page}&search=${search}&orderBy=${sortBy}&paginate=${itemsPerPage}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((error) => {
        return { error };
      });
  },

  async getOrderMajorResourcesGeneral(id) {
    return repository.get(`/order/${id}/majorResources`).then((response) => {
      if (response.data.error == null) return response.data;
    });
  },

  async addFileToOrder(orderId, file) {
    let formData = new FormData();
    formData.append('file', file);
    formData.append('creation_date', '2020-10-02');
    formData.append('created_by', 3); //TODO check it, not used?

    return repository
      .post(`/order/${orderId}/addFile`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(function (response) {
        if (response && response.data && response.data.message) return response.data.message;
        else return null;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async deleteFile(resourceId) {
    return repository
      .delete(`/resource/${resourceId}`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async shareFile(fileId) {
    return repository
      .get(`/resource/${fileId}/share`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async deleteTransportDate(transportId) {
    return repository
      .delete(`/transport/${transportId}`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async deleteInvoice(invoiceId) {
    return repository
      .delete(`/fv/${invoiceId}`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async deleteAcceptanceProtocol(acceptanceProtocolId) {
    return repository
      .delete(`/po/${acceptanceProtocolId}`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async deleteGeodeticProtocol(geodeticProtocolId) {
    return repository
      .delete(`/pg/${geodeticProtocolId}`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async deleteOrderResource(resourceId) {
    return repository
      .get(`/resource/${resourceId}/deleteOrderResource`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async deletActionResource(resourceId) {
    return repository
      .get(`/resource/${resourceId}/deletActionResource`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async deleteAddressResource(resourceId) {
    return repository
      .get(`/resource/${resourceId}/deleteAddressResource`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async deleteTransportResource(resourceId) {
    return repository
      .get(`/resource/${resourceId}/deleteTransportResource`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  }
};
