<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import ClusterMap from '../map/ClusterMap.vue';
import { copyToClipboard, isNotEmptyArray } from '../../helpers/utilsHelper';
import { useMapLogic } from '../../composables/mapLogic';
import { showOnGoogleMaps } from '../../helpers/navigator';

const RepositoryDictionaries = RepositoryFactory.get('dictionaries');

const props = defineProps({
  orderId: Number,
  addressName: String,
  quantity: Number,
  coords: Object
});

const emit = defineEmits(['demElemTypePicked', 'noteUpdated']);
const isLoading = ref(false);
const demElemTypes = ref(null);
const pickedDemElemTypeId = ref(null);
const note = ref(null);
const { clusterMap } = useMapLogic();

const coordsValid = computed(() => {
  return props.coords?.lat && props.coords?.lon;
});

const demElemTypesAvailable = computed(() => isNotEmptyArray(demElemTypes.value?.data));

const pickedDemElemTypeName = computed(() => {
  if (pickedDemElemTypeId.value == null) return null;
  if (!demElemTypesAvailable.value) return null;

  return demElemTypes.value.data.find((x) => x.id === pickedDemElemTypeId.value)?.name;
});

watch(pickedDemElemTypeId, () => emit('demElemTypePicked', pickedDemElemTypeId.value));
watch(note, () => emit('noteUpdated', note.value));

async function fetchDemElemTypes() {
  isLoading.value = true;

  RepositoryDictionaries.getDictionaryElementsObject(1, 100, '', '', '', 'demelemtype')
    .then((data) => {
      demElemTypes.value = data;
    })
    .catch((error) => console.log(error))
    .finally(() => {
      isLoading.value = false;
    });
}

function prepareMarkerForMap() {
  if (!coordsValid.value) {
    console.warn('CoordsFromAddressStep3.prepareMarkerForMap(): No valid coords.');
    return;
  }

  let markers = [
    {
      lat: props.coords.lat,
      lng: props.coords.lon,
      draggable: false,
      selected: false
    }
  ];

  clusterMap.value.initializeMap({ markersData: markers });
}

onMounted(() => {
  prepareMarkerForMap();
});

// created
fetchDemElemTypes();
</script>

<template>
  <div class="row">
    <div class="col-8">
      <ClusterMap ref="clusterMap" height="500px" />
    </div>
    <div class="col-4">
      <div class="ibox">
        <div class="ibox-content" :class="{ 'sk-loading': isLoading }" style="border-style: none">
          <div class="sk-spinner sk-spinner-three-bounce">
            <div class="sk-bounce1"></div>
            <div class="sk-bounce2"></div>
            <div class="sk-bounce3"></div>
          </div>

          <h4>
            <b> Typ punktu i uwagi</b>
          </h4>

          <div class="form-group mt-2">
            <label>Zakres/typ</label>
            <select
              class="form-control m-b"
              v-if="demElemTypesAvailable"
              v-model="pickedDemElemTypeId"
              required
            >
              <option disabled :value="null">Wybierz zakres/typ</option>
              <option
                v-for="demElemType in demElemTypes.data"
                :key="demElemType.id"
                :value="demElemType.id"
              >
                {{ demElemType.name }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label>Uwagi</label>
            <input
              type="text"
              placeholder="Wpisz uwagi"
              v-model="note"
              class="form-control"
              maxlength="500"
            />
          </div>

          <h4 class="mt-4">
            <b> Podsumowanie </b>
          </h4>

          <div class="mt-2">
            Adres:
            {{ props.addressName ?? '-' }}
            <br />
            Ilość:
            {{ props.quantity ?? '-' }}
            <br />
            Współrzędne:
            {{ coordsValid ? `${props.coords.lat}, ${props.coords?.lon}` : '-' }}
            <button
              v-if="coordsValid"
              type="button"
              class="btn btn-white btn-xs ml-1"
              @click="copyToClipboard(`${props.coords.lat}, ${props.coords?.lon}`)"
            >
              <i class="fa fa-copy" />
            </button>
            <button
              v-if="coordsValid"
              type="button"
              class="btn btn-white btn-xs ml-1"
              @click="showOnGoogleMaps(props.coords.lat, props.coords?.lon)"
            >
              <i class="fa fa-google" />
            </button>
            <br />
            Typ punktu:
            {{ pickedDemElemTypeName ?? '-' }}
            <br />
            Uwagi:
            {{ note != null && note !== '' ? note : '-' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
