<script setup>
import { ref } from 'vue';
import LoginPanel from '../components/auth/LoginPanel.vue';
import TwoFactorAuthPanel from '../components/auth/TwoFactorAuthPanel.vue';

const STAGE_MAIN = 1;
const STAGE_2FA = 2;

const _stage = ref(STAGE_MAIN);
const _username = ref(null);
const _remainingSeconds = ref(null);

function onRedirectTo2FA(username, remainingSeconds) {
  _stage.value = STAGE_2FA;
  _username.value = username;
  _remainingSeconds.value = remainingSeconds;
}
</script>

<template>
  <LoginPanel v-if="_stage === STAGE_MAIN" @redirect-to-2fa="onRedirectTo2FA" />
  <TwoFactorAuthPanel
    v-else-if="_stage === STAGE_2FA"
    :username="_username"
    :remainingSeconds="_remainingSeconds"
    @redirect-to-login="_stage = STAGE_MAIN"
  />
</template>

<style scoped></style>
