<script setup>
import { ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { MODAL_BOROUGHS_TABLE } from '../../data/constants/modalConstants';
import DictionaryTable from '../dictionaries/DictionaryTable.vue';
import FilterAndSearchPanel from '../utils/FilterAndSearchPanel.vue';
import ModalInputField from '../utils/ModalInputField.vue';

const props = defineProps({ userId: Number });
const emit = defineEmits(['onAcceptanceProtocolsFetched', 'onFileDeleted']);
const dictTableKey = ref(0);
const router = useRouter();
const filterComponent = ref();
const searchBox = ref('');
const userFilter = ref(null);

const tableColumns = [
  { key: 'order.order_number', header: 'Numer zlecenia' },
  { key: 'order.gmina.name', header: 'Gmina' },
  { key: 'gathered', header: 'Elementy zebrane', computed: true, tdStyle: 'white-space: pre;' },
  { key: 'left', header: 'Elementy pozostawione', computed: true, tdStyle: 'white-space: pre;' },
  {
    key: 'status',
    header: 'Status transportu',
    computed: true,
    thStyle: 'white-space: nowrap; text-align: center',
    tdStyle: 'white-space: nowrap; text-align: center'
  },
  { key: 'note', header: 'Notatka' }
];

const boroughApplied = computed(() => {
  return filterComponent.value
    ? filterComponent.value.filterApplied(
        filterComponent.value.filterPanel?.borough?.id,
        filterComponent.value.appliedFilter?.borough?.id
      )
    : false;
});

watch(
  () => props.orderId,
  () => dictTableKey.value++
);

function onDataFetched(dataObject) {
  emit('onAcceptanceProtocolsFetched', dataObject);
}

function showTransportOrderInNewTab(transportOrderId) {
  let routeData = router.resolve({
    name: 'transportOrderDetails',
    params: { id: transportOrderId }
  });

  window.open(routeData.href, '_blank');
}

function getGatheredColumnText(order) {
  if (!order?.gathered) return null;

  let text = '';

  for (const property in order.gathered) {
    text += `${property}: ${order.gathered[property]}\n`;
  }

  if (text.endsWith('\n')) {
    text = text.slice(0, -1);
  }

  return text;
}

function getLeftColumnText(order) {
  if (!order?.left) return null;

  let text = '';

  for (const property in order.left) {
    text += `${property}: ${order.left[property]}\n`;
  }

  if (text.endsWith('\n')) {
    text = text.slice(0, -1);
  }

  return text;
}

function onModalCompleted(modalType, data) {
  switch (modalType) {
    case MODAL_BOROUGHS_TABLE:
      filterComponent.value.filterPanel.borough = data;
      filterComponent.value.filterPanelDisplay.boroughDisplayName = data?.name ?? '';
      break;
  }
}

function onModalRejected(modalType) {
  switch (modalType) {
    case MODAL_BOROUGHS_TABLE:
      filterComponent.value.filterPanel.borough = null;
      filterComponent.value.filterPanelDisplay.boroughDisplayName = null;
      break;
  }
}

function getUserFilter() {
  let userFilter = new Object();

  if (filterComponent.value.filterPanel?.borough?.id != null) {
    userFilter.id_borough = filterComponent.value.filterPanel.borough.id;
  }

  return userFilter;
}

function onFetchDataWithFiltersRequest({ search, filter }) {
  searchBox.value = search;
  userFilter.value = filter;
  dictTableKey.value++;
}
</script>

<template>
  <FilterAndSearchPanel
    ref="filterComponent"
    :longerSearchBar="false"
    :userFilterEnabled="true"
    :onModalCompletedAction="onModalCompleted"
    :onModalRejectedAction="onModalRejected"
    :getUserFilterOverride="getUserFilter"
    @fetchData="onFetchDataWithFiltersRequest"
  >
    <div class="col-sm-4">
      <ModalInputField
        title="Gmina"
        :titleCssClass="boroughApplied ? '' : 'text-warning'"
        :displayText="filterComponent?.filterPanelDisplay?.boroughDisplayName ?? '-'"
        @showModal="filterComponent.showModal(MODAL_BOROUGHS_TABLE)"
      />
    </div>
  </FilterAndSearchPanel>

  <DictionaryTable
    :key="dictTableKey"
    v-if="props.userId != null"
    :showHeader="false"
    :showBorder="false"
    :url="`user/${props.userId}/ordersTransport`"
    :columns="tableColumns"
    :customButtons="true"
    :searchFromOutside="searchBox"
    :userFilter="userFilter"
    :showSearchBar="false"
    @dataFetched="onDataFetched"
  >
    <template #gathered="{ element }">
      {{ getGatheredColumnText(element) ?? '-' }}
    </template>

    <template #left="{ element }">
      {{ getLeftColumnText(element) ?? '-' }}
    </template>

    <template #status="{ element }">
      <span
        class="label label-primary"
        :style="element?.status?.color != null ? 'background-color:' + element.status.color : ''"
      >
        {{ element?.status?.name ?? '-' }}</span
      >
    </template>
    <template #buttons="{ element }">
      <button
        type="button"
        class="btn btn-outline-info"
        @click="showTransportOrderInNewTab(element?.id_order_transport)"
        onclick="event.stopPropagation()"
      >
        Pokaż
      </button>
    </template>
  </DictionaryTable>
</template>

<style scoped></style>
