import { isNotEmptyArray, isNullOrEmptyString } from '../utilsHelper';

const SNAPSHOT_LOCAL_STORAGE_KEY = 'ORDER_SUMMARY_FILTERS_SNAPSHOT_LOCAL_STORAGE_KEY';

const LAST_ACTIONS_ONLY_FILTER_KEY = 'LAST_ACTIONS_ONLY_FILTER_KEY';
const ACTION_TYPES_FILTER_KEY = 'ACTION_TYPES_FILTER_KEY';
const FAILURE_REASONS_FILTER_KEY = 'FAILURE_REASONS_FILTER_KEY';
const ADDITIONAL_DISMANTLING_SOURCES_FILTER_KEY = 'ADDITIONAL_DISMANTLING_SOURCES_FILTER_KEY';
const USER_ORDERS_FILTER_KEY = 'USER_ORDERS_FILTER_KEY';
const SHOW_INACTIVE_DEM_POINTS_FILTER_KEY = 'SHOW_INACTIVE_DEM_POINTS_FILTER_KEY';

function createFiltersSnapshot(
  lastActionsOnlyFilter,
  actionTypesFilter,
  failureReasonsFilter,
  additionalDismantlingSourcesFilter,
  userOrdersFilter,
  showInactiveDemPointsFilter
) {
  const snapshot = {};

  snapshot[LAST_ACTIONS_ONLY_FILTER_KEY] = lastActionsOnlyFilter;
  snapshot[ACTION_TYPES_FILTER_KEY] = actionTypesFilter;
  snapshot[FAILURE_REASONS_FILTER_KEY] = failureReasonsFilter;
  snapshot[ADDITIONAL_DISMANTLING_SOURCES_FILTER_KEY] = additionalDismantlingSourcesFilter;
  snapshot[USER_ORDERS_FILTER_KEY] = userOrdersFilter;
  snapshot[SHOW_INACTIVE_DEM_POINTS_FILTER_KEY] = showInactiveDemPointsFilter;

  return snapshot;
}

export function tryApplyFiltersSnapshot(
  lastActionsOnlyFilterRef,
  actionTypesFilterRef,
  failureReasonsFilterRef,
  additionalDismantlingSourcesFilterRef,
  userOrdersFilterRef,
  showInactiveDemPointsFilterRef,
  deleteAfterApplied = true
) {
  let snapshot = localStorage.getItem(SNAPSHOT_LOCAL_STORAGE_KEY);

  if (isNullOrEmptyString(snapshot)) return;

  snapshot = JSON.parse(snapshot);

  // last actions only filter
  if (snapshot[LAST_ACTIONS_ONLY_FILTER_KEY] != undefined) {
    lastActionsOnlyFilterRef.value = snapshot[LAST_ACTIONS_ONLY_FILTER_KEY];
  }

  // action types filter
  tryApplyFilterSnapshot(snapshot, actionTypesFilterRef, ACTION_TYPES_FILTER_KEY);

  // failure reasons filter
  tryApplyFilterSnapshot(snapshot, failureReasonsFilterRef, FAILURE_REASONS_FILTER_KEY);

  // additional dismantling sources filter
  tryApplyFilterSnapshot(
    snapshot,
    additionalDismantlingSourcesFilterRef,
    ADDITIONAL_DISMANTLING_SOURCES_FILTER_KEY
  );

  // user orders filter
  tryApplyFilterSnapshot(snapshot, userOrdersFilterRef, USER_ORDERS_FILTER_KEY);

  // show inactive dem points filter
  if (snapshot[SHOW_INACTIVE_DEM_POINTS_FILTER_KEY] != undefined) {
    showInactiveDemPointsFilterRef.value = snapshot[SHOW_INACTIVE_DEM_POINTS_FILTER_KEY];
  }

  if (deleteAfterApplied) {
    deleteFiltersSnapshot();
  }
}

function tryApplyFilterSnapshot(snapshot, filterRef, filterKey) {
  if (!isNotEmptyArray(filterRef.value) || !isNotEmptyArray(snapshot[filterKey])) return;

  const tempFilter = [];
  filterRef.value.forEach((filter) => {
    const foundInSnapshot = snapshot[filterKey].find((x) => x.id === filter.id);
    tempFilter.push(foundInSnapshot != null ? foundInSnapshot : filter);
  });
  filterRef.value = tempFilter;
}

export function saveFiltersSnapshot(
  lastActionsOnlyFilter,
  actionTypesFilter,
  failureReasonsFilter,
  additionalDismantlingSourcesFilter,
  userOrdersFilter,
  showInactiveDemPointsFilter
) {
  const snapshot = createFiltersSnapshot(
    lastActionsOnlyFilter,
    actionTypesFilter,
    failureReasonsFilter,
    additionalDismantlingSourcesFilter,
    userOrdersFilter,
    showInactiveDemPointsFilter
  );
  localStorage.setItem(SNAPSHOT_LOCAL_STORAGE_KEY, JSON.stringify(snapshot));
}

export function deleteFiltersSnapshot() {
  localStorage.removeItem(SNAPSHOT_LOCAL_STORAGE_KEY);
}
