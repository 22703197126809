<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import {
  APP_PRIMARY_COLOR,
  REPORT_STORAGE_PLACE_TYPE_FOR_MAP,
  OSD_TYPES_FOR_MAP_IDS,
  OSD_DEM_ELEM_TYPE_ID,
  POLE_DEM_ELEM_TYPE_ID,
  DISMANTLING_FAILURE_TYPE_FOR_MAP,
  DISMANTLING_FAILURE_TYPE_FOR_POINTS,
  FAILURE_REASON_ACTIVE_POLE_ID,
  FAILURE_REASON_OWNER_DISAGREEMENT_ID,
  ADDITIONAL_DISMANTLING_ACTION_TYPE_ID,
  ADDITIONAL_MANUAL_DISMANTLING_ACTION_TYPE_ID
} from '../../data/constants/appConstants';
import { useFilesHandler } from '../../composables/filesHandler';
import { createToaster } from '@meforma/vue-toaster';
import { TOASTER_SERVER_ERROR_MSG } from '../../data/constants/toasterMessagesConstants';
import {
  getDemPointsForMapMarkers,
  getActionsWithNoDemPointForMapMarkers
} from '../../helpers/actions/actionMarkersHelper';
import ClusterMap from '@/components/map/ClusterMap.vue';
import DropArea from '../utils/DropArea.vue';
import FilesTable from '../files/FilesTable.vue';
import FormErrorPanel from '../utils/FormErrorPanel.vue';
import { isNotEmptyArray } from '../../helpers/utilsHelper';
import { useMapLogic } from '../../composables/mapLogic';
import { DEFAULT_INITIAL_LAT, DEFAULT_INITIAL_LON } from '../../data/constants/appConstants';
import ActionAddForOSDStep2 from './ActionAddForOSDStep2.vue';
import { useAuthStore } from '../../stores/auth';

const TOASTER_LAST_DRAGGABLE_MARKER_MSG = 'Nie można usunąć ostatniego markera.';
const REMOVE_MARKER_BUTTON_TYPE_NAME = 'REMOVE_MARKER_BUTTON_TYPE_NAME';
const DRAGGABLE_MARKERS_FEATURE_GROUP_KEY_PREFIX = 'actionAddDraggable_';
const RepositoryOrders = RepositoryFactory.get('orders');
const RepositoryDictionaries = RepositoryFactory.get('dictionaries');
const FILES_INPUT_ID = 'fileInputFieldActionAdd';

const props = defineProps({
  orderId: Number,
  demPointsForMap: { type: Array, default: null },
  actionsWithNoDemPointForMap: { type: Array, default: null },
  selectedDemPointsIds: { type: Array, default: null }
});
const emit = defineEmits(['succeeded', 'modalTitleChanged']);
const toaster = createToaster({ position: 'top-right', duration: 3000 });
const filesHandler = useFilesHandler(true, FILES_INPUT_ID);
const userOrders = ref(null);
const typesForPoints = ref(null);
const typesForMap = ref(null);
const demElemTypes = ref(null);
const osdReleaseTypes = ref(null);
const osdElementLinkTypes = ref(null);
const componentReady = ref(false);
const draggableMarkersCount = ref(0);
const failureReasons = ref(null);
const cableTypesForFailure = ref(null);
const additionalDismantlingSources = ref(null);

const {
  clusterMap,
  selectedMarkerIds,
  onSelectedMarkersChanged,
  onSingleMarkerSelected,
  resetSelectedIfCtrl
} = useMapLogic();

// form data
const note = ref(null);
const pickedUserOrderId = ref(null);
const pickedDemElemTypeId = ref(null);
const pickedTypeForPointsId = ref(null);
const pickedTypeForMapId = ref(null);
const pickedOsdReleaseTypeId = ref(null);
const pickedOsdElementLinkTypeId = ref(null);
const initialMarkerPosition = ref(null);
const storageTypesFormData = ref(null);
const storagePlacePriority = ref(false);
const actionDirectlyOnTheMap = ref(false);
const filesForTable = ref([]);
const formErrors = ref([]);
const pickedActionTypeId = computed(() => {
  return actionDirectlyOnTheMap.value ? pickedTypeForMapId.value : pickedTypeForPointsId.value;
});
const nextAvailableDraggableMarkerId = ref(0);
const copperCableAmount = ref(null);
const opticalCableAmount = ref(null);
const step = ref(1);
const submitButtonBlocked = ref(false);
const authStore = useAuthStore();
const pickedFailureReasonId = ref(null);
const pickedCableTypeForFailureId = ref(null);
const declarationOfDisagreement = ref(false);
const pickedAdditionalDismantlingSourceId = ref(null);

setTimeout(() => {
  componentReady.value = true;
}, 500);

const showStoragePlaceFormSection = computed(() => {
  return (
    actionDirectlyOnTheMap.value && pickedTypeForMapId.value === REPORT_STORAGE_PLACE_TYPE_FOR_MAP
  );
});

const showOSDFormSection = computed(() => {
  return (
    actionDirectlyOnTheMap.value &&
    OSD_TYPES_FOR_MAP_IDS.includes(pickedTypeForMapId.value) &&
    pickedDemElemTypeId.value === OSD_DEM_ELEM_TYPE_ID
  );
});

const isLoadingMap = computed(() => {
  if (!componentReady.value) return true;

  return (
    props.demPointsForMap == null ||
    props.actionsWithNoDemPointForMap == null ||
    initialMarkerPosition.value == null
  );
});

const isLoadingForm = computed(() => {
  return (
    !isNotEmptyArray(userOrders.value?.data) &&
    !isNotEmptyArray(typesForPoints.value?.data) &&
    !isNotEmptyArray(typesForMap.value?.data) &&
    !isNotEmptyArray(demElemTypes.value?.data) &&
    !isNotEmptyArray(storageTypesFormData.value) &&
    !isNotEmptyArray(osdReleaseTypes.value) &&
    !isNotEmptyArray(osdElementLinkTypes.value) &&
    !isNotEmptyArray(failureReasons.value?.data) &&
    !isNotEmptyArray(cableTypesForFailure.value?.data) &&
    !isNotEmptyArray(additionalDismantlingSources.value?.data)
  );
});

const showDynamicDemElemTypeInput = computed(() => {
  return isNotEmptyArray(demElemTypes.value?.data) && actionDirectlyOnTheMap.value;
});

const showStaticDemElemTypeInput = computed(() => {
  return !actionDirectlyOnTheMap.value && selectedMarkerIds.value.length === 1;
});

const staticDemElemTypeInputValue = computed(() => {
  return showStaticDemElemTypeInput.value
    ? props.demPointsForMap.find((demPoint) => demPoint.id === selectedMarkerIds.value[0])
        .dem_elem_type?.name
    : '-';
});

const addActionHeader = computed(() => {
  return actionDirectlyOnTheMap.value && draggableMarkersCount.value > 1
    ? `Dodaj akcje (${draggableMarkersCount.value})`
    : 'Dodaj akcję';
});

const modalTitle = computed(() => {
  if (step.value === 2) return 'Zdefiniuj powiązania';
  if (showOSDFormSection.value) return 'Dodaj akcję OSD';

  return 'Dodaj akcję';
});

const demPointsForOSDMap = computed(() => {
  if (!isNotEmptyArray(props.demPointsForMap)) return [];

  return props.demPointsForMap.filter(
    (demPoint) => demPoint.dem_elem_type?.id === POLE_DEM_ELEM_TYPE_ID
  );
});

const isDismantlingFailureActionTypeSet = computed(() => {
  return actionDirectlyOnTheMap.value
    ? pickedActionTypeId.value === DISMANTLING_FAILURE_TYPE_FOR_MAP
    : pickedActionTypeId.value === DISMANTLING_FAILURE_TYPE_FOR_POINTS;
});

const showCableTypesForFailureSelect = computed(() => {
  return (
    isDismantlingFailureActionTypeSet.value &&
    pickedFailureReasonId.value === FAILURE_REASON_ACTIVE_POLE_ID
  );
});

const showDeclarationOfDisagreementCheckbox = computed(() => {
  return (
    isDismantlingFailureActionTypeSet.value &&
    pickedFailureReasonId.value === FAILURE_REASON_OWNER_DISAGREEMENT_ID
  );
});

const showAdditionalDismantlingSourceInput = computed(() => {
  return (
    pickedActionTypeId.value === ADDITIONAL_DISMANTLING_ACTION_TYPE_ID ||
    pickedActionTypeId.value === ADDITIONAL_MANUAL_DISMANTLING_ACTION_TYPE_ID
  );
});

watch(modalTitle, (newValue) => {
  emit('modalTitleChanged', newValue);
});

function getDraggableMarkersPositions() {
  if (!isNotEmptyArray(clusterMap.value?.markersRef)) return [];

  return clusterMap.value.markersRef
    .filter((marker) => marker.data.draggable === true)
    .map((marker) => ({ lat: marker._latlng.lat, lon: marker._latlng.lng }));
}

watch(isLoadingMap, (newValue) => {
  if (!newValue) {
    prepareMarkersForMap();
  }
});

watch(actionDirectlyOnTheMap, () => {
  formErrors.value = [];
  prepareMarkersForMap(false);
});

watch(filesHandler.filesRef, () => {
  recomputeFilesForTable();
});

function recomputeFilesForTable() {
  filesForTable.value = filesForTable.value.concat(filesHandler.filesRef.value);

  for (let i = 0; i < filesForTable.value.length; i++) {
    filesForTable.value[i].id = i;
  }
}

onMounted(() => {
  initializeMarker();
  fetchUserOrders();
  fetchTypesForPoints();
  fetchTypesForMap();
  fetchDemElemTypes();
  fetchStorageTypes();
  fetchOSDReleaseTypes();
  fetchOSDElementLinkTypes();
  fetchFailureReasons();
  fetchCableTypesForFailure();
  fetchAdditionalDismantlingSources();
});

async function initializeMarker() {
  const coords = await RepositoryOrders.getNewActionInitialLocation(props.orderId);

  initialMarkerPosition.value = {
    lat: coords?.lat ?? DEFAULT_INITIAL_LAT,
    lon: coords?.lon ?? DEFAULT_INITIAL_LON
  };
}

function prepareMarkersForMap(setBoundsAfterRefresh = true) {
  const nonDraggableMarkers = getAllNonDraggableMarkers();

  clusterMap.value.initializeMap({
    markersData: nonDraggableMarkers,
    additionalAction: actionDirectlyOnTheMap.value ? initializeDraggableMarker : null,
    setBoundsAfterRefresh
  });
}

function getAllNonDraggableMarkers() {
  const demPointMarkersSelectionBlocked = actionDirectlyOnTheMap.value;
  const markersClassName = actionDirectlyOnTheMap.value ? 'half-transparent' : '';

  const demPointsForMapMarkers = getDemPointsForMapMarkers(
    props.demPointsForMap,
    [],
    [],
    [],
    [],
    false,
    true,
    false,
    demPointMarkersSelectionBlocked,
    markersClassName,
    true,
    authStore,
    false,
    { selectableActionsOverride: null, selectableDemPointsOverride: null }
  );

  if (
    !actionDirectlyOnTheMap.value &&
    isNotEmptyArray(demPointsForMapMarkers) &&
    isNotEmptyArray(props.selectedDemPointsIds)
  ) {
    demPointsForMapMarkers.forEach((marker) => {
      if (marker.markerId != null && props.selectedDemPointsIds.includes(marker.markerId)) {
        marker.selected = true;
      }
    });
  }

  const actionsWithNoDemPointForMapMarkers = getActionsWithNoDemPointForMapMarkers(
    props.actionsWithNoDemPointForMap,
    [],
    [],
    [],
    [],
    false,
    markersClassName,
    authStore,
    false,
    { selectableActionsOverride: null }
  );

  return demPointsForMapMarkers.concat(actionsWithNoDemPointForMapMarkers);
}

function initializeDraggableMarker() {
  const markerData = getAnotherDraggableMarkerData(
    initialMarkerPosition.value.lat,
    initialMarkerPosition.value.lon
  );
  clusterMap.value.addMarker(
    DRAGGABLE_MARKERS_FEATURE_GROUP_KEY_PREFIX + markerData.markerId,
    markerData
  );
  draggableMarkersCount.value = 1;
}

async function fetchUserOrders() {
  RepositoryDictionaries.getDictionaryElementsObject(
    1,
    100,
    '',
    '',
    '',
    `order/${props.orderId}/userOrders`
  )
    .then((data) => {
      userOrders.value = data;

      if (isNotEmptyArray(userOrders.value.data)) {
        pickedUserOrderId.value = userOrders.value.data[0].id;
      }
    })
    .catch((error) => console.log(error))
    .finally(() => {});
}

async function fetchTypesForPoints() {
  RepositoryDictionaries.getDictionaryElementsObject(
    1,
    100,
    '',
    '',
    '',
    'actiontype/typesForPoints'
  )
    .then((data) => {
      typesForPoints.value = data;
    })
    .catch((error) => console.log(error))
    .finally(() => {});
}

async function fetchTypesForMap() {
  RepositoryDictionaries.getDictionaryElementsObject(1, 100, '', '', '', 'actiontype/typesForMap')
    .then((data) => {
      typesForMap.value = data;
    })
    .catch((error) => console.log(error))
    .finally(() => {});
}

async function fetchDemElemTypes() {
  RepositoryDictionaries.getDictionaryElementsObject(1, 100, '', '', '', 'demelemtype')
    .then((data) => {
      demElemTypes.value = data;
    })
    .catch((error) => console.log(error))
    .finally(() => {});
}

async function fetchStorageTypes() {
  RepositoryDictionaries.getDictionaryElementsObject(1, 100, '', '', '', 'storagetype')
    .then((data) => {
      setStorageTypesFormData(data);
    })
    .catch((error) => console.log(error))
    .finally(() => {});
}

async function fetchFailureReasons() {
  RepositoryDictionaries.getDictionaryElementsObject(1, 100, '', '', '', 'failureactionreason')
    .then((data) => {
      failureReasons.value = data;
    })
    .catch((error) => console.log(error))
    .finally(() => {});
}

async function fetchCableTypesForFailure() {
  RepositoryDictionaries.getDictionaryElementsObject(1, 100, '', '', '', 'cabletypeforfailure')
    .then((data) => {
      cableTypesForFailure.value = data;
    })
    .catch((error) => console.log(error))
    .finally(() => {});
}

function setStorageTypesFormData(storageTypesObject) {
  if (!isNotEmptyArray(storageTypesObject?.data)) {
    storageTypesFormData.value = [];
    return;
  }

  storageTypesFormData.value = storageTypesObject.data.map((type) => {
    return {
      id_storage_type: type.id,
      name: type.name.charAt(0).toUpperCase() + type.name.slice(1),
      number: 0
    };
  });
}

async function fetchOSDReleaseTypes() {
  RepositoryDictionaries.getDictionaryElementsObject(1, 100, '', '', '', 'osdrelease')
    .then((data) => {
      osdReleaseTypes.value = data;
    })
    .catch((error) => console.log(error))
    .finally(() => {});
}

async function fetchOSDElementLinkTypes() {
  RepositoryDictionaries.getDictionaryElementsObject(1, 100, '', '', '', 'osdelementlink')
    .then((data) => {
      osdElementLinkTypes.value = data;
    })
    .catch((error) => console.log(error))
    .finally(() => {});
}

async function fetchAdditionalDismantlingSources() {
  RepositoryDictionaries.getDictionaryElementsObject(1, 100, '', '', '', 'extrademsource')
    .then((data) => {
      additionalDismantlingSources.value = data;
    })
    .catch((error) => console.log(error))
    .finally(() => {});
}

function onFileDeleted(id) {
  filesForTable.value = filesForTable.value.filter((x) => x.id !== id);
}

function submitForm(e) {
  e.preventDefault();

  if (showOSDFormSection.value) {
    step.value = 2;
  } else {
    if (validateForm()) {
      createAction();
    }
  }
}

function validateForm() {
  if (!actionDirectlyOnTheMap.value && !isNotEmptyArray(selectedMarkerIds.value)) {
    formErrors.value = ['Zaznacz punkt (bądź punkty) na mapie'];
    return false;
  }

  return true;
}

async function createAction(osdStringified = null) {
  const files = isNotEmptyArray(filesForTable.value)
    ? filesForTable.value.map((photo) => photo.file)
    : [];

  submitButtonBlocked.value = true;

  var response = await RepositoryOrders.createAction(
    pickedUserOrderId.value,
    showDynamicDemElemTypeInput.value ? pickedDemElemTypeId.value : null,
    pickedActionTypeId.value,
    actionDirectlyOnTheMap.value ? getDraggableMarkersPositions() : null,
    note.value,
    showStoragePlaceFormSection.value ? storageTypesFormData.value : null,
    showStoragePlaceFormSection.value ? storagePlacePriority.value : null,
    isNotEmptyArray(selectedMarkerIds.value) ? selectedMarkerIds.value : null,
    files,
    osdStringified,
    pickedFailureReasonId.value,
    showCableTypesForFailureSelect.value ? pickedCableTypeForFailureId.value : null,
    showDeclarationOfDisagreementCheckbox.value ? declarationOfDisagreement.value : null,
    showAdditionalDismantlingSourceInput.value ? pickedAdditionalDismantlingSourceId.value : null
  );

  submitButtonBlocked.value = false;

  if (response?.serverError) {
    toaster.show(TOASTER_SERVER_ERROR_MSG, { type: 'error' });
  } else {
    if (isNotEmptyArray(response?.success_ids)) {
      const message =
        response.success_ids.length === 1
          ? 'Akcja została utworzona.'
          : `Liczba utworzonych akcji: ${response.success_ids.length}`;
      toaster.show(message, { type: 'success' });
      emit('succeeded');
    } else {
      formErrors.value = response;
    }
  }
}

function onAddMarkerButtonClicked() {
  const markerData = getAnotherDraggableMarkerData(
    clusterMap.value.currentCenter.lat,
    clusterMap.value.currentCenter.lng
  );
  clusterMap.value?.addMarker(
    DRAGGABLE_MARKERS_FEATURE_GROUP_KEY_PREFIX + markerData.markerId,
    markerData
  );
  draggableMarkersCount.value++;
}

function getAnotherDraggableMarkerData(lat, lon) {
  const markerId = nextAvailableDraggableMarkerId.value++;
  let popupCaption = `<button clickable type='button' class='btn btn-outline btn-danger btn-xs' id='${REMOVE_MARKER_BUTTON_TYPE_NAME}:${markerId}' id_marker='${markerId}' click_action_type='${REMOVE_MARKER_BUTTON_TYPE_NAME}'>Usuń</button>`;

  return {
    markerId,
    lat,
    lng: lon,
    draggable: true,
    selected: false,
    selectable: false,
    popupCaption,
    zIndexOffset: 1000
  };
}

function onMapClickableClicked(clickable) {
  let clickActionType = clickable.getAttribute('click_action_type');

  if (clickActionType !== REMOVE_MARKER_BUTTON_TYPE_NAME) return;
  let markerId = clickable.getAttribute('id_marker');

  tryToDeleteDraggableMarkerWithId(markerId);
}

function tryToDeleteDraggableMarkerWithId(markerId) {
  if (draggableMarkersCount.value <= 1) {
    toaster.show(TOASTER_LAST_DRAGGABLE_MARKER_MSG, { type: 'info' });
    return;
  }

  const removed = clusterMap.value.removeFeatureGroup(
    DRAGGABLE_MARKERS_FEATURE_GROUP_KEY_PREFIX + markerId
  );

  if (removed) {
    draggableMarkersCount.value--;
  }
}

function createOSDAction(demPoints) {
  const osd = {
    id_osd_release: pickedOsdReleaseTypeId.value,
    id_osd_element_link: pickedOsdElementLinkTypeId.value,
    optical_cable: opticalCableAmount.value,
    copper_cable: copperCableAmount.value,
    demPoints: demPoints
  };

  const osdStringified = JSON.stringify(osd);

  if (validateForm()) {
    createAction(osdStringified);
  }
}

function onMapMouseUp() {
  if (clusterMap.value?.isBeingDragged === false) {
    resetSelectedIfCtrl();
  }
}
</script>

<template>
  <div v-show="step === 1" class="row">
    <div class="ibox col-lg-6 pr-1">
      <div class="ibox-content" :class="{ 'sk-loading': isLoadingMap }" style="border-style: none">
        <div class="sk-spinner sk-spinner-three-bounce">
          <div class="sk-bounce1"></div>
          <div class="sk-bounce2"></div>
          <div class="sk-bounce3"></div>
        </div>

        <ClusterMap
          ref="clusterMap"
          height="700px"
          :showZeroPositions="false"
          :disableClusteringAtZoomOverride="19"
          :maxClusterRadiusOverride="1"
          :areaSelecting="true"
          @selected-markers-changed="onSelectedMarkersChanged"
          @markerClicked="onSingleMarkerSelected"
          @clickableClicked="onMapClickableClicked"
          @mapMouseUp="onMapMouseUp"
        >
          <button
            v-if="actionDirectlyOnTheMap"
            class="btn btn-primary"
            style="position: absolute; top: 10px; right: 10px; z-index: 500"
            @click="onAddMarkerButtonClicked"
          >
            Dodaj marker
          </button>
        </ClusterMap>
      </div>
    </div>

    <div class="ibox col-lg-6 pl-1">
      <div
        class="ibox-content profile-content pl-4"
        :class="{ 'sk-loading': isLoadingForm }"
        style="border-style: none"
      >
        <div class="sk-spinner sk-spinner-three-bounce">
          <div class="sk-bounce1"></div>
          <div class="sk-bounce2"></div>
          <div class="sk-bounce3"></div>
        </div>

        <FormErrorPanel :errors="formErrors" />

        <form @submit="submitForm">
          <div class="row" :style="`border-bottom: 1px dashed #${APP_PRIMARY_COLOR}`">
            <div class="col-6">
              <h4>{{ addActionHeader }}</h4>

              <div v-if="isNotEmptyArray(userOrders?.data)" class="form-group mt-2">
                <label>Pracownik</label>
                <select class="form-control m-b" v-model="pickedUserOrderId" required>
                  <option disabled :value="null" label="Wybierz pracownika" />
                  <option
                    v-for="userOrder in userOrders.data"
                    :key="userOrder.id"
                    :value="userOrder.id"
                  >
                    {{ userOrder.user.firstName + ' ' + userOrder.user.lastName }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label>Akcja bezpośrednio na mapę</label>
                <div class="switch">
                  <div class="onoffswitch">
                    <input
                      type="checkbox"
                      class="onoffswitch-checkbox"
                      id="actionDirectlyOnTheMapSwitchId"
                      v-model="actionDirectlyOnTheMap"
                    />
                    <label class="onoffswitch-label" for="actionDirectlyOnTheMapSwitchId">
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div
                v-if="actionDirectlyOnTheMap && isNotEmptyArray(typesForMap?.data)"
                class="form-group mt-2"
              >
                <label>Typ akcji</label>
                <select class="form-control m-b" v-model="pickedTypeForMapId" required>
                  <option disabled :value="null" label="Wybierz typ akcji" />
                  <option
                    v-for="typeForMap in typesForMap.data"
                    :key="typeForMap.id"
                    :value="typeForMap.id"
                  >
                    {{ typeForMap.name }}
                  </option>
                </select>
              </div>

              <div
                v-if="!actionDirectlyOnTheMap && isNotEmptyArray(typesForPoints?.data)"
                class="form-group mt-2"
              >
                <label>Typ akcji</label>
                <select class="form-control m-b" v-model="pickedTypeForPointsId" required>
                  <option disabled :value="null" label="Wybierz typ akcji" />
                  <option
                    v-for="typeForPoints in typesForPoints.data"
                    :key="typeForPoints.id"
                    :value="typeForPoints.id"
                  >
                    {{ typeForPoints.name }}
                  </option>
                </select>
              </div>

              <div v-if="isDismantlingFailureActionTypeSet" class="form-group mt-2">
                <label>Powód niepowodzenia</label>
                <select class="form-control m-b" v-model="pickedFailureReasonId" required>
                  <option disabled :value="null" label="Wybierz powód niepowodzenia" />
                  <option
                    v-for="failureReason in failureReasons.data"
                    :key="failureReason.id"
                    :value="failureReason.id"
                  >
                    {{ failureReason.name }}
                  </option>
                </select>
              </div>

              <div v-if="showCableTypesForFailureSelect" class="form-group mt-2">
                <label>Typ przewodu</label>
                <select class="form-control m-b" v-model="pickedCableTypeForFailureId" required>
                  <option disabled :value="null" label="Wybierz typ przewodu" />
                  <option
                    v-for="cableType in cableTypesForFailure.data"
                    :key="cableType.id"
                    :value="cableType.id"
                  >
                    {{ cableType.name }}
                  </option>
                </select>
              </div>

              <div v-if="showDeclarationOfDisagreementCheckbox" class="form-group">
                <label>Spisano oświadczenie</label>
                <div class="switch">
                  <div class="onoffswitch">
                    <input
                      type="checkbox"
                      class="onoffswitch-checkbox"
                      id="declarationOfDisagreementSwitchId"
                      v-model="declarationOfDisagreement"
                    />
                    <label class="onoffswitch-label" for="declarationOfDisagreementSwitchId">
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div v-if="showDynamicDemElemTypeInput" class="form-group mt-2">
                <label>Typ elementu</label>
                <select
                  class="form-control m-b"
                  v-model="pickedDemElemTypeId"
                  :disabled="!actionDirectlyOnTheMap"
                  required
                >
                  <option disabled :value="null" label="Wybierz typ elementu" />
                  <option
                    v-for="demElemType in demElemTypes.data"
                    :key="demElemType.id"
                    :value="demElemType.id"
                  >
                    {{ demElemType.name }}
                  </option>
                </select>
              </div>

              <div v-if="showStaticDemElemTypeInput" class="form-group mt-2">
                <label>Typ elementu</label>
                <select
                  class="form-control m-b"
                  v-model="staticDemElemTypeInputValue"
                  :disabled="true"
                >
                  <option disabled :value="staticDemElemTypeInputValue">
                    {{ staticDemElemTypeInputValue }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label>Notatka</label>
                <input
                  :required="isDismantlingFailureActionTypeSet"
                  type="text"
                  placeholder="Wpisz notatkę"
                  v-model="note"
                  class="form-control"
                  maxlength="500"
                />
              </div>

              <div v-if="showAdditionalDismantlingSourceInput" class="form-group mt-2">
                <label>Źródło demontażu dodatkowego</label>
                <select
                  class="form-control m-b"
                  v-model="pickedAdditionalDismantlingSourceId"
                  required
                >
                  <option disabled :value="null" label="Wybierz źródło" />
                  <option
                    v-for="additionalDismantlingSource in additionalDismantlingSources.data"
                    :key="additionalDismantlingSource.id"
                    :value="additionalDismantlingSource.id"
                  >
                    {{ additionalDismantlingSource.name }}
                  </option>
                </select>
              </div>
            </div>

            <div
              v-if="showStoragePlaceFormSection && isNotEmptyArray(storageTypesFormData)"
              class="col-6"
            >
              <h4>Miejsce składowania</h4>
              <div
                v-for="storageType in storageTypesFormData"
                :key="storageType.id_storage_type"
                class="form-group"
              >
                <label>{{ storageType.name }}</label>
                <input
                  type="number"
                  @mousewheel.prevent=""
                  placeholder="-"
                  v-model="storageType.number"
                  class="form-control"
                  min="0"
                  max="999999999"
                  required
                />
              </div>

              <div class="form-group">
                <label>Priorytet</label>
                <div class="switch">
                  <div class="onoffswitch">
                    <input
                      type="checkbox"
                      class="onoffswitch-checkbox"
                      id="prioritySwitchId"
                      v-model="storagePlacePriority"
                    />
                    <label class="onoffswitch-label" for="prioritySwitchId">
                      <span class="onoffswitch-inner"></span>
                      <span class="onoffswitch-switch"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="showOSDFormSection" class="col-6">
              <h4>Demontaż OSD</h4>

              <div class="form-group mt-2">
                <label>Uwolnienie</label>
                <select class="form-control m-b" v-model="pickedOsdReleaseTypeId" required>
                  <option disabled :value="null" label="Wybierz typ uwolnienia" />
                  <option
                    v-for="osdReleaseType in osdReleaseTypes.data"
                    :key="osdReleaseType.id"
                    :value="osdReleaseType.id"
                  >
                    {{ osdReleaseType.name }}
                  </option>
                </select>
              </div>

              <div class="form-group mt-2">
                <label>Powiązanie z elementem</label>
                <select class="form-control m-b" v-model="pickedOsdElementLinkTypeId" required>
                  <option disabled :value="null" label="Wybierz typ powiązania" />
                  <option
                    v-for="osdElementLinkType in osdElementLinkTypes.data"
                    :key="osdElementLinkType.id"
                    :value="osdElementLinkType.id"
                  >
                    {{ osdElementLinkType.name }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label>Liczba kabli miedzianych</label>
                <input
                  type="number"
                  placeholder="Wpisz liczbę kabli miedzianych"
                  v-model="copperCableAmount"
                  class="form-control"
                  required
                  min="0"
                  max="999999999"
                />
              </div>

              <div class="form-group">
                <label>Liczba kabli światłowodowych</label>
                <input
                  type="number"
                  placeholder="Wpisz liczbę kabli światłowodowych"
                  v-model="opticalCableAmount"
                  class="form-control"
                  required
                  min="0"
                  max="999999999"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 mb-3">
              <FilesTable :filesArray="filesForTable" @fileDeleted="onFileDeleted" />
            </div>

            <div class="col-12">
              <h5>Dodaj zdjęcia</h5>

              <DropArea
                id="dropAreaFiles"
                class="dropArea"
                caption="Upuść pliki tutaj albo kliknij 'Wybierz pliki' poniżej"
                :fileInputId="FILES_INPUT_ID"
                @onFileChanged="filesHandler.onDropAreaFilesChanged($event)"
              />
              <input
                :id="FILES_INPUT_ID"
                name="file"
                type="file"
                class="mt-2"
                multiple
                @change="filesHandler.onFilesChanged($event)"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button
                :disabled="submitButtonBlocked"
                class="btn btn-primary mt-3 float-right"
                type="submit"
              >
                {{ `${showOSDFormSection ? 'Krok 2. Zdefiniuj powiązania' : 'Prześlij dane'}` }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div v-if="step === 2">
    <ActionAddForOSDStep2
      :dem-points-of-type-pole-for-map="demPointsForOSDMap"
      :locations="getDraggableMarkersPositions()"
      @go-to-the-first-step="step = 1"
      @createOSDAction="createOSDAction"
    />
  </div>
</template>

<style scoped></style>
