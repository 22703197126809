<script setup>
import { ref, computed } from 'vue';
import FormErrorPanel from '@/components/utils/FormErrorPanel.vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { useAuthStore } from '@/stores/auth';
import { createToaster } from '@meforma/vue-toaster';
import { TOASTER_SERVER_ERROR_MSG } from '../../data/constants/toasterMessagesConstants';

const RepositoryAuth = RepositoryFactory.get('auth');

const emit = defineEmits(['on-completed']);

const formErrors = ref([]);
const isLoading = ref(false);
const oldPassword = ref(null);
const newPassword = ref(null);
const newPasswordConfirmed = ref(null);
const authStore = useAuthStore();

const toaster = createToaster({
  position: 'top-right'
});

const loggedInUser = computed(() => authStore.loggedInUser);

async function submitForm(e) {
  e.preventDefault();
  if (newPassword.value == null || newPassword.value !== newPasswordConfirmed.value) {
    formErrors.value.push('Wpisane hasła nie są identyczne.');
  } else {
    isLoading.value = true;

    var response = await RepositoryAuth.changePassword(
      loggedInUser.value.id,
      oldPassword.value,
      newPassword.value
    );

    if (response?.serverError) {
      toaster.show(TOASTER_SERVER_ERROR_MSG, { duration: 3000, type: 'error' });
    } else {
      if (response) {
        formErrors.value = response;
      } else {
        reset();
        toaster.show('Hasło zostało zmienione.', { duration: 3000, type: 'success' });
      }
    }

    isLoading.value = false;
  }
}

function reset() {
  oldPassword.value = null;
  newPassword.value = null;
  newPasswordConfirmed.value = null;

  emit('on-completed');
}
</script>

<template>
  <div class="ibox">
    <div class="ibox-title">
      <h5>Zmień hasło</h5>
    </div>
    <div class="ibox-content" :class="{ 'sk-loading': isLoading }">
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <FormErrorPanel :errors="formErrors" />

      <form @submit="submitForm">
        <div class="form-group">
          <label>Aktualne hasło</label>
          <input
            autocomplete="new-password"
            type="password"
            placeholder="Wpisz aktualne hasło"
            v-model="oldPassword"
            class="form-control"
            required
          />
        </div>
        <div class="form-group">
          <label>Nowe hasło</label>
          <input
            autocomplete="new-password"
            type="password"
            placeholder="Wpisz nowe hasło"
            v-model="newPassword"
            class="form-control"
            required
          />
        </div>
        <div class="form-group">
          <label>Powtórz nowe hasło</label>
          <input
            autocomplete="new-password"
            type="password"
            placeholder="Powtórz nowe hasło"
            v-model="newPasswordConfirmed"
            class="form-control"
            required
          />
        </div>
        <br />
        <div class="row">
          <div class="col-12">
            <button
              class="float-right btn btn-sm btn-primary m-t-n-xs ml-1"
              type="button"
              @click="reset"
            >
              <strong>Anuluj</strong>
            </button>
            <button class="float-right btn btn-sm btn-primary m-t-n-xs" type="submit">
              <strong>Zatwierdź</strong>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped></style>
