<script setup>
import { ref, watch } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import Swal from 'sweetalert2';
import ModalGallery from '../utils/ModalGallery.vue';
import { APP_PRIMARY_COLOR } from '../../data/constants/appConstants.js';
import { usePaginationStore } from '@/stores/storePagination';
import ServerErrorPanel from '@/components/utils/ServerErrorPanel.vue';
import { handleResponseWithMessages } from '@/helpers/wwwHelper.js';
import { isNotEmptyArray } from '../../helpers/utilsHelper';
import { REPO_FILES_TABLE_GET_COMPONENT_PAGINATION_KEY } from '../../data/paginationInjectionKeys';
import { getSwalConfiguration } from '../../helpers/swalHelper';

const RepositoryFiles = RepositoryFactory.get('files');

const props = defineProps({
  orderId: { type: Number, default: null },
  showBorder: { type: Boolean, default: true },
  widerPaginationCountPicker: { type: Boolean, default: false },
  noElementsCaption: { type: String, default: 'Brak plików do wyświetlenia' },
  modalId: { type: String, default: 'abcasd' },
  deletingEnabled: { type: Boolean, default: false },
  mobileAppSharingEnabled: { type: Boolean, default: false },
  showSearchBar: { type: Boolean, default: false },
  customPaginationKey: { type: String, default: null },
  deleteFunctionOverride: { type: Function, default: null }
});

const emit = defineEmits(['fileDeleted', 'fileShared']);
const paginationStore = usePaginationStore();
const isLoading = ref(false);
const filesSets = ref(null);
const searchBox = ref('');
const modalGallery = ref();
const resources = ref([]);
const imgSources = ref([]);

watch(
  () => props.orderId,
  () => {
    fetchData();
  },
  { immediate: true }
);

async function fetchData(page = 1) {
  isLoading.value = true;

  RepositoryFiles.getFilesObject(
    page,
    paginationStore.getPaginationItemsCount(
      props.customPaginationKey ?? REPO_FILES_TABLE_GET_COMPONENT_PAGINATION_KEY
    ),
    searchBox.value,
    '',
    '',
    `order/${props.orderId}/addressesFiles`
  )
    .then((data) => {
      filesSets.value = data;

      filesSets.value?.forEach((fileSet) => {
        fileSet.resources.forEach((resource) => {
          if (resource.path != null) {
            resource.path = import.meta.env.VITE_VUE_APP_IMAGES_URL + resource.path;
          }

          if (resource.path_min != null) {
            resource.path_min = import.meta.env.VITE_VUE_APP_IMAGES_URL + resource.path_min;
          }
        });
      });

      if (filesSets.value != null) {
        resources.value = [];

        filesSets.value.forEach((fileSet) => {
          resources.value = resources.value.concat(fileSet.resources);
        });

        imgSources.value = resources.value.map((resource) => resource.path);
      }
    })
    .catch((error) => console.log(error))
    .finally(() => {
      isLoading.value = false;
    });
}

function showModal(pickedImgId) {
  const foundSourceIndex = resources.value.findIndex((x) => x.id === pickedImgId);
  modalGallery.value.currentSourceIndex = foundSourceIndex;
}

function getFileIcon(file) {
  if (!file) return "<div class='icon'><i class='fa fa-file'></i></div>";

  if (file?.id_resource_type == null)
    return `<div class='icon'><i class='fa fa-file' style='color: #${APP_PRIMARY_COLOR}77;'></i></div>`;

  switch (file?.id_resource_type) {
    case 2:
      return `<div class='icon'><i class='fa fa-file-pdf-o' style='color: #${APP_PRIMARY_COLOR}77;'></i></div>`;
    case 3:
    case 4:
    case 5:
      return `<div class='image text-center'><img alt='image' class='img-fluid mini-img' src='${
        file.path_min ?? file.path
      }' /></div>`;
    case 7:
      return `<div class='icon'><i class='fa fa-file-zip-o' style='color: #${APP_PRIMARY_COLOR}77;'></i></div>`;
    default:
      return `<div class='icon'><i class='fa fa-file' style='color: #${APP_PRIMARY_COLOR}77;'></i></div>`;
  }
}

function showFileInNewTab(fileToShow) {
  if (fileToShow?.path != null) {
    window.open(fileToShow.path, '_blank');
  }
}

function onDeleteFileButtonClicked(fileToDelete) {
  Swal.fire(
    getSwalConfiguration('Usuwanie pliku', 'Czy na pewno chcesz usunąć wybrany plik?')
  ).then((result) => {
    if (result.value) {
      if (props.deleteFunctionOverride != null) {
        props.deleteFunctionOverride(fileToDelete?.id);
      } else {
        deleteFile(fileToDelete);
      }
    }
  });
}

async function deleteFile(fileToDelete) {
  var response = await RepositoryFiles.deleteFile(fileToDelete?.id);

  handleResponseWithMessages(response, 'Plik został usunięty.', () => {
    emit('fileDeleted');
  });
}

function onShareFileToMobileAppButtonClicked(file) {
  Swal.fire(
    getSwalConfiguration(
      'Udostępnianie pliku',
      `Czy na pewno chcesz ${
        file.shared === 1 ? 'ukryć' : 'pokazać'
      } wybrany plik w aplikacji mobilnej?`
    )
  ).then((result) => {
    if (result.value) {
      shareFileToMobileApp(file);
    }
  });
}

async function shareFileToMobileApp(file) {
  var response = await RepositoryFiles.shareFile(file?.id, file.shared === 0);

  handleResponseWithMessages(
    response,
    `Plik będzie ${file.shared === 1 ? 'ukryty' : 'widoczny'} ukryty w aplikacji mobilnej.`,
    () => {
      emit('fileShared');
    }
  );
}
</script>

<template>
  <div class="ibox">
    <div
      class="ibox-content"
      :class="{ 'sk-loading': isLoading }"
      :style="props.showBorder ? '' : 'border-style: none'"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <div v-if="props.showSearchBar" class="row">
        <div class="col-12">
          <div class="input-group mb-3">
            <input
              placeholder="Szukaj.."
              type="text"
              class="form-control form-control-sm"
              v-model="searchBox"
              v-on:keyup.enter="search"
            />
            <span class="input-group-append">
              <button @click="search" type="button" class="btn btn-sm btn-primary">
                <i class="fa fa-search"></i> Szukaj
              </button>
            </span>
          </div>
        </div>
      </div>

      <div>
        <div v-if="isNotEmptyArray(filesSets)">
          <div v-for="fileSet in filesSets" :key="fileSet" class="mb-4">
            <p class="mb-1">
              <b style="font-size: larger">{{ fileSet.address }}</b>
            </p>

            <p class="mb-1">
              Zakres: {{ fileSet.order_range_name }} Tel. właściciela:
              {{ fileSet.owner_phone ?? '-' }}
            </p>

            <p>Opis: {{ fileSet.description ?? '-' }}</p>

            <div class="grid-container" v-if="isNotEmptyArray(fileSet?.resources)">
              <div
                class="file-box grid-item"
                v-for="file in fileSet.resources"
                :key="file.id"
                @click="
                  file.id_resource_type === 3 ||
                  file.id_resource_type === 4 ||
                  file.id_resource_type === 5
                    ? showModal(file.id)
                    : showFileInNewTab(file)
                "
                data-toggle="modal"
                :data-target="
                  file.id_resource_type === 3 ||
                  file.id_resource_type === 4 ||
                  file.id_resource_type === 5
                    ? `#${modalId}`
                    : ''
                "
              >
                <div class="file grid-child-item">
                  <button
                    v-if="mobileAppSharingEnabled"
                    type="button"
                    class="btn btn-white btn-xs"
                    style="position: absolute; left: 5px; top: 5px"
                    :style="file?.shared === 1 ? `background-color: #${APP_PRIMARY_COLOR}77;` : ''"
                    :title="`${file?.shared === 1 ? 'Ukryj' : 'Pokaż'} plik w aplikacji mobilnej`"
                    @click="onShareFileToMobileAppButtonClicked(file)"
                    onclick="event.stopPropagation()"
                  >
                    <i
                      class="fa fa-users"
                      :style="file && file.shared === 1 ? 'color: green' : 'color: gray'"
                    ></i>
                  </button>

                  <button
                    v-if="props.deletingEnabled"
                    type="button"
                    class="btn btn-white btn-xs"
                    style="position: absolute; right: 5px; top: 5px"
                    title="Usuń plik"
                    @click="onDeleteFileButtonClicked(file)"
                    onclick="event.stopPropagation()"
                  >
                    <i class="fa fa-trash-o" style="color: gray"></i>
                  </button>

                  <span v-html="getFileIcon(file)" />

                  <div class="file-name" style="overflow-wrap: break-word">
                    {{
                      file.path != null ? file.path.substring(file.path.lastIndexOf('/') + 1) : '-'
                    }}
                    <br />
                    <small
                      >Dodane przez:
                      {{
                        file.created_by?.firstName && file.created_by?.lastName
                          ? file.created_by.firstName + ' ' + file.created_by.lastName
                          : '-'
                      }}</small
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="filesSets?.error">
          <ServerErrorPanel @onRetry="fetchData" />
        </div>

        <div v-else class="col-12">
          <p class="text-center mt-3">{{ noElementsCaption }}</p>
        </div>
      </div>
    </div>

    <ModalGallery ref="modalGallery" :sources="imgSources" :id="modalId" />
  </div>
</template>

<style scoped>
.grid-child-item {
  cursor: pointer;
  margin: 0%;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
}

.grid-item {
  min-width: 200px;
  max-width: 250px;
  width: 100%;
}
</style>
