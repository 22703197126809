<script setup>
import { ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import {
  orderAddressesSortingOptions as sortingOptions,
  getSortingIcon,
  getSortingDirection
} from '@/helpers/sortTableHelper.js';
import { PAGINATION_LIMIT } from '../../data/constants/appConstants.js';
import PaginationCountPicker from '@/components/utils/PaginationCountPicker.vue';
import ServerErrorPanel from '@/components/utils/ServerErrorPanel.vue';
import TableElementsInfo from '@/components/utils/TableElementsInfo.vue';
import { usePaginationStore } from '@/stores/storePagination';
import { Bootstrap4Pagination } from 'laravel-vue-pagination';
import { ORDER_ADDRESSES_TABLE_PAGINATION_KEY } from '../../data/paginationInjectionKeys';
import Swal from 'sweetalert2';
import { getSwalConfiguration } from '../../helpers/swalHelper';
import { handleResponseWithMessages } from '../../helpers/wwwHelper';

const RepositoryOrders = RepositoryFactory.get('orders');
const RepositoryAddresses = RepositoryFactory.get('addresses');

const props = defineProps({
  showBorder: { type: Boolean, default: true },
  longerSearchBar: { type: Boolean, default: false },
  noItemsText: { type: String, default: 'Brak elementów do wyświetlenia' }
});

const router = useRouter();
const route = useRoute();
const isLoading = ref(false);
const addressesObject = ref({});
const searchBox = ref('');
const currentSort = ref({ by: null, direction: null });
const paginationStore = usePaginationStore();

const orderId = computed(() => parseInt(route.params?.id));
const addressSortIcon = computed(() => getSortingIcon(currentSort.value, sortingOptions.ADDRESS));

// created
if (orderId.value != null) {
  fetchData();
}

function fetchData(page = 1) {
  if (orderId.value == null) return;

  isLoading.value = true;
  RepositoryOrders.getOrderAddressesObject(
    orderId.value,
    page,
    paginationStore.getPaginationItemsCount(ORDER_ADDRESSES_TABLE_PAGINATION_KEY),
    searchBox.value,
    currentSort.value.by,
    currentSort.value.direction
  )
    .then((data) => {
      addressesObject.value = data;
    })
    .catch((error) => console.log(error))
    .finally(() => {
      isLoading.value = false;
    });
}

function search() {
  fetchData();
}

function sortBy(newSortBy) {
  currentSort.value.direction = getSortingDirection(currentSort.value, newSortBy);
  currentSort.value.by = newSortBy;
  fetchData();
}

function onItemsPerPageChanged() {
  fetchData();
}

function onEditAddressButtonClicked(address) {
  router.push({
    name: 'orderAddressAddEdit',
    params: { orderId: orderId.value, addressId: address.id }
  });
}

function onDeleteAddressButtonClicked(address) {
  Swal.fire(getSwalConfiguration('Usunięcie adresu', 'Czy na pewno chcesz usunąć adres?')).then(
    (result) => {
      if (result.value) {
        deleteAddress(address.id);
      }
    }
  );
}

async function deleteAddress(addressId) {
  var response = await RepositoryAddresses.deleteAddress(addressId);

  handleResponseWithMessages(response, 'Usunięto adres.', () => {
    fetchData();
  });
}
</script>

<template>
  <div class="ibox">
    <div
      class="ibox-content"
      :class="{ 'sk-loading': isLoading }"
      :style="props.showBorder ? '' : 'border-style: none'"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>
      <div class="row">
        <div :class="props.longerSearchBar ? 'col-sm-4 offset-sm-8' : 'col-sm-3 offset-sm-9'">
          <div class="input-group mb-3">
            <input
              placeholder="Szukaj.."
              type="text"
              class="form-control form-control-sm"
              v-model="searchBox"
              v-on:keyup.enter="search"
            />
            <span class="input-group-append">
              <button @click="search" type="button" class="btn btn-sm btn-primary">
                <i class="fa fa-search"></i> Szukaj
              </button>
            </span>
          </div>
        </div>
      </div>

      <TableElementsInfo :metaObject="addressesObject?.meta ?? null" />

      <div v-if="addressesObject?.data && addressesObject.data.length > 0" class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th
                @click="sortBy(sortingOptions.ADDRESS)"
                style="cursor: pointer; white-space: nowrap"
              >
                Adres <span v-html="addressSortIcon" />
              </th>
              <th style="white-space: nowrap">Ilość</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="address in addressesObject.data" :key="address.id">
              <td style="white-space: nowrap">
                {{ address?.address ?? '-' }}
              </td>

              <td style="white-space: nowrap">
                {{ address?.quantity ?? '-' }}
              </td>

              <td>
                <div class="float-right">
                  <button
                    class="btn btn-outline-success btn-xs mr-2"
                    type="button"
                    @click="onEditAddressButtonClicked(address)"
                    onclick="event.stopPropagation()"
                  >
                    Edytuj
                  </button>
                  <button
                    class="btn btn-outline-danger btn-xs"
                    type="button"
                    @click="onDeleteAddressButtonClicked(address)"
                    onclick="event.stopPropagation()"
                  >
                    Usuń
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="addressesObject?.error">
        <ServerErrorPanel @onRetry="fetchData" />
      </div>
      <div v-else>
        <p class="text-center mt-3">{{ props.noItemsText }}</p>
      </div>

      <div class="row mt-3">
        <div class="col-xl-6 offset-xl-3">
          <Bootstrap4Pagination
            v-if="addressesObject?.data"
            align="center"
            :data="addressesObject"
            :limit="PAGINATION_LIMIT"
            @pagination-change-page="fetchData"
          ></Bootstrap4Pagination>
        </div>

        <div class="col-xl-3">
          <PaginationCountPicker
            :paginationKey="ORDER_ADDRESSES_TABLE_PAGINATION_KEY"
            v-show="!isLoading"
            @selectionChanged="onItemsPerPageChanged"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
