<script setup>
import { ref, computed } from 'vue';
import { MODAL_ACTION_TYPES_TABLE } from '@/data/constants/modalConstants.js';
import ModalInputField from '@/components/utils/ModalInputField.vue';
import FilterAndSearchPanel from '../utils/FilterAndSearchPanel.vue';
import { multifilterApplied } from '../../helpers/tablesHelper';
import { isNotEmptyArray } from '../../helpers/utilsHelper';

const props = defineProps({
  longerSearchBar: { type: Boolean, default: false },
  userFilterEnabled: { type: Boolean, default: true }
});
const emit = defineEmits(['fetchData']);
const filterComponent = ref();

const actionTypesApplied = computed(() => {
  return filterComponent.value
    ? multifilterApplied(
        filterComponent.value.filterPanel?.actionTypes,
        filterComponent.value.appliedFilter?.actionTypes
      )
    : false;
});

// eslint-disable-next-line no-unused-vars
function onModalCompleted(modalType, data, data2, modalProperties) {
  switch (modalType) {
    case MODAL_ACTION_TYPES_TABLE:
      filterComponent.value.filterPanel.actionTypes = isNotEmptyArray(data2) ? data2 : null;
      filterComponent.value.filterPanelDisplay.actionTypesDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.name !== null)
            .map((obj) => obj.name)
            .join(', ')
        : '-';
      break;
  }
}

function onModalRejected(modalType) {
  switch (modalType) {
    case MODAL_ACTION_TYPES_TABLE:
      filterComponent.value.filterPanel.actionTypes = null;
      filterComponent.value.filterPanelDisplay.actionTypesDisplayName = null;
      break;
  }
}

function getUserFilter() {
  let userFilter = new Object();

  if (isNotEmptyArray(filterComponent.value.filterPanel?.actionTypes)) {
    userFilter.id_action_type = filterComponent.value.filterPanel.actionTypes.map(
      (item) => item.id
    );
  }

  return userFilter;
}

function updateAppliedFilters() {
  filterComponent.value?.updateAppliedFilters();
}

defineExpose({ updateAppliedFilters });
</script>

<template>
  <FilterAndSearchPanel
    ref="filterComponent"
    :longerSearchBar="props.longerSearchBar"
    :userFilterEnabled="props.userFilterEnabled"
    :onModalCompletedAction="onModalCompleted"
    :onModalRejectedAction="onModalRejected"
    :getUserFilterOverride="getUserFilter"
    @fetchData="emit('fetchData', $event)"
  >
    <div class="col-sm-4">
      <ModalInputField
        title="Typ akcji"
        :titleCssClass="actionTypesApplied ? '' : 'text-warning'"
        :displayText="filterComponent?.filterPanelDisplay?.actionTypesDisplayName ?? '-'"
        @showModal="
          filterComponent.showModal(MODAL_ACTION_TYPES_TABLE, {
            additionalProps: {
              multiSelections: true,
              initiallyPickedItems: filterComponent?.filterPanel?.actionTypes
            }
          })
        "
      />
    </div>
  </FilterAndSearchPanel>
</template>

<style scoped></style>
