<script setup>
import { ref, computed } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import ServerErrorPanel from '@/components/utils/ServerErrorPanel.vue';
import ModalBase from '@/components/utils/ModalBase.vue';
import ModalBody from '@/components/utils/ModalBody.vue';
import { useModalTables } from '@/composables/modalTables.js';
import { createToaster } from '@meforma/vue-toaster';
import Swal from 'sweetalert2';
import OrdersMapSummary from './OrdersMapSummary.vue';
import OrdersTableForMap from '@/components/orders/OrdersTableForMap.vue';
import { MODAL_USERS_TABLE } from '@/data/constants/modalConstants.js';
import { copyToClipboard, isNotEmptyArray } from '../../helpers/utilsHelper';
import {
  TOASTER_ERROR_OCCURED_MSG,
  TOASTER_SERVER_ERROR_MSG
} from '../../data/constants/toasterMessagesConstants';
import { getSwalConfiguration } from '../../helpers/swalHelper';
import { ACCESS_KEY_ADD_WORKER_TO_ORDER_BUTTON } from '../../data/constants/authConstants';
import { useAuthStore } from '../../stores/auth';

// map
import ClusterMap from '@/components/map/ClusterMap.vue';
import { useMapLogic } from '../../composables/mapLogic';
import { getMarkersForOrdersMap } from '../../helpers/orderMarkersHelper';
import {
  COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME,
  SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME
} from '../../data/constants/buttonsNamesConstants';
import { showOnGoogleMaps } from '../../helpers/navigator';

const RepositoryOrders = RepositoryFactory.get('orders');

const props = defineProps({
  filterFromParent: { type: Object, default: null },
  mapTabActive: { type: Boolean, default: true },
  searchFromParent: { type: String, default: '' }
});
const emit = defineEmits(['afterSuccessfulFetch']);
const isLoading = ref(false);
const ordersObject = ref({});
const modalBase = ref(null);
const toaster = createToaster({ position: 'top-right' });
const { modal, showModal } = useModalTables(modalBase);
const {
  clusterMap,
  allSelected,
  noneSelected,
  onSelectedMarkersChanged,
  onSingleMarkerSelected,
  resetSelectedMarkers,
  selectAllSelectableMarkers,
  resetSelectedIfCtrl
} = useMapLogic();
const authStore = useAuthStore();

const centerLat = computed(() => {
  if (
    Array.isArray(ordersObject.value?.data) &&
    ordersObject.value?.data.length > 0 &&
    ordersObject.value.data[0]?.geotimestamp?.lat != null
  )
    return Number.parseFloat(ordersObject.value.data[0].geotimestamp.lat);

  return 50.28709;
});

const centerLng = computed(() => {
  if (
    Array.isArray(ordersObject.value?.data) &&
    ordersObject.value?.data.length > 0 &&
    ordersObject.value.data[0]?.geotimestamp?.lon != null
  )
    return Number.parseFloat(ordersObject.value.data[0].geotimestamp.lon);

  return 21.4239;
});

const selectedOrdersIds = computed(() => {
  if (!isNotEmptyArray(clusterMap.value?.markersRef)) return [];

  let selectedIds = [];

  clusterMap.value.markersRef.forEach((marker) => {
    if (marker.data.selected) selectedIds.push(marker.data.orderId);
  });

  return selectedIds;
});

function fetchData(page = 1, setBoundsAfterRefresh = true) {
  isLoading.value = true;

  RepositoryOrders.getOrdersForMapObject(
    page,
    1000,
    props.searchFromParent ?? '',
    null,
    null,
    props.filterFromParent
  )
    .then((data) => {
      ordersObject.value = data;
      prepareMarkers(setBoundsAfterRefresh);
      emit('afterSuccessfulFetch');
    })
    .catch((error) => console.log(error))
    .finally(() => {
      isLoading.value = false;
    });
}

function prepareMarkers(setBoundsAfterRefresh = true) {
  const { markers, failedToGetMarkerOrders } = getMarkersForOrdersMap(ordersObject.value);

  clusterMap.value.initializeMap({ markersData: markers, setBoundsAfterRefresh });

  if (props.mapTabActive && isNotEmptyArray(failedToGetMarkerOrders)) {
    failedToGetMarkerOrders.forEach((order) => {
      toaster.show(
        'Dla zlecenia <b>' +
          (order?.order_number ?? '-') +
          '</b> nie udało się uzyskać pozycji i wyświetlić go na mapie. Edytuj adres zlecenia, aby to naprawić.',
        {
          type: 'warning',
          duration: false
        }
      );
    });
  }
}

function onModalCompleted(data) {
  modalBase.value.hide();

  switch (modal.value.type) {
    case MODAL_USERS_TABLE:
      onContractorChose(data);
      break;
  }
}

function onContractorChose(contractor) {
  Swal.fire(
    getSwalConfiguration(
      'Przypisanie wykonawcy',
      'Czy na pewno chcesz przypisać wykonawcę do ' +
        (selectedOrdersIds.value.length === 1
          ? 'wybranego zlecenia?'
          : `${selectedOrdersIds.value.length} wybranych zleceń?`)
    )
  ).then((result) => {
    if (result.value) {
      addContractorToSelectedOrders(contractor);
    }
  });
}

async function addContractorToSelectedOrders(contractor) {
  var response = await RepositoryOrders.addContractorToMultipleOrders(
    contractor.id,
    selectedOrdersIds.value
  );

  if (response?.serverError) {
    toaster.show(TOASTER_SERVER_ERROR_MSG, { type: 'error', duration: 3000 });
  } else {
    if (response?.error != null) {
      if (response?.message != null && Array.isArray(response?.message)) {
        response.message.forEach((element) => {
          toaster.show(element, { duration: 3000, type: 'warning' });
        });
      } else toaster.show(TOASTER_ERROR_OCCURED_MSG, { duration: 3000, type: 'warning' });
    } else {
      let message = '';
      message += `<div class="text-left" style="font-size: 16">Przypisano: <b>${response?.success}</b><br/>`;
      message += `Pominięto: <b>${
        response?.user_already_had + response?.other_worker_in_progress
      }</b>, w tym:<br/>`;
      message += `  - wcześniej przypisanych: <b>${response?.user_already_had}</b><br/>`;
      message += `  - zajętych przez innego wykonawcę: <b>${response?.other_worker_in_progress}</b></div>`;

      Swal.fire({
        //TODO ASK TEST check
        icon: 'info',
        title: 'Wynik operacji',
        html: message
      });

      fetchData(1, false);
    }
  }
}

function onMapClickableClicked(clickable) {
  let clickActionType = clickable.getAttribute('click_action_type');

  if (clickActionType == undefined) return;

  const lat = clickable.getAttribute('lat');
  const lon = clickable.getAttribute('lon');

  switch (clickActionType) {
    case COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME:
      if (lat != undefined && lon != undefined) {
        copyToClipboard(lat + ',' + lon);
      }
      break;
    case SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME:
      if (lat != undefined && lon != undefined) {
        showOnGoogleMaps(lat, lon);
      }
      break;
  }
}

function refreshMapBounds(setBounds = true) {
  clusterMap.value?.refreshMapAfterTick(setBounds);
}

function onMapMouseUp() {
  if (clusterMap.value?.isBeingDragged === false) {
    resetSelectedIfCtrl();
  }
}

// created
fetchData();

defineExpose({ fetchData, refreshMapBounds });
</script>

<template>
  <div class="row">
    <div class="col-lg-8">
      <div class="ibox">
        <div class="ibox-content" :class="{ 'sk-loading': isLoading }" style="border-style: none">
          <div class="sk-spinner sk-spinner-three-bounce">
            <div class="sk-bounce1"></div>
            <div class="sk-bounce2"></div>
            <div class="sk-bounce3"></div>
          </div>

          <ClusterMap
            ref="clusterMap"
            v-show="isNotEmptyArray(ordersObject?.data)"
            :centerLat="centerLat"
            :centerLng="centerLng"
            height="700px"
            :areaSelecting="true"
            :disableClusteringAtZoomOverride="19"
            :maxClusterRadiusOverride="1"
            @selected-markers-changed="onSelectedMarkersChanged"
            @mapMouseUp="onMapMouseUp"
            @markerClicked="onSingleMarkerSelected"
            @clickableClicked="onMapClickableClicked"
          >
            <button
              class="btn btn-primary"
              style="position: absolute; bottom: 10px; left: 10px; z-index: 500"
              @click="refreshMapBounds"
            >
              <i class="fa fa-refresh" />
            </button>
          </ClusterMap>
          <div v-if="ordersObject?.error">
            <ServerErrorPanel @onRetry="fetchData" />
          </div>
          <div v-else-if="!isNotEmptyArray(ordersObject?.data)">
            <p class="text-center mt-3">Brak elementów do wyświetlenia</p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-4 pt-3">
      <slot name="globalFilterPanel"></slot>

      <button
        v-if="authStore.canAccess(ACCESS_KEY_ADD_WORKER_TO_ORDER_BUTTON)"
        :disabled="!Array.isArray(selectedOrdersIds) || selectedOrdersIds.length === 0"
        class="btn btn-primary btn-xs mt-1"
        @click="
          showModal(MODAL_USERS_TABLE, {
            additionalProps: {
              filter: { url: 'user/workers' }
            }
          })
        "
      >
        Dodaj wykonawcę
      </button>

      <button
        class="btn btn-primary btn-xs ml-1 mt-1"
        :disabled="allSelected"
        @click="selectAllSelectableMarkers"
      >
        Zaznacz wszystkie
      </button>
      <button
        class="btn btn-primary btn-xs ml-1 mt-1"
        :disabled="noneSelected"
        @click="resetSelectedMarkers"
      >
        Odznacz wszystkie
      </button>

      <OrdersMapSummary class="mt-4" :selectedOrdersIds="selectedOrdersIds" />

      <OrdersTableForMap class="mt-4" :selectedOrdersIds="selectedOrdersIds" />
    </div>

    <ModalBase ref="modalBase">
      <ModalBody>
        <template v-slot:title>
          {{ modal.title }}
        </template>
        <template v-slot:body>
          <component
            :is="modal.component"
            @on-completed="onModalCompleted"
            :key="modal.key"
            v-bind="modal.properties"
          >
          </component>
        </template>
      </ModalBody>
    </ModalBase>
  </div>
</template>

<style scoped></style>
