import {
  FAILURE_REASON_ACTIVE_POLE_ID,
  FAILURE_REASON_OWNER_DISAGREEMENT_ID
} from '../../data/constants/appConstants';
import {
  ACCESS_KEY_ACTION_EDIT_BUTTON,
  ACCESS_KEY_ACTION_SHOW_HIDE_BUTTON,
  ACCESS_KEY_ACTION_REMOVE_BUTTON,
  ACCESS_KEY_DEMPOINT_ACTIVATE_DEACTIVATE_BUTTON
} from '../../data/constants/authConstants';
import {
  UPDATE_ACTION_BUTTON_TYPE_NAME,
  HIDE_ACTION_BUTTON_TYPE_NAME,
  REMOVE_ACTION_BUTTON_TYPE_NAME,
  SHOW_ACTION_GALLERY_BUTTON_TYPE_NAME,
  COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME,
  SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME,
  ACTIVATE_DEACTIVATE_DEMPOINT_BUTTON_TYPE_NAME
} from '../../data/constants/buttonsNamesConstants';
import { COORDS_DIFFERENCE_METERS_WARNING_THRESHOLD } from '../../data/projectConfig';
import snippets from '../htmlSnippets/actionsTooltipSnippets';
import { isNotEmptyArray } from '../utilsHelper';

export function getCreatedActionMarkerTooltip(
  lat,
  lon,
  action,
  demPoint,
  authStore,
  hideOperationalButtons,
  showActionsBrowserIfDemPoint
) {
  let actionNote = action.note ? `Notatka: <i>${action.note}</i><br>` : '';
  let demPointNote = demPoint?.note ? `Uwagi: <i>${demPoint.note}</i><br/>` : '';

  const diff = action?.geotimestamp?.difference;
  const coordsDifferenceText =
    diff && diff > COORDS_DIFFERENCE_METERS_WARNING_THRESHOLD
      ? `<br/><span style='color:#f8ac59'>${getFormattedCoordsDifference(
          diff
        )} od urządzenia</span>`
      : '';

  const actionTypeDependentInfoText = getActionTypeDependentInfoText(action);

  const optionalActionFailureText = getOptionalActionFailureInfoText(action);
  const optionalActionAdditionalDismantlingSourceText =
    getOptionalActionAdditionalDismantlingSourceText(action);

  const groupCountInfoText =
    action.groupCount != null && action.groupCount > 0
      ? `<br/><b>Akcji w grupie: ${action.groupCount}</b>`
      : '';

  const workerName = action.worker?.firstName + ' ' + action.worker?.lastName;
  const actionCreatedDate = `Dodano: ${action.geotimestamp?.date}`;

  const demPointTag =
    demPoint?.oznaczenie != null ? `<br/>Oznaczenie: ${demPoint?.oznaczenie}` : '';

  const copyTagButton =
    demPointTag === ''
      ? ''
      : `<button clickable type='button' class="btn btn-white btn-xs ml-1" id='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}:${action?.id}:tag' tag='${demPoint.oznaczenie}' click_action_type='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}'><i class="fa fa-copy" clickable_child></i></button>`;
  const tagSection = demPointTag + copyTagButton;

  const buttonsSection = createButtonsSection(authStore, hideOperationalButtons, action, demPoint);

  let markerPopupCaption =
    `<b>${action.action_type.name}</b><br>` +
    optionalActionFailureText +
    optionalActionAdditionalDismantlingSourceText +
    `${workerName}<br/>` +
    `${actionNote}` +
    `${demPointNote}` +
    `${lat}, ${lon} ` +
    `<button clickable type='button' class="btn btn-white btn-xs ml-1 mb-1" id='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}:${action?.id}' id_action='${action?.id}' lat='${lat}' lon='${lon}' click_action_type='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}'><i class="fa fa-copy" clickable_child></i></button>` +
    `<button clickable type='button' class="btn btn-white btn-xs ml-1 mb-1" id='${SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME}:${action?.id}' id_action='${action?.id}' lat='${lat}' lon='${lon}' click_action_type='${SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME}'><i class="fa fa-google" clickable_child></i></button>` +
    coordsDifferenceText +
    actionTypeDependentInfoText +
    tagSection +
    groupCountInfoText +
    `<br/>${actionCreatedDate}` +
    `<br>` +
    buttonsSection +
    (demPoint && showActionsBrowserIfDemPoint
      ? getOtherActionsTooltipSection(action, demPoint)
      : '');
  return markerPopupCaption;
}

function getShowGalleryButton(action) {
  return `<button clickable type='button' class='btn btn-outline btn-info btn-xs' id='${SHOW_ACTION_GALLERY_BUTTON_TYPE_NAME}:${action?.id}' id_action='${action?.id}' action_type_name='${action?.action_type?.name}' click_action_type='${SHOW_ACTION_GALLERY_BUTTON_TYPE_NAME}'>Galeria</button>`;
}

function getEditButton(authStore, hideOperationalButtons, action) {
  return authStore.canAccess(ACCESS_KEY_ACTION_EDIT_BUTTON) && !hideOperationalButtons
    ? `<button clickable type='button' class='btn btn-outline btn-info btn-xs' id='${UPDATE_ACTION_BUTTON_TYPE_NAME}:${action?.id}' id_action='${action?.id}' click_action_type='${UPDATE_ACTION_BUTTON_TYPE_NAME}'>Edytuj</button>`
    : '';
}

function getHideButton(authStore, hideOperationalButtons, action) {
  return authStore.canAccess(ACCESS_KEY_ACTION_SHOW_HIDE_BUTTON) && !hideOperationalButtons
    ? `<button clickable type='button' class='btn btn-outline btn-warning btn-xs' id='${HIDE_ACTION_BUTTON_TYPE_NAME}:${action?.id}' id_action='${action?.id}' click_action_type='${HIDE_ACTION_BUTTON_TYPE_NAME}'>Ukryj</button>`
    : '';
}

function getRemoveButton(authStore, hideOperationalButtons, action) {
  return authStore.canAccess(ACCESS_KEY_ACTION_REMOVE_BUTTON) && !hideOperationalButtons
    ? `<button clickable type='button' class='btn btn-outline btn-danger btn-xs' id='${REMOVE_ACTION_BUTTON_TYPE_NAME}:${action?.id}' id_action='${action?.id}' click_action_type='${REMOVE_ACTION_BUTTON_TYPE_NAME}'>Usuń</button>`
    : '';
}

function getToggleDemPointButton(authStore, hideOperationalButtons, demPoint) {
  return demPoint != null &&
    authStore.canAccess(ACCESS_KEY_DEMPOINT_ACTIVATE_DEACTIVATE_BUTTON) &&
    !hideOperationalButtons
    ? `<button clickable type='button' class='btn btn-outline btn-info btn-xs' id='${ACTIVATE_DEACTIVATE_DEMPOINT_BUTTON_TYPE_NAME}:${
        demPoint?.id
      }' id_demPoint='${
        demPoint?.id
      }' click_action_type='${ACTIVATE_DEACTIVATE_DEMPOINT_BUTTON_TYPE_NAME}' demPoint_add_action_allowed='${
        demPoint.add_action_allowed
      }'>${demPoint.add_action_allowed == 1 ? 'Dezaktywuj' : 'Aktywuj'}</button>`
    : '';
}

function getAddEditDemPointNoteButton(hideOperationalButtons, demPoint) {
  return !hideOperationalButtons ? snippets.addEditDemPointNoteButtonSnippet(demPoint) : '';
}

function createButtonsSection(authStore, hideOperationalButtons, action, demPoint) {
  const showGalleryButton = getShowGalleryButton(action);
  const editButton = getEditButton(authStore, hideOperationalButtons, action);
  const hideButton = getHideButton(authStore, hideOperationalButtons, action);
  const removeButton = getRemoveButton(authStore, hideOperationalButtons, action);
  const toggleDemPointButton = getToggleDemPointButton(authStore, hideOperationalButtons, demPoint);
  const addEditDemPointNoteButton = getAddEditDemPointNoteButton(hideOperationalButtons, demPoint);

  return `<div class="mt-1" style="display: flex; flex-wrap: wrap; gap: 5px;">${showGalleryButton}${editButton}${hideButton}${removeButton}${toggleDemPointButton}${addEditDemPointNoteButton}</div>`;
}

function getFormattedCoordsDifference(difference) {
  return difference > 1000 ? (difference / 1000).toFixed(1) + ' km' : difference + ' m';
}

function getActionTypeDependentInfoText(action) {
  if (action.osd_detail != null) {
    let result = '<br/><b>Szczegóły OSD</b>';

    result +=
      action.osd_detail?.osd_release != null
        ? `<br/>Uwolnienie: ${action.osd_detail.osd_release.name}`
        : '';

    result +=
      action.osd_detail?.osd_element_link != null
        ? `<br/>Powiązanie: ${action.osd_detail.osd_element_link.name}`
        : '';

    result +=
      action.osd_detail?.optical_cable != null
        ? `<br/>Kabel optyczny: ${action.osd_detail.optical_cable}`
        : '';

    result +=
      action.osd_detail?.copper_cable != null
        ? `<br/>Kabel miedziany: ${action.osd_detail.copper_cable}`
        : '';

    if (isNotEmptyArray(action.osd_detail?.osd_poles)) {
      result += '<br/>Powiązane słupy:';

      action.osd_detail.osd_poles.forEach((pole) => {
        result += `<br/>- ${pole.oznaczenie}`;
      });
    }

    return result;
  }

  if (isNotEmptyArray(action.storage_details)) {
    let result = '<br/><b>Szczegóły miejsca składowania</b>';

    action.storage_details.forEach((element) => {
      result += `<br/>${element.name}: ${element.number}`;
    });

    return result;
  }

  return '';
}

function getOptionalActionFailureInfoText(action) {
  const optionalDismantlingFailureReason = action.failure_reason
    ? `Powód niepowodzenia: ${action.failure_reason.name}<br/>`
    : '';

  const optionalDismantlingFailureCableType =
    action.failure_reason != null &&
    action.failure_reason.id === FAILURE_REASON_ACTIVE_POLE_ID &&
    action.cable_type_for_failure
      ? `Typ przewodu: ${action.cable_type_for_failure.name}<br/>`
      : '';

  const optionalDismantlingFailureDeclarationOfDisagreement =
    action.failure_reason != null &&
    action.failure_reason.id === FAILURE_REASON_OWNER_DISAGREEMENT_ID
      ? `Spisano oświadczenie: ${action.declaration_of_disagreement === 1 ? 'Tak' : 'Nie'}<br/>`
      : '';

  return (
    optionalDismantlingFailureReason +
    optionalDismantlingFailureCableType +
    optionalDismantlingFailureDeclarationOfDisagreement
  );
}

function getOptionalActionAdditionalDismantlingSourceText(action) {
  if (action.extra_dem_source == null) return '';

  return `Źródło: ${action.extra_dem_source.name}<br/>`;
}

function getOtherActionsTooltipSection(action, demPoint) {
  if (!isNotEmptyArray(demPoint?.actions)) return '';

  const actionOrderId = demPoint.actions.indexOf(action) + 1;
  const actionsCount = demPoint.actions.length;

  if (actionsCount < 2) return '';

  return snippets.leftRightButtonsSnippet(actionOrderId, actionsCount, action.id, demPoint.id);
}
