<script setup>
import { navItems } from '@/helpers/navigationPanelHelper.js';
import UsersTable from '@/components/users/UsersTable.vue';
import { useNavigationPanelStore } from '@/stores/navigationPanel';

const navigationPanelStore = useNavigationPanelStore();

// created
navigationPanelStore.setNavigationPanelItems(navItems.USERS_LIST);
</script>

<template>
  <div>
    <users-table :addButton="true" :editButton="true" :showFilters="true" />
  </div>
</template>

<style scoped></style>
