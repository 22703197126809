<script setup>
import { ref, computed } from 'vue';
import TransportOrderDriversTable from '@/components/transport/TransportOrderDriversTable.vue';
import FileRepositoriesViewForTransportOrder from '@/components/fileRepositories/FileRepositoriesViewForTransportOrder.vue';
import { MODAL_USERS_TABLE } from '@/data/constants/modalConstants.js';
import { useModalTables } from '@/composables/modalTables.js';
import ModalBase from '@/components/utils/ModalBase.vue';
import ModalBody from '@/components/utils/ModalBody.vue';
import { createToaster } from '@meforma/vue-toaster';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { TOASTER_SERVER_ERROR_MSG } from '../../data/constants/toasterMessagesConstants';
import { APP_PRIMARY_COLOR } from '../../data/constants/appConstants';

const RepositoryTransportOrders = RepositoryFactory.get('transportOrders');
const props = defineProps({ id: Number, order: Object, isLoading: Boolean });

const emit = defineEmits(['refresh-general', 'refreshAll']);

const alreadyLoaded = ref({ drivers: false, fileRepositories: false });
const currentlyLoaded = ref(null);
const modalBase = ref(null);
const { modal, showModal } = useModalTables(modalBase);
const toaster = createToaster({ position: 'top-right' });
const driversTableKey = ref(0);

const contractorsTabCompleted = computed(() => {
  if (!Array.isArray(props.order?.usersOrders) || props.order?.usersOrders.length < 1) return false;

  let completed = true;
  props.order.usersOrders.forEach((userOrder) => {
    if (userOrder?.stop == null) completed = false;
  });

  return completed;
});

function onModalCompleted(data) {
  modalBase.value.hide();

  switch (modal.value.type) {
    case MODAL_USERS_TABLE:
      addDriverToOrder(data);
      break;
  }
}

async function addDriverToOrder(driver) {
  var response = await RepositoryTransportOrders.addDriverToOrder(props.id, driver?.id);
  if (response?.serverError) {
    toaster.show(TOASTER_SERVER_ERROR_MSG, { duration: 3000, type: 'error' });
  } else {
    if (response) {
      let msg = 'Coś poszło nie tak.';
      if (Array.isArray(response)) {
        msg = '';
        response.forEach((element) => {
          msg += element + '. ';
        });
      }
      toaster.show(msg, { duration: 3000, type: 'warning' });
    } else {
      driversTableKey.value++;
      toaster.show('Kierowca został przypisany do zlecenia.', { duration: 3000, type: 'success' });
      onRefreshEvent();
    }
  }
}

function onRefreshEvent() {
  emit('refresh-general');
}

function onTabClicked(tabId) {
  switch (tabId) {
    case 1:
      alreadyLoaded.value.drivers = true;
      currentlyLoaded.value = 'drivers';
      break;
    case 2:
      alreadyLoaded.value.fileRepositories = true;
      currentlyLoaded.value = 'fileRepositories';
      break;
  }
}

function initialize() {
  alreadyLoaded.value.drivers = true;
  currentlyLoaded.value = 'drivers';
}

initialize();

defineExpose({ currentlyLoaded });
</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="tabs-container">
          <ul class="nav nav-tabs" role="tablist">
            <li @click="onTabClicked(1)">
              <a class="nav-link active" data-toggle="tab" href="#tab-1">
                <i
                  class="fa fa-users"
                  :style="contractorsTabCompleted ? `color: #${APP_PRIMARY_COLOR}` : ''"
                />
                <span :style="contractorsTabCompleted ? `color: #${APP_PRIMARY_COLOR}` : ''"
                  >Wykonawcy</span
                ></a
              >
            </li>

            <li @click="onTabClicked(2)">
              <a class="nav-link" data-toggle="tab" href="#tab-2">
                <i class="fa fa-files-o" />
                Zdjęcia</a
              >
            </li>
          </ul>

          <div class="tab-content">
            <div role="tabpanel" id="tab-1" class="tab-pane active">
              <div class="panel-body">
                <button
                  class="btn btn-primary btn-xs"
                  @click="
                    showModal(MODAL_USERS_TABLE, {
                      additionalProps: {
                        filter: { url: 'user/drivers' }
                      }
                    })
                  "
                >
                  Dodaj kierowcę
                </button>

                <TransportOrderDriversTable
                  v-if="alreadyLoaded.drivers"
                  :key="driversTableKey"
                  :filter="{ url: `ordertransport/${id}/workersWeb` }"
                  :showBorder="false"
                  :longerSearchBar="true"
                  :orderId="props.order?.id"
                  :orderNumber="props.order?.order_number"
                  @refresh="emit('refreshAll')"
                />
              </div>
            </div>

            <div role="tabpanel" id="tab-2" class="tab-pane">
              <div class="panel-body">
                <FileRepositoriesViewForTransportOrder
                  v-if="alreadyLoaded.fileRepositories"
                  :orderId="id"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalBase ref="modalBase">
      <ModalBody>
        <template v-slot:title>
          {{ modal.title }}
        </template>
        <template v-slot:body>
          <component
            :is="modal.component"
            @on-completed="onModalCompleted"
            :key="modal.key"
            v-bind="modal.properties"
          ></component>
        </template>
      </ModalBody>
    </ModalBase>
  </div>
</template>

<style scoped></style>
