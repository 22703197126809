<script setup>
import { ref, watch } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { FAILURE_ACTION_TYPE_ID } from '../../data/constants/appConstants.js';
import { isNotEmptyArray } from '../../helpers/utilsHelper.js';

const RepositoryOrders = RepositoryFactory.get('orders');

const props = defineProps({
  selectedOrdersIds: { type: Array, default: () => [] }
});

const isLoading = ref(false);
const summaryData = ref(null);
const isCollapsed = ref(false);

watch(
  () => props.selectedOrdersIds,
  () => fetchData()
);

function fetchData() {
  isLoading.value = true;

  RepositoryOrders.getOrdersDetailsForMapObjectByOrdersIds(props.selectedOrdersIds)
    .then((data) => {
      summaryData.value = data?.data;
    })
    .catch((error) => console.log(error))
    .finally(() => {
      isLoading.value = false;
    });
}

function toggleCollapse() {
  isCollapsed.value = !isCollapsed.value;
}
</script>

<template>
  <div class="ibox-content p-0" :class="{ 'sk-loading': isLoading }" style="border-style: none">
    <div class="sk-spinner sk-spinner-three-bounce">
      <div class="sk-bounce1"></div>
      <div class="sk-bounce2"></div>
      <div class="sk-bounce3"></div>
    </div>

    <div v-if="summaryData">
      <h3>Podsumowanie zaznaczenia:</h3>

      <div class="pl-3">
        <div>Zaznaczone zlecenia: {{ summaryData.orders_sum ?? '-' }}</div>
        <div>Pozostało do zdemontowania: {{ summaryData.left_sum ?? '-' }}</div>
        <div>Liczba elementów: {{ summaryData.sum_points ?? '-' }}</div>
        <div>Liczba dezaktywowanych punktów: {{ summaryData.deactivated_number ?? '-' }}</div>

        <a type="button" class="btn btn-w-m btn-default mt-3" @click="toggleCollapse">
          Akcje
          <i :class="isCollapsed ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"></i>
        </a>

        <div v-show="isCollapsed" class="content">
          <b>Liczba akcji: {{ summaryData.actions_sum ?? '-' }}</b>
          <div class="pl-3">
            <div>Akcje dla punktów: {{ summaryData.actions_dem_point ?? '-' }}</div>
            <div>Akcje na mapę: {{ summaryData.actions_no_dem_point ?? '-' }}</div>
          </div>

          <div class="pt-3">
            <b>Akcje ze względu na typy:</b>
            <div
              class="pl-3"
              v-for="actionSummaryRow in summaryData.actions_summary"
              :key="actionSummaryRow.id_action_type"
            >
              {{ actionSummaryRow.name }}: {{ actionSummaryRow.total }}

              <div
                v-if="
                  actionSummaryRow.id_action_type === FAILURE_ACTION_TYPE_ID &&
                  isNotEmptyArray(summaryData.actions_failures)
                "
              >
                <div
                  v-for="actionFailureRow in summaryData.actions_failures"
                  :key="actionFailureRow.id_failure_action_reason"
                  class="pl-3"
                >
                  {{ actionFailureRow.name }}: {{ actionFailureRow.total }}
                </div>
              </div>
            </div>
          </div>

          <div v-if="isNotEmptyArray(summaryData.actions_extra_dem_source)" class="pt-3">
            <b>Źródła dodatkowych demontaży:</b>
            <div
              class="pl-3"
              v-for="additionalDismantlingSource in summaryData.actions_extra_dem_source"
              :key="additionalDismantlingSource.id_extra_dem_source"
            >
              {{ additionalDismantlingSource.name }}: {{ additionalDismantlingSource.total }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.content {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-top: 5px;
  overflow: hidden;
}
</style>
