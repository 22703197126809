import { ref, computed } from 'vue';
import { defineStore } from 'pinia';

export const useNavigationPanelStore = defineStore('navigationPanel', () => {
  // state
  const navigationPanelState = ref(localStorage.getItem('navigationPanelState'));

  // computed
  const navigationPanelItems = computed(() => {
    let currentNavigationPanelState = getCurrentNavigationPanelState();

    return currentNavigationPanelState ? currentNavigationPanelState.items : null;
  });

  const orderId = computed(() => {
    let currentNavigationPanelState = getCurrentNavigationPanelState();

    return currentNavigationPanelState ? currentNavigationPanelState.orderId : null;
  });

  const orderNumber = computed(() => {
    let currentNavigationPanelState = getCurrentNavigationPanelState();

    return currentNavigationPanelState ? currentNavigationPanelState.orderNumber : null;
  });

  const transportOrderNumber = computed(() => {
    let currentNavigationPanelState = getCurrentNavigationPanelState();

    return currentNavigationPanelState ? currentNavigationPanelState.transportOrderNumber : null;
  });

  // actions
  function setNavigationPanelItems(value) {
    let currentNavigationPanelState = getCurrentNavigationPanelState();
    let valueStr = JSON.stringify(value);
    valueStr = valueStr.replace('#orderId', orderId);
    value = JSON.parse(valueStr);

    currentNavigationPanelState.items = value;
    navigationPanelState.value = JSON.stringify(currentNavigationPanelState);
    localStorage.setItem('navigationPanelState', navigationPanelState.value);
  }

  function setOrderId(value) {
    let currentNavigationPanelState = getCurrentNavigationPanelState();

    currentNavigationPanelState.orderId = value;
    navigationPanelState.value = JSON.stringify(currentNavigationPanelState);
    localStorage.setItem('navigationPanelState', navigationPanelState.value);
  }

  function setOrderNumber(value) {
    let currentNavigationPanelState = getCurrentNavigationPanelState();

    currentNavigationPanelState.orderNumber = value;
    navigationPanelState.value = JSON.stringify(currentNavigationPanelState);
    localStorage.setItem('navigationPanelState', navigationPanelState.value);
  }

  function setTransportOrderNumber(value) {
    let currentNavigationPanelState = getCurrentNavigationPanelState();

    currentNavigationPanelState.transportOrderNumber = value;
    navigationPanelState.value = JSON.stringify(currentNavigationPanelState);
    localStorage.setItem('navigationPanelState', navigationPanelState.value);
  }

  function getCurrentNavigationPanelState() {
    return navigationPanelState.value ? JSON.parse(navigationPanelState.value) : {};
  }

  return {
    navigationPanelItems,
    setNavigationPanelItems,
    setOrderId,
    setOrderNumber,
    orderNumber,
    setTransportOrderNumber,
    transportOrderNumber
  };
});
