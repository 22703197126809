import { isNotEmptyArray } from '../helpers/utilsHelper.js';
import repository from './repository.js';

export default {
  async getGeocodingsForTheAddress(addressId) {
    return repository.get(`/address/${addressId}/GetGeocodingForAddress`).then((response) => {
      if (response.data.error == null) return response.data;
    });
  },

  async setGeocoding(addressId, address, lat, lon, id_dem_elem_type, note) {
    return repository
      .post(`/address/${addressId}/SetGeocoding`, {
        address,
        lat,
        lon,
        id_dem_elem_type,
        note
      })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async getAddressToEdit(id) {
    return repository.get(`/address/${id}/edit`).then((response) => {
      if (response.data.error == null) return response.data.data;
    });
  },

  async updateAddress(address) {
    let formData = new FormData();

    formData.append('address', address.address);
    formData.append('description', address.description);
    formData.append('id_order_range', address.order_range.id);
    formData.append('id_unit_of_measure', address.unit_of_measure.id);
    formData.append('owner_phone', address.owner_phone);
    formData.append('quantity', address.quantity);

    if (isNotEmptyArray(address.files)) {
      for (let index = 0; index < address.files.length; index++) {
        formData.append(`files[${index}][file]`, address.files[index]);
      }
    }

    return repository
      .post(`/address/${address.id}/update`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then((response) => {
        return response?.data?.message ?? null;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async deleteAddress(addressId) {
    return repository
      .delete(`/address/${addressId}`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  }
};
