<script setup>
import { ref } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import RepoFilesTableGET from '../fileRepositories/RepoFilesTableGET.vue';
import RepoFilesTablePOST from '../fileRepositories/RepoFilesTablePOST.vue';
import TransportRepoFilesFilterAndSearchPanel from './TransportRepoFilesFilterAndSearchPanel.vue';

const RepositoryTransportOrders = RepositoryFactory.get('transportOrders');

const props = defineProps({
  actionId: { type: Number, default: null }
});

const isLoading = ref(false);
const summaryObject = ref(null);
const actionsResourcesKey = ref(0);
const transportRepoFilesFilterAndSearchPanel = ref();
const transportKey = ref(0);
const transportUserFilter = ref(null);

async function fetchData() {
  if (props.actionId == null) return;

  isLoading.value = true;

  summaryObject.value =
    await RepositoryTransportOrders.getAdditionalSummaryForSingleSelectedTransportPoint(
      props.actionId
    );

  isLoading.value = false;
}

function getElementsText(elementsObj) {
  if (elementsObj == null || (Array.isArray(elementsObj) && elementsObj.length === 0))
    return '<brak>';

  let text = '';

  for (const property in elementsObj) {
    text += `${property}: ${elementsObj[property]}\n`;
  }

  if (text.endsWith('\n')) {
    text = text.slice(0, -1);
  }

  return text;
}

function onFetchDataWithFiltersRequest({ filter }) {
  transportUserFilter.value = filter;
  transportKey.value++;
}

defineExpose({ fetchData });
</script>

<template>
  <div
    class="ibox-content"
    :class="{ 'sk-loading': isLoading }"
    style="border-style: none; padding: 0%"
  >
    <div class="sk-spinner sk-spinner-three-bounce">
      <div class="sk-bounce1"></div>
      <div class="sk-bounce2"></div>
      <div class="sk-bounce3"></div>
    </div>

    <h3>Dodatkowe szczegóły pojedynczego punktu:</h3>

    <span style="font-size: 14px; font-weight: 600">Nr zlecenia:</span>
    {{ summaryObject?.data?.order_number }}

    <h4>Wykonano:</h4>
    <div class="mb-2 pl-2" style="white-space: pre">
      {{ getElementsText(summaryObject?.data?.done) }}
    </div>

    <h4>Pozostawiono:</h4>
    <div class="mb-2 pl-2" style="white-space: pre">
      {{ getElementsText(summaryObject?.data?.left_last_worker) }}
    </div>

    <h4>Notatka:</h4>
    <div class="mb-2 pl-2" style="white-space: normal">
      {{ summaryObject?.data?.note ?? '<brak>' }}
    </div>

    <h4 class="mb-3">Zdjęcia z miejsca składowania</h4>

    <RepoFilesTableGET
      :key="actionsResourcesKey"
      modalId="RepoFilesTableGET_modalImageContainer2234"
      :url="`action/${props.actionId}/resources`"
      :showBorder="false"
      :longerSearchBar="true"
      :deletingEnabled="false"
      :wider-pagination-count-picker="true"
      @fileDeleted="actionsResourcesKey++"
    />

    <h4 class="mb-3">Zdjęcia z wykonania</h4>

    <TransportRepoFilesFilterAndSearchPanel
      ref="transportRepoFilesFilterAndSearchPanel"
      :showSearchBar="false"
      @fetchData="onFetchDataWithFiltersRequest"
    />

    <RepoFilesTablePOST
      :key="transportKey"
      modalId="RepoFilesTablePOST_modalImageContainer2432"
      :url="`action/${props.actionId}/orderTransportPhotos`"
      :showBorder="false"
      longerSearchBar="true"
      :deletingEnabled="true"
      :userFilter="transportUserFilter"
      :widerPaginationCountPicker="true"
      @dataFetched="transportRepoFilesFilterAndSearchPanel?.updateAppliedFilters"
    />
  </div>
</template>
