import { createApp } from 'vue';
import { createPinia } from 'pinia';

import App from './App.vue';
import router from './router';
import repository from '@/data/repository.js';
import * as Sentry from '@sentry/vue';

const token = localStorage.getItem('user-token');

if (token) {
  repository.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

const app = createApp(App);

if (import.meta.env.MODE === 'production') {
  Sentry.init({
    app,
    dsn: 'https://4415328a9a84486d9fed015075b47c9d@o438282.ingest.sentry.io/4504768747864064',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
}

app.use(createPinia());
app.use(router);

app.mount('#app');

export default app;
