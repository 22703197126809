import { RepositoryFactory } from '../data/repositoryFactory';

const RepositoryDictionaries = RepositoryFactory.get('dictionaries');

export async function fetchDictionaryData(url) {
  try {
    const data = await RepositoryDictionaries.getDictionaryElementsObject(1, 100, '', '', '', url);
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
