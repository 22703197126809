export const PAGINATION_LIMIT = 5;
export const PAGINATION_INITIAL_ITEMS_PER_PAGE = 10;
export const FILE_MAX_SIZE = 50;
export const USER_COLORS = [
  '#6e400b',
  '#ad323b',
  '#CD3F2C',
  '#845EC2',
  '#c493ff',
  '#f556ef',
  '#faccff',
  '#ff8066',
  '#ffc800',
  '#61ffbb',
  '#00c9a7',
  '#0e4a2a',
  '#42eff5',
  '#78acff',
  '#428df5',
  '#4f4694'
];
export const ORDER_TYPES_IDS_WITHOUT_GEODESY = [1];

export const START_ORDER_ACTION_POPUP_CAPTION = 'Rozpoczęcie wykonania zlecenia';
export const STOP_ORDER_ACTION_POPUP_CAPTION = 'Zakończenie wykonania zlecenia';
export const RADO_LOGO_SIZE = [151, 54];
export const RADO_LOGO_SIZE_LOGIN_PAGE = [151 * 2.5, 54 * 2.5];
export const APP_PRIMARY_COLOR = '276fb3';
export const DEFAULT_INITIAL_LAT = 50.29044;
export const DEFAULT_INITIAL_LON = 21.42891;

export const REPORT_STORAGE_PLACE_TYPE_FOR_MAP = 4;
export const OSD_TYPES_FOR_MAP_IDS = [5, 6];
export const OSD_DEM_ELEM_TYPE_ID = 6;
export const POLE_DEM_ELEM_TYPE_ID = 1;
export const DISMANTLING_FAILURE_TYPE_FOR_MAP = 3;
export const DISMANTLING_FAILURE_TYPE_FOR_POINTS = 3;
export const FAILURE_REASON_ACTIVE_POLE_ID = 6;
export const FAILURE_REASON_OWNER_DISAGREEMENT_ID = 10;

export const NUMBER_UNIT_TYPE_ID = 1;
export const KG_UNIT_TYPE_ID = 2;

// order statuses
export const DISMANTLING_INTERRUPTED_ORDER_STATUS_ID = 3;
export const OPL_VERIFICATION_ORDER_STATUS_ID = 4;
export const CANCELLED_ORDER_STATUS_ID = 5;
export const RADO_VERIFICATION_ORDER_STATUS_ID = 11;

// transport order statuses
export const DRIVER_IS_WAITING_ORDER_STATUS_ID = 1;
export const TRANSPORT_ORDER_COMPLETED_STATUS_ID = 2;
export const DRIVER_IS_WAITING_FOR_VERIFICATION_STATUS_ID = 3;

export const FAILURE_ACTION_TYPE_ID = 3;
export const ADDITIONAL_DISMANTLING_ACTION_TYPE_ID = 5;
export const ADDITIONAL_MANUAL_DISMANTLING_ACTION_TYPE_ID = 6;

export const COMPLETED_REPORT_TYPE_ID = 5;
export const ORDER_PROTOCOL_TYPE_POK_TYPE_ID = 2;
export const ORDER_INITIATIVE_WZOS_TYPE_ID = 2;

export const SINGLE_DEM_POINT_TYPE_ID = 1;
export const AGGREGATE_DEM_POINT_TYPE_ID = 2;

export const EDIT_ACTIONS_GROUP_TOOLTIP_TEXT =
  "Przytrzymanie lewego przycisku 'Ctrl' umożliwia zaznaczanie i odznaczanie akcji należących do grupy";

export const ACCEPTANCE_PROTOCOL_GENERATION_FORM_ADDITIONAL_DATA_ROW_ORDER_RANGE =
  'Weryfikacja linii napowietrznej jednego słupa';

export const DEM_POINT_MARKER_TYPE_ID = 'DEM_POINT_MARKER_TYPE_ID';
export const ACTION_FROM_DEM_POINT_MARKER_TYPE_ID = 'ACTION_FROM_DEM_POINT_MARKER_TYPE_ID';
export const ACTION_WITH_NO_DEM_POINT_MARKER_TYPE_ID = 'ACTION_WITH_NO_DEM_POINT_MARKER_TYPE_ID';
export const ACTION_PHOTO_MARKER_TYPE_ID = 'ACTION_PHOTO_MARKER_TYPE_ID';

// order directions
export const ORDER_DIRECTION_SSS_TYPE_ID = 1;
export const ORDER_DIRECTION_STANDARD_TYPE_ID = 2;

export const ORDER_DEADLINE_DAYS_FOR_DIRECTION_SSS = 45;
export const ORDER_DEADLINE_DAYS_FOR_DIRECTION_STANDARD = 90;

export const DEVICE_LOCATION_CONNECTION_LINE_COLOR = '#00000077';
