<template>
  <div>
    <div class="modal-header">
      <slot name="header"></slot>
      <h4 class="modal-title"><slot name="title"></slot></h4>
    </div>
    <div class="modal-body">
      <slot name="body"></slot>
    </div>
    <div v-if="$slots.footer" class="modal-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
