<script setup>
import { ref, computed } from 'vue';
import OrderContractorsTable from '@/components/orders/OrderContractorsTable.vue';
import FileRepositoriesViewForOrder from '@/components/fileRepositories/FileRepositoriesViewForOrder.vue';
import { MODAL_USERS_TABLE } from '@/data/constants/modalConstants.js';
import { useModalTables } from '@/composables/modalTables.js';
import ModalBase from '@/components/utils/ModalBase.vue';
import ModalBody from '@/components/utils/ModalBody.vue';
import { createToaster } from '@meforma/vue-toaster';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import OrderTransportPhase from '@/components/orders/OrderTransportPhase.vue';
import OrderAcceptanceProtocolPhase from '@/components/orders/OrderAcceptanceProtocolPhase.vue';
import OrderGeodeticProtocolPhase from '@/components/orders/OrderGeodeticProtocolPhase.vue';
import OrderAdditionalInfoTabPanel from './OrderAdditionalInfoTabPanel.vue';
import { TOASTER_SERVER_ERROR_MSG } from '../../data/constants/toasterMessagesConstants';
import { useAuthStore } from '../../stores/auth';
import {
  ACCESS_KEY_ORDER_DETAILS_ACCEPTANCE_PROTOCOL_TAB,
  ACCESS_KEY_ORDER_DETAILS_CONTRACTORS_TAB,
  ACCESS_KEY_ORDER_DETAILS_GEODETIC_PROTOCOL_TAB,
  ACCESS_KEY_ORDER_DETAILS_REPOSITORY_TAB,
  ACCESS_KEY_ORDER_DETAILS_TRANSPORT_TAB,
  ACCESS_KEY_ADD_WORKER_TO_ORDER_BUTTON,
  ACCESS_KEY_ORDER_DETAILS_ADDITIONAL_INFO_TAB,
  ACCESS_KEY_ORDER_DETAILS_MAP_POINTS_REPORTS_TAB
} from '../../data/constants/authConstants';
import MapPointsReportsOrderView from '../mapPointsReports/MapPointsReportsOrderView.vue';
import { ORDER_PROTOCOL_TYPE_POK_TYPE_ID } from '../../data/constants/appConstants';

const RepositoryOrders = RepositoryFactory.get('orders');
const props = defineProps({ id: Number, order: Object, isLoading: Boolean });
const emit = defineEmits(['refresh-general', 'refreshActionsMap']);

const alreadyLoaded = ref({
  orderContractors: false,
  transport: false,
  acceptanceProtocol: false,
  geodeticProtocol: false,
  fileRepositories: false,
  additionalInfo: false,
  mapPointsReports: false
});
const currentlyLoaded = ref(null);
const modalBase = ref(null);
const { modal, showModal } = useModalTables(modalBase);
const toaster = createToaster({ position: 'top-right' });
const contractorsTableKey = ref(0);
const authStore = useAuthStore();
const orderAdditionalInfoTabPanel = ref();
const mapPointsReportsOrderViewRef = ref();

const acceptanceProtocolTabCompleted = computed(() => {
  return props.order?.protocol_type?.id === ORDER_PROTOCOL_TYPE_POK_TYPE_ID;
});

const transportTabCompleted = computed(() => {
  return (
    props.order?.transport_status?.all_done != null && props.order.transport_status.all_done == 1
  );
});

function onModalCompleted(data) {
  modalBase.value.hide();

  switch (modal.value.type) {
    case MODAL_USERS_TABLE:
      addContractorToOrder(data);
      break;
  }
}

async function addContractorToOrder(contractor) {
  var response = await RepositoryOrders.addContractorToOrder(props.id, contractor?.id);

  if (response?.serverError) {
    toaster.show(TOASTER_SERVER_ERROR_MSG, { duration: 3000, type: 'error' });
  } else {
    if (response) {
      let msg = 'Coś poszło nie tak.';

      if (Array.isArray(response)) {
        msg = '';
        response.forEach((element) => {
          msg += element + '. ';
        });
      }

      toaster.show(msg, { duration: 3000, type: 'warning' });
    } else {
      contractorsTableKey.value++;
      toaster.show('Wykonawca został przypisany do zlecenia.', { duration: 3000, type: 'success' });
      onRefreshEvent();
    }
  }
}

function onRefreshEvent() {
  emit('refresh-general');
}

function onTabClicked(tabId) {
  if (currentlyLoaded.value === 'additionalInfo' && tabId !== 8) {
    orderAdditionalInfoTabPanel.value?.onComponentDeactivated();
  }

  switch (tabId) {
    case 1:
      alreadyLoaded.value.fileRepositories = true;
      currentlyLoaded.value = 'fileRepositories';
      break;
    case 3:
      alreadyLoaded.value.orderContractors = true;
      currentlyLoaded.value = 'orderContractors';
      emit('refreshActionsMap');
      break;
    case 4:
      alreadyLoaded.value.transport = true;
      currentlyLoaded.value = 'transport';
      break;
    case 5:
      alreadyLoaded.value.acceptanceProtocol = true;
      currentlyLoaded.value = 'acceptanceProtocol';
      break;
    case 6:
      alreadyLoaded.value.geodeticProtocol = true;
      currentlyLoaded.value = 'geodeticProtocol';
      break;
    case 8:
      alreadyLoaded.value.additionalInfo = true;
      currentlyLoaded.value = 'additionalInfo';
      break;
    case 9:
      alreadyLoaded.value.mapPointsReports = true;
      currentlyLoaded.value = 'mapPointsReports';
      setTimeout(() => {
        mapPointsReportsOrderViewRef.value?.refreshMapPointsReportsMapBounds();
      }, 100);
      break;
  }
}

function initialize() {
  alreadyLoaded.value.orderContractors = true;
  currentlyLoaded.value = 'orderContractors';
}

initialize();

defineExpose({ currentlyLoaded });
</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="tabs-container">
          <ul class="nav nav-tabs" role="tablist">
            <li
              v-if="authStore.canAccess(ACCESS_KEY_ORDER_DETAILS_CONTRACTORS_TAB)"
              @click="onTabClicked(3)"
            >
              <a class="nav-link active" data-toggle="tab" href="#tab-3">
                <i class="fa fa-users" />
                Wykonawcy
              </a>
            </li>

            <li
              v-if="authStore.canAccess(ACCESS_KEY_ORDER_DETAILS_TRANSPORT_TAB)"
              @click="onTabClicked(4)"
            >
              <a class="nav-link" data-toggle="tab" href="#tab-4">
                <i class="fa fa-truck" :style="transportTabCompleted ? 'color: #1ab394' : ''" />
                <span :style="transportTabCompleted ? 'color: #1ab394' : ''">Składowanie</span>
              </a>
            </li>

            <li
              v-if="authStore.canAccess(ACCESS_KEY_ORDER_DETAILS_GEODETIC_PROTOCOL_TAB)"
              @click="onTabClicked(6)"
            >
              <a class="nav-link" data-toggle="tab" href="#tab-6">
                <img
                  src="/static/img/map-icon-gray.png"
                  alt="Protokół geodezji"
                  width="13"
                  height="13"
                  style="margin-right: 6px"
                />
                Protokół geodezji
              </a>
            </li>

            <li
              v-if="authStore.canAccess(ACCESS_KEY_ORDER_DETAILS_MAP_POINTS_REPORTS_TAB)"
              @click="onTabClicked(9)"
            >
              <a class="nav-link" data-toggle="tab" href="#tab-9">
                <i class="fa fa-map-marker" />
                Mapy zgłoszeń
              </a>
            </li>

            <li
              v-if="authStore.canAccess(ACCESS_KEY_ORDER_DETAILS_ACCEPTANCE_PROTOCOL_TAB)"
              @click="onTabClicked(5)"
            >
              <a class="nav-link" data-toggle="tab" href="#tab-5">
                <i
                  class="fa fa-check-square-o"
                  :style="acceptanceProtocolTabCompleted ? 'color: #1ab394' : ''"
                />
                <span :style="acceptanceProtocolTabCompleted ? 'color: #1ab394' : ''"
                  >Protokół odbioru</span
                >
              </a>
            </li>

            <li
              v-if="authStore.canAccess(ACCESS_KEY_ORDER_DETAILS_ADDITIONAL_INFO_TAB)"
              @click="onTabClicked(8)"
            >
              <a class="nav-link" data-toggle="tab" href="#tab-8">
                <i class="fa fa-info" />
                Uwagi
              </a>
            </li>

            <li
              v-if="authStore.canAccess(ACCESS_KEY_ORDER_DETAILS_REPOSITORY_TAB)"
              class="ml-auto"
              @click="onTabClicked(1)"
            >
              <a class="nav-link" data-toggle="tab" href="#tab-1">
                <i class="fa fa-files-o" />
                Repozytorium</a
              >
            </li>
          </ul>

          <div class="tab-content">
            <div
              v-if="authStore.canAccess(ACCESS_KEY_ORDER_DETAILS_CONTRACTORS_TAB)"
              role="tabpanel"
              id="tab-3"
              class="tab-pane active"
            >
              <div class="panel-body">
                <button
                  v-if="authStore.canAccess(ACCESS_KEY_ADD_WORKER_TO_ORDER_BUTTON)"
                  class="btn btn-primary btn-xs"
                  @click="
                    showModal(MODAL_USERS_TABLE, {
                      additionalProps: {
                        filter: { url: 'user/workers' }
                      }
                    })
                  "
                >
                  Dodaj wykonawcę
                </button>

                <OrderContractorsTable
                  v-if="alreadyLoaded.orderContractors"
                  :key="contractorsTableKey"
                  :filter="{ url: `/order/${id}/userOrders` }"
                  :showBorder="false"
                  :longerSearchBar="true"
                  useCaseId="orderDetailsAdditional"
                  @refresh="onRefreshEvent"
                />
              </div>
            </div>

            <div
              v-if="authStore.canAccess(ACCESS_KEY_ORDER_DETAILS_TRANSPORT_TAB)"
              role="tabpanel"
              id="tab-4"
              class="tab-pane"
            >
              <div class="panel-body">
                <OrderTransportPhase
                  v-if="alreadyLoaded.transport"
                  :orderId="id"
                  :isLoading="isLoading"
                />
              </div>
            </div>

            <div
              v-if="authStore.canAccess(ACCESS_KEY_ORDER_DETAILS_GEODETIC_PROTOCOL_TAB)"
              role="tabpanel"
              id="tab-6"
              class="tab-pane"
            >
              <div class="panel-body">
                <OrderGeodeticProtocolPhase
                  v-if="alreadyLoaded.geodeticProtocol"
                  :orderId="id"
                  :order="order"
                  @refresh="onRefreshEvent"
                />
              </div>
            </div>

            <div
              v-if="authStore.canAccess(ACCESS_KEY_ORDER_DETAILS_MAP_POINTS_REPORTS_TAB)"
              role="tabpanel"
              id="tab-9"
              class="tab-pane"
            >
              <div class="panel-body">
                <MapPointsReportsOrderView
                  v-if="alreadyLoaded.mapPointsReports"
                  ref="mapPointsReportsOrderViewRef"
                  :orderId="props.id"
                  :order="props.order"
                />
              </div>
            </div>

            <div
              v-if="authStore.canAccess(ACCESS_KEY_ORDER_DETAILS_ACCEPTANCE_PROTOCOL_TAB)"
              role="tabpanel"
              id="tab-5"
              class="tab-pane"
            >
              <div class="panel-body">
                <OrderAcceptanceProtocolPhase
                  v-if="alreadyLoaded.acceptanceProtocol"
                  :orderId="id"
                  @refresh="onRefreshEvent"
                  :isLoading="isLoading"
                  :protocolType="props.order?.protocol_type"
                  :orderEbp="props.order?.ebp"
                />
              </div>
            </div>

            <div
              v-if="authStore.canAccess(ACCESS_KEY_ORDER_DETAILS_ADDITIONAL_INFO_TAB)"
              role="tabpanel"
              id="tab-8"
              class="tab-pane"
            >
              <div class="panel-body">
                <OrderAdditionalInfoTabPanel
                  ref="orderAdditionalInfoTabPanel"
                  v-if="alreadyLoaded.additionalInfo"
                  :orderId="id"
                  :isLoading="isLoading"
                />
              </div>
            </div>

            <div
              v-if="authStore.canAccess(ACCESS_KEY_ORDER_DETAILS_REPOSITORY_TAB)"
              role="tabpanel"
              id="tab-1"
              class="tab-pane"
            >
              <div class="panel-body">
                <FileRepositoriesViewForOrder
                  v-if="alreadyLoaded.fileRepositories"
                  :orderId="id"
                  :order="order"
                  @refresh="onRefreshEvent"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalBase ref="modalBase">
      <ModalBody>
        <template v-slot:title>
          {{ modal.title }}
        </template>
        <template v-slot:body>
          <component
            :is="modal.component"
            @on-completed="onModalCompleted"
            :key="modal.key"
            v-bind="modal.properties"
          ></component>
        </template>
      </ModalBody>
    </ModalBase>
  </div>
</template>

<style scoped></style>
