<script setup>
/* eslint-env jquery */
import { ref, computed, watch, onMounted } from 'vue';
import Swal from 'sweetalert2';
import { useAuthStore } from '@/stores/auth';
import { useFilesHandler } from '@/composables/filesHandler.js';
import { createToaster } from '@meforma/vue-toaster';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import GeodeticProtocolAggregateAssigning from '@/components/geodeticProtocols/GeodeticProtocolAggregateAssigning.vue';
import GeodeticDatesForOrder from '@/components/orders/GeodeticDatesForOrder.vue';
import GeodeticProtocolsTable from '../geodeticProtocols/GeodeticProtocolsTable.vue';
import DropArea from '@/components/utils/DropArea.vue';
import { isNotEmptyArray } from '../../helpers/utilsHelper';
import {
  TOASTER_ATTACH_FILE_MSG,
  TOASTER_SERVER_ERROR_MSG
} from '../../data/constants/toasterMessagesConstants';
import { APP_PRIMARY_COLOR } from '../../data/constants/appConstants';
import { getSwalConfiguration } from '../../helpers/swalHelper';

const PROTOCOL_FILE_INPUT_ID = 'fileInputField3';

const RepositoryOrders = RepositoryFactory.get('orders');
const props = defineProps({ orderId: null, order: null });
const emit = defineEmits(['refresh']);

const toaster = createToaster({ position: 'top-right', duration: 3000 });
const authStore = useAuthStore();
const geodetic_relization_date = ref(null);
const addToMultipleOrdersEnabled = ref(false);
const isLoading = ref(false);
const {
  filesRef,
  showFileInput,
  onFilesChanged,
  onDropAreaFilesChanged,
  getCreationDate,
  clearFileInput
} = useFilesHandler(false, PROTOCOL_FILE_INPUT_ID);
const geodeticProtocolsCount = ref(null);
const geodeticProtocolsTableKey = ref(0);
const additionalOrdersComponent = ref();

const loggedInUser = computed(() => authStore.loggedInUser);

const multipleOrdersCount = computed(() => {
  return Array.isArray(additionalOrdersComponent.value.orders)
    ? additionalOrdersComponent.value.orders.length
    : '-';
});

watch(geodeticProtocolsCount, () => {
  showFileInput.value = geodeticProtocolsCount.value === 0;
});

function onGeodeticProtocolsFetched(GPObject) {
  const geodeticProtocolsObjectData = GPObject?.data;
  geodeticProtocolsCount.value = Array.isArray(geodeticProtocolsObjectData)
    ? geodeticProtocolsObjectData.length
    : null;
}

function onDataUpload(e) {
  e.preventDefault();

  if (!isNotEmptyArray(filesRef.value)) {
    toaster.show(TOASTER_ATTACH_FILE_MSG, { type: 'warning' });
    return;
  }

  if (!addToMultipleOrdersEnabled.value) {
    sendGeodeticProtocol();
    return;
  }

  let ordersCountText = multipleOrdersCount.value.toString();

  if (multipleOrdersCount.value === 1) ordersCountText += ' zlecenia';
  else ordersCountText += ' zleceń';

  Swal.fire(
    getSwalConfiguration('Dodawanie pliku', `Czy na pewno chcesz dodać plik do ${ordersCountText}?`)
  ).then((result) => {
    if (result.value) sendGeodeticProtocol();
  });
}

function updateGeodeticRealizationDateFromPicker() {
  geodetic_relization_date.value = document.getElementById('geodeticRealizationDateId').value;
}

async function sendGeodeticProtocol() {
  let orders_ids = null;

  if (addToMultipleOrdersEnabled.value && Array.isArray(additionalOrdersComponent.value.orders)) {
    orders_ids = additionalOrdersComponent.value.orders.map((x) => x.id);
    orders_ids = orders_ids.filter((x) => x !== props.orderId);
    if (orders_ids.length === 0) orders_ids = null;
  }

  var response = await RepositoryOrders.sendGeodeticProtocol(
    props.orderId,
    filesRef.value?.[0].file,
    getCreationDate(),
    loggedInUser.value?.id,
    geodetic_relization_date.value,
    orders_ids
  );
  if (response?.serverError) {
    toaster.show(TOASTER_SERVER_ERROR_MSG, { type: 'error' });
  } else {
    if (response) {
      let msg = 'Coś poszło nie tak.';
      if (Array.isArray(response)) {
        msg = '';
        response.forEach((element) => {
          msg += element + '. ';
        });
      }
      toaster.show(msg, { type: 'warning' });
    } else {
      emit('refresh');
      toaster.show('Protokół geodezji został przesłany.', { type: 'success' });
      filesRef.value = [];
      geodetic_relization_date.value = null;
      geodeticProtocolsTableKey.value++;
      clearFileInput();
    }
  }
}

onMounted(() => {
  $('#data_1 .input-group.date').datepicker({
    todayBtn: 'linked',
    keyboardNavigation: true,
    forceParse: true,
    calendarWeeks: true,
    autoclose: true,
    weekStart: 1
  });

  $(document.body).on(
    'change',
    'input[id^="geodeticRealizationDateId"]',
    updateGeodeticRealizationDateFromPicker
  );
});

// created
showFileInput.value = geodeticProtocolsCount.value === 0;
</script>

<template>
  <div class="ibox">
    <div class="ibox-content" :class="{ 'sk-loading': isLoading }" style="border-style: none">
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <div class="row">
        <div class="col-12 mt-2" :style="`border-bottom: 1px dashed #${APP_PRIMARY_COLOR}`">
          <h5>I. Wypełnij daty dotyczące geodezji</h5>
          <GeodeticDatesForOrder :orderId="props.orderId" />
        </div>

        <div class="col-12 mt-2">
          <h5>II. Wczytaj protokół geodezji</h5>

          <div v-show="!showFileInput">
            <button class="btn btn-primary mt-2" @click="showFileInput = true">Dodaj PG</button>
          </div>

          <form
            v-show="showFileInput"
            class="mb-3 pb-3"
            :style="`border-bottom: 1px dashed #${APP_PRIMARY_COLOR}`"
            @submit="onDataUpload"
          >
            <div class="row">
              <div class="col-4">
                <div class="form-group" id="data_1">
                  <label>Data realizacji</label>
                  <div class="input-group date">
                    <span class="input-group-addon" :style="'background-color: #e9ecef'"
                      ><i class="fa fa-calendar"></i></span
                    ><input
                      id="geodeticRealizationDateId"
                      type="text"
                      class="form-control"
                      v-model="geodetic_relization_date"
                      required
                    />
                  </div>
                </div>

                <div class="row mt-2 ml-1">
                  <div class="switch mr-2">
                    <div class="onoffswitch">
                      <input
                        type="checkbox"
                        class="onoffswitch-checkbox"
                        id="example1"
                        v-model="addToMultipleOrdersEnabled"
                      />
                      <label class="onoffswitch-label" for="example1">
                        <span class="onoffswitch-inner"></span>
                        <span class="onoffswitch-switch"></span>
                      </label>
                    </div>
                  </div>
                  <label>Dodaj do wielu zleceń</label>
                </div>
              </div>

              <div class="col-8">
                <DropArea
                  id="dropArea3"
                  class="dropArea"
                  caption="Upuść plik tutaj albo kliknij 'Wybierz plik' poniżej"
                  :fileInputId="PROTOCOL_FILE_INPUT_ID"
                  @onFileChanged="onDropAreaFilesChanged($event)"
                />
                <input
                  :id="PROTOCOL_FILE_INPUT_ID"
                  name="file"
                  type="file"
                  class="mt-2"
                  @change="onFilesChanged($event)"
                />
                <button class="btn btn-primary mt-2 float-right" type="submit">
                  Prześlij dane
                </button>
              </div>

              <div v-show="showFileInput && addToMultipleOrdersEnabled" class="col-12 mt-4 p-0">
                <GeodeticProtocolAggregateAssigning
                  ref="additionalOrdersComponent"
                  :initialOrders="[order]"
                />
              </div>
            </div>
          </form>

          <GeodeticProtocolsTable
            :key="geodeticProtocolsTableKey"
            :orderId="props.orderId"
            @onGeodeticProtocolsFetched="onGeodeticProtocolsFetched"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
