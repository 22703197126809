<script setup>
import { APP_PRIMARY_COLOR } from '../../data/constants/appConstants';
import { isNotEmptyArray } from '../../helpers/utilsHelper.js';
const props = defineProps({ filesArray: null });
const emit = defineEmits(['fileDeleted']);

function getFileIcon(file) {
  if (file.type === 'application/pdf')
    return `<i class='fa fa-file-pdf-o' style='color: #${APP_PRIMARY_COLOR}77; font-size: 20px'/>`;

  if (file.type.includes('image'))
    return `<i class='fa fa-file-image-o' style='color: #${APP_PRIMARY_COLOR}77; font-size: 20px'/>`;

  return `<i class='fa fa-file' style='color: #${APP_PRIMARY_COLOR}77; font-size: 20px'/>`;
}

async function deleteFile(fileToDelete) {
  emit('fileDeleted', fileToDelete.id);
}
</script>

<template>
  <div v-if="isNotEmptyArray(props.filesArray)">
    <h5 class="mt-3 mb-3">Dodane pliki</h5>
    <div style="display: flex; flex-direction: column">
      <div
        v-for="fileObj in props.filesArray"
        :key="fileObj.id"
        class="file"
        style="margin-bottom: 10px"
      >
        <div class="row m-0" style="display: flex; justify-content: center; align-items: center">
          <div class="col-auto" style="height: 20px">
            <span v-html="getFileIcon(fileObj.file)" />
          </div>

          <div
            class="file-name col"
            style="overflow-wrap: break-word; width: 200px; border-top: none"
          >
            {{ fileObj.file?.name ?? '-' }}
          </div>
          <div style="background-color: #f8f8f8">
            <button
              type="button"
              class="btn btn-white btn-xs"
              style="margin-right: 2px; margin-top: 2px"
              title="Usuń plik"
              @click="deleteFile(fileObj)"
              onclick="event.stopPropagation()"
            >
              <i class="fa fa-trash-o" style="color: gray"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
