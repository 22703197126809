<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
  steps: { type: Array, required: true },
  onFinishAction: { type: Function, required: true }
});

const currentStepNumber = ref(1);

const currentStep = computed(() => {
  return props.steps.find((step) => step.number === currentStepNumber.value);
});

const previousStep = computed(() => {
  return props.steps.find((step) => step.number === currentStepNumber.value - 1);
});

const nextStep = computed(() => {
  return props.steps.find((step) => step.number === currentStepNumber.value + 1);
});

function onPreviousStepClicked() {
  if (!previousStep.value) return;

  if (currentStep.value.onPreviousAction) {
    currentStep.value?.onPreviousAction();
  }

  currentStepNumber.value--;
}

function onNextStepClicked() {
  if (!nextStep.value) return;

  if (currentStep.value.onNextAction) {
    currentStep.value?.onNextAction();
  }

  currentStepNumber.value++;
}

function onFinishClicked() {
  if (props.onFinishAction) {
    props.onFinishAction();
  }
}

function goToPreviousStep() {
  onPreviousStepClicked();
}

function goToNextStep() {
  onNextStepClicked();
}

defineExpose({ goToPreviousStep, goToNextStep });
</script>

<template>
  <div class="ibox">
    <div class="ibox-content">
      <div class="row">
        <div class="col-12 wizard-header">
          <div
            v-for="step in props.steps"
            :key="step.number"
            class="btn btn-primary wizard-header-item"
            :class="step.number === currentStepNumber ? '' : 'wizard-inactive-header-item'"
          >
            {{ step.header }}
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="ibox">
            <div class="ibox-content" style="border-style: none">
              <slot :name="`wizardContent${currentStepNumber}`"></slot>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 wizard-footer">
          <slot :name="`wizardButtons${currentStepNumber}`"></slot>
          <button
            v-if="previousStep"
            class="btn btn-primary"
            :disabled="currentStep.previousButtonDisabled"
            @click="onPreviousStepClicked"
          >
            Wstecz
          </button>
          <button
            class="btn btn-primary"
            :disabled="currentStep.nextButtonDisabled"
            @click="nextStep ? onNextStepClicked() : onFinishClicked()"
          >
            <span v-if="nextStep">Dalej</span>
            <span v-else>Zakończ</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.wizard-header {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px 10px;
}

.wizard-footer {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px 10px;
}

.wizard-header-item {
  flex-grow: 1;
  pointer-events: none;
}

.wizard-inactive-header-item {
  background-color: #6f9dd1a1;
  border-style: none;
}
</style>
