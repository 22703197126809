<script setup>
import { ref, watch } from 'vue';
import DictionaryTable from '../dictionaries/DictionaryTable.vue';

const props = defineProps({ orderId: Number });
const emit = defineEmits(['onDataFetched', 'addressPicked']);
const dictTableKey = ref(0);

const tableColumns = [
  {
    key: 'converted_to_dem_point_at',
    header: 'Przekonwertowano',
    computed: true,
    tdStyle: 'padding-left: 20px;'
  },
  { key: 'address', header: 'Adres' },
  { key: 'quantity', header: 'Ilość' }
];

watch(
  () => props.orderId,
  () => dictTableKey.value++
);

function onDataFetched(dataObject) {
  emit('onDataFetched', dataObject);
}

function onAddressRowPicked(addressRow) {
  emit('addressPicked', addressRow);
}
</script>

<template>
  <DictionaryTable
    :key="dictTableKey"
    v-if="props.orderId != null"
    :showHeader="false"
    :showBorder="false"
    :url="`order/${props.orderId}/details_web_addresses`"
    :columns="tableColumns"
    :customButtons="true"
    @dataFetched="onDataFetched"
  >
    <template #converted_to_dem_point_at="{ element }">
      <span v-if="element.converted_to_dem_point_at"><i class="fa fa-check text-navy" /></span>
      <span v-else>-</span>
    </template>

    <template #buttons="{ element }">
      <button
        type="button"
        class="btn btn-outline-info ml-2"
        @click="onAddressRowPicked(element)"
        onclick="event.stopPropagation()"
      >
        Wybierz
      </button>
    </template>
  </DictionaryTable>
</template>

<style scoped></style>
