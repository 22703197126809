<script setup>
import { ref, computed, onMounted } from 'vue';
import { useMapLogic } from '../../composables/mapLogic';
import { getDemPointsForMapMarkers } from '../../helpers/actions/actionMarkersHelper';
import { isNotEmptyArray } from '../../helpers/utilsHelper';
import ClusterMap from '../map/ClusterMap.vue';
import FormErrorPanel from '../utils/FormErrorPanel.vue';
import { useAuthStore } from '../../stores/auth';

const props = defineProps({
  demPointsOfTypePoleForMap: { type: Array, default: null },
  location: { type: Object, default: null },
  formErrors: { type: Array, default: () => [] },
  initiallySelectedPoles: { type: Array, default: () => [] }
});

const { clusterMap, onSelectedMarkersChanged, onSingleMarkerSelected, resetSelectedIfCtrl } =
  useMapLogic();

const isLoadingMap = ref(false); //TODO probably to delete
const isLoadingRightPanel = ref(false); //TODO probably to delete
const authStore = useAuthStore();

onMounted(() => {
  prepareMarkersForMap();
});

const emit = defineEmits(['goToTheFirstStep', 'updateOSDAction']);

const selectedDemPointsIdsAndTags = computed(() => {
  if (!isNotEmptyArray(clusterMap.value?.markersRef)) return [];

  let selectedIdsAndTags = [];

  clusterMap.value.markersRef.forEach((marker) => {
    if (marker.data.selected)
      selectedIdsAndTags.push({ id: marker.markerId, oznaczenie: marker.data.demPointTag });
  });

  return selectedIdsAndTags;
});

function onGoToTheFirstStepButtonClicked() {
  emit('goToTheFirstStep');
}

function prepareMarkersForMap() {
  const markers = getMarkers();
  selectPreviouslySelectedMarkers(markers);
  clusterMap.value.initializeMap({ markersData: markers });
}

function selectPreviouslySelectedMarkers(markers) {
  if (!isNotEmptyArray(markers)) return;
  if (!isNotEmptyArray(props.initiallySelectedPoles)) return;

  markers.forEach((marker) => {
    if (
      props.initiallySelectedPoles.some((pole) => pole.id != null && pole.id === marker.markerId)
    ) {
      marker.selected = true;
    }
  });
}

function getMarkers() {
  const demPointsForMapMarkers = getDemPointsForMapMarkers(
    props.demPointsOfTypePoleForMap,
    [],
    [],
    [],
    [],
    false,
    true,
    false,
    false,
    '',
    false,
    authStore,
    false,
    { selectableActionsOverride: null, selectableDemPointsOverride: null }
  );

  const locationMarker = getLocationMarker();

  return demPointsForMapMarkers.concat(locationMarker);
}

function getLocationMarker() {
  if (props.location == null) return [];

  let marker = {
    lat: props.location.lat,
    lng: props.location.lon,
    popupCaption: null,
    draggable: false,
    selected: false,
    selectable: false,
    markerId: 0
  };

  return [marker];
}

function onAddOSDActionButtonClicked() {
  emit('updateOSDAction', selectedDemPointsIdsAndTags.value);
}
</script>

<template>
  <div class="row">
    <div class="ibox col-lg-8 pr-1">
      <div
        class="ibox-content"
        :class="{ 'sk-loading': isLoadingMap }"
        style="border-style: none; height: 750px"
      >
        <div class="sk-spinner sk-spinner-three-bounce">
          <div class="sk-bounce1"></div>
          <div class="sk-bounce2"></div>
          <div class="sk-bounce3"></div>
        </div>

        <ClusterMap
          ref="clusterMap"
          height="700px"
          :showZeroPositions="false"
          :disableClusteringAtZoomOverride="19"
          :maxClusterRadiusOverride="1"
          :areaSelecting="true"
          @selected-markers-changed="onSelectedMarkersChanged"
          @map-clicked="resetSelectedIfCtrl"
          @markerClicked="onSingleMarkerSelected"
        />
      </div>
    </div>

    <div class="ibox col-lg-4 pl-1">
      <div
        class="ibox-content profile-content pl-4"
        :class="{ 'sk-loading': isLoadingRightPanel }"
        style="border-style: none; min-height: 750px"
      >
        <div class="sk-spinner sk-spinner-three-bounce">
          <div class="sk-bounce1"></div>
          <div class="sk-bounce2"></div>
          <div class="sk-bounce3"></div>
        </div>

        <FormErrorPanel :errors="props.formErrors" />

        <div class="row">
          <div class="col-6">
            <h4>Powiązane słupy ({{ selectedDemPointsIdsAndTags.length }})</h4>

            <span
              v-for="selectedDemPoint in selectedDemPointsIdsAndTags"
              :key="selectedDemPoint.id"
            >
              {{ selectedDemPoint.oznaczenie }} <br />
            </span>
          </div>
        </div>

        <div class="row">
          <div class="col-12 mt-3">
            <button
              :disabled="selectedDemPointsIdsAndTags.length === 0"
              @click="onAddOSDActionButtonClicked"
              class="btn btn-primary float-right"
            >
              Prześlij dane
            </button>
            <button
              @click="onGoToTheFirstStepButtonClicked"
              class="btn btn-primary float-right mr-2"
            >
              Wstecz
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
