<script setup>
/* eslint-env jquery */
import { ref } from 'vue';

const props = defineProps({ maxWidth: { type: String, default: '80%' } });
const modalBaseRef = ref();

function show() {
  $(modalBaseRef.value).modal('show');
}

function hide() {
  $(modalBaseRef.value).modal('hide');
}

defineExpose({ show, hide });
</script>

<template>
  <div ref="modalBaseRef" class="modal inmodal fade" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg" :style="'max-width:' + props.maxWidth + ';'">
      <div class="modal-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
