<script setup>
/* eslint-env jquery */
import { ref, watch, nextTick } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import FormErrorPanel from '../utils/FormErrorPanel.vue';
import { createToaster } from '@meforma/vue-toaster';
import { TOASTER_SERVER_ERROR_MSG } from '../../data/constants/toasterMessagesConstants';
import { getDateWithoutTime, transformDateToYYYYMM } from '../../helpers/dateFormatHelper';

const RepositoryAcceptanceProtocols = RepositoryFactory.get('acceptanceProtocols');
const props = defineProps({ orderId: Number, acceptanceProtocol: Object });
const toaster = createToaster({ position: 'top-right', duration: 3000 });
const isLoading = ref(false);
const formRef = ref();
const formErrors = ref([]);
const emit = defineEmits(['succeeded']);
const PONumber = ref(null);
const amount = ref(null);
const billingMonth = ref(null);

watch(
  () => props.acceptanceProtocol,
  async (newValue) => {
    if (newValue != null) {
      await nextTick();

      const datepickerConfig = {
        todayBtn: 'linked',
        keyboardNavigation: true,
        forceParse: true,
        calendarWeeks: true,
        autoclose: true,
        weekStart: 1,
        format: 'yyyy-mm',
        minViewMode: 'months'
      };

      PONumber.value = props.acceptanceProtocol.number;
      amount.value = props.acceptanceProtocol.amount;
      billingMonth.value =
        props.acceptanceProtocol.billing_month != null
          ? transformDateToYYYYMM(getDateWithoutTime(props.acceptanceProtocol.billing_month))
          : null;

      $('#billingMonthFormGroupId .input-group.date').datepicker(datepickerConfig);
      $('#billingMonthFormGroupId .input-group.date').datepicker('setDate', billingMonth.value);
      $('#billingMonthFormGroupId .input-group.date')
        .datepicker()
        .on('changeDate', function () {
          billingMonth.value = $(this).datepicker('getFormattedDate', 'yyyy-mm');
        });
    }
  },
  { immediate: true }
);

function requestSubmit() {
  formRef.value.requestSubmit();
}

function submitForm(e) {
  e.preventDefault();

  sendRequest();
}

async function sendRequest() {
  isLoading.value = true;

  var response = await RepositoryAcceptanceProtocols.updateAP(
    props.acceptanceProtocol.id,
    PONumber.value,
    amount.value,
    billingMonth.value
  );

  if (response?.serverError) {
    toaster.show(TOASTER_SERVER_ERROR_MSG, { duration: 3000, type: 'error' });
  } else {
    if (response) {
      formErrors.value = response;
    } else {
      toaster.show('Protokół odbioru został zaktualizowany.', { duration: 3000, type: 'success' });
      emit('succeeded');
    }
  }

  isLoading.value = false;
}

defineExpose({ requestSubmit });
</script>

<template>
  <div class="ibox">
    <div class="ibox-content" :class="{ 'sk-loading': isLoading }" style="border-style: none">
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <FormErrorPanel :errors="formErrors" />

      <form ref="formRef" v-if="props.acceptanceProtocol" @submit="submitForm">
        <div class="form-group">
          <label>Numer</label>
          <input
            type="text"
            placeholder="Wpisz numer"
            v-model="PONumber"
            class="form-control"
            required
            minlength="1"
            maxlength="100"
          />
        </div>

        <div class="form-group">
          <label>Liczba elementów</label>
          <input
            type="number"
            @mousewheel.prevent=""
            placeholder="Wpisz liczbę"
            v-model="amount"
            class="form-control"
            required
            min="0"
            max="999999999"
          />
        </div>

        <div class="form-group" id="billingMonthFormGroupId">
          <label>Miesiąc rozliczeniowy</label>
          <div class="input-group date">
            <span class="input-group-addon" :style="'background-color: #e9ecef'"
              ><i class="fa fa-calendar"></i></span
            ><input
              id="billingMonthInputId"
              type="text"
              class="form-control"
              v-model="billingMonth"
              required
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped></style>
