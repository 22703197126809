import repository from './repository.js';

export default {
  async activateDemPoint(demPointId) {
    return repository
      .get(`/dempoint/${demPointId}/activate`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async deactivateDemPoint(demPointId) {
    return repository
      .get(`/dempoint/${demPointId}/deactivate`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async deleteDemPoint(id) {
    return repository
      .delete(`/dempoint/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async getDemPointDataToEdit(id) {
    return repository.get(`/dempoint/${id}/edit`).then((response) => {
      if (response.data.error == null) return response.data.data;
    });
  },

  async updateDemPoint(demPoint) {
    return repository
      .put(`/dempoint/${demPoint.id}`, demPoint)
      .then((response) => {
        return response?.data?.message ?? null;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async deactivateGroupOfDemPoints(orderId, demPointsIds) {
    return repository
      .post(`/dempoint/deactivateGroup`, { id_order: orderId, dem_points: demPointsIds })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async changeNoteForDemPoints(orderId, demPointsIds, note) {
    return repository
      .post(`/dempoint/changeNoteForGroup`, { id_order: orderId, dem_points: demPointsIds, note })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  }
};
