import repository from './repository.js';
// import { getLocalFormattedDateAndTime } from "@/helpers/dateFormatHelper.js";

export default {
  async getOrderContractorsObject(
    page,
    itemsPerPage,
    search,
    sortBy,
    orderType,
    filter,
    userFilter
  ) {
    let url = '/orderContractor';

    if (filter && filter.subject && filter.id != null)
      url = `/${filter.subject}/${filter.id}/orderContractors`;
    else if (filter && filter.url) url = filter.url;

    let userFilterUrlEnding = '';

    if (userFilter) {
      for (const property in userFilter) {
        userFilterUrlEnding += `&${property}=${userFilter[property]}`;
      }
    }

    return repository
      .get(
        `${url}?page=${page}&search=${search}&orderBy=${sortBy}&orderType=${orderType}&paginate=${itemsPerPage}${userFilterUrlEnding}`
      )
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((error) => {
        return { error };
      });
  },

  async getOrderContractorDetailsGeneral(id) {
    return repository.get(`/userorder/${id}/details`).then((response) => {
      if (response.data.error == null) return response.data.data;
    });
  },

  async cancelOrderForContractor(orderId, orderContractorId, creation_date) {
    return repository
      .post(`/order/${orderId}/cancelOrder`, {
        id_user_order: orderContractorId,
        lat: 0.0,
        lon: 0.0,
        creation_date
      })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async endOrderForContractor(orderId, orderContractorId, creation_date) {
    return repository
      .post(`/order/${orderId}/stopOrder`, {
        id_user_order: orderContractorId,
        lat: 0.0,
        lon: 0.0,
        creation_date
      })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  //TODO to delete probably
  async deleteAction(orderId, orderContractorId, actionId, actionTypeId) {
    return repository
      .post(`/order/removeAction`, {
        id_order: orderId,
        id_user_order: orderContractorId,
        id: actionId,
        id_action_type: actionTypeId
      })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async changeUserOrderLNPresent(id) {
    return repository.get(`/userorder/${id}/changeLNPresentStatus`).then((response) => {
      if (response.data.error == null) return response.data.data;
    });
  }
};
