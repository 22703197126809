import { ref } from 'vue';
import { defineStore } from 'pinia';
import { PAGINATION_INITIAL_ITEMS_PER_PAGE } from '../data/constants/appConstants';

export const usePaginationStore = defineStore('pagination', () => {
  const paginationState = ref(localStorage.getItem('paginationState'));

  function setPaginationItemsCount(value, paginationKey) {
    let currentPaginationState = paginationState.value ? JSON.parse(paginationState.value) : {};

    if (paginationKey) {
      if (!currentPaginationState.custom) {
        currentPaginationState.custom = {};
      }

      currentPaginationState.custom[paginationKey] = value;
    } else {
      currentPaginationState.global = value;
    }

    paginationState.value = JSON.stringify(currentPaginationState);
    localStorage.setItem('paginationState', paginationState.value);
  }

  function getPaginationItemsCount(paginationKey) {
    let currentPaginationState = paginationState.value ? JSON.parse(paginationState.value) : {};

    if (currentPaginationState == null) return PAGINATION_INITIAL_ITEMS_PER_PAGE;

    if (paginationKey && currentPaginationState?.custom?.[paginationKey]) {
      return currentPaginationState.custom[paginationKey];
    }

    return currentPaginationState.global ?? PAGINATION_INITIAL_ITEMS_PER_PAGE;
  }

  return { getPaginationItemsCount, setPaginationItemsCount };
});
