<script setup>
import { ref, watch } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { isNotEmptyArray } from '../../helpers/utilsHelper';
import { APP_PRIMARY_COLOR } from '../../data/constants/appConstants';
import { useFilesHandler } from '../../composables/filesHandler';
import { createToaster } from '@meforma/vue-toaster';
import { TOASTER_SERVER_ERROR_MSG } from '../../data/constants/toasterMessagesConstants';
import ClusterMap from '@/components/map/ClusterMap.vue';
import DropArea from '../utils/DropArea.vue';
import FilesTable from '../files/FilesTable.vue';
import FormErrorPanel from '@/components/utils/FormErrorPanel.vue';
import { useMapLogic } from '../../composables/mapLogic';

const RepositoryTransportOrders = RepositoryFactory.get('transportOrders');
const RepositoryDictionaries = RepositoryFactory.get('dictionaries');
const BEFORE_FILES_INPUT_ID = 'fileInputFieldBefore';
const AFTER_FILES_INPUT_ID = 'fileInputFieldAfter';
const DEFAULT_INITIAL_LAT = 50.29044;
const DEFAULT_INITIAL_LON = 21.42891;

const props = defineProps({ orderId: Number, userOrderId: Number });
const emit = defineEmits(['succeeded']);
const toaster = createToaster({ position: 'top-right', duration: 3000 });
const isLoadingMap = ref(false);
const isLoadingForm = ref(false);
const beforeFilesHandler = useFilesHandler(true, BEFORE_FILES_INPUT_ID);
const afterFilesHandler = useFilesHandler(true, AFTER_FILES_INPUT_ID);
const { clusterMap } = useMapLogic();

// form data
const markerPosition = ref(null);
const gatheredStorageTypesFormData = ref(null);
const leftStorageTypesFormData = ref(null);
const beforeFilesForTable = ref([]);
const afterFilesForTable = ref([]);
const formErrors = ref([]);

watch(beforeFilesHandler.filesRef, () => {
  recomputeFilesForBeforeTable();
});

function recomputeFilesForBeforeTable() {
  beforeFilesForTable.value = beforeFilesForTable.value.concat(beforeFilesHandler.filesRef.value);

  for (let i = 0; i < beforeFilesForTable.value.length; i++) {
    beforeFilesForTable.value[i].id = i;
  }
}

watch(afterFilesHandler.filesRef, () => {
  recomputeFilesForAfterTable();
});

function recomputeFilesForAfterTable() {
  afterFilesForTable.value = afterFilesForTable.value.concat(afterFilesHandler.filesRef.value);

  for (let i = 0; i < afterFilesForTable.value.length; i++) {
    afterFilesForTable.value[i].id = i;
  }
}

async function initializeMarker() {
  isLoadingMap.value = true;

  const coords = await RepositoryTransportOrders.getTransportOrderNewActionInitialLocation(
    props.orderId
  );

  let lat = coords?.lat ?? DEFAULT_INITIAL_LAT;
  let lon = coords?.lon ?? DEFAULT_INITIAL_LON;

  const markers = [
    {
      lat: lat,
      lng: lon,
      draggable: true,
      selected: false
    }
  ];

  markerPosition.value = { lat, lon };
  isLoadingMap.value = false;
  clusterMap.value.initializeMap({ markersData: markers });
}

async function fetchStorageTypes() {
  isLoadingForm.value = true;

  RepositoryDictionaries.getDictionaryElementsObject(1, 100, '', '', '', 'storagetype')
    .then((data) => {
      setStorageTypesFormData(data);
    })
    .catch((error) => console.log(error))
    .finally(() => {
      isLoadingForm.value = false;
    });
}

function setStorageTypesFormData(storageTypesObject) {
  if (!isNotEmptyArray(storageTypesObject?.data)) {
    gatheredStorageTypesFormData.value = [];
    leftStorageTypesFormData.value = [];
    return;
  }

  gatheredStorageTypesFormData.value = storageTypesObject.data.map((type) => {
    return { id_storage_type: type.id, name: type.name, number: 0 };
  });

  leftStorageTypesFormData.value = storageTypesObject.data.map((type) => {
    return { id_storage_type: type.id, name: type.name, number: 0 };
  });
}

function onMarkerDragged(marker) {
  markerPosition.value = { lat: marker.lat, lon: marker.lng };
}

function onBeforeFileDeleted(id) {
  beforeFilesForTable.value = beforeFilesForTable.value.filter((x) => x.id !== id);
}

function onAfterFileDeleted(id) {
  afterFilesForTable.value = afterFilesForTable.value.filter((x) => x.id !== id);
}

function submitForm(e) {
  e.preventDefault();
  createTransportAction();
}

async function createTransportAction() {
  isLoadingForm.value = true;

  const photosBefore = !isNotEmptyArray(beforeFilesForTable.value)
    ? []
    : beforeFilesForTable.value.map((photo) => photo.file);
  const photosAfter = !isNotEmptyArray(afterFilesForTable.value)
    ? []
    : afterFilesForTable.value.map((photo) => photo.file);

  var response = await RepositoryTransportOrders.createTransportAction(
    props.orderId,
    props.userOrderId,
    markerPosition.value,
    gatheredStorageTypesFormData.value,
    leftStorageTypesFormData.value,
    photosBefore,
    photosAfter
  );

  if (response?.serverError) {
    toaster.show(TOASTER_SERVER_ERROR_MSG, { type: 'error' });
  } else {
    if (response?.id != null) {
      toaster.show('Akcja transportowa została utworzona.', { type: 'success' });
      emit('succeeded');
    } else {
      formErrors.value = response;
    }
  }

  isLoadingForm.value = false;
}

// created
fetchStorageTypes();
initializeMarker();
</script>

<template>
  <div class="row">
    <div class="ibox col-lg-6 pr-1">
      <div class="ibox-content" :class="{ 'sk-loading': isLoadingMap }" style="border-style: none">
        <div class="sk-spinner sk-spinner-three-bounce">
          <div class="sk-bounce1"></div>
          <div class="sk-bounce2"></div>
          <div class="sk-bounce3"></div>
        </div>

        <ClusterMap ref="clusterMap" height="700px" @markerDragged="onMarkerDragged" />
      </div>
    </div>

    <div class="ibox col-lg-6 pl-1">
      <div
        class="ibox-content profile-content pl-4"
        :class="{ 'sk-loading': isLoadingForm }"
        style="border-style: none"
      >
        <div class="sk-spinner sk-spinner-three-bounce">
          <div class="sk-bounce1"></div>
          <div class="sk-bounce2"></div>
          <div class="sk-bounce3"></div>
        </div>

        <FormErrorPanel :errors="formErrors" />

        <form @submit="submitForm">
          <div class="row">
            <div class="col-6">
              <h4>Zebrano</h4>
              <div
                v-if="isNotEmptyArray(gatheredStorageTypesFormData)"
                class="mt-3"
                :style="`border-bottom: 1px dashed #${APP_PRIMARY_COLOR}`"
              >
                <div
                  v-for="storageType in gatheredStorageTypesFormData"
                  :key="storageType.id_storage_type"
                  class="form-group"
                >
                  <label>{{ storageType.name }}</label>
                  <input
                    type="number"
                    @mousewheel.prevent=""
                    placeholder="-"
                    v-model="storageType.number"
                    class="form-control"
                    min="0"
                    max="999999999"
                    required
                  />
                </div>
              </div>
            </div>

            <div class="col-6">
              <h4>Pozostawiono</h4>
              <div
                v-if="isNotEmptyArray(leftStorageTypesFormData)"
                class="mt-3"
                :style="`border-bottom: 1px dashed #${APP_PRIMARY_COLOR}`"
              >
                <div
                  v-for="storageType in leftStorageTypesFormData"
                  :key="storageType.id_storage_type"
                  class="form-group"
                >
                  <label>{{ storageType.name }}</label>
                  <input
                    type="number"
                    @mousewheel.prevent=""
                    placeholder="-"
                    v-model="storageType.number"
                    class="form-control"
                    min="0"
                    max="999999999"
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="col-12 mb-3">
                <FilesTable :filesArray="beforeFilesForTable" @fileDeleted="onBeforeFileDeleted" />
              </div>

              <div class="col-12">
                <h5>Wczytaj pliki przed</h5>

                <DropArea
                  id="dropAreaFilesBefore"
                  class="dropArea"
                  caption="Upuść pliki tutaj albo kliknij 'Wybierz plik' poniżej"
                  :fileInputId="BEFORE_FILES_INPUT_ID"
                  @onFileChanged="beforeFilesHandler.onDropAreaFilesChanged($event)"
                />
                <input
                  :id="BEFORE_FILES_INPUT_ID"
                  name="file"
                  type="file"
                  class="mt-2"
                  multiple
                  @change="beforeFilesHandler.onFilesChanged($event)"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="col-12 mb-3">
                <FilesTable :filesArray="afterFilesForTable" @fileDeleted="onAfterFileDeleted" />
              </div>

              <div class="col-12">
                <h5>Wczytaj pliki po</h5>

                <DropArea
                  id="dropAreaFilesAfter"
                  class="dropArea"
                  caption="Upuść pliki tutaj albo kliknij 'Wybierz plik' poniżej"
                  :fileInputId="AFTER_FILES_INPUT_ID"
                  @onFileChanged="afterFilesHandler.onDropAreaFilesChanged($event)"
                />
                <input
                  :id="AFTER_FILES_INPUT_ID"
                  name="file"
                  type="file"
                  class="mt-2"
                  multiple
                  @change="afterFilesHandler.onFilesChanged($event)"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <button class="btn btn-primary mt-3 float-right" type="submit">Prześlij dane</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
