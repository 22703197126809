import {
  COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME,
  GO_TO_ORDER_DETAILS_BUTTON_TYPE_NAME,
  REMOVE_LOCATION_BUTTON_TYPE_NAME,
  SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME,
  UPDATE_LOCATION_BUTTON_TYPE_NAME
} from '../data/constants/buttonsNamesConstants';
import { isNotEmptyArray, isNullOrEmptyString } from './utilsHelper';

export function getLocationMarkerTooltip(location) {
  if (location?.lat == null || location?.lon == null) return null;

  const lat = location.lat;
  const lon = location.lon;

  const copyCoordsButton = `<button clickable type='button' class="btn btn-white btn-xs ml-1" id='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}:${location?.id}:coords' lat='${lat}' lon='${lon}' click_action_type='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}'><i class="fa fa-copy" clickable_child></i></button>`;
  const navigateButton = `<button clickable type='button' class="btn btn-white btn-xs ml-1" id='${SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME}:${location?.id}' lat='${lat}' lon='${lon}' click_action_type='${SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME}'><i class="fa fa-google" clickable_child></i></button>`;
  const coordsSection = `${lat}, ${lon} ` + copyCoordsButton + navigateButton;

  const tag = location?.oznaczenie ? '<br/><b>' + location?.oznaczenie + '</b>' : '';
  const copyTagButton =
    tag === ''
      ? ''
      : `<button clickable type='button' class="btn btn-white btn-xs ml-1" id='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}:${location?.id}:tag' tag='${location.oznaczenie}'click_action_type='${COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME}'><i class="fa fa-copy" clickable_child></i></button>`;
  const tagSection = tag + copyTagButton;

  let noteSection = location.note ? `<br/>Uwagi: <i>${location.note}</i>` : '';

  const editButton = `<button clickable type='button' class='btn btn-outline btn-info btn-xs mr-1' id='${UPDATE_LOCATION_BUTTON_TYPE_NAME}:${location?.id}' id_location='${location?.id}' click_action_type='${UPDATE_LOCATION_BUTTON_TYPE_NAME}'>Edytuj</button>`;
  const removeButton = `<button clickable type='button' class='btn btn-outline btn-danger btn-xs mr-1' id='${REMOVE_LOCATION_BUTTON_TYPE_NAME}:${location?.id}' id_location='${location?.id}' click_action_type='${REMOVE_LOCATION_BUTTON_TYPE_NAME}'>Usuń</button>`;
  const buttonsSection = `<br/><div class='mt-1'>` + editButton + removeButton + '</div>';

  return (
    coordsSection + tagSection + noteSection + getTooltipWarningsSection(location) + buttonsSection
  );
}

function getTooltipWarningsSection(location) {
  if (location.warning_marker !== 1 || isNullOrEmptyString(location.warnings)) return '';

  const warningsArray = JSON.parse(location.warnings);
  if (!isNotEmptyArray(warningsArray)) return '';

  let warningsSection = '<br/><br/>Ostrzeżenia:';
  warningsSection += '<ol>';

  warningsArray.forEach((warning) => {
    warningsSection += `<br/><li>${warning.message}`;

    if (warning.other_id_order != null) {
      warningsSection += getGoToOrderDetailsButtonHtml(location, warning);
    }

    warningsSection += '</li>';
  });
  warningsSection += '</ol>';

  return warningsSection;
}

function getGoToOrderDetailsButtonHtml(location, warning) {
  const orderId = warning?.other_id_order;
  const demPointId = warning?.other_id_dem_point;

  if (orderId == null) return '';

  return ` (<span class='fake-link' style='white-space: nowrap' clickable id='${GO_TO_ORDER_DETAILS_BUTTON_TYPE_NAME}:${location?.id}' id_order='${orderId}' id_demPoint='${demPointId}' click_action_type='${GO_TO_ORDER_DETAILS_BUTTON_TYPE_NAME}'>Zobacz zlecenie</span>)`;
}
