<script setup>
import { ref, computed, watch, onMounted } from 'vue';
/* eslint-disable no-undef */
import {
  MODAL_USERS_TABLE,
  MODAL_ORDER_STATUSES_TABLE,
  MODAL_ORDER_TYPES_TABLE,
  MODAL_DIRECTIONS_TABLE,
  MODAL_REGIONS_TABLE,
  MODAL_BOROUGHS_TABLE,
  MODAL_COUNTIES_TABLE,
  MODAL_ORDER_INTERRUPTION_STATUSES_TABLE,
  MODAL_ORDER_RANGES_TABLE
} from '@/data/constants/modalConstants.js';
import ModalInputField from '@/components/utils/ModalInputField.vue';
import ModalTable from '@/components/utils/ModalTable.vue';
import { useAuthStore } from '../../stores/auth';
import { ACCESS_KEY_ORDERS_TABLE_FILTERS_ORDER_STATUS_FILTER } from '../../data/constants/authConstants';
import { isNotEmptyArray, generateRandomString } from '../../helpers/utilsHelper';
import DateRangeInput from '../utils/DateRangeInput.vue';
import { MODAL_ORDER_INITIATIVES_TABLE } from '../../data/constants/modalConstants';

const COORDINATORS_MODAL_URL = 'user/coordinators';
const WORKERS_MODAL_URL = 'user/workers';
const COORDINATORS_MODAL_USE_CASE_ID = 'coordinators_modal_use_case_id';
const WORKERS_MODAL_USE_CASE_ID = 'workers_modal_use_case_id';
const LAST_WORKERS_MODAL_USE_CASE_ID = 'last_workers_modal_use_case_id';
const AUTHORS_MODAL_USE_CASE_ID = 'authors_modal_use_case_id';

const props = defineProps({
  longerSearchBar: { type: Boolean, default: false },
  userFilterEnabled: { type: Boolean, default: true },
  hideSearchBar: { type: Boolean, default: false },
  oneFilterPerRow: { type: Boolean, default: false },
  filterPanel: { type: Object, default: null },
  filterPanelDisplay: { type: Object, default: null },
  appliedFilter: { type: Object, default: null },
  searchBox: { type: String, default: '' },
  customSearchBarCssClass: { type: String, default: null }
});

const emit = defineEmits([
  'updateFilterPanel',
  'updateFilterPanelDisplay',
  'fetchData',
  'searchChanged'
]);

const searchString = ref(null);
const modalTable = ref();
const priorityChange = ref(props.filterPanel.priorityChange);
const claim = ref(props.filterPanel.claim);
const lnPresent = ref(props.filterPanel.lnPresent);
const authStore = useAuthStore();
const componentInstanceId = generateRandomString(15);

const filtersCount = computed(() => {
  let count = 0;

  for (const property in props.appliedFilter) {
    if (isFilterDateRange(props.appliedFilter[property])) {
      if (
        props.appliedFilter[property].startDate != null ||
        props.appliedFilter[property].endDate != null
      )
        count++;
    } else {
      if (props.appliedFilter[property] != null) count++;
    }
  }

  return count;
});

function isFilterDateRange(filter) {
  return filter?.startDate !== undefined && filter?.endDate !== undefined;
}

const coordinatorsApplied = computed(() => {
  return multifilterApplied(props.filterPanel?.coordinators, props.appliedFilter?.coordinators);
});

const orderStatusesApplied = computed(() => {
  return multifilterApplied(props.filterPanel?.orderStatuses, props.appliedFilter?.orderStatuses);
});

const orderTypesApplied = computed(() => {
  return multifilterApplied(props.filterPanel?.orderTypes, props.appliedFilter?.orderTypes);
});

const priorityChangeApplied = computed(() => {
  return filterApplied(props.filterPanel?.priorityChange, props.appliedFilter?.priorityChange);
});

const directionsApplied = computed(() => {
  return multifilterApplied(props.filterPanel?.directions, props.appliedFilter?.directions);
});

const regionsApplied = computed(() => {
  return multifilterApplied(props.filterPanel?.regions, props.appliedFilter?.regions);
});

const boroughsApplied = computed(() => {
  return multifilterApplied(props.filterPanel?.boroughs, props.appliedFilter?.boroughs);
});

const countiesApplied = computed(() => {
  return multifilterApplied(props.filterPanel?.counties, props.appliedFilter?.counties);
});

const interruptionStatusesApplied = computed(() => {
  return multifilterApplied(
    props.filterPanel?.interruptionStatuses,
    props.appliedFilter?.interruptionStatuses
  );
});

const orderRangesApplied = computed(() => {
  return multifilterApplied(props.filterPanel?.orderRanges, props.appliedFilter?.orderRanges);
});

const claimApplied = computed(() => {
  return filterApplied(props.filterPanel?.claim, props.appliedFilter?.claim);
});

const lnPresentApplied = computed(() => {
  return filterApplied(props.filterPanel?.lnPresent, props.appliedFilter?.lnPresent);
});

const receiveDateApplied = computed(() => {
  return (
    filterApplied(
      props.filterPanel?.receiveDate?.startDate,
      props.appliedFilter?.receiveDate?.startDate
    ) &&
    filterApplied(
      props.filterPanel?.receiveDate?.endDate,
      props.appliedFilter?.receiveDate?.endDate
    )
  );
});

const deadlineApplied = computed(() => {
  return (
    filterApplied(
      props.filterPanel?.deadline?.startDate,
      props.appliedFilter?.deadline?.startDate
    ) && filterApplied(props.filterPanel?.deadline?.endDate, props.appliedFilter?.deadline?.endDate)
  );
});

const acceptanceProtocolCreationDateApplied = computed(() => {
  return (
    filterApplied(
      props.filterPanel?.acceptanceProtocolCreationDate?.startDate,
      props.appliedFilter?.acceptanceProtocolCreationDate?.startDate
    ) &&
    filterApplied(
      props.filterPanel?.acceptanceProtocolCreationDate?.endDate,
      props.appliedFilter?.acceptanceProtocolCreationDate?.endDate
    )
  );
});

const workersApplied = computed(() => {
  return multifilterApplied(props.filterPanel?.workers, props.appliedFilter?.workers);
});

const lastWorkersApplied = computed(() => {
  return multifilterApplied(props.filterPanel?.lastWorkers, props.appliedFilter?.lastWorkers);
});

const createdAtDateApplied = computed(() => {
  return (
    filterApplied(
      props.filterPanel?.createdAtDate?.startDate,
      props.appliedFilter?.createdAtDate?.startDate
    ) &&
    filterApplied(
      props.filterPanel?.createdAtDate?.endDate,
      props.appliedFilter?.createdAtDate?.endDate
    )
  );
});

const authorsApplied = computed(() => {
  return multifilterApplied(props.filterPanel?.authors, props.appliedFilter?.authors);
});

const initiativesApplied = computed(() => {
  return multifilterApplied(props.filterPanel?.initiatives, props.appliedFilter?.initiatives);
});

watch(
  () => props.searchBox,
  () => {
    searchString.value = props.searchBox;
  },
  { immediate: true }
);

watch(
  () => props.filterPanel.priorityChange,
  () => {
    priorityChange.value = props.filterPanel.priorityChange;
  }
);

watch(priorityChange, () => {
  let updatedFilterPanel = props.filterPanel;
  updatedFilterPanel.priorityChange = priorityChange.value;
  emit('updateFilterPanel', updatedFilterPanel);
});

watch(
  () => props.filterPanel.claim,
  () => {
    claim.value = props.filterPanel.claim;
  }
);

watch(claim, () => {
  let updatedFilterPanel = props.filterPanel;
  updatedFilterPanel.claim = claim.value;
  emit('updateFilterPanel', updatedFilterPanel);
});

watch(
  () => props.filterPanel.lnPresent,
  () => {
    lnPresent.value = props.filterPanel.lnPresent;
  }
);

watch(lnPresent, () => {
  let updatedFilterPanel = props.filterPanel;
  updatedFilterPanel.lnPresent = lnPresent.value;
  emit('updateFilterPanel', updatedFilterPanel);
});

onMounted(() => {
  configureCollapsingFilterPanel();
});

function configureCollapsingFilterPanel() {
  $('.collapse-link-modified')
    .off('click')
    .on('click', function (e) {
      e.preventDefault();
      var ibox = $(this).closest('div.ibox');
      var button = $(this).find('i');
      var content = ibox.children('.ibox-content');
      content.slideToggle(200);
      button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
      setTimeout(function () {
        ibox.resize();
        ibox.find('[id^=map-]').resize();
      }, 50);
    });
}

function onModalCompleted(data, data2, modalProperties) {
  let updatedFilterPanel = props.filterPanel;
  let updatedFilterPanelDisplay = props.filterPanelDisplay;

  switch (modalTable.value.modal.type) {
    case MODAL_USERS_TABLE:
      {
        let newValue = isNotEmptyArray(data2) ? data2 : null;
        let newDisplayNameValue = isNotEmptyArray(data2)
          ? data2
              .filter((obj) => obj.firstName !== null && obj.lastName !== null)
              .map((obj) => obj.firstName + ' ' + obj.lastName)
              .join(', ')
          : '-';

        if (modalProperties?.useCaseId === COORDINATORS_MODAL_USE_CASE_ID) {
          updatedFilterPanel.coordinators = newValue;
          updatedFilterPanelDisplay.coordinatorsDisplayName = newDisplayNameValue;
        } else if (modalProperties?.useCaseId === WORKERS_MODAL_USE_CASE_ID) {
          updatedFilterPanel.workers = newValue;
          updatedFilterPanelDisplay.workersDisplayName = newDisplayNameValue;
        } else if (modalProperties?.useCaseId === LAST_WORKERS_MODAL_USE_CASE_ID) {
          updatedFilterPanel.lastWorkers = newValue;
          updatedFilterPanelDisplay.lastWorkersDisplayName = newDisplayNameValue;
        } else if (modalProperties?.useCaseId === AUTHORS_MODAL_USE_CASE_ID) {
          updatedFilterPanel.authors = newValue;
          updatedFilterPanelDisplay.authorsDisplayName = newDisplayNameValue;
        }
      }
      break;
    case MODAL_ORDER_STATUSES_TABLE:
      updatedFilterPanel.orderStatuses = isNotEmptyArray(data2) ? data2 : null;
      updatedFilterPanelDisplay.orderStatusesDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.name !== null)
            .map((obj) => obj.name)
            .join(', ')
        : '-';
      break;
    case MODAL_ORDER_TYPES_TABLE:
      updatedFilterPanel.orderTypes = isNotEmptyArray(data2) ? data2 : null;
      updatedFilterPanelDisplay.orderTypesDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.name !== null)
            .map((obj) => obj.name)
            .join(', ')
        : '-';
      break;
    case MODAL_DIRECTIONS_TABLE:
      updatedFilterPanel.directions = isNotEmptyArray(data2) ? data2 : null;
      updatedFilterPanelDisplay.directionsDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.name !== null)
            .map((obj) => obj.name)
            .join(', ')
        : '-';
      break;
    case MODAL_REGIONS_TABLE:
      updatedFilterPanel.regions = isNotEmptyArray(data2) ? data2 : null;
      updatedFilterPanelDisplay.regionsDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.name !== null)
            .map((obj) => obj.name)
            .join(', ')
        : '-';
      break;
    case MODAL_BOROUGHS_TABLE:
      updatedFilterPanel.boroughs = isNotEmptyArray(data2) ? data2 : null;
      updatedFilterPanelDisplay.boroughsDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.name !== null)
            .map((obj) => obj.name)
            .join(', ')
        : '-';
      break;
    case MODAL_COUNTIES_TABLE:
      updatedFilterPanel.counties = isNotEmptyArray(data2) ? data2 : null;
      updatedFilterPanelDisplay.countiesDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.name !== null)
            .map((obj) => obj.name)
            .join(', ')
        : '-';
      break;
    case MODAL_ORDER_INTERRUPTION_STATUSES_TABLE:
      updatedFilterPanel.interruptionStatuses = isNotEmptyArray(data2) ? data2 : null;
      updatedFilterPanelDisplay.orderInterruptionStatusesDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.name !== null)
            .map((obj) => obj.name)
            .join(', ')
        : '-';
      break;
    case MODAL_ORDER_RANGES_TABLE:
      updatedFilterPanel.orderRanges = isNotEmptyArray(data2) ? data2 : null;
      updatedFilterPanelDisplay.orderRangesDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.name !== null)
            .map((obj) => obj.name)
            .join(', ')
        : '-';
      break;
    case MODAL_ORDER_INITIATIVES_TABLE:
      updatedFilterPanel.initiatives = isNotEmptyArray(data2) ? data2 : null;
      updatedFilterPanelDisplay.initiativesDisplayName = isNotEmptyArray(data2)
        ? data2
            .filter((obj) => obj.name !== null)
            .map((obj) => obj.name)
            .join(', ')
        : '-';
      break;
  }

  emit('updateFilterPanel', updatedFilterPanel);
  emit('updateFilterPanelDisplay', updatedFilterPanelDisplay);
}

function onModalRejected(modalProperties) {
  modalTable.value.hide();

  let updatedFilterPanel = props.filterPanel;
  let updatedFilterPanelDisplay = props.filterPanelDisplay;

  switch (modalTable.value.modal.type) {
    case MODAL_USERS_TABLE:
      if (modalProperties?.useCaseId === COORDINATORS_MODAL_USE_CASE_ID) {
        updatedFilterPanel.coordinators = null;
        updatedFilterPanelDisplay.coordinatorsDisplayName = null;
      } else if (modalProperties?.useCaseId === WORKERS_MODAL_USE_CASE_ID) {
        updatedFilterPanel.workers = null;
        updatedFilterPanelDisplay.workersDisplayName = null;
      } else if (modalProperties?.useCaseId === LAST_WORKERS_MODAL_USE_CASE_ID) {
        updatedFilterPanel.lastWorkers = null;
        updatedFilterPanelDisplay.lastWorkersDisplayName = null;
      } else if (modalProperties?.useCaseId === AUTHORS_MODAL_USE_CASE_ID) {
        updatedFilterPanel.authors = null;
        updatedFilterPanelDisplay.authorsDisplayName = null;
      }
      break;
    case MODAL_ORDER_STATUSES_TABLE:
      updatedFilterPanel.orderStatuses = null;
      updatedFilterPanelDisplay.orderStatusesDisplayName = null;
      break;
    case MODAL_ORDER_TYPES_TABLE:
      updatedFilterPanel.orderTypes = null;
      updatedFilterPanelDisplay.orderTypesDisplayName = null;
      break;
    case MODAL_DIRECTIONS_TABLE:
      updatedFilterPanel.directions = null;
      updatedFilterPanelDisplay.directionsDisplayName = null;
      break;
    case MODAL_REGIONS_TABLE:
      updatedFilterPanel.regions = null;
      updatedFilterPanelDisplay.regionsDisplayName = null;
      break;
    case MODAL_BOROUGHS_TABLE:
      updatedFilterPanel.boroughs = null;
      updatedFilterPanelDisplay.boroughsDisplayName = null;
      break;
    case MODAL_COUNTIES_TABLE:
      updatedFilterPanel.counties = null;
      updatedFilterPanelDisplay.countiesDisplayName = null;
      break;
    case MODAL_ORDER_INTERRUPTION_STATUSES_TABLE:
      updatedFilterPanel.interruptionStatuses = null;
      updatedFilterPanelDisplay.orderInterruptionStatusesDisplayName = null;
      break;
    case MODAL_ORDER_RANGES_TABLE:
      updatedFilterPanel.orderRanges = null;
      updatedFilterPanelDisplay.orderRangesDisplayName = null;
      break;
    case MODAL_ORDER_INITIATIVES_TABLE:
      updatedFilterPanel.initiatives = null;
      updatedFilterPanelDisplay.initiativesDisplayName = null;
      break;
  }

  emit('updateFilterPanel', updatedFilterPanel);
  emit('updateFilterPanelDisplay', updatedFilterPanelDisplay);
}

function onClearAllFiltersButtonClicked() {
  let updatedFilterPanel = props.filterPanel;
  let updatedFilterPanelDisplay = props.filterPanelDisplay;

  for (const property in updatedFilterPanel) {
    if (isFilterDateRange(updatedFilterPanel[property])) {
      updatedFilterPanel[property].startDate = null;
      updatedFilterPanel[property].endDate = null;
    } else {
      updatedFilterPanel[property] = null;
    }
  }

  for (const property in updatedFilterPanelDisplay) {
    updatedFilterPanelDisplay[property] = null;
  }

  emit('updateFilterPanel', updatedFilterPanel);
  emit('updateFilterPanelDisplay', updatedFilterPanelDisplay);

  triggerFetchData();
}

function onApplyFiltersButtonClicked() {
  triggerFetchData();
}

function getUserFilter() {
  let userFilter = new Object();

  if (isNotEmptyArray(props.filterPanel?.coordinators))
    userFilter.id_coordinator_opl = props.filterPanel.coordinators.map((item) => item.id);

  if (isNotEmptyArray(props.filterPanel?.orderStatuses))
    userFilter.id_order_status = props.filterPanel.orderStatuses.map((item) => item.id);

  if (isNotEmptyArray(props.filterPanel?.orderTypes))
    userFilter.id_order_type = props.filterPanel.orderTypes.map((item) => item.id);

  if (props.filterPanel?.priorityChange != null)
    userFilter.priority = props.filterPanel.priorityChange ? 1 : 0;

  if (isNotEmptyArray(props.filterPanel?.directions))
    userFilter.id_direction = props.filterPanel.directions.map((item) => item.id);

  if (isNotEmptyArray(props.filterPanel?.regions))
    userFilter.id_region = props.filterPanel.regions.map((item) => item.id);

  if (isNotEmptyArray(props.filterPanel?.boroughs))
    userFilter.id_gmina = props.filterPanel.boroughs.map((item) => item.id);

  if (isNotEmptyArray(props.filterPanel?.counties))
    userFilter.id_powiat = props.filterPanel.counties.map((item) => item.id);

  if (isNotEmptyArray(props.filterPanel?.interruptionStatuses))
    userFilter.id_order_interruption_status = props.filterPanel.interruptionStatuses.map(
      (item) => item.id
    );

  if (isNotEmptyArray(props.filterPanel?.orderRanges))
    userFilter.order_ranges = props.filterPanel.orderRanges.map((item) => item.id);

  if (props.filterPanel?.claim != null) userFilter.claim = props.filterPanel.claim ? 1 : 0;

  if (props.filterPanel?.lnPresent != null)
    userFilter.ln_present = props.filterPanel.lnPresent ? 1 : 0;

  if (props.filterPanel?.receiveDate?.startDate != null)
    userFilter.receive_date_start = props.filterPanel.receiveDate.startDate + ' 00:00:00';

  if (props.filterPanel?.receiveDate?.endDate != null)
    userFilter.receive_date_end = props.filterPanel.receiveDate.endDate + ' 23:59:59';

  if (props.filterPanel?.deadline?.startDate != null)
    userFilter.deadline_date_start = props.filterPanel.deadline.startDate + ' 00:00:00';

  if (props.filterPanel?.deadline?.endDate != null)
    userFilter.deadline_date_end = props.filterPanel.deadline.endDate + ' 23:59:59';

  if (props.filterPanel?.acceptanceProtocolCreationDate?.startDate != null)
    userFilter.acceptance_protocol_creation_date_start =
      props.filterPanel.acceptanceProtocolCreationDate.startDate + ' 00:00:00';

  if (props.filterPanel?.acceptanceProtocolCreationDate?.endDate != null)
    userFilter.acceptance_protocol_creation_date_end =
      props.filterPanel.acceptanceProtocolCreationDate.endDate + ' 23:59:59';

  if (isNotEmptyArray(props.filterPanel?.workers))
    userFilter.id_worker = props.filterPanel.workers.map((item) => item.id);

  if (isNotEmptyArray(props.filterPanel?.lastWorkers))
    userFilter.id_last_worker = props.filterPanel.lastWorkers.map((item) => item.id);

  if (props.filterPanel?.createdAtDate?.startDate != null)
    userFilter.created_at_start = props.filterPanel.createdAtDate.startDate + ' 00:00:00';

  if (props.filterPanel?.createdAtDate?.endDate != null)
    userFilter.created_at_end = props.filterPanel.createdAtDate.endDate + ' 23:59:59';

  if (isNotEmptyArray(props.filterPanel?.authors))
    userFilter.id_created_by = props.filterPanel.authors.map((item) => item.id);

  if (isNotEmptyArray(props.filterPanel?.initiatives))
    userFilter.id_order_initiative = props.filterPanel.initiatives.map((item) => item.id);

  return userFilter;
}

function filterApplied(filterPanelProp, appliedFilterProp) {
  if (appliedFilterProp != null && filterPanelProp != null)
    return appliedFilterProp === filterPanelProp;
  else return appliedFilterProp == filterPanelProp;
}

function multifilterApplied(array1, array2) {
  if (array1 == null && array2 == null) return true;
  if ((array1 == null && array2 != null) || (array1 != null && array2 == null)) return false;

  const array1Ids = array1.map((x) => x.id);
  const array2Ids = array2.map((x) => x.id);

  if (array1Ids.length !== array2Ids.length) return false;

  return array1Ids.every((value) => array2Ids.includes(value));
}

function onStartDateChanged(datesObjectName, newValue) {
  let updatedFilterPanel = props.filterPanel;
  let newDatesObj = new Object();
  newDatesObj.startDate = newValue;
  newDatesObj.endDate = updatedFilterPanel[datesObjectName].endDate ?? newValue;
  updatedFilterPanel[datesObjectName] = newDatesObj;
  emit('updateFilterPanel', updatedFilterPanel);
}

function onEndDateChanged(datesObjectName, newValue) {
  let updatedFilterPanel = props.filterPanel;
  let newDatesObj = new Object();
  newDatesObj.startDate = updatedFilterPanel[datesObjectName].startDate;
  newDatesObj.endDate = newValue;
  updatedFilterPanel[datesObjectName] = newDatesObj;
  emit('updateFilterPanel', updatedFilterPanel);
}

function search() {
  triggerFetchData();
}

function triggerFetchData() {
  emit('fetchData', {
    filter: getUserFilter()
  });
}

function searchChanged() {
  emit('searchChanged', searchString.value);
}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="ibox">
          <div class="row mb-3">
            <div v-if="userFilterEnabled" class="col-sm-6">
              <a type="button" class="collapse-link-modified btn btn-w-m btn-default">
                <span v-if="filtersCount > 0" class="badge badge-warning">{{ filtersCount }}</span>
                Filtry
                <i class="fa fa-chevron-down"></i>
              </a>
            </div>

            <div
              v-if="!hideSearchBar"
              :class="
                customSearchBarCssClass != null
                  ? customSearchBarCssClass
                  : longerSearchBar
                  ? 'col-sm-4 ' + (userFilterEnabled ? 'offset-sm-2' : 'offset-sm-8')
                  : 'col-sm-3 ' + (userFilterEnabled ? 'offset-sm-3' : 'offset-sm-9')
              "
            >
              <div class="input-group">
                <input
                  placeholder="Szukaj.."
                  type="text"
                  class="form-control form-control-sm"
                  v-model="searchString"
                  v-on:keyup.enter="search"
                  v-on:keyup="searchChanged"
                />
                <span class="input-group-append">
                  <button @click="search" type="button" class="btn btn-sm btn-primary">
                    <i class="fa fa-search"></i> Szukaj
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div class="ibox-content" style="display: none">
            <div class="row">
              <div
                v-if="authStore.canAccess(ACCESS_KEY_ORDERS_TABLE_FILTERS_ORDER_STATUS_FILTER)"
                :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'"
              >
                <ModalInputField
                  title="Status zlecenia"
                  :titleCssClass="orderStatusesApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.orderStatusesDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_ORDER_STATUSES_TABLE, {
                      additionalProps: {
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.orderStatuses
                      }
                    })
                  "
                />
              </div>

              <div :class="props.oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <modal-input-field
                  title="Typ zlecenia"
                  :titleCssClass="orderTypesApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.orderTypesDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_ORDER_TYPES_TABLE, {
                      additionalProps: {
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.orderTypes
                      }
                    })
                  "
                />
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <div class="form-group">
                  <label
                    class="col-form-label pt-0"
                    :class="priorityChangeApplied ? '' : 'text-warning'"
                    >Priorytet</label
                  >

                  <div class="btn-group btn-group-toggle" style="display: block">
                    <label
                      class="btn btn-sm btn-white"
                      :class="filterPanel.priorityChange == null ? 'active' : ''"
                    >
                      <input type="radio" :value="null" v-model="priorityChange" />
                      -
                    </label>
                    <label
                      class="btn btn-sm btn-white"
                      :class="filterPanel.priorityChange === true ? 'active' : ''"
                    >
                      <input type="radio" :value="true" v-model="priorityChange" />
                      Tak
                    </label>
                    <label
                      class="btn btn-sm btn-white"
                      :class="filterPanel.priorityChange === false ? 'active' : ''"
                    >
                      <input type="radio" :value="false" v-model="priorityChange" />
                      Nie
                    </label>
                  </div>
                </div>
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <ModalInputField
                  title="Rodzaj zlecenia"
                  :titleCssClass="directionsApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.directionsDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_DIRECTIONS_TABLE, {
                      additionalProps: {
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.directions
                      }
                    })
                  "
                />
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <ModalInputField
                  title="Koordynator"
                  :titleCssClass="coordinatorsApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.coordinatorsDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_USERS_TABLE, {
                      additionalProps: {
                        filter: { url: COORDINATORS_MODAL_URL },
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.coordinators,
                        useCaseId: COORDINATORS_MODAL_USE_CASE_ID
                      }
                    })
                  "
                />
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <ModalInputField
                  title="Region"
                  :titleCssClass="regionsApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.regionsDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_REGIONS_TABLE, {
                      additionalProps: {
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.regions
                      }
                    })
                  "
                />
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <ModalInputField
                  title="Gmina"
                  :titleCssClass="boroughsApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.boroughsDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_BOROUGHS_TABLE, {
                      additionalProps: {
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.boroughs
                      }
                    })
                  "
                />
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <ModalInputField
                  title="Powiat"
                  :titleCssClass="countiesApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.countiesDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_COUNTIES_TABLE, {
                      additionalProps: {
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.counties
                      }
                    })
                  "
                />
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <ModalInputField
                  title="Powód przerwania"
                  :titleCssClass="interruptionStatusesApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.orderInterruptionStatusesDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_ORDER_INTERRUPTION_STATUSES_TABLE, {
                      additionalProps: {
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.interruptionStatuses
                      }
                    })
                  "
                />
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <ModalInputField
                  title="Zakres prac"
                  :titleCssClass="orderRangesApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.orderRangesDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_ORDER_RANGES_TABLE, {
                      additionalProps: {
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.orderRanges
                      }
                    })
                  "
                />
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <div class="form-group">
                  <label class="col-form-label pt-0" :class="claimApplied ? '' : 'text-warning'"
                    >Roszczenie</label
                  >

                  <div class="btn-group btn-group-toggle" style="display: block">
                    <label
                      class="btn btn-sm btn-white"
                      :class="filterPanel.claim == null ? 'active' : ''"
                    >
                      <input type="radio" :value="null" v-model="claim" />
                      -
                    </label>
                    <label
                      class="btn btn-sm btn-white"
                      :class="filterPanel.claim === true ? 'active' : ''"
                    >
                      <input type="radio" :value="true" v-model="claim" />
                      Tak
                    </label>
                    <label
                      class="btn btn-sm btn-white"
                      :class="filterPanel.claim === false ? 'active' : ''"
                    >
                      <input type="radio" :value="false" v-model="claim" />
                      Nie
                    </label>
                  </div>
                </div>
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <DateRangeInput
                  :inputId="`receiveDateRangeId_${componentInstanceId}`"
                  label="Wpłynęło do RADO"
                  :labelCssClass="receiveDateApplied ? '' : 'text-warning'"
                  :startDate="filterPanel.receiveDate.startDate"
                  :endDate="filterPanel.receiveDate.endDate"
                  @startDateChanged="(p1) => onStartDateChanged('receiveDate', p1)"
                  @endDateChanged="(p2) => onEndDateChanged('receiveDate', p2)"
                />
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <DateRangeInput
                  :inputId="`deadlineRangeId_${componentInstanceId}`"
                  label="Termin realizacji"
                  :labelCssClass="deadlineApplied ? '' : 'text-warning'"
                  :startDate="filterPanel.deadline.startDate"
                  :endDate="filterPanel.deadline.endDate"
                  @startDateChanged="(p1) => onStartDateChanged('deadline', p1)"
                  @endDateChanged="(p2) => onEndDateChanged('deadline', p2)"
                />
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <DateRangeInput
                  :inputId="`acceptanceProtocolCreationDateRangeId_${componentInstanceId}`"
                  label="Wygenerowano PO"
                  :labelCssClass="acceptanceProtocolCreationDateApplied ? '' : 'text-warning'"
                  :startDate="filterPanel.acceptanceProtocolCreationDate.startDate"
                  :endDate="filterPanel.acceptanceProtocolCreationDate.endDate"
                  @startDateChanged="
                    (p1) => onStartDateChanged('acceptanceProtocolCreationDate', p1)
                  "
                  @endDateChanged="(p2) => onEndDateChanged('acceptanceProtocolCreationDate', p2)"
                />
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <ModalInputField
                  title="Brygadzista (dodane akcje)"
                  :titleCssClass="workersApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.workersDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_USERS_TABLE, {
                      additionalProps: {
                        filter: { url: WORKERS_MODAL_URL },
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.workers,
                        useCaseId: WORKERS_MODAL_USE_CASE_ID
                      }
                    })
                  "
                />
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <ModalInputField
                  title="Ostatni przydzielony brygadzista"
                  :titleCssClass="lastWorkersApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.lastWorkersDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_USERS_TABLE, {
                      additionalProps: {
                        filter: { url: WORKERS_MODAL_URL },
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.lastWorkers,
                        useCaseId: LAST_WORKERS_MODAL_USE_CASE_ID
                      }
                    })
                  "
                />
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <div class="form-group">
                  <label class="col-form-label pt-0" :class="lnPresentApplied ? '' : 'text-warning'"
                    >Informacja o kablach z LN</label
                  >

                  <div class="btn-group btn-group-toggle" style="display: block">
                    <label
                      class="btn btn-sm btn-white"
                      :class="filterPanel.lnPresent == null ? 'active' : ''"
                    >
                      <input type="radio" :value="null" v-model="lnPresent" />
                      -
                    </label>
                    <label
                      class="btn btn-sm btn-white"
                      :class="filterPanel.lnPresent === true ? 'active' : ''"
                    >
                      <input type="radio" :value="true" v-model="lnPresent" />
                      Tak
                    </label>
                    <label
                      class="btn btn-sm btn-white"
                      :class="filterPanel.lnPresent === false ? 'active' : ''"
                    >
                      <input type="radio" :value="false" v-model="lnPresent" />
                      Nie
                    </label>
                  </div>
                </div>
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <DateRangeInput
                  :inputId="`createdAtDateRangeId_${componentInstanceId}`"
                  label="Utworzone"
                  :labelCssClass="createdAtDateApplied ? '' : 'text-warning'"
                  :startDate="filterPanel.createdAtDate.startDate"
                  :endDate="filterPanel.createdAtDate.endDate"
                  @startDateChanged="(p1) => onStartDateChanged('createdAtDate', p1)"
                  @endDateChanged="(p2) => onEndDateChanged('createdAtDate', p2)"
                />
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <ModalInputField
                  title="Autor"
                  :titleCssClass="authorsApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.authorsDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_USERS_TABLE, {
                      additionalProps: {
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.authors,
                        useCaseId: AUTHORS_MODAL_USE_CASE_ID
                      }
                    })
                  "
                />
              </div>

              <div :class="oneFilterPerRow ? 'col-sm-12' : 'col-sm-4'">
                <ModalInputField
                  title="Inicjatywa"
                  :titleCssClass="initiativesApplied ? '' : 'text-warning'"
                  :displayText="filterPanelDisplay.initiativesDisplayName"
                  @showModal="
                    modalTable.showModal(MODAL_ORDER_INITIATIVES_TABLE, {
                      additionalProps: {
                        multiSelections: true,
                        initiallyPickedItems: filterPanel.initiatives
                      }
                    })
                  "
                />
              </div>
            </div>

            <div class="row">
              <div class="col-6 offset-6">
                <button
                  class="btn btn-outline btn-info btn-xs float-right"
                  @click="onApplyFiltersButtonClicked"
                >
                  Zastosuj filtry
                </button>
                <button
                  class="btn btn-outline btn-danger btn-xs float-right mr-1"
                  @click="onClearAllFiltersButtonClicked"
                >
                  Wyczyść filtry
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalTable
      ref="modalTable"
      :showCloseButton="true"
      overrideCloseButtonText="Zatwierdź wybór"
      @onModalCompleted="onModalCompleted"
      @onModalRejected="onModalRejected"
    ></ModalTable>
  </div>
</template>

<style scoped></style>
