export const REMOVE_ACTION_BUTTON_TYPE_NAME = 'removeActionButtonType';
export const HIDE_ACTION_BUTTON_TYPE_NAME = 'hideActionButtonType';
export const COPY_TO_CLIPBOARD_BUTTON_TYPE_NAME = 'copyToClipboardButtonType';
export const SHOW_ACTION_GALLERY_BUTTON_TYPE_NAME = 'showActionGalleryButtonType';
export const UPDATE_ACTION_BUTTON_TYPE_NAME = 'updateActionButtonType';
export const ACTIVATE_DEACTIVATE_DEMPOINT_BUTTON_TYPE_NAME = 'activateDeactivateDemPointButtonType';
export const SHOW_COORDS_ON_MAP_BUTTON_TYPE_NAME = 'showCoordsOnMapsButtonTypeName';
export const REMOVE_LOCATION_BUTTON_TYPE_NAME = 'removeLocationButtonType';
export const UPDATE_LOCATION_BUTTON_TYPE_NAME = 'updateLocationButtonType';
export const GO_TO_ORDER_DETAILS_BUTTON_TYPE_NAME = 'goToOrderDetailsButtonTypeName';
export const PREVIOUS_ACTION_BUTTON_TYPE_NAME = 'previousActionButtonTypeName';
export const NEXT_ACTION_BUTTON_TYPE_NAME = 'nextActionButtonTypeName';
export const ADD_ACTION_TO_DEMPOINT_BUTTON_TYPE_NAME = 'addActionToDemPointButtonTypeName';
export const ADD_EDIT_DEMPOINT_NOTE_BUTTON_TYPE_NAME = 'addEditDemPointNoteButtonTypeName';
