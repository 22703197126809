<script setup>
/* eslint-env jquery */
import { ref, computed, onMounted } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { handleResponseWithMessages } from '@/helpers/wwwHelper.js';

const RepositoryOrders = RepositoryFactory.get('orders');

const props = defineProps({ orderId: Number });

const isLoading = ref(false);
const geodeticRealizationDate = ref(null);
const sharedWithOPLForVerificationDate = ref(null);
const lastRealizationDate = ref(null);
const lastSharedWithOPLDate = ref(null);

const hasRealizationDateChanged = computed(() => {
  if (
    (lastRealizationDate.value == null && geodeticRealizationDate.value === '') ||
    (lastRealizationDate.value === '' && geodeticRealizationDate.value == null)
  )
    return false;

  return lastRealizationDate.value != geodeticRealizationDate.value;
});

const hasSharedWithOPLDateChanged = computed(() => {
  if (
    (lastSharedWithOPLDate.value == null && sharedWithOPLForVerificationDate.value === '') ||
    (lastSharedWithOPLDate.value === '' && sharedWithOPLForVerificationDate.value == null)
  )
    return false;

  return lastSharedWithOPLDate.value != sharedWithOPLForVerificationDate.value;
});

async function getGeodeticDates() {
  isLoading.value = true;

  let geodeticDates = await RepositoryOrders.getOrderGeodeticDates(props.orderId);

  geodeticRealizationDate.value = geodeticDates?.geodesy_realization_date?.split(' ')[0];
  sharedWithOPLForVerificationDate.value =
    geodeticDates?.sended_to_verification_date?.split(' ')[0];

  lastRealizationDate.value = geodeticRealizationDate.value;
  lastSharedWithOPLDate.value = sharedWithOPLForVerificationDate.value;

  isLoading.value = false;
}

async function onDatesSubmit(e) {
  e.preventDefault();

  isLoading.value = true;

  var response = await RepositoryOrders.updateGeodeticDates(
    props.orderId,
    geodeticRealizationDate.value == null || geodeticRealizationDate.value === ''
      ? null
      : geodeticRealizationDate.value,
    sharedWithOPLForVerificationDate.value == null || sharedWithOPLForVerificationDate.value === ''
      ? null
      : sharedWithOPLForVerificationDate.value
  );

  isLoading.value = false;
  handleResponseWithMessages(response, 'Daty zostały zaktualizowane.', () => {});
  getGeodeticDates();
}

onMounted(() => {
  const datePickerConf = {
    todayBtn: 'linked',
    keyboardNavigation: true,
    forceParse: true,
    calendarWeeks: true,
    autoclose: true,
    weekStart: 1
  };
  $('#geoRealizationDateFGId .input-group.date').datepicker(datePickerConf);
  $('#sharedWithOPLDateFGId .input-group.date').datepicker(datePickerConf);
  $(document.body).on('change', 'input[id^="geoRealizationDateIFId"]', function () {
    geodeticRealizationDate.value = document.getElementById('geoRealizationDateIFId').value;
  });
  $(document.body).on('change', 'input[id^="sharedWithOPLDateIFId"]', function () {
    sharedWithOPLForVerificationDate.value = document.getElementById('sharedWithOPLDateIFId').value;
  });
});

// created
getGeodeticDates();
</script>

<template>
  <div class="ibox-content p-0" :class="{ 'sk-loading': isLoading }" style="border-style: none">
    <div class="sk-spinner sk-spinner-three-bounce">
      <div class="sk-bounce1"></div>
      <div class="sk-bounce2"></div>
      <div class="sk-bounce3"></div>
    </div>

    <form @submit="onDatesSubmit">
      <div class="form-group" id="geoRealizationDateFGId">
        <label>Termin realizacji dla geodety</label>
        <div class="input-group date">
          <span class="input-group-addon" :style="'background-color: #e9ecef'"
            ><i class="fa fa-calendar"></i></span
          ><input
            id="geoRealizationDateIFId"
            type="text"
            class="form-control"
            :class="{ 'input-modified': hasRealizationDateChanged }"
            v-model="geodeticRealizationDate"
          />
        </div>
      </div>

      <div class="form-group" id="sharedWithOPLDateFGId">
        <label>Data wysłania do weryfikacji do Orange</label>
        <div class="input-group date">
          <span class="input-group-addon" :style="'background-color: #e9ecef'"
            ><i class="fa fa-calendar"></i></span
          ><input
            id="sharedWithOPLDateIFId"
            type="text"
            class="form-control"
            :class="{ 'input-modified': hasSharedWithOPLDateChanged }"
            v-model="sharedWithOPLForVerificationDate"
          />
        </div>
      </div>

      <div class="row mb-3" style="float: right">
        <button
          type="submit"
          class="btn btn-primary mt-2 mr-3"
          :disabled="!hasRealizationDateChanged && !hasSharedWithOPLDateChanged"
        >
          Zapisz
        </button>
      </div>
    </form>
  </div>
</template>

<style scoped>
.input-modified {
  border-color: #fcba03;
}
</style>
