import Swal from 'sweetalert2';
import { isNotEmptyArray } from '../utilsHelper';
import { APP_PRIMARY_COLOR } from '../../data/constants/appConstants';

export async function verifyOrderArchiving(apiVerificationFunction, onConfirmed) {
  const response = await apiVerificationFunction();

  const canArchive = response?.can_archive === true;
  const icon = !canArchive ? 'error' : isNotEmptyArray(response?.warnings) ? 'warning' : 'question';

  let message = response?.message ?? '';

  if (isNotEmptyArray(response?.warnings)) {
    message += '<br/><br/><b>Ostrzeżenia:</b>';
    response.warnings.forEach((warning) => {
      message += "<br/><span style='font-size: 14px'>" + warning + '</span>';
    });
  }

  message += canArchive ? `<br/><br/>Czy na pewno chcesz zarchiwizować zlecenie?` : '';

  const swalConfiguration = {
    title: 'Weryfikacja archiwizacji zlecenia',
    html: message,
    icon: icon,
    showConfirmButton: canArchive,
    showCancelButton: true,
    confirmButtonColor: `#${APP_PRIMARY_COLOR}`,
    cancelButtonColor: '#f8ac59',
    confirmButtonText: 'Archiwizuj',
    cancelButtonText: canArchive ? 'Anuluj' : 'Zamknij',
    customClass: {
      title: 'swal2-title'
    },
    width: isNotEmptyArray(response?.warnings) ? '50%' : ''
  };

  Swal.fire(swalConfiguration).then((result) => {
    if (result.value) {
      onConfirmed();
    }
  });
}
