<script setup>
import { ref } from 'vue';
import RepoFilesTableGET from '@/components/fileRepositories/RepoFilesTableGET.vue';
import RadioToggle from '@/components/utils/RadioToggle.vue';
import TransportRepoFilesWithFilters from './TransportRepoFilesWithFilters.vue';

const props = defineProps({ orderId: { type: Number, default: null } });
const activeTabId = ref(0);
const alreadyLoaded = ref({
  actionsResources: true,
  transportPhotos: false
});
const actionsResourcesKey = ref(0);

function onTabChanged(activeId) {
  activeTabId.value = activeId;

  switch (activeId) {
    case 0:
      alreadyLoaded.value.actionsResources = true;
      break;
    case 1:
      alreadyLoaded.value.transportPhotos = true;
      break;
  }
}
</script>

<template>
  <div class="row">
    <div class="mt-1 mb-4 pl-3">
      <RadioToggle
        :initialOptions="[
          { id: 0, title: `Miejsce składowania`, checked: true },
          { id: 1, title: `Kierowcy`, checked: false }
        ]"
        @onChanged="onTabChanged"
      />
    </div>
  </div>

  <!-- span elements for avoiding duplicating children keys TODO check that-->
  <span v-if="alreadyLoaded.actionsResources" v-show="activeTabId === 0">
    <RepoFilesTableGET
      :key="actionsResourcesKey"
      class="mt-4"
      modalId="modalImageContainer212"
      :url="`ordertransport/${props.orderId}/actionFiles`"
      :showBorder="false"
      :longerSearchBar="true"
      :deletingEnabled="true"
      :mobileAppSharingEnabled="true"
      @fileDeleted="actionsResourcesKey++"
      @fileShared="actionsResourcesKey++"
    />
  </span>

  <span v-if="alreadyLoaded.transportPhotos" v-show="activeTabId === 1">
    <TransportRepoFilesWithFilters
      :orderId="orderId"
      :urlOverride="`ordertransport/${props.orderId}/transportFiles`"
      :longerSearchBar="true"
      modalImageContainerId="modalImageContainer434"
    />
  </span>
</template>

<style scoped></style>
