<script setup>
import { ref, computed, watch } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { copyToClipboard, isNotEmptyArray } from '../../helpers/utilsHelper';
import { createToaster } from '@meforma/vue-toaster';
import { TOASTER_SERVER_ERROR_MSG } from '../../data/constants/toasterMessagesConstants';
import FormErrorPanel from '../utils/FormErrorPanel.vue';
import ClusterMap from '../map/ClusterMap.vue';
import { DEFAULT_INITIAL_LAT, DEFAULT_INITIAL_LON } from '../../data/constants/appConstants';
import { useMapLogic } from '../../composables/mapLogic';
import { showOnGoogleMaps } from '../../helpers/navigator';

const RepositoryOrders = RepositoryFactory.get('orders');
const RepositoryDictionaries = RepositoryFactory.get('dictionaries');
const RepositoryDemPoints = RepositoryFactory.get('demPoints');

const props = defineProps({ orderId: Number, locationId: { type: Number, default: null } });
const emit = defineEmits(['refresh']);
const isLoadingForm = ref(false);
const demElemTypes = ref(null);
const componentReady = ref(false);
const toaster = createToaster({ position: 'top-right', duration: 3000 });
const { clusterMap } = useMapLogic();

// form data
const pickedDemElemTypeId = ref(null);
const markerPosition = ref(null);
const number = ref(null);
const tag = ref(null);
const aggregatedPoint = ref(false);
const note = ref(null);
const formErrors = ref([]);

const addMode = computed(() => {
  return props.locationId == null;
});

const coordsValid = computed(() => {
  return markerPosition.value?.lat && markerPosition.value?.lon;
});

const demElemTypesAvailable = computed(() => isNotEmptyArray(demElemTypes.value?.data));

const pickedDemElemTypeName = computed(() => {
  if (pickedDemElemTypeId.value == null) return null;
  if (!demElemTypesAvailable.value) return null;

  return demElemTypes.value.data.find((x) => x.id === pickedDemElemTypeId.value)?.name;
});

const isLoadingMap = computed(() => {
  if (!componentReady.value) return true;

  return markerPosition.value == null;
});

watch(isLoadingMap, (newValue) => {
  if (!newValue) {
    prepareMarkerForMap();
  }
});

async function fetchDemElemTypes() {
  isLoadingForm.value = true;

  RepositoryDictionaries.getDictionaryElementsObject(1, 100, '', '', '', 'demelemtype')
    .then((data) => {
      demElemTypes.value = data;
    })
    .catch((error) => console.log(error))
    .finally(() => {
      isLoadingForm.value = false;
    });
}

async function initializeMarker() {
  const coords = await RepositoryOrders.getNewActionInitialLocation(props.orderId);

  markerPosition.value = {
    lat: coords?.lat ?? DEFAULT_INITIAL_LAT,
    lon: coords?.lon ?? DEFAULT_INITIAL_LON
  };
}

async function getLocationDataToEdit() {
  isLoadingForm.value = true;

  const fetchedLocationData = await RepositoryDemPoints.getDemPointDataToEdit(props.locationId);

  if (fetchedLocationData?.lat != null && fetchedLocationData?.lon != null) {
    markerPosition.value = {
      lat: fetchedLocationData.lat,
      lon: fetchedLocationData.lon
    };
  } else {
    initializeMarker();
  }

  pickedDemElemTypeId.value = fetchedLocationData?.dem_elem_type?.id;
  aggregatedPoint.value = fetchedLocationData?.dem_point_type?.id === 2;
  note.value = fetchedLocationData?.note;
  tag.value = fetchedLocationData?.oznaczenie;
  number.value = fetchedLocationData?.number;

  isLoadingForm.value = false;
}

function prepareMarkerForMap() {
  let marker = {
    lat: markerPosition.value.lat,
    lng: markerPosition.value.lon,
    draggable: true,
    selected: false
  };

  const markers = [marker];
  clusterMap.value.initializeMap({ markersData: markers });
}

function onMarkerDragged(marker) {
  markerPosition.value = { lat: marker.lat, lon: marker.lng };
}

function submitForm(e) {
  e.preventDefault();

  if (addMode.value) {
    addDemPointManually();
  } else {
    sendEditRequest();
  }
}

async function addDemPointManually() {
  const formData = getPreparedFormData();

  var response = await RepositoryOrders.addDemPointManually(props.orderId, formData);

  if (response?.serverError) {
    toaster.show(TOASTER_SERVER_ERROR_MSG, { duration: 3000, type: 'error' });
  } else {
    if (response) {
      formErrors.value = response;
    } else {
      toaster.show('Punkt został dodany.', { type: 'success' });
      emit('refresh');
    }
  }
}

async function sendEditRequest() {
  isLoadingForm.value = true;

  const formData = getPreparedFormData();

  var response = await RepositoryDemPoints.updateDemPoint(formData);

  if (response?.serverError) {
    toaster.show(TOASTER_SERVER_ERROR_MSG, { duration: 3000, type: 'error' });
  } else {
    if (response) {
      formErrors.value = response;
    } else {
      toaster.show('Punkt został edytowany.', { type: 'success' });
      emit('refresh');
    }
  }

  isLoadingForm.value = false;
}

function getPreparedFormData() {
  const tagForForm = tag.value != null && tag.value !== '' ? tag.value : undefined;

  const formData = {
    id: addMode.value ? undefined : props.locationId,
    id_dem_elem_type: pickedDemElemTypeId.value,
    id_dem_point_type: aggregatedPoint.value ? 2 : 1,
    number: aggregatedPoint.value ? number.value : undefined,
    lat: markerPosition.value.lat,
    lon: markerPosition.value.lon,
    oznaczenie: aggregatedPoint.value ? undefined : tagForForm,
    note: note.value != null && note.value !== '' ? note.value : undefined
  };

  return formData;
}

// created
fetchDemElemTypes();

if (addMode.value) {
  initializeMarker();
} else {
  getLocationDataToEdit();
}

setTimeout(() => {
  componentReady.value = true;
}, 500);
</script>

<template>
  <div class="row">
    <div class="col-8">
      <div class="ibox-content" :class="{ 'sk-loading': isLoadingMap }" style="border-style: none">
        <div class="sk-spinner sk-spinner-three-bounce">
          <div class="sk-bounce1"></div>
          <div class="sk-bounce2"></div>
          <div class="sk-bounce3"></div>
        </div>

        <ClusterMap
          ref="clusterMap"
          :showZeroPositions="false"
          height="500px"
          @markerDragged="onMarkerDragged"
        />
      </div>
    </div>
    <div class="col-4">
      <div class="ibox">
        <div
          class="ibox-content"
          :class="{ 'sk-loading': isLoadingForm }"
          style="border-style: none"
        >
          <div class="sk-spinner sk-spinner-three-bounce">
            <div class="sk-bounce1"></div>
            <div class="sk-bounce2"></div>
            <div class="sk-bounce3"></div>
          </div>

          <FormErrorPanel :errors="formErrors" />

          <h4>
            <b> Wprowadź dane</b>
          </h4>
          <form role="form" @submit="submitForm">
            <div v-if="demElemTypesAvailable" class="form-group mt-2">
              <label>Typ elementu</label>
              <select class="form-control m-b" v-model="pickedDemElemTypeId" required>
                <option disabled :value="null" label="Wybierz typ elementu"></option>
                <option
                  v-for="demElemType in demElemTypes.data"
                  :key="demElemType.id"
                  :value="demElemType.id"
                >
                  {{ demElemType.name }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label>Punkt zbiorczy</label>
              <div class="switch">
                <div class="onoffswitch">
                  <input
                    type="checkbox"
                    :disabled="!addMode"
                    class="onoffswitch-checkbox"
                    id="aggregatedPointSwitchId"
                    v-model="aggregatedPoint"
                  />
                  <label class="onoffswitch-label" for="aggregatedPointSwitchId">
                    <span class="onoffswitch-inner"></span>
                    <span class="onoffswitch-switch"></span>
                  </label>
                </div>
              </div>
            </div>

            <div v-if="aggregatedPoint" class="form-group">
              <label>Ilość</label>
              <input
                type="number"
                placeholder="Wpisz ilość"
                v-model="number"
                class="form-control"
                required
                min="1"
                max="999999999"
              />
            </div>

            <div v-if="!aggregatedPoint" class="form-group">
              <label>Oznaczenie (opcjonalne)</label>
              <input
                type="text"
                placeholder="Wpisz oznaczenie"
                v-model="tag"
                class="form-control"
                maxlength="500"
              />
            </div>

            <div class="form-group">
              <label>Uwagi (opcjonalne)</label>
              <input
                type="text"
                placeholder="Wpisz uwagi"
                v-model="note"
                class="form-control"
                maxlength="500"
              />
            </div>

            <h4 class="mt-4">
              <b> Podsumowanie </b>
            </h4>

            <div class="mt-2">
              <br />
              Współrzędne:
              {{ coordsValid ? `${markerPosition.lat}, ${markerPosition?.lon}` : '-' }}
              <button
                v-if="coordsValid"
                type="button"
                class="btn btn-white btn-xs ml-1"
                @click="copyToClipboard(`${markerPosition.lat}, ${markerPosition.lon}`)"
              >
                <i class="fa fa-copy" />
              </button>
              <button
                v-if="coordsValid"
                type="button"
                class="btn btn-white btn-xs ml-1"
                @click="showOnGoogleMaps(markerPosition.lat, markerPosition.lon)"
              >
                <i class="fa fa-google" />
              </button>
              <br />
              <span v-if="aggregatedPoint"> Ilość: {{ number ?? '-' }} <br /> </span>
              <span v-if="!aggregatedPoint"> Oznaczenie: {{ tag ?? '-' }} <br /> </span>
              Typ punktu: {{ aggregatedPoint ? 'zbiorczy' : 'pojedynczy' }}
              <br />
              Typ elementu: {{ pickedDemElemTypeName ?? '-' }}
              <br />
              Uwagi: {{ note ?? '-' }}
            </div>

            <div class="row mb-2">
              <div class="col-12">
                <button type="submit" class="btn btn-primary mt-2" style="float: right">
                  Prześlij
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
