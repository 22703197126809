import { ref, computed } from 'vue';
import { defineStore } from 'pinia';

export const useMapStore = defineStore('map', () => {
  const mapState = ref(localStorage.getItem('mapState'));

  const mapRefreshKey = computed(() => {
    let currentMapState = mapState.value ? JSON.parse(mapState.value) : {};
    if (currentMapState === null) return 0;

    return currentMapState.refreshKey !== null ? currentMapState.refreshKey : 0;
  });

  function tryToRefreshMaps() {
    let currentMapState = mapState.value ? JSON.parse(mapState.value) : {};

    currentMapState.refreshKey++;
    mapState.value = JSON.stringify(currentMapState);
    localStorage.setItem('mapState', mapState.value);
  }

  return { mapState, mapRefreshKey, tryToRefreshMaps };
});
