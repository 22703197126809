export const DELAY_STATUS_1_COLOR = 'green';
export const DELAY_STATUS_2_COLOR = 'orange';
export const DELAY_STATUS_3_COLOR = 'red';

export const ORDERS_MAP_STATUS_1_DELAY_1_ICON = {
  icon: `${DELAY_STATUS_1_COLOR}_marker`,
  iconSize: [19, 30],
  iconAnchor: [9.5, 30]
};

export const ORDERS_MAP_STATUS_1_DELAY_2_ICON = {
  icon: `${DELAY_STATUS_2_COLOR}_marker`,
  iconSize: [19, 30],
  iconAnchor: [9.5, 30]
};

export const ORDERS_MAP_STATUS_1_DELAY_3_ICON = {
  icon: `${DELAY_STATUS_3_COLOR}_marker`,
  iconSize: [19, 30],
  iconAnchor: [9.5, 30]
};

export const ORDERS_MAP_STATUS_2_DISASSEMBLY_3_ICON = {
  icon: 'red_question_mark',
  iconSize: [30, 30],
  iconAnchor: [15, 15]
};

export const ORDERS_MAP_STATUS_2_DISASSEMBLY_4_ICON = {
  icon: 'blue_marker',
  iconSize: [19, 30],
  iconAnchor: [9.5, 30]
};

export const ORDERS_MAP_STATUS_3_INTERRUPTION_1_ICON = {
  icon: 'POR_green',
  iconSize: [28, 27],
  iconAnchor: [14, 13.5]
};

export const ORDERS_MAP_STATUS_3_INTERRUPTION_2_ICON = {
  icon: 'grain',
  iconSize: [30, 29],
  iconAnchor: [15, 14.5]
};

export const ORDERS_MAP_STATUS_3_INTERRUPTION_3_ICON = {
  icon: 'water',
  iconSize: [26.5, 28],
  iconAnchor: [13.25, 14]
};

export const ORDERS_MAP_STATUS_3_INTERRUPTION_4_ICON = {
  icon: 'phone_crossed_out',
  iconSize: [30, 29],
  iconAnchor: [15, 14.5]
};

export const ORDERS_MAP_STATUS_3_INTERRUPTION_5_ICON = {
  icon: 'tree_v2',
  iconSize: [30, 29],
  iconAnchor: [15, 14.5]
};

export const RED_PRIORITY_ICON = {
  icon: 'red_exclamation_mark_circle_icon',
  iconSize: [30, 30],
  iconAnchor: [15, 15]
};

export const ORDERS_MAP_STATUS_3_INTERRUPTION_6_ICON = {
  icon: 'x_v2',
  iconSize: [30, 30],
  iconAnchor: [15, 15]
};

export const ORDERS_MAP_STATUS_3_INTERRUPTION_7_ICON = RED_PRIORITY_ICON;

export const ORDERS_MAP_STATUS_3_INTERRUPTION_8_ICON = {
  icon: 'red_question_mark',
  iconSize: [30, 30],
  iconAnchor: [15, 15]
};

export const ORDERS_MAP_STATUS_3_INTERRUPTION_9_ICON = {
  icon: 'hammer',
  iconSize: [30, 30],
  iconAnchor: [15, 15]
};

export const ORDERS_MAP_STATUS_3_INTERRUPTION_10_ICON = {
  icon: 'agreed',
  iconSize: [30, 30],
  iconAnchor: [15, 15]
};

export const ORDERS_MAP_STATUS_3_INTERRUPTION_11_ICON = {
  icon: 'POR_white',
  iconSize: [28, 27],
  iconAnchor: [14, 13.5]
};

export const ORDERS_MAP_STATUS_4_ICON = {
  icon: 'red_question_mark_orange_background',
  iconSize: [30, 30],
  iconAnchor: [15, 15]
};

export const ORDERS_MAP_STATUS_5_ICON = {
  icon: 'x_v2',
  iconSize: [30, 30],
  iconAnchor: [15, 15]
};

export const ORDERS_MAP_STATUS_6_ICON = {
  icon: 'blue_marker',
  iconSize: [19, 30],
  iconAnchor: [9.5, 30]
};

export const ORDERS_MAP_STATUS_7_ICON = {
  icon: 'blue_marker',
  iconSize: [19, 30],
  iconAnchor: [9.5, 30]
};

export const ORDERS_MAP_STATUS_8_ICON = {
  icon: 'blue_marker',
  iconSize: [19, 30],
  iconAnchor: [9.5, 30]
};

export const ORDERS_MAP_STATUS_9_ICON = {
  icon: 'blue_marker',
  iconSize: [19, 30],
  iconAnchor: [9.5, 30]
};

export const ORDERS_MAP_STATUS_10_ICON = {
  icon: 'blue_marker',
  iconSize: [19, 30],
  iconAnchor: [9.5, 30]
};

export const ORDERS_MAP_STATUS_11_ICON = {
  icon: 'red_question_mark_blue_background',
  iconSize: [30, 30],
  iconAnchor: [15, 15]
};

export const PRIORITY_ICON = {
  icon: 'exclamation_mark_circle_icon',
  iconSize: [30, 30],
  iconAnchor: [15, 15]
};

export const BACKUP_ICON = {
  icon: 'red_x_with_question_mark',
  iconSize: [30, 30],
  iconAnchor: [15, 15]
};
