<script setup>
import { ref, watch } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { isNotEmptyArray } from '../../helpers/utilsHelper.js';
import ClusterMap from '../map/ClusterMap.vue';
import { useMapLogic } from '../../composables/mapLogic';

const GEOCODING_MARKER_TYPE = 'geo';
const DEM_POINT_MARKER_TYPE = 'demPoint';
const DRAGGABLE_MARKER_INITIAL_LAT = 52;
const DRAGGABLE_MARKER_INITIAL_LON = 19.5;
const DRAGGABLE_MARKER_ID = 'draggableId';
const DRAGGABLE_MARKER_FEATURE_GROUP_KEY = 'draggableFG';

const RepositoryOrders = RepositoryFactory.get('orders');
const RepositoryAddresses = RepositoryFactory.get('addresses');

const props = defineProps({ orderId: Number, addressId: Number, showDraggableMarker: Boolean });
const emit = defineEmits(['pointPicked']);
const isLoading = ref(false);
const { clusterMap, updateMarkerSelectedState } = useMapLogic();

const geocodingsForTheAddress = ref(null);
const demPointsCoordinates = ref(null);
const pickedPoint = ref(null);
const draggableMarker = ref({
  lat: DRAGGABLE_MARKER_INITIAL_LAT,
  lng: DRAGGABLE_MARKER_INITIAL_LON,
  draggable: true,
  markerId: DRAGGABLE_MARKER_ID,
  selected: true
});
const draggableMarkerVisible = ref(false);

watch(
  () => props.showDraggableMarker,
  (newValue) => {
    newValue ? tryToShowDraggableMarker() : tryToHideDraggableMarker();
  }
);

async function fetchData() {
  isLoading.value = true;

  geocodingsForTheAddress.value = await RepositoryAddresses.getGeocodingsForTheAddress(
    props.addressId
  );
  demPointsCoordinates.value = await RepositoryOrders.getDemPointsCoordinates(props.orderId);
  assignMarkerIdsForGeocodings();
  assignMarkerIdsForDemPointsCoordinates();
  prepareMarkersForMap();
  initializeDraggableMarker();

  isLoading.value = false;
}

function assignMarkerIdsForGeocodings() {
  if (!isNotEmptyArray(geocodingsForTheAddress.value)) return;

  for (let index = 0; index < geocodingsForTheAddress.value.length; index++) {
    geocodingsForTheAddress.value[index].markerId = GEOCODING_MARKER_TYPE + index;
  }
}

function assignMarkerIdsForDemPointsCoordinates() {
  if (!isNotEmptyArray(demPointsCoordinates.value.data)) return;

  for (let index = 0; index < demPointsCoordinates.value.data.length; index++) {
    demPointsCoordinates.value.data[index].markerId = DEM_POINT_MARKER_TYPE + index;
  }
}

function prepareMarkersForMap() {
  const geocodingMarkers = getGeocodingsMarkers();
  const demPointsCoordinatesMarkers = getDemPointsCoordinatesMarkers();

  const markers = geocodingMarkers.concat(demPointsCoordinatesMarkers);
  clusterMap.value.initializeMap({ markersData: markers });
}

function getGeocodingsMarkers() {
  const geocodingsMarkers = [];

  if (!isNotEmptyArray(geocodingsForTheAddress.value)) {
    console.warn('!isNotEmptyArray(geocodingsForTheAddress.value)');
    return geocodingsMarkers;
  }

  geocodingsForTheAddress.value.forEach((geocoding) => {
    let marker = {
      lat: geocoding.lat,
      lng: geocoding.lon,
      draggable: false,
      markerId: geocoding.markerId,
      options: { markerType: GEOCODING_MARKER_TYPE },
      selected: false
    };

    geocodingsMarkers.push(marker);
  });

  return geocodingsMarkers;
}

function getDemPointsCoordinatesMarkers() {
  const demPointsMarkers = [];

  if (!isNotEmptyArray(demPointsCoordinates.value.data)) {
    console.warn('!isNotEmptyArray(demPointsCoordinates.value.data)');
    return demPointsMarkers;
  }

  demPointsCoordinates.value.data.forEach((demPoint) => {
    let marker = {
      lat: demPoint.lat,
      lng: demPoint.lon,
      draggable: false,
      markerId: demPoint.markerId,
      options: { markerType: DEM_POINT_MARKER_TYPE },
      selected: false,
      icon: 'gray_circle_marker',
      iconSize: [10, 10],
      iconAnchor: [5, 5]
    };

    demPointsMarkers.push(marker);
  });

  return demPointsMarkers;
}

function onMarkerClicked(clickedMarker) {
  if (props.showDraggableMarker) return;
  if (clickedMarker?.options?.markerType === DEM_POINT_MARKER_TYPE) return;

  pickedPoint.value = geocodingsForTheAddress.value.find(
    (point) => point.markerId === clickedMarker.markerId
  );

  if (!pickedPoint.value) {
    console.warn(
      'CoordsFromAddressStep2.onMarkerClicked(): Not found clicked marker in data, sth may be wrong.'
    );
    return;
  }

  clusterMap.value?.markersRef.forEach((marker) => {
    if (marker.options?.markerType === DEM_POINT_MARKER_TYPE) return;

    updateMarkerSelectedState(marker, pickedPoint.value.markerId === marker.markerId);
  });

  if (pickedPoint.value) {
    emit('pointPicked', pickedPoint.value);
  }
}

function tryToShowDraggableMarker() {
  if (draggableMarkerVisible.value) return;
  if (!isNotEmptyArray(clusterMap.value?.markersRef)) return;

  if (clusterMap.value?.currentCenter) {
    draggableMarker.value.lat = clusterMap.value.currentCenter.lat;
    draggableMarker.value.lng = clusterMap.value.currentCenter.lng;
  }

  clusterMap.value.addMarker(DRAGGABLE_MARKER_FEATURE_GROUP_KEY, draggableMarker.value);
  unselectPickedPoint();
  emit('pointPicked', { lat: draggableMarker.value.lat, lon: draggableMarker.value.lng });
  draggableMarkerVisible.value = true;
}

function unselectPickedPoint() {
  if (!pickedPoint.value) return;

  const foundMarker = clusterMap.value?.markersRef.find(
    (marker) => marker.markerId == pickedPoint.value.markerId
  );

  if (foundMarker) {
    updateMarkerSelectedState(foundMarker, false);
  }
}

function tryToHideDraggableMarker() {
  if (!draggableMarkerVisible.value) return;
  if (!isNotEmptyArray(clusterMap.value?.markersRef)) return;

  clusterMap.value.removeFeatureGroup(DRAGGABLE_MARKER_FEATURE_GROUP_KEY);
  emit('pointPicked', null);
  draggableMarkerVisible.value = false;
}

function initializeDraggableMarker() {
  if (props.showDraggableMarker) {
    tryToShowDraggableMarker();
  } else {
    tryToHideDraggableMarker();
  }
}

// created
fetchData();
</script>

<template>
  <div class="ibox">
    <div class="ibox-content" :class="{ 'sk-loading': isLoading }" style="border-style: none">
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>

      <ClusterMap
        ref="clusterMap"
        height="500px"
        :disableClusteringAtZoomOverride="3"
        @markerClicked="onMarkerClicked"
        @markerDragged="emit('pointPicked', $event)"
      />
    </div>
  </div>
</template>

<style scoped></style>
