<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { RepositoryFactory } from '../data/repositoryFactory';
import { generateReport } from '../helpers/registerReportGeneratorHelper';

const RepositoryOrders = RepositoryFactory.get('orders');
const route = useRoute();
const isLoading = ref(false);

function generateRegisterReport() {
  isLoading.value = true;

  RepositoryOrders.getRegisterRaportContentByUUID(route.params.uuid)
    .then((data) => {
      generateReport(data, 'Rejestr zleceń');
    })
    .catch((error) => console.log(error))
    .finally(() => {
      isLoading.value = false;
    });
}

onMounted(() => {
  generateRegisterReport();
});
</script>

<template>
  <div class="ibox">
    <div
      class="ibox-content"
      :class="{ 'sk-loading': isLoading }"
      style="border-style: none; height: 100px"
    >
      <div class="sk-spinner sk-spinner-three-bounce">
        <div class="sk-bounce1"></div>
        <div class="sk-bounce2"></div>
        <div class="sk-bounce3"></div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
