<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import ModalBase from '@/components/utils/ModalBase.vue';
import ModalBody from '@/components/utils/ModalBody.vue';
import TransportActionAddEdit from './TransportActionAddEdit.vue';

const props = defineProps({
  userOrderTransport: Object,
  orderId: Number,
  orderNumber: String
});
const emit = defineEmits(['succeeded']);
const router = useRouter();
const modalBase = ref();
const showTransportAddEditComponent = ref(false);

function goToTransportOrderDetails(id) {
  if (id == null) return;
  router.push({ name: 'transportOrderDetails', params: { id } });
}

onMounted(() => {
  show();
});

function show() {
  modalBase.value.show();
  setTimeout(() => (showTransportAddEditComponent.value = true), 500);
}

function onTransportActionSucceeded() {
  modalBase.value.hide();
  emit('succeeded');
}

defineExpose({ show });
</script>

<template>
  <ModalBase ref="modalBase">
    <ModalBody>
      <template v-slot:title>
        Wykonanie zlecenia
        <a @click="goToTransportOrderDetails(props.orderId)" :class="'text-navy'">
          {{ props.orderNumber }}
        </a>
        dla
        {{
          props.userOrderTransport?.worker.firstName +
          ' ' +
          props.userOrderTransport?.worker.lastName
        }}
      </template>
      <template v-slot:body>
        <TransportActionAddEdit
          v-if="showTransportAddEditComponent"
          :orderId="props.orderId"
          :userOrderId="props.userOrderTransport?.id"
          @succeeded="onTransportActionSucceeded"
        />
      </template>
    </ModalBody>
  </ModalBase>
</template>

<style scoped></style>
