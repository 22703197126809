//TODO NOT IMPORTANT check if up-to-date

import { isNotEmptyArray } from '../helpers/utilsHelper.js';
import repository from './repository.js';
import { createToaster } from '@meforma/vue-toaster';
import { isFormattedYYYYMM } from '../helpers/dateFormatHelper.js';

export default {
  async getOrdersObject(page, itemsPerPage, search, sortBy, orderType, filter, userFilter) {
    let url = '/order';

    if (filter && filter.subject && filter.id != null)
      url = `/${filter.subject}/${filter.id}/orders`;
    else if (filter && filter.url) url = filter.url;

    let userFilterUrlEnding = '';

    if (userFilter) {
      for (const property in userFilter) {
        if (Array.isArray(userFilter[property])) {
          userFilter[property].forEach((x) => {
            userFilterUrlEnding += `&${property}[]=${x}`;
          });
        } else userFilterUrlEnding += `&${property}=${userFilter[property]}`;
      }
    }

    return repository
      .get(
        `${url}?page=${page}&search=${search}&orderBy=${sortBy}&orderType=${orderType}&paginate=${itemsPerPage}${userFilterUrlEnding}`
      )
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((error) => {
        return { error };
      });
  },

  async getOrderDetailsGeneral(id, urlEnding = null) {
    return repository.get(`/order/${id}/${urlEnding ?? 'details_web'}`).then((response) => {
      if (response.data.error == null) return response.data.data;
    });
  },

  async getOrderAddresses(id) {
    return repository.get(`/order/${id}/details_web_addresses`).then((response) => {
      if (response.data.error == null) return response.data.data;
    });
  },

  async getOrderSummaryData(id) {
    return repository.get(`/order/${id}/summary`).then((response) => {
      if (response.data.error == null) return response.data;
    });
  },

  async getOrderSummaryRangesData(id) {
    return repository.get(`/order/${id}/summaryRanges`).then((response) => {
      if (response.data.error == null) return response.data;
    });
  },

  async getOrderUsers(id) {
    return repository.get(`/order/${id}/userOrders`).then((response) => {
      if (response.data.error == null) return response.data.data;
    });
  },

  async createOrder(order) {
    return repository
      .post('/order', order)
      .then((response) => {
        if (response && response.data && response.data.message) return response.data.message;
        else
          return {
            id: response.data.data.id,
            geotimestamp: response?.data?.data?.geotimestamp
          };
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async getOrderDataToEdit(id) {
    return repository.get(`/order/${id}/edit`).then((response) => {
      if (response.data.error == null) return response.data.data;
    });
  },

  async updateOrder(order) {
    return repository
      .put(`/order/${order.id}`, order)
      .then((response) => {
        if (response && response.data && response.data.message)
          return { message: response.data.message };
        else
          return {
            id: response?.data?.data?.id
          };
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async deleteOrder(id) {
    return repository
      .delete(`/order/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async reopenOrder(orderId) {
    return repository
      .get(`/order/${orderId}/unlockOrder`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async archiveOrder(orderId) {
    return repository
      .get(`/order/${orderId}/archive`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async restoreOrder(orderId) {
    return repository
      .get(`/order/${orderId}/restore`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async addContractorToOrder(orderId, userId) {
    return repository
      .post(`/order/${orderId}/addWorker`, { id_user: userId })
      .then((response) => {
        return response?.data?.message ?? null;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async sendTransportDate(orderId, transportDate) {
    let formData = new FormData();
    formData.append('transport_date', transportDate);

    return repository
      .post(`/order/${orderId}/addTransportFile`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then((response) => {
        if (response && response.data && response.data.message) return response.data.message;
        else return null;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async sendAcceptanceProtocol(orderId, file, creationDate, userId, number, amount, billingMonth) {
    let formData = new FormData();

    formData.append('file', file);
    formData.append('creation_date', creationDate);
    formData.append('created_by', userId);
    formData.append('number', number);
    formData.append('amount', amount);

    if (isFormattedYYYYMM(billingMonth)) {
      formData.append('billing_month', billingMonth + '-1');
    }

    return repository
      .post(`/order/${orderId}/addPO`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then((response) => {
        if (response && response.data && response.data.message) return response.data.message;
        else return null;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async sendGeodeticProtocol(orderId, file, creationDate, userId, pgDate, orders_ids = null) {
    let formData = new FormData();

    formData.append('file', file);
    formData.append('creation_date', creationDate);
    formData.append('created_by', userId);
    formData.append('pg_date', pgDate);

    if (Array.isArray(orders_ids)) {
      for (let index = 0; index < orders_ids.length; index++) {
        formData.append(`orders_ids[${index}]`, orders_ids[index]);
      }
    }

    return repository
      .post(`/order/${orderId}/addPG`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then((response) => {
        return response?.data?.message ?? null;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async sendInvoice(orderId, file, creationDate, userId, invoiceNumber, cost) {
    let formData = new FormData();

    formData.append('file', file);
    formData.append('creation_date', creationDate);
    formData.append('created_by', userId);
    formData.append('number', invoiceNumber);
    formData.append('cost', cost);

    return repository
      .post(`/order/${orderId}/addFV`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then((response) => {
        if (response && response.data && response.data.message) return response.data.message;
        else return null;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async getTransportList(orderId) {
    return repository
      .get(`/order/${orderId}/getDataForTransportList`)
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((error) => {
        return { error };
      });
  },

  async updateGeodeticPoints(orderId, geodeticPointsObject) {
    return repository
      .post(`/order/${orderId}/geodeticPointsUpdate`, {
        data: geodeticPointsObject
      })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async getPolesList(orderId) {
    return repository
      .get(`/order/${orderId}/poles`)
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((error) => {
        return { error };
      });
  },

  async getPolesForPGList(orderId) {
    return repository
      .get(`/order/${orderId}/polesForPGList`)
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((error) => {
        return { error };
      });
  },

  async getPointsForGeodesistByUUID(orderUUID) {
    return repository
      .get(`/order/${orderUUID}/polesForPGListByUUID`)
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((error) => {
        return { error };
      });
  },

  async getOrdersForMapObject(page, itemsPerPage, search, sortBy, orderType, userFilter) {
    let url = '/order/mapWeb';

    let userFilterUrlEnding = '';

    if (userFilter) {
      for (const property in userFilter) {
        if (Array.isArray(userFilter[property])) {
          userFilter[property].forEach((x) => {
            userFilterUrlEnding += `&${property}[]=${x}`;
          });
        } else userFilterUrlEnding += `&${property}=${userFilter[property]}`;
      }
    }

    return repository
      .get(
        `${url}?page=${page}&search=${search}&orderBy=${sortBy}&orderType=${orderType}&paginate=${itemsPerPage}${userFilterUrlEnding}`
      )
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((error) => {
        return { error };
      });
  },

  //TODO is it needed?
  async getOrderActions(id) {
    return repository.get(`/order/${id}/actionsForMap`).then((response) => {
      if (response.data.error == null) return response.data.data;
    });
  },

  async getDemPointsForMap(id) {
    return repository.get(`/order/${id}/demPointsForMap`).then((response) => {
      if (response.data.error == null) return response.data.data;
    });
  },

  async getActionsWithNoDemPointForMap(id) {
    return repository.get(`/order/${id}/actionsWithNoDemPointForMap`).then((response) => {
      if (response.data.error == null) return response.data.data;
    });
  },

  async getWorkersStartStopData(id) {
    return repository.get(`/order/${id}/markersForMap`).then((response) => {
      if (response.data.error == null) return response.data.data;
    });
  },

  async getOrdersForMapObjectByOrdersIds(
    page,
    itemsPerPage,
    search,
    sortBy,
    orderType,
    userFilter,
    ordersIds
  ) {
    let url = '/order/map_array';

    let userFilterUrlEnding = '';

    if (userFilter) {
      for (const property in userFilter) {
        userFilterUrlEnding += `&${property}=${userFilter[property]}`;
      }
    }

    return repository
      .post(
        `${url}?page=${page}&search=${search}&orderBy=${sortBy}&orderType=${orderType}&paginate=${itemsPerPage}${userFilterUrlEnding}`,
        { data: ordersIds }
      )
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((error) => {
        return { error };
      });
  },

  async addContractorToMultipleOrders(userId, ordersIds) {
    return repository
      .post(`/order/addWorkerToMultiple`, {
        id_user: userId,
        orders: ordersIds
      })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async sendAttachmentNo6(orderId, poles) {
    return repository
      .post(`/order/${orderId}/addPoles`, {
        data: poles
      })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async changeOrderStatus(orderId, statusId) {
    return repository
      .post(`/order/${orderId}/changeStatus`, { new_id: statusId })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async changeOrderGeodeticPhase(orderId, geodeticPhaseId) {
    return repository
      .post(`/order/${orderId}/changeGeodesyStatus`, { new_id: geodeticPhaseId })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async addFileToOrder(orderId, file, creationDate, userId) {
    const toaster = createToaster({ position: 'top-right', duration: 2000, type: 'info' });
    toaster.show(`<b>${file.name}</b>Rozpoczęto przesyłanie pliku.`);

    let formData = new FormData();

    formData.append('file', file);
    formData.append('creation_date', creationDate);
    formData.append('created_by', userId);

    return repository
      .post(`/order/${orderId}/addFile`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(function (response) {
        return response?.data?.message ?? null;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async exportOrders(search, sortBy, orderType, userFilter) {
    let url = '/order/index_raport';

    let userFilterUrlEnding = '';

    if (userFilter) {
      for (const property in userFilter) {
        userFilterUrlEnding += `&${property}=${userFilter[property]}`;
      }
    }

    return repository
      .get(`${url}?search=${search}&orderBy=${sortBy}&orderType=${orderType}${userFilterUrlEnding}`)
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async createPolesTypesSetForOrder(orderId, formData) {
    return repository
      .post(`/orderaddress`, {
        id_order: orderId,
        address: formData.address,
        poles: formData.parameters
      })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async updatePolesTypesSetForOrder(orderId, setId, formData) {
    return repository
      .put(`/orderaddress/${setId}`, {
        id_order: orderId,
        address: formData.address,
        poles: formData.parameters
      })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async removePolesTypesSetForOrder(orderAddressId) {
    return repository
      .delete(`/orderaddress/${orderAddressId}`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async getOrderGeodeticDates(id) {
    return repository.get(`/order/${id}/geodeticDates`).then((response) => {
      if (response.data.error == null) return response.data;
    });
  },

  async updateGeodeticDates(orderId, realizationDate, sharedWithOPLDate) {
    return repository
      .post(`/order/${orderId}/updatePGDates`, {
        geodesy_realization_date: realizationDate,
        sended_to_verification_date: sharedWithOPLDate
      })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async getOrderAddressesObject(orderId, page, itemsPerPage, search, sortBy) {
    let url = `/order/${orderId}/addressesList`;

    return repository
      .get(`${url}?page=${page}&search=${search}&orderBy=${sortBy}&paginate=${itemsPerPage}`)
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((error) => {
        return { error };
      });
  },

  async getOrderTransportStats(id) {
    return repository.get(`/order/${id}/transportStats`).then((response) => {
      if (response.data.error == null) return response.data.data;
    });
  },

  async createAddressForOrder(orderId, addressFormData) {
    let formData = new FormData();

    formData.append('id_order', orderId);
    formData.append('address', addressFormData?.address);

    if (addressFormData?.owner_phone != null && addressFormData?.owner_phone !== '') {
      formData.append('owner_phone', addressFormData?.owner_phone);
    }

    formData.append('quantity', addressFormData?.quantity);
    formData.append('id_order_range', addressFormData?.order_range?.id);
    formData.append('id_unit_of_measure', addressFormData?.unit_of_measure?.id);

    if (addressFormData?.description != null) {
      formData.append('description', addressFormData?.description);
    }

    formData.append('create_storage_place', addressFormData?.createStoragePlace ? 1 : 0);

    if (isNotEmptyArray(addressFormData?.files)) {
      for (let index = 0; index < addressFormData.files.length; index++) {
        formData.append(`files[${index}][file]`, addressFormData.files[index]);
      }
    }

    return repository
      .post(`/address`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async getOrderLocations(orderId) {
    return repository.get(`/order/${orderId}/demPoints`).then((response) => {
      if (response.data.error == null) return response.data.data;
    });
  },

  async addOrderLocations(orderId, demElemTypeId, locations) {
    return repository
      .post(`/order/${orderId}/addDemPoints`, {
        id_elem_type: demElemTypeId,
        data: locations
      })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async getGeodeticPoints(orderId) {
    return repository.get(`/order/${orderId}/geodeticPoints`).then((response) => {
      if (response.data.error == null) return response.data;
    });
  },

  async getDemPointsCoordinates(orderId) {
    return repository.get(`/order/${orderId}/demPointsCoorinates`).then((response) => {
      if (response.data.error == null) return response.data;
    });
  },

  async getNewActionInitialLocation(orderId) {
    return repository.get(`/order/${orderId}/orderPosition`).then((response) => {
      if (response.data.error == null && response.data?.data) {
        return {
          lat: response.data?.data?.first_dem_point?.lat ?? response.data?.data?.first_address?.lat,
          lon: response.data?.data?.first_dem_point?.lon ?? response.data?.data?.first_address?.lon
        };
      }
    });
  },

  async createAction(
    id_user_order,
    id_dem_elem_type,
    id_action_type,
    locations,
    note,
    storage,
    priority,
    dem_points,
    files,
    osdStringified,
    id_failure_action_reason,
    id_cable_type_for_failure,
    declarationOfDisagreement,
    id_extra_dem_source
  ) {
    let formData = new FormData();

    formData.append('id_user_order', id_user_order);

    if (id_dem_elem_type != null) {
      formData.append('id_dem_elem_type', id_dem_elem_type);
    }

    if (id_action_type != null) {
      formData.append('id_action_type', id_action_type);
    }

    if (isNotEmptyArray(locations)) {
      formData.append('locations', JSON.stringify(locations));
    }

    if (note != null) {
      formData.append('note', note);
    }

    if (isNotEmptyArray(storage)) {
      formData.append('storage', JSON.stringify(storage));
    }

    if (Array.isArray(dem_points)) {
      for (let index = 0; index < dem_points.length; index++) {
        formData.append(`dem_points[${index}]`, dem_points[index]);
      }
    }

    if (Array.isArray(files)) {
      for (let index = 0; index < files.length; index++) {
        formData.append(`files[${index}][file]`, files[index]);
      }
    }

    if (osdStringified != null) {
      formData.append('osd', osdStringified);
    }

    if (priority != null) {
      formData.append('priority', priority ? 1 : 0);
    }

    if (id_failure_action_reason != null) {
      formData.append('id_failure_action_reason', id_failure_action_reason);
    }

    if (id_cable_type_for_failure != null) {
      formData.append('id_cable_type_for_failure', id_cable_type_for_failure);
    }

    if (declarationOfDisagreement != null) {
      formData.append('declaration_of_disagreement', declarationOfDisagreement ? 1 : 0);
    }

    if (id_extra_dem_source != null) {
      formData.append('id_extra_dem_source', id_extra_dem_source);
    }

    return repository
      .post(`/order/storeActionWEB`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then((response) => {
        return isNotEmptyArray(response?.data?.actions)
          ? { success_ids: response.data.actions.map((x) => x.id_global).filter((x) => x != null) }
          : response?.data?.message ?? null;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async changeInterruptionStatus(orderId, interruptionStatusId) {
    return repository
      .post(`/order/${orderId}/changeInterruptionStatus`, {
        new_id: interruptionStatusId
      })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async addDemPointManually(orderId, formData) {
    return repository
      .post(`/order/${orderId}/addDemPointManualy`, formData)
      .then((response) => {
        return response?.data?.message ?? null;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async stopOrCancelOrder(userOrderId, stopReason, ln_present) {
    return repository
      .post(`/order/stopOrderWEB`, {
        id_user_order: userOrderId,
        stop_reason: stopReason,
        ln_present: stopReason === 'stop' ? ln_present : undefined
      })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async changeSubcontractor(orderId, subcontractorId) {
    return repository
      .post(`/order/${orderId}/changeSubcontractor`, {
        id_subcontractor: subcontractorId
      })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async changeSettlementStatus(orderId, settlementStatusId) {
    return repository
      .post(`/order/${orderId}/changeSettlementStatus`, {
        new_id: settlementStatusId
      })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async requestOrdersReport(authorId, search, userFilter) {
    let userFilterUrlEnding = '';

    if (userFilter) {
      for (const property in userFilter) {
        if (Array.isArray(userFilter[property])) {
          userFilter[property].forEach((x) => {
            userFilterUrlEnding += `&${property}[]=${x}`;
          });
        } else userFilterUrlEnding += `&${property}=${userFilter[property]}`;
      }
    }

    return repository
      .post(`/registerraport/GenerateRaport?search=${search}${userFilterUrlEnding}`, {
        id_author: authorId
      })
      .then((response) => {
        return { success: response?.data?.id != null };
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async getOrderRegisterDetails(id) {
    return repository
      .get(`/order/${id}/registerDetails`)
      .then((response) => {
        if (response.data.error == null && response.data.data != null) return response.data.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async updateOrderRegisterDetails(
    orderId,
    ln_present,
    commentsRegistration,
    commentsInternals,
    dates
  ) {
    return repository
      .post(`/order/${orderId}/updateCommentsAndDates`, {
        ln_present: ln_present,
        comments_registration: commentsRegistration,
        comments_internals: commentsInternals,
        dates: dates
      })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async getRegisterReportContent(reportId) {
    return repository
      .get(`/registerraport/${reportId}/GetRaportContent`)
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((error) => {
        return { error };
      });
  },

  async getRegisterRaportContentByUUID(uuid) {
    return repository
      .get(`/registerraport/${uuid}/GetRaportContentByUUID`)
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((error) => {
        return { error };
      });
  },

  async removeSubcontractor(orderId) {
    return repository
      .get(`/order/${orderId}/removeSubcontractor`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async updateCommentsForSettlement(orderId, settlementComment) {
    return repository
      .post(`/order/${orderId}/updateCommentsForSettlement`, {
        settlement_comment: settlementComment
      })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async deleteRegisterReport(id) {
    return repository
      .delete(`/registerraport/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async verifyDelete(id) {
    return repository
      .get(`/order/${id}/verifyDelete`)
      .then((response) => {
        if (response.data.error == null && response.data != null) return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async verifyArchive(id) {
    return repository
      .get(`/order/${id}/verifyArchive`)
      .then((response) => {
        if (response.data.error == null && response.data != null) return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async toggleActionPhotoShareStatus(orderId) {
    return repository
      .get(`/order/${orderId}/changeActionPhotoShareStatus`)
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async changeClaim(orderId, newClaimValue) {
    return repository
      .post(`/order/${orderId}/setClaim`, { claim: newClaimValue })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async changeOrderProtocolType(orderId, id_protocol_type) {
    return repository
      .post(`/order/${orderId}/changeProtocolType`, { id_protocol_type })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async getLocationsVerificationInfo(orderId) {
    return repository.get(`/order/${orderId}/demPointManualVerify`).then((response) => {
      if (response.data.error == null) return response.data;
    });
  },

  async changePriority(orderId, newPriorityValue) {
    return repository
      .post(`/order/${orderId}/setPriority`, { priority: newPriorityValue })
      .then((response) => {
        return response.data;
      })
      .catch((serverError) => {
        return { serverError };
      });
  },

  async getOrdersDetailsForMapObjectByOrdersIds(ordersIds) {
    return repository
      .post('/order/summaryMap', { data: ordersIds })
      .then((response) => {
        if (response.data.error == null) return response.data;
      })
      .catch((error) => {
        return { error };
      });
  }
};
