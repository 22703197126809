<script setup>
import { computed } from 'vue';
import { RepositoryFactory } from '@/data/repositoryFactory.js';
import { useAuthStore } from '@/stores/auth';
import { getCurrentDate } from '@/helpers/utilsHelper.js';
import { createToaster } from '@meforma/vue-toaster';
import DropArea from '@/components/utils/DropArea.vue';
import { isNotEmptyArray } from '../../helpers/utilsHelper';

import { useFilesHandler } from '@/composables/filesHandler.js';
import {
  TOASTER_ATTACH_FILE_MSG,
  TOASTER_SERVER_ERROR_MSG
} from '../../data/constants/toasterMessagesConstants';
const { showFileInput, filesRef, onFilesChanged, onDropAreaFilesChanged } = useFilesHandler(
  true,
  'fileInputField45'
);

const props = defineProps({ orderId: Number });
const emit = defineEmits(['refresh']);
const authStore = useAuthStore();
const RepositoryOrders = RepositoryFactory.get('orders');
const toaster = createToaster({ position: 'top-right', duration: 3000 });

const loggedInUser = computed(() => authStore.loggedInUser);

async function onFileUpload() {
  if (!isNotEmptyArray(filesRef.value)) {
    toaster.show(TOASTER_ATTACH_FILE_MSG, { type: 'warning' });
    return;
  }

  filesRef.value.forEach(async (fileObj) => {
    var response = await RepositoryOrders.addFileToOrder(
      props.orderId,
      fileObj.file,
      getCurrentDate(),
      loggedInUser.value?.id
    );

    if (response?.serverError) {
      toaster.show(TOASTER_SERVER_ERROR_MSG, { type: 'error' });
    } else {
      if (response) {
        let msg = 'Coś poszło nie tak.';
        if (Array.isArray(response)) {
          msg = '';
          response.forEach((element) => {
            msg += element + '. ';
          });
        }
        toaster.show(msg, { type: 'warning' });
      } else {
        emit('refresh');
        showFileInput.value = false;
        toaster.show(`<b>${fileObj.file.name}</b>Plik został przesłany.`, { type: 'success' });
      }
    }
  });

  filesRef.value = [];
}
</script>

<template>
  <div v-if="showFileInput" class="mt-4">
    <DropArea
      id="dropArea45"
      class="dropArea"
      caption="Upuść plik tutaj albo kliknij 'Wybierz plik' poniżej"
      @onFileChanged="onDropAreaFilesChanged($event)"
    />
    <input
      id="fileInputField45"
      name="file"
      type="file"
      class="mt-2"
      multiple
      @change="onFilesChanged($event)"
    />
    <button class="btn btn-primary mt-2 float-right" @click="onFileUpload">Prześlij plik</button>
  </div>
  <div v-else class="mt-3">
    <button class="btn btn-outline btn-primary mt-2" @click="showFileInput = true">
      Dodaj plik
    </button>
  </div>
</template>

<style scoped></style>
