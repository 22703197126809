import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import repository from '@/data/repository.js';
import { isNonEmptyString } from '../helpers/utilsHelper.js';
import {
  ADMIN_TYPE_ID,
  WORKER_TYPE_ID,
  DEV_TYPE_ID,
  COORDINATOR_TYPE_ID,
  DRIVER_TYPE_ID,
  TRANSPORT_MANAGER_TYPE_ID,
  SUBCONTRACTOR_TYPE_ID,
  RADO_COORDINATOR_TYPE_ID,
  ACCESS_KEY_SIDE_MENU_DASHBOARD,
  ACCESS_KEY_SIDE_MENU_ORDER_STATUSES,
  ACCESS_KEY_SIDE_MENU_GEODESY_PHASES,
  ACCESS_KEY_SIDE_MENU_USERS,
  ACCESS_KEY_SIDE_MENU_DICTIONARIES,
  ACCESS_KEY_SIDE_MENU_TRANSPORT_ORDERS,
  ACCESS_KEY_SIDE_MENU_TRANSPORT_ARCHIVED_ORDERS,
  ACCESS_KEY_SIDE_MENU_ARCHIVED_ORDERS,
  ACCESS_KEY_ORDERS_TABLE_NEW_ORDER_BUTTON,
  ACCESS_KEY_ORDERS_TABLE_EXPORT_BUTTON,
  ACCESS_KEY_ORDERS_TABLE_FILTERS_ORDER_STATUS_FILTER,
  ACCESS_KEY_ORDER_DETAILS_CONTRACTORS_TAB,
  ACCESS_KEY_ORDER_DETAILS_TRANSPORT_TAB,
  ACCESS_KEY_ORDER_DETAILS_GEODETIC_PROTOCOL_TAB,
  ACCESS_KEY_ORDER_DETAILS_ACCEPTANCE_PROTOCOL_TAB,
  ACCESS_KEY_ORDER_DETAILS_REPOSITORY_TAB,
  ACCESS_KEY_ORDER_DETAILS_ARCHIVE_BUTTON,
  ACCESS_KEY_ORDER_DETAILS_EDIT_BUTTON,
  ACCESS_KEY_ORDER_DETAILS_DELETE_BUTTON,
  ACCESS_KEY_ACTION_ADD_BUTTON,
  ACCESS_KEY_ACTION_EDIT_BUTTON,
  ACCESS_KEY_ACTION_SHOW_HIDE_BUTTON,
  ACCESS_KEY_ACTION_REMOVE_BUTTON,
  ACCESS_KEY_DEMPOINT_ACTIVATE_DEACTIVATE_BUTTON,
  ACCESS_KEY_ADD_WORKER_TO_ORDER_BUTTON,
  ACCESS_KEY_ORDERS_TABLE_REQUEST_REPORT_BUTTON,
  ACCESS_KEY_ORDER_DETAILS_ADDITIONAL_INFO_TAB,
  ACCESS_KEY_SIDE_MENU_REPORTS_HISTORY,
  ACCESS_KEY_ORDERS_TABLE_REPORTS_HISTORY_TAB,
  ACCESS_KEY_ORDERS_TABLE_ARCHIVED_TAB,
  ACCESS_KEY_SETTLEMENT_COMMENT,
  ACCESS_KEY_SIDE_MENU_ACTIONS_REGISTER,
  ACCESS_KEY_ACTIONS_REGISTER_TABLE_REQUEST_REPORT_BUTTON,
  ACCESS_KEY_ORDER_DETAILS_MAP_POINTS_REPORTS_TAB,
  ACCESS_KEY_TRANSPORT_ORDER_DETAILS_DELETE_BUTTON,
  ACCESS_KEY_USER_CREATE,
  ACCESS_KEY_USER_UPDATE,
  ACCESS_KEY_OTHER_USER_PASSWORD_UPDATE,
  ACCESS_KEY_UPDATE_USERS_2FA,
  ACCESS_KEY_DICTIONARIES_VIEW_ID_COLUMN
} from '../data/constants/authConstants.js';

import {
  LOGIN_ACCESS_USER_TYPES,
  SIDE_MENU_DASHBOARD_ACCESS_USER_TYPES,
  SIDE_MENU_ORDER_STATUSES_ACCESS_USER_TYPES,
  SIDE_MENU_GEODESY_PHASES_ACCESS_USER_TYPES,
  SIDE_MENU_USERS_ACCESS_USER_TYPES,
  SIDE_MENU_DICTIONARIES_ACCESS_USER_TYPES,
  SIDE_MENU_TRANSPORT_ORDERS_ACCESS_USER_TYPES,
  SIDE_MENU_TRANSPORT_ARCHIVED_ORDERS_ACCESS_USER_TYPES,
  SIDE_MENU_ARCHIVED_ORDERS_ACCESS_USER_TYPES,
  SIDE_MENU_REPORTS_HISTORY_ACCESS_USER_TYPES,
  ORDERS_TABLE_NEW_ORDER_BUTTON_ACCESS_USER_TYPES,
  ORDERS_TABLE_EXPORT_BUTTON_ACCESS_USER_TYPES,
  ORDERS_TABLE_FILTERS_ORDER_STATUS_FILTER_ACCESS_USER_TYPES,
  ORDER_DETAILS_CONTRACTORS_TAB_ACCESS_USER_TYPES,
  ORDER_DETAILS_TRANSPORT_TAB_ACCESS_USER_TYPES,
  ORDER_DETAILS_GEODETIC_PROTOCOL_TAB_ACCESS_USER_TYPES,
  ORDER_DETAILS_ACCEPTANCE_PROTOCOL_TAB_ACCESS_USER_TYPES,
  ORDER_DETAILS_REPOSITORY_TAB_ACCESS_USER_TYPES,
  ORDER_DETAILS_ARCHIVE_BUTTON_ACCESS_USER_TYPES,
  ORDER_DETAILS_EDIT_BUTTON_ACCESS_USER_TYPES,
  ORDER_DETAILS_DELETE_BUTTON_ACCESS_USER_TYPES,
  ADD_ACTIONS_FEATURE_ACCESS_USER_TYPES,
  EDIT_ACTIONS_FEATURE_ACCESS_USER_TYPES,
  SHOW_HIDE_ACTIONS_FEATURE_ACCESS_USER_TYPES,
  REMOVE_ACTIONS_FEATURE_ACCESS_USER_TYPES,
  ACTIVATE_DEACTIVATE_DEMPOINT_FEATURE_ACCESS_USER_TYPES,
  ADD_WORKER_TO_ORDER_FEATURE_ACCESS_USER_TYPES,
  ORDERS_TABLE_REQUEST_REPORT_BUTTON_ACCESS_USER_TYPES,
  ORDER_DETAILS_ADDITIONAL_INFO_TAB_ACCESS_USER_TYPES,
  ORDERS_TABLE_REPORTS_HISTORY_TAB_ACCESS_USER_TYPES,
  ORDERS_TABLE_ARCHIVED_TAB_ACCESS_USER_TYPES,
  SETTLEMENT_COMMENT_ACCESS_USER_TYPES,
  SIDE_MENU_ACTIONS_REGISTER_ACCESS_USER_TYPES,
  ACTIONS_REGISTER_TABLE_REQUEST_REPORT_BUTTON_ACCESS_USER_TYPES,
  ORDER_DETAILS_MAP_POINTS_REPORTS_TAB_ACCESS_USER_TYPES,
  TRANSPORT_ORDER_DETAILS_DELETE_BUTTON_ACCESS_USER_TYPES,
  USER_CREATE_ACCESS_USER_TYPES,
  USER_UPDATE_ACCESS_USER_TYPES,
  OTHER_USERS_PASSWORD_UPDATE_ACCESS_USER_TYPES,
  UPDATE_USERS_2FA_ACCESS_USER_TYPES,
  DICTIONARIES_VIEW_ID_COLUMN_ACCESS_USER_TYPES
} from '../data/projectConfig.js';

export const useAuthStore = defineStore('auth', () => {
  // state
  const token = ref(localStorage.getItem('user-token') || '');
  const loggedInUserState = ref(localStorage.getItem('loggedInUserState'));
  const formInfo = ref('');

  // computed
  const isAuthenticated = computed(() => !!token.value);
  const loggedInUser = computed(() => getCurrentLoggedInUserState()?.loggedInUser ?? null);
  const loggedInUserTypeId = computed(() => loggedInUser.value?.type?.id ?? null);
  const isAdminLoggedIn = computed(() => loggedInUser.value?.type?.id === ADMIN_TYPE_ID);
  const isWorkerLoggedIn = computed(() => loggedInUser.value?.type?.id === WORKER_TYPE_ID);
  const isDeveloperLoggedIn = computed(() => loggedInUser.value?.type?.id === DEV_TYPE_ID);
  const isCoordinatorLoggedIn = computed(
    () => loggedInUser.value?.type?.id === COORDINATOR_TYPE_ID
  );
  const isDriverLoggedIn = computed(() => loggedInUser.value?.type?.id === DRIVER_TYPE_ID);
  const isTransportManagerLoggedIn = computed(
    () => loggedInUser.value?.type?.id === TRANSPORT_MANAGER_TYPE_ID
  );
  const isSubcontractorLoggedIn = computed(
    () => loggedInUser.value?.type?.id === SUBCONTRACTOR_TYPE_ID
  );
  const isRADOCCoordinatorLoggedIn = computed(
    () => loggedInUser.value?.type?.id === RADO_COORDINATOR_TYPE_ID
  );

  const loggedInUserSettings = computed(() =>
    isNonEmptyString(loggedInUser.value?.user_settings)
      ? JSON.parse(loggedInUser.value.user_settings)
      : {}
  );

  // actions
  function requestAuth(user) {
    return new Promise((resolve, reject) => {
      repository
        .get('/login', {
          auth: {
            username: user.username,
            password: user.password
          }
        })
        .then((response) => handleAuthResponse(response, resolve, reject))
        .catch((error) => handleAuthError(error, reject));
    });
  }

  function request2FAVerification(username, code) {
    return new Promise((resolve, reject) => {
      repository
        .post('/user/Confirm2FA', {
          email: username,
          code
        })
        .then((response) => handleAuthResponse(response, resolve, reject))
        .catch((error) => handleAuthError(error, reject));
    });
  }

  function handleAuthResponse(response, resolve, reject) {
    if (
      response?.data?.data?.type?.id != null &&
      !LOGIN_ACCESS_USER_TYPES.includes(response?.data?.data?.type?.id)
    ) {
      formInfo.value = 'Brak uprawnień.';
      reject('Brak uprawnień.');
      return;
    }

    const apiToken = response.data.data.api_token;
    localStorage.setItem('user-token', apiToken);
    token.value = apiToken;
    changeLoggedInUser(response.data.data);
    repository.defaults.headers.common['Authorization'] = 'Bearer ' + apiToken;
    resolve(response);
  }

  function handleAuthError(error, reject) {
    formInfo.value = error?.response?.data[0];
    localStorage.removeItem('user-token');
    reject(error);
  }

  function logOut() {
    return new Promise((resolve) => {
      localStorage.removeItem('user-token');
      changeLoggedInUser(null);
      delete repository.defaults.headers.common['Authorization'];
      token.value = null;
      resolve();
    });
  }

  function resetFormError() {
    formInfo.value = null;
  }

  function changeLoggedInUserFirstName(firstName) {
    let currentLoggedInUserState = getCurrentLoggedInUserState();
    currentLoggedInUserState.loggedInUser.firstName = firstName;
    loggedInUserState.value = JSON.stringify(currentLoggedInUserState);
    localStorage.setItem('loggedInUserState', loggedInUserState.value);
  }

  function changeLoggedInUserLastName(lastName) {
    let currentLoggedInUserState = getCurrentLoggedInUserState();
    currentLoggedInUserState.loggedInUser.lastName = lastName;
    loggedInUserState.value = JSON.stringify(currentLoggedInUserState);
    localStorage.setItem('loggedInUserState', loggedInUserState.value);
  }

  function changeLoggedInUser(user) {
    let currentLoggedInUserState = getCurrentLoggedInUserState();
    currentLoggedInUserState.loggedInUser = user;
    loggedInUserState.value = JSON.stringify(currentLoggedInUserState);
    localStorage.setItem('loggedInUserState', loggedInUserState.value);
  }

  async function updateUserSettings(settings) {
    return new Promise((resolve, reject) => {
      repository
        .post(`/user/${loggedInUser.value.id}/updateUserSettings`, {
          user_settings: settings
        })
        .then((response) => {
          console.log(response);
          if (response?.data?.data?.type?.id != null) {
            changeLoggedInUser(response.data.data);
          }
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  }

  async function resetUserColumnsSettings() {
    try {
      const currentSettings = loggedInUserSettings.value || {};
      const newSettings = { ...currentSettings };

      if (newSettings.tables) {
        delete newSettings.tables;
      }

      await updateUserSettings(JSON.stringify(newSettings));
      return true;
    } catch (error) {
      console.error('resetUserColumnsSettings(): Failed to reset columns settings:', error);
      throw error;
    }
  }

  function getCurrentLoggedInUserState() {
    return loggedInUserState.value ? JSON.parse(loggedInUserState.value) : {};
  }

  function canAccess(accessKey) {
    switch (accessKey) {
      case ACCESS_KEY_SIDE_MENU_DASHBOARD:
        return SIDE_MENU_DASHBOARD_ACCESS_USER_TYPES.includes(loggedInUserTypeId.value);
      case ACCESS_KEY_SIDE_MENU_ORDER_STATUSES:
        return SIDE_MENU_ORDER_STATUSES_ACCESS_USER_TYPES.includes(loggedInUserTypeId.value);
      case ACCESS_KEY_SIDE_MENU_GEODESY_PHASES:
        return SIDE_MENU_GEODESY_PHASES_ACCESS_USER_TYPES.includes(loggedInUserTypeId.value);
      case ACCESS_KEY_SIDE_MENU_USERS:
        return SIDE_MENU_USERS_ACCESS_USER_TYPES.includes(loggedInUserTypeId.value);
      case ACCESS_KEY_SIDE_MENU_DICTIONARIES:
        return SIDE_MENU_DICTIONARIES_ACCESS_USER_TYPES.includes(loggedInUserTypeId.value);
      case ACCESS_KEY_SIDE_MENU_TRANSPORT_ORDERS:
        return SIDE_MENU_TRANSPORT_ORDERS_ACCESS_USER_TYPES.includes(loggedInUserTypeId.value);
      case ACCESS_KEY_SIDE_MENU_TRANSPORT_ARCHIVED_ORDERS:
        return SIDE_MENU_TRANSPORT_ARCHIVED_ORDERS_ACCESS_USER_TYPES.includes(
          loggedInUserTypeId.value
        );
      case ACCESS_KEY_SIDE_MENU_ARCHIVED_ORDERS:
        return SIDE_MENU_ARCHIVED_ORDERS_ACCESS_USER_TYPES.includes(loggedInUserTypeId.value);
      case ACCESS_KEY_SIDE_MENU_REPORTS_HISTORY:
        return SIDE_MENU_REPORTS_HISTORY_ACCESS_USER_TYPES.includes(loggedInUserTypeId.value);
      case ACCESS_KEY_ORDERS_TABLE_NEW_ORDER_BUTTON:
        return ORDERS_TABLE_NEW_ORDER_BUTTON_ACCESS_USER_TYPES.includes(loggedInUserTypeId.value);
      case ACCESS_KEY_ORDERS_TABLE_EXPORT_BUTTON:
        return ORDERS_TABLE_EXPORT_BUTTON_ACCESS_USER_TYPES.includes(loggedInUserTypeId.value);
      case ACCESS_KEY_ORDERS_TABLE_FILTERS_ORDER_STATUS_FILTER:
        return ORDERS_TABLE_FILTERS_ORDER_STATUS_FILTER_ACCESS_USER_TYPES.includes(
          loggedInUserTypeId.value
        );
      case ACCESS_KEY_ORDER_DETAILS_CONTRACTORS_TAB:
        return ORDER_DETAILS_CONTRACTORS_TAB_ACCESS_USER_TYPES.includes(loggedInUserTypeId.value);
      case ACCESS_KEY_ORDER_DETAILS_TRANSPORT_TAB:
        return ORDER_DETAILS_TRANSPORT_TAB_ACCESS_USER_TYPES.includes(loggedInUserTypeId.value);
      case ACCESS_KEY_ORDER_DETAILS_GEODETIC_PROTOCOL_TAB:
        return ORDER_DETAILS_GEODETIC_PROTOCOL_TAB_ACCESS_USER_TYPES.includes(
          loggedInUserTypeId.value
        );
      case ACCESS_KEY_ORDER_DETAILS_ACCEPTANCE_PROTOCOL_TAB:
        return ORDER_DETAILS_ACCEPTANCE_PROTOCOL_TAB_ACCESS_USER_TYPES.includes(
          loggedInUserTypeId.value
        );
      case ACCESS_KEY_ORDER_DETAILS_REPOSITORY_TAB:
        return ORDER_DETAILS_REPOSITORY_TAB_ACCESS_USER_TYPES.includes(loggedInUserTypeId.value);
      case ACCESS_KEY_ORDER_DETAILS_ARCHIVE_BUTTON:
        return ORDER_DETAILS_ARCHIVE_BUTTON_ACCESS_USER_TYPES.includes(loggedInUserTypeId.value);
      case ACCESS_KEY_ORDER_DETAILS_EDIT_BUTTON:
        return ORDER_DETAILS_EDIT_BUTTON_ACCESS_USER_TYPES.includes(loggedInUserTypeId.value);
      case ACCESS_KEY_ORDER_DETAILS_DELETE_BUTTON:
        return ORDER_DETAILS_DELETE_BUTTON_ACCESS_USER_TYPES.includes(loggedInUserTypeId.value);
      case ACCESS_KEY_ACTION_ADD_BUTTON:
        return ADD_ACTIONS_FEATURE_ACCESS_USER_TYPES.includes(loggedInUserTypeId.value);
      case ACCESS_KEY_ACTION_EDIT_BUTTON:
        return EDIT_ACTIONS_FEATURE_ACCESS_USER_TYPES.includes(loggedInUserTypeId.value);
      case ACCESS_KEY_ACTION_SHOW_HIDE_BUTTON:
        return SHOW_HIDE_ACTIONS_FEATURE_ACCESS_USER_TYPES.includes(loggedInUserTypeId.value);
      case ACCESS_KEY_ACTION_REMOVE_BUTTON:
        return REMOVE_ACTIONS_FEATURE_ACCESS_USER_TYPES.includes(loggedInUserTypeId.value);
      case ACCESS_KEY_DEMPOINT_ACTIVATE_DEACTIVATE_BUTTON:
        return ACTIVATE_DEACTIVATE_DEMPOINT_FEATURE_ACCESS_USER_TYPES.includes(
          loggedInUserTypeId.value
        );
      case ACCESS_KEY_ADD_WORKER_TO_ORDER_BUTTON:
        return ADD_WORKER_TO_ORDER_FEATURE_ACCESS_USER_TYPES.includes(loggedInUserTypeId.value);
      case ACCESS_KEY_ORDERS_TABLE_REQUEST_REPORT_BUTTON:
        return ORDERS_TABLE_REQUEST_REPORT_BUTTON_ACCESS_USER_TYPES.includes(
          loggedInUserTypeId.value
        );
      case ACCESS_KEY_ORDER_DETAILS_ADDITIONAL_INFO_TAB:
        return ORDER_DETAILS_ADDITIONAL_INFO_TAB_ACCESS_USER_TYPES.includes(
          loggedInUserTypeId.value
        );
      case ACCESS_KEY_ORDERS_TABLE_REPORTS_HISTORY_TAB:
        return ORDERS_TABLE_REPORTS_HISTORY_TAB_ACCESS_USER_TYPES.includes(
          loggedInUserTypeId.value
        );
      case ACCESS_KEY_ORDERS_TABLE_ARCHIVED_TAB:
        return ORDERS_TABLE_ARCHIVED_TAB_ACCESS_USER_TYPES.includes(loggedInUserTypeId.value);
      case ACCESS_KEY_SETTLEMENT_COMMENT:
        return SETTLEMENT_COMMENT_ACCESS_USER_TYPES.includes(loggedInUserTypeId.value);
      case ACCESS_KEY_SIDE_MENU_ACTIONS_REGISTER:
        return SIDE_MENU_ACTIONS_REGISTER_ACCESS_USER_TYPES.includes(loggedInUserTypeId.value);
      case ACCESS_KEY_ACTIONS_REGISTER_TABLE_REQUEST_REPORT_BUTTON:
        return ACTIONS_REGISTER_TABLE_REQUEST_REPORT_BUTTON_ACCESS_USER_TYPES.includes(
          loggedInUserTypeId.value
        );
      case ACCESS_KEY_ORDER_DETAILS_MAP_POINTS_REPORTS_TAB:
        return ORDER_DETAILS_MAP_POINTS_REPORTS_TAB_ACCESS_USER_TYPES.includes(
          loggedInUserTypeId.value
        );
      case ACCESS_KEY_TRANSPORT_ORDER_DETAILS_DELETE_BUTTON:
        return TRANSPORT_ORDER_DETAILS_DELETE_BUTTON_ACCESS_USER_TYPES.includes(
          loggedInUserTypeId.value
        );
      case ACCESS_KEY_USER_CREATE:
        return USER_CREATE_ACCESS_USER_TYPES.includes(loggedInUserTypeId.value);
      case ACCESS_KEY_USER_UPDATE:
        return USER_UPDATE_ACCESS_USER_TYPES.includes(loggedInUserTypeId.value);
      case ACCESS_KEY_OTHER_USER_PASSWORD_UPDATE:
        return OTHER_USERS_PASSWORD_UPDATE_ACCESS_USER_TYPES.includes(loggedInUserTypeId.value);
      case ACCESS_KEY_UPDATE_USERS_2FA:
        return UPDATE_USERS_2FA_ACCESS_USER_TYPES.includes(loggedInUserTypeId.value);
      case ACCESS_KEY_DICTIONARIES_VIEW_ID_COLUMN:
        return DICTIONARIES_VIEW_ID_COLUMN_ACCESS_USER_TYPES.includes(loggedInUserTypeId.value);

      default:
        console.warn(`auth.js: canAccess(): Unknown access key: ${accessKey}`);
        return false;
    }
  }

  return {
    formInfo,
    isAuthenticated,
    loggedInUser,
    isAdminLoggedIn,
    isWorkerLoggedIn,
    isDeveloperLoggedIn,
    isCoordinatorLoggedIn,
    isDriverLoggedIn,
    isTransportManagerLoggedIn,
    isSubcontractorLoggedIn,
    isRADOCCoordinatorLoggedIn,
    loggedInUserSettings,
    requestAuth,
    request2FAVerification,
    logOut,
    resetFormError,
    changeLoggedInUserFirstName,
    changeLoggedInUserLastName,
    canAccess,
    updateUserSettings,
    resetUserColumnsSettings
  };
});
