<script setup>
const emit = defineEmits(['onRetry']);

function onRetryButtonClicked() {
  emit('onRetry');
}
</script>

<template>
  <div class="text-center animated fadeInDown">
    <h3 class="font-bold">Błąd serwera</h3>

    <div class="error-desc">
      Wystąpił błąd serwera, spróbuj ponownie naciskając przycisk poniżej.<br />
      <button class="btn btn-outline btn-primary m-t" @click="onRetryButtonClicked">
        Spróbuj ponownie
      </button>
    </div>
  </div>
</template>

<style></style>
